import React, { useCallback } from "react";

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  href: string;
};

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  isActive,
  href,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className="nav-item flex" role="presentation">
      <a
        href={"#" + href}
        className={`
      nav-link
      font-semibold
      text-center
      border-t-0 border-b-2
      md:px-6
      py-3 text-sm
      hover:border-neutral-500 hover:text-neutral-700
      focus:border-jonar-blue focus:text-jonar-blue
      ${isActive ? "border-jonar-blue border-b-2 text-jonar-blue" : ""}`}
        id={title}
        data-bs-toggle="pill"
        data-bs-target="#tabs-home"
        role="tab"
        aria-controls="tabs-home"
        aria-selected="true"
        onClick={onClick}
      >
        {title}
      </a>
    </li>
  );
};

export default TabTitle;
