import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import { UserProfileViewModel } from "@assets/types";
import Button from "@components/Button/button";
import { AppState } from "@state/reducers/types";
import { UserType } from "@assets/constants";

const OldServiceWeb = process.env.OldServiceWeb;

type ActionBarProps = {
  show: boolean;
  haveNotClearedCustoms: any;
  haveClearedCustoms: any;
  references: string[];
  userProfile: UserProfileViewModel;
  onClickCallback?: Function;
};

const ActionBar: React.FC<ActionBarProps> = ({
  userProfile,
  show,
  references,
  haveClearedCustoms,
  haveNotClearedCustoms,
  onClickCallback,
}) => {
  const NotAdminAndNotCleared: boolean =
    !(userProfile.Access.UserType === "E") && haveNotClearedCustoms.length > 0
      ? true
      : false;

  const onClickHandler = () => {
    window.open(
      `${OldServiceWeb}/${
        userProfile.Access.UserType == UserType.EMPLOYEE
          ? "DeliveryModule"
          : "CustomerDeliveryModule"
      }.aspx?&RequestType=DELIVREQ&BookNos=${
        NotAdminAndNotCleared ? haveClearedCustoms.join() : references.join()
      }`,
      "_blank",
      "location=yes,height=780,width=700,scrollbars=yes,status=yes"
    );
    onClickCallback && onClickCallback();
  };

  return (
    <Translation>
      {(t) => (
        <div
          className={`absolute bg-white bottom-0 md:bottom-5 left-0 right-0 z-100 md:border-2 md:rounded-3xl md:w-2/3 md:mx-auto ${
            show
              ? "animate-fade-in transition-visibility delay-0 visible"
              : "animate-fade-out transition-visibility delay-300 invisible"
          }`}
        >
          <div className="px-2 py-2 flex flex-row justify-around items-center">
            <div className="flex flex-col">
              <span className="text-lg">{`${references.length || 0} ${t(
                "TEXT_BOOKINGS_SELECTED",
                {
                  count: references.length,
                }
              )}`}</span>
              {NotAdminAndNotCleared === true ? (
                <span className="text-sm">
                  {haveClearedCustoms.length} {t("LABEL_CUSTOMSCLEARED")} -{" "}
                  {haveNotClearedCustoms.length} {t("LABEL_NOT_CUSTOMSCLEARED")}
                </span>
              ) : null}
              {NotAdminAndNotCleared === true ? (
                <span className="text-sm">
                  {t("TEXT_DELIVERY_CUSTOMS_NOT_CLEARED")}
                </span>
              ) : null}
            </div>
            <div>
              <Button className="mt-0" primary onClick={onClickHandler}>
                {t("LABEL_ACTION_DELIVREQ")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
  }),
  {}
)(ActionBar);
