import React, { PureComponent } from "react";
import { connect } from "react-redux";

import Modal from "@components/Modal/modal";
import Spinner from "@components/Spinner/spinner";

import { closeModal } from "@state/actions/index";

import { ModalProps } from "@assets/types";
import Iframe from "react-iframe";

const OldServiceWeb = process.env.OldServiceWeb;

class UserModal extends PureComponent<ModalProps, { loading: boolean }> {
  state = { loading: true };

  onClose = () => {
    this.setState({ loading: true });
    this.props.closeModal();
  };

  render() {
    const { open, header } = this.props;
    const { loading } = this.state;
    return (
      <Modal open={open} onClose={this.onClose} size="xlarge">
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content className="h-748">
          <Spinner isHidden={!loading} />
          <Iframe
            url={`${OldServiceWeb}/UserProfile.aspx?Iframe=1`}
            className="overflow-hidden iframe-main-container shadow-lg"
            width={loading ? "0%" : "100%"}
            height={loading ? "0%" : "100%"}
            onLoad={() => this.setState({ loading: false })}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  () => {
    return {};
  },
  { closeModal }
)(UserModal);
