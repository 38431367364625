import React, { useEffect, useState } from "react";
import HeaderPage from "@components/Page/headerPage";
import Page from "@components/Page/page";
import Tabs from "@components/Tab/tabs";
import Tab from "@components/Tab/tab";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Link from "@components/Link/link";
import CustomIcon from "@components/CustomIcon/customIcon";
import { BookingStatus, FreightMode } from "@assets/constants";
import BookingUtilities from "utilities/BookingUtilities";
import RouteTab from "./routeTab";
import { RouteBookingResponse } from "@assets/types";
import { useTranslation } from "react-i18next";
import DocumentsTab from "./documentsTab";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotesTab from "./notesTab";
import DropdownButton from "@components/DropdownButton/dropdownButton";
import RequestsTab from "./requestsTab";
import InvoicesTab from "./invoiceTab";
import LayoutCell from "@components/LayoutCell/layoutCell";
import CargoTab from "./cargoTab";
import PalletInfoTab from "./palletInfoTab";
import Divider from "@components/Divider/divider";
import moment from "moment";
import { openTabOrWindow } from "@assets/utilities/windowUtilities";
import UserService from "@services/UserService";
import SwitchCompanyModal from "@components/Modal/switchCompanyModal";
import {
  bookingAddressRequestToBookingAddress,
  routeBookingResponseToBooking,
} from "@assets/mappings";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { getUserProfileFromAPI, userState } from "@state/slices/userSlice";
import {
  getRouteBooking,
  routeBookingState,
} from "@state/slices/routeBookingSlice";
import {
  cargoBookingState,
  getBookingCargo,
} from "@state/slices/cargoBookingSlice";
import { bookingsState, getBookingActions } from "@state/slices/bookingsSlice";
import { getRequests } from "@state/slices/requestsSlice";
import { Tooltip } from "@samskip/frontend-components";

interface SingleBookingProps {
  path?: string;
  freightMode: string;
  jobReference: string;
  iframeUpdater: number;
}

type SwitchCompanyModal = {
  open: boolean;
  booking: RouteBookingResponse | null;
};

const SingleBooking: React.FC<SingleBookingProps> = (
  props: SingleBookingProps
) => {
  const icon = BookingUtilities.getBookingFreightModeIcon(
    FreightMode[props.freightMode]
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [switchCompanyModal, setSwitchCompanyModal] =
    useState<SwitchCompanyModal>({
      open: false,
      booking: null,
    });
  const [loadingSwitchCompany, setLoadingSwitchCompany] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const OldServiceWeb = process.env.OldServiceWeb;

  const userProfile = useAppSelector(userState).userProfile;
  const routeBooking = useAppSelector(routeBookingState).routeBooking;
  const cargoBooking = useAppSelector(cargoBookingState).cargoBooking;
  const bookingActions = useAppSelector(bookingsState).bookingActions;
  const loadingBookingActions =
    useAppSelector(bookingsState).loadingBookingActions;
  const loadingRouteBooking =
    useAppSelector(routeBookingState).loadingRouteBooking;
  const loadingCargoBooking =
    useAppSelector(cargoBookingState).loadingCargoBooking;

  useEffect(() => {
    dispatch(getRouteBooking(props.jobReference));
    dispatch(getBookingCargo(props.jobReference));
    // if we have the href (ex. "#Invoices") in the link, go to the respective tab.
    // Should be refactored to not hardcode the numbers
    Promise.all([dispatch(getUserProfileFromAPI()).unwrap()]).then(([user]) => {
      switch (window.location.href.split("#")[1]) {
        case "BookingDetails":
          setSelectedTab(0);
          break;
        case "Cargo":
          setSelectedTab(1);
          break;
        case "Documents":
          setSelectedTab(2);
          break;
        case "Invoices":
          setSelectedTab(3);
          break;
        case "Notes":
          user?.Access.UserType === "E" && setSelectedTab(4);
          break;
        case "Requests":
          setSelectedTab(5);
          break;
      }
    });
  }, []);

  useEffect(() => {
    // Need to wait for the routeBooking to complete, otherwise we always get the 0 status (when a booking can have another status) a
    // action within the action bar
    dispatch(
      getBookingActions({
        jobReference: props.jobReference,
        status: routeBooking?.Status ?? 0,
        isCustomsCleared: routeBooking?.CustomsStatus === "CC",
      })
    );
  }, [routeBooking]);

  // In the future, take this out.
  const forwardToOldWeb = (typeId: number) => {
    const url = bookingActions?.find((x) => x.TypeID == typeId)?.Site;
    if (url !== null) {
      window.open(
        `${OldServiceWeb}/${url}${props.jobReference}`,
        "_blank",
        "location=yes,height=780,width=700,scrollbars=yes,status=yes"
      );
    }
  };

  const openSwitchCompanyModal = (booking: RouteBookingResponse | null) => {
    const switchCompany = { ...switchCompanyModal };
    switchCompany.open = true;
    switchCompany.booking = booking;
    setSwitchCompanyModal(switchCompany);
  };

  const closeSwitchCompanyModal = () => {
    const switchCompany = { ...switchCompanyModal };
    switchCompany.open = false;
    setSwitchCompanyModal(switchCompany);
  };

  const handleSwitchCompany = async (partnerCode: string) => {
    setLoadingSwitchCompany(true);
    UserService.changeUserCompany(partnerCode)
      .then(() => {
        openTabOrWindow(
          `/shipments/${
            props.freightMode === FreightMode.AI
              ? "copycreateairfreightbooking"
              : "copycreateseafreightbooking"
          }/${props.jobReference}`
        );
      })
      .finally(() => {
        setLoadingSwitchCompany(false);
        closeSwitchCompanyModal();
        window.location.reload();
      });
  };

  const onHandleRequests = (id: number) => {
    if (bookingActions) {
      switch (bookingActions.find((x) => x.TypeID == id)?.TypeMap) {
        case "COPYBOOKING":
          {
            if (
              routeBooking?.Consignee?.PartnerCode ===
                userProfile?.SelectedCompany.PartnerCode ||
              routeBooking?.Shipper?.PartnerCode ===
                userProfile?.SelectedCompany.PartnerCode
            ) {
              openTabOrWindow(
                `/shipments/${
                  props.freightMode === FreightMode.AI
                    ? "copycreateairfreightbooking"
                    : "copycreateseafreightbooking"
                }/${props.jobReference}`
              );
            } else {
              openSwitchCompanyModal(routeBooking ? routeBooking : null);
            }
          }
          break;
        case "CORRECTION":
          forwardToOldWeb(id);
          break;
        case "DELIVREQ":
          forwardToOldWeb(id);
          break;
        case "DELIVREG":
          userProfile?.Access.UserType === "C" && forwardToOldWeb(id);
          break;
        case "CUSTOMSREG":
          forwardToOldWeb(id);
          break;
        case "CUSTOMSREQ":
          forwardToOldWeb(id);
          break;
        case "PAYMSTATUS":
          userProfile?.Access.UserType === "E" && forwardToOldWeb(id);
          break;
        case "INVOICES":
          setSelectedTab(3);
          break;
        case "WEBNOTE":
          userProfile?.Access.UserType === "E" && setSelectedTab(4);
          break;
        case "ARRDOC": //ArrivalNotice, future point to the document itself whichy is generated from gfs, instead of the OLD web
          userProfile?.Access.UserType === "E" && forwardToOldWeb(id);
          break;
        case "ALLDOCS":
          userProfile?.Access.UserType === "E" && setSelectedTab(2);
          break;
        case "INNERWEBNOTE":
          setSelectedTab(4);
          break;
        case "CUSTDELIVREQ":
          userProfile?.Access.UserType === "C" && forwardToOldWeb(id);
          break;
        case "BILLOFLADING":
          setSelectedTab(2);
          break;
        default:
          setSelectedTab(5);
          dispatch(
            getRequests({
              jobReference: props.jobReference,
              requestType: bookingActions[id]?.TypeMap,
            })
          );
          break;
      }
    }
  };

  const onHandleBuyersConsolidationState = (): string => {
    if (routeBooking?.HBLFlag) {
      return t("LABEL_BUYERS_CONSOLIDATION");
    }
    if (routeBooking?.HBLFlag == false && routeBooking.ShipReference != null) {
      return t("LABEL_BUYERS_CONSOLIDATION");
    }
    return "";
  };

  return (
    <main className="flex flex-col items-center mb-10">
      <SwitchCompanyModal
        userSelectedCompany={userProfile?.SelectedCompany ?? null}
        shipper={
          switchCompanyModal.booking?.Shipper
            ? bookingAddressRequestToBookingAddress(
                switchCompanyModal.booking?.Shipper
              )
            : null
        }
        consignee={
          switchCompanyModal.booking?.Consignee
            ? bookingAddressRequestToBookingAddress(
                switchCompanyModal.booking?.Consignee
              )
            : null
        }
        booking={
          switchCompanyModal.booking
            ? routeBookingResponseToBooking(switchCompanyModal.booking)
            : null
        }
        open={switchCompanyModal.open}
        closeModal={() => closeSwitchCompanyModal()}
        onSwitchCompany={(booking, partnerCode) =>
          handleSwitchCompany(partnerCode)
        }
        loadingSwitchCompany={loadingSwitchCompany}
      ></SwitchCompanyModal>
      <ToastContainer
        newestOnTop
        bodyClassName={() =>
          "my-auto mx-0 p-1.5 flex flex-auto items-center text-base text-neutral-900 font-semibold"
        }
      />
      <div className="w-375px md:w-1204px">
        <Link
          className="w-13 h-5 font-semibold text-sm my-8 flex text-jonar-blue"
          icon={faArrowLeft}
          href={"/shipments"}
        >
          {t("LABEL_BACK")}
        </Link>
        <HeaderPage
          className="top-14 my-8 gap-6 flex flex-col shadow-sm rounded"
          variantWidth="xl"
        >
          <div className="flex flex-row justify-between pt-4 px-8">
            <div className="flex flex-col">
              <div className="flex items-baseline">
                <h4 className="font-semibold">{props.jobReference}</h4>
                {icon && (
                  <div className="ml-2">
                    {icon === "iFlutningiSjor" ? (
                      <Tooltip content={`${t("LABEL_SEA_FREIGHT")}`}>
                        <CustomIcon
                          className="svg-dark text-lg"
                          iconName={icon}
                        />
                      </Tooltip>
                    ) : icon === "iFlutningiFlug" ? (
                      <Tooltip content={`${t("LABEL_AIR_FREIGHT")}`}>
                        <CustomIcon
                          className="svg-dark text-lg"
                          iconName={icon}
                        />
                      </Tooltip>
                    ) : (
                      <CustomIcon
                        className="svg-dark text-lg"
                        iconName={icon}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="text-neutral-700 font-normal text-sm">
                {onHandleBuyersConsolidationState()}
              </div>
            </div>
            <DropdownButton
              label={t("LABEL_BOOKING_ACTIONS")}
              onClick={onHandleRequests}
              options={
                bookingActions != null && bookingActions != undefined
                  ? bookingActions?.map((x) => {
                      return {
                        id: x.TypeID,
                        title: t(`LABEL_ACTION_${x.TypeMap}`),
                        type: x.TypeMap,
                      };
                    })
                  : []
              }
              type="primary"
              disabled={loadingBookingActions}
            />
          </div>
          <Divider />
          <div className="mx-8">
            <div className="flex flex-row mb-6">
              <LayoutCell
                id="singlebooking_booking_status"
                className="md:w-1/3 w-12/12"
                labelValue={t("LABEL_BOOKING_STATUS")}
              >
                <div className="text-neutral-9">
                  {routeBooking?.Status
                    ? t("BOOKINGSTATUS_" + BookingStatus[routeBooking?.Status])
                    : ""}
                </div>
              </LayoutCell>
              <LayoutCell
                id="singlebooking_customs_status"
                className="md:w-1/3 w-12/12"
                labelValue={t("LABEL_CUSTOMS_STATUS")}
              >
                <div className="text-neutral-9">
                  {routeBooking?.CustomsClearanceDate !== null &&
                  routeBooking?.CustomsStatus === "CC"
                    ? t("LABEL_CUSTOMSCLEARED") +
                      " " +
                      moment(routeBooking?.CustomsClearanceDate)
                        .format("DD MMM YYYY")
                        .toString()
                    : t("LABEL_NOT_CUSTOMSCLEARED")}
                </div>
              </LayoutCell>
              {props.freightMode === FreightMode.AI ? (
                <LayoutCell
                  id="singlebooking_mawb_number"
                  className="md:w-1/3 w-12/12"
                  labelValue={t("LABEL_MAWB_NUMBER")}
                >
                  <div className="text-neutral-9">
                    {routeBooking?.BOLNumber}
                  </div>
                </LayoutCell>
              ) : (
                ""
              )}
            </div>
          </div>
        </HeaderPage>
        <Tabs
          className="w-1204px text-sm"
          selectedTab={selectedTab}
          setSelectedTab={(tab) => {
            setSelectedTab(tab);
          }}
        >
          <Tab title={t("LABEL_BOOKING_DETAILS")} href={"BookingDetails"}>
            <Page className="gap-6 flex flex-col items-start shadow-sm rounded-sm md:w-1204px w-375px">
              <RouteTab
                routeBooking={routeBooking}
                freightMode={props.freightMode}
                isLoadingRoute={loadingRouteBooking}
              />
            </Page>
          </Tab>
          <Tab title={t("LABEL_CARGO")} href={"Cargo"}>
            <Page className="gap-6 flex flex-col items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
              <CargoTab
                cargoBooking={cargoBooking}
                freightMode={props.freightMode}
                isLoadingCargo={loadingCargoBooking}
              />
            </Page>
            {cargoBooking?.Pallets && cargoBooking?.Pallets?.length > 0 && (
              <Page className="gap-6 flex flex-col items-start shadow-sm rounded-sm md:w-1204px w-375px">
                <PalletInfoTab cargoBooking={cargoBooking} />
              </Page>
            )}
          </Tab>
          <Tab title={t("LABEL_DOCUMENTS")} href={"Documents"}>
            <DocumentsTab
              jobReference={props.jobReference}
              freightMode={props.freightMode}
              userProfile={userProfile}
              routeBooking={routeBooking}
            />
          </Tab>
          <Tab title={t("LABEL_INVOICES")} href={"Invoices"}>
            <InvoicesTab
              jobReference={props.jobReference}
              sapID={userProfile?.SelectedCompany?.SapID ?? ""}
            />
          </Tab>
          <Tab title={t("LABEL_NOTES")} href={"Notes"}>
            <NotesTab
              jobReference={props.jobReference}
              userProfile={userProfile}
            />
          </Tab>
          <Tab title={t("LABEL_REQUESTS")} href={"Requests"}>
            <RequestsTab jobReference={props.jobReference} />
          </Tab>
        </Tabs>
      </div>
    </main>
  );
};

export default SingleBooking;
