import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableInfo,
  TransactionFilter,
  UserProfileViewModel,
} from "@assets/types";
import "react-toastify/dist/ReactToastify.css";
import { FiX } from "react-icons/fi";
import ListFilterLayout from "@components/Layout/listFilterLayout";
import Tag from "@components/Tag/tag";
import Card from "@components/Card/card";
import Pagination from "@components/Pagination/pagination";
import Icon from "@components/CustomIcon/Icons";
import Divider from "@components/Divider/divider";
import Checkbox from "@components/Input/checkbox";
import Table from "@components/Table/table";
import ArrayUtilities from "@assets/utilities/arrayUtilities";
import SearchEx from "@components/Search/searchEx";
import Link from "@components/Link/link";
import ExpandableComponent from "@components/ExpendableComponent/expendableComponent";
import Group from "@components/Group/group";
import Alert from "@components/Alert/alert";
import { FaBan, FaInfoCircle, FaSpinner } from "react-icons/fa";
import { format, subMonths } from "date-fns";
import LabelEx from "@components/Label/labelEx";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import StringUtilities from "utilities/StringUtilities";
import {
  CompanyFilterTypes,
  DateRangeOptions,
  InvoiceType,
} from "@assets/constants";
import IconButton from "@components/Button/iconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DateRangePicker,
  FocusedInputShape,
  isInclusivelyBeforeDay,
} from "react-dates";
import moment from "moment";
import { toUpper } from "lodash";
import Select from "@components/Select/select";
import stylesShipments from "@pages/Shipments/shipments.module.less";
import stylesDatePicker from "@components/Input/reactDateOverrides.module.less";
import Button from "@components/Button/button";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { getUserProfileFromAPI, userState } from "@state/slices/userSlice";
import {
  customerState,
  getCustomerStatus,
  getCustomerStatusByDate,
} from "@state/slices/customerSlice";
import {
  filterTransactions,
  filterTransactionsExcel,
  filterTransactionsState,
} from "@state/slices/transactionsSlice";

interface FinanceProps {
  path?: string;
}

const Finance: React.FC<FinanceProps> = (props: FinanceProps) => {
  const { t } = useTranslation();
  let PageSize = 10;
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector(userState).userProfile;
  const customerStatus = useAppSelector(customerState).customerStatus;
  const customerStatusByDate =
    useAppSelector(customerState).customerStatusByDate;
  const filteredTransactions = useAppSelector(
    filterTransactionsState
  ).filterTransactions;
  const loadingCustomerStatus =
    useAppSelector(customerState).loadingCustomerStatus;
  const loadingCustomerStatusByDate =
    useAppSelector(customerState).loadingCustomerStatusByDate;
  const loadingFilteredTransactions = useAppSelector(
    filterTransactionsState
  ).loadingFilteredTransactions;

  let initialFilterInvoicesState: TransactionFilter = {
    SapIDs: [userProfile?.SelectedCompany?.SapID ?? ""],
    InvoiceTypes: [],
    SearchString: "",
    DateFrom: subMonths(new Date(), 1),
    DateTo: new Date(),
  };

  useEffect(() => {
    Promise.all([dispatch(getUserProfileFromAPI()).unwrap()]).then(([user]) => {
      initialFilterInvoicesState = {
        SapIDs: [user?.SelectedCompany?.SapID ?? ""],
        InvoiceTypes: [],
        SearchString: "",
        DateFrom: subMonths(new Date(), 1),
        DateTo: new Date(),
      };
      resetStatus(user);
    });
  }, []);

  const dateRangeOptions = [
    {
      value: DateRangeOptions.LAST_WEEK,
      translationId: `LABEL_${DateRangeOptions.LAST_WEEK}`,
      text: "",
    },
    {
      value: DateRangeOptions.LAST_TWO_WEEKS,
      translationId: `LABEL_${DateRangeOptions.LAST_TWO_WEEKS}`,
      text: "",
    },
    {
      value: DateRangeOptions.LAST_MONTH,
      translationId: `LABEL_${DateRangeOptions.LAST_MONTH}`,
      text: "",
    },
    {
      value: DateRangeOptions.LAST_THREE_MONTHS,
      translationId: `LABEL_${DateRangeOptions.LAST_THREE_MONTHS}`,
      text: "",
    },
    {
      value: DateRangeOptions.LAST_YEAR,
      translationId: `LABEL_${DateRangeOptions.LAST_YEAR}`,
      text: "",
    },
    {
      value: DateRangeOptions.CUSTOM_DATE,
      translationId: `LABEL_${DateRangeOptions.CUSTOM_DATE}`,
      text: "",
    },
  ];

  const [currentPage, setCurrentPage] = useState<string | number | any>(1);
  const [headerFilter, setHeaderFilter] = useState<
    string | number | any | null
  >(null);
  const [sortType, setSortType] = useState(false);

  const [companyFilter, setCompanyFilter] = useState<CompanyFilterTypes>(
    CompanyFilterTypes.SelectedCompany
  );
  const [isCustomsClearance, setIsCustomsClearance] = useState<boolean>(false);
  const [isStorage, setIsStorage] = useState<boolean>(false);
  const [isTransport, setIsTransport] = useState<boolean>(false);
  const [invoicesTypes, setInvoicesTypes] = useState<string[]>(
    initialFilterInvoicesState.InvoiceTypes
  );
  const [invoiceNumber, setInvoiceNumber] = useState<string>(
    initialFilterInvoicesState.SearchString
  );
  const [sapIds, setSapIds] = useState<string[]>(
    initialFilterInvoicesState.SapIDs
  );
  const [startDate, setStartDate] = useState<Date | null>(
    initialFilterInvoicesState.DateFrom
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialFilterInvoicesState.DateTo
  );
  const [mobileFilter, setMobilefilter] = useState<boolean>(false);

  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState<
    string | null
  >(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const resetStatus = (user: UserProfileViewModel | null) => {
    setCurrentPage(1);
    setHeaderFilter(null);
    setCompanyFilter(CompanyFilterTypes.SelectedCompany);
    setIsCustomsClearance(false);
    setIsStorage(false);
    setIsTransport(false);
    setInvoicesTypes(initialFilterInvoicesState.InvoiceTypes);
    setInvoiceNumber(initialFilterInvoicesState.SearchString);
    setStartDate(initialFilterInvoicesState.DateFrom);
    setEndDate(initialFilterInvoicesState.DateTo);
    setSapIds(initialFilterInvoicesState.SapIDs);
    setSelectedDateRangeOption(DateRangeOptions.CUSTOM_DATE);
    setFocusedInput(null);
    setSortType(false);

    dispatch(getCustomerStatus(user?.SelectedCompany.SapID ?? ""));
    dispatch(
      getCustomerStatusByDate({
        sapId: user?.SelectedCompany.SapID ?? "",
        dateFrom: initialFilterInvoicesState.DateFrom,
        dateTo: initialFilterInvoicesState.DateTo,
      })
    );
    dispatch(filterTransactions(initialFilterInvoicesState));
  };

  let currentTableData = useMemo(() => {
    if (filteredTransactions) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;

      let invoiceData = [...filteredTransactions];

      if (sortType) {
        invoiceData = ArrayUtilities.sortAlphabeticallyAscending(
          headerFilter,
          filteredTransactions.slice()
        );
      } else {
        invoiceData = ArrayUtilities.sortAlphabeticallyDescending(
          headerFilter,
          filteredTransactions.slice()
        );
      }

      return invoiceData.slice(firstPageIndex, lastPageIndex);
    }
    return [];
  }, [currentPage, headerFilter, sortType, loadingFilteredTransactions]);

  const onSortTable = (head: string) => {
    let tableHead: string = "";
    switch (head) {
      case t("LABEL_INVOICE"):
        tableHead = "InvoiceNumberField";
        break;
      case t("LABEL_TYPE"):
        tableHead = "InvoiceTypeLabel";
        break;
      case t("LABEL_DATE"):
        tableHead = "DueDateField";
        break;
      case t("LABEL_AMOUNT"):
        tableHead = "AmountField";
        break;
      case t("LABEL_DOCUMENT_NUMBER"):
        tableHead = "SapDocumentNumberField";
        break;
      case t("LABEL_COMPANY"):
        tableHead = "CompanyCodeField";
        break;
      case t("LABEL_STATUS"):
        tableHead = "Status";
        break;
    }
    setHeaderFilter(tableHead);
    setSortType(!sortType);
  };

  const onChangeCompanyStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyFilter(
      e.target.value === CompanyFilterTypes.SelectedCompany
        ? CompanyFilterTypes.SelectedCompany
        : CompanyFilterTypes.AllCompanies
    );
    let sapIds: string[] = [];
    if (e.target.value === CompanyFilterTypes.SelectedCompany) {
      sapIds.push(userProfile?.SelectedCompany?.SapID ?? "");
    } else {
      userProfile?.Companies.forEach((company) => {
        sapIds.push(company.SapID);
      });
    }

    setSapIds(sapIds);
    dispatch(
      filterTransactions({
        SapIDs: sapIds,
        InvoiceTypes: invoicesTypes,
        SearchString: invoiceNumber,
        DateFrom: startDate ?? subMonths(new Date(), 1),
        DateTo: endDate ?? new Date(),
      })
    );
  };

  const handleDateRangeSelect = (value: string): any => {
    switch (value) {
      case DateRangeOptions.INIT: {
        setSelectedDateRangeOption(DateRangeOptions.INIT);
        handleDateChange(moment().subtract(4, "weeks"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.LAST_WEEK: {
        setSelectedDateRangeOption(DateRangeOptions.LAST_WEEK);
        handleDateChange(moment().subtract(1, "weeks"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.LAST_TWO_WEEKS: {
        setSelectedDateRangeOption(DateRangeOptions.LAST_TWO_WEEKS);
        handleDateChange(moment().subtract(2, "weeks"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.LAST_MONTH: {
        setSelectedDateRangeOption(DateRangeOptions.LAST_MONTH);
        handleDateChange(moment().subtract(1, "months"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.LAST_THREE_MONTHS: {
        setSelectedDateRangeOption(DateRangeOptions.LAST_THREE_MONTHS);
        handleDateChange(moment().subtract(3, "months"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.LAST_YEAR: {
        setSelectedDateRangeOption(DateRangeOptions.LAST_YEAR);
        handleDateChange(moment().subtract(1, "years"), moment());
        return selectedDateRangeOption;
      }
      case DateRangeOptions.CUSTOM_DATE: {
        setSelectedDateRangeOption(DateRangeOptions.CUSTOM_DATE);
        return selectedDateRangeOption;
      }
      default:
        return;
    }
  };

  const handleDateChange = (
    dateStart: moment.Moment | null,
    dateEnd: moment.Moment | null,
    shouldRender = true,
    revertToCustom = false
  ) => {
    if (revertToCustom) {
      setSelectedDateRangeOption(DateRangeOptions.CUSTOM_DATE);
    }
    const start = dateStart?.toDate() ?? null;
    const end = dateEnd?.toDate() ?? null;
    if (
      (dateEnd &&
        dateEnd.format("DD, MM, YYYY") !=
          moment(endDate).format("DD, MM, YYYY")) ||
      shouldRender
    ) {
      if (start && end) {
        dispatch(
          getCustomerStatusByDate({
            sapId: userProfile?.SelectedCompany.SapID ?? "",
            dateFrom: start,
            dateTo: end,
          })
        );
        dispatch(
          filterTransactions({
            SapIDs: sapIds,
            InvoiceTypes: invoicesTypes,
            SearchString: invoiceNumber,
            DateFrom: start,
            DateTo: end,
          })
        );
      }
    }

    setStartDate(start);
    setEndDate(end);
  };

  const renderYearOptions = () => {
    const optionsArray = [];
    for (let x = 0; x < 5; x++) {
      optionsArray[x] = (
        <option value={moment().year() - x + 1}>
          {moment().year() - x + 1}
        </option>
      );
    }
    return optionsArray;
  };

  const handleAddInvoiceType = (invoiceType: string): string[] => {
    let newInvoicesTypes: string[] = [];
    newInvoicesTypes = [...invoicesTypes, invoiceType];
    setInvoicesTypes(newInvoicesTypes);
    return newInvoicesTypes;
  };

  const handleRemoveInvoiceType = (invoiceType: string): string[] => {
    let newInvoicesTypes = [...invoicesTypes];
    newInvoicesTypes.splice(invoicesTypes.indexOf(invoiceType), 1);
    setInvoicesTypes(newInvoicesTypes);
    return newInvoicesTypes;
  };

  const onChangeCustomsClearance = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newInvoicesType: string[] = [];
    setIsCustomsClearance((oldVal) => !oldVal);
    if (e.target.checked) {
      newInvoicesType = handleAddInvoiceType(InvoiceType.Customs);
    } else {
      newInvoicesType = handleRemoveInvoiceType(InvoiceType.Customs);
    }
    dispatch(
      filterTransactions({
        SapIDs: sapIds,
        InvoiceTypes: newInvoicesType,
        SearchString: invoiceNumber,
        DateFrom: startDate ?? subMonths(new Date(), 1),
        DateTo: endDate ?? new Date(),
      })
    );
  };

  const onChangeStorage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newInvoicesType: string[] = [];
    setIsStorage((oldVal) => !oldVal);
    if (e.target.checked) {
      newInvoicesType = handleAddInvoiceType(InvoiceType.Storage);
    } else {
      newInvoicesType = handleRemoveInvoiceType(InvoiceType.Storage);
    }
    dispatch(
      filterTransactions({
        SapIDs: sapIds,
        InvoiceTypes: newInvoicesType,
        SearchString: invoiceNumber,
        DateFrom: startDate ?? subMonths(new Date(), 1),
        DateTo: endDate ?? new Date(),
      })
    );
  };

  const onChangeTransport = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newInvoicesType: string[] = [];
    setIsTransport((oldVal) => !oldVal);
    if (e.target.checked) {
      newInvoicesType = handleAddInvoiceType(InvoiceType.Transport);
    } else {
      newInvoicesType = handleRemoveInvoiceType(InvoiceType.Transport);
    }
    dispatch(
      filterTransactions({
        SapIDs: sapIds,
        InvoiceTypes: newInvoicesType,
        SearchString: invoiceNumber,
        DateFrom: startDate ?? subMonths(new Date(), 1),
        DateTo: endDate ?? new Date(),
      })
    );
  };

  const onChangeInvoiceNumber = (value: string) => {
    setInvoiceNumber(value);
    if (value.length > 5) {
      dispatch(
        filterTransactions({
          SapIDs: sapIds,
          InvoiceTypes: invoicesTypes,
          SearchString: value,
          DateFrom: startDate ?? subMonths(new Date(), 1),
          DateTo: endDate ?? new Date(),
        })
      );
    }
  };

  // TODO: Implement this when open multiple invoices is ready
  // const onHandleCheckBox = (checked: boolean, index: number) => {
  //   // insert here implementation for table checkbox
  // };

  const getInvoiceStatusTag = (status: string) => {
    if (status === "Deposit") {
      return (
        <Tag size="small" color="success" isFaded>
          {t("LABEL_DEPOSIT")}
        </Tag>
      );
    }
    if (status === "Unpaid") {
      return (
        <Tag size="small" color="warning" isFaded>
          {t("LABEL_UNPAID")}
        </Tag>
      );
    }
    if (status === "Paid") {
      return (
        <Tag size="small" color="success" isFaded>
          {t("LABEL_PAID")}
        </Tag>
      );
    }
    if (status === "Credit") {
      return (
        <Tag size="small" color="primary">
          {t("LABEL_CREDIT")}
        </Tag>
      );
    }
    return;
  };

  const invoiceDataTable: TableInfo = {
    tableHeader: [
      // TODO: Implement this when open multiple invoices is ready
      // <Checkbox type="checkbox" value="" onChange={() => {}} />,
      t("LABEL_STATUS"),
      t("LABEL_INVOICE"),
      companyFilter === CompanyFilterTypes.AllCompanies
        ? t("LABEL_COMPANY")
        : undefined,
      t("LABEL_TYPE"),
      t("LABEL_DATE"),
      t("LABEL_AMOUNT"),
      t("LABEL_DOCUMENT_NUMBER"),
    ],
    tableData:
      currentTableData.length > 0
        ? currentTableData.map((invoiceData, index: number) => {
            return [
              // TODO: Implement this when open multiple invoices is ready
              // <Checkbox
              //   type="checkbox"
              //   value=""
              //   onChange={(event) =>
              //     onHandleCheckBox(event.target.checked, index)
              //   }
              // />,
              getInvoiceStatusTag(invoiceData.Status ?? ""),
              <Link
                key={`view_invoice-${invoiceData.InvoiceNumberField}`}
                className="text-jonar-blue"
                href={invoiceData.InvoiceUrl ?? ""}
                target="_blank"
              >
                {t(invoiceData?.InvoiceNumberField ?? "")}
              </Link>,
              companyFilter === CompanyFilterTypes.AllCompanies
                ? invoiceData?.CompanyCodeField
                : null,
              t(`${invoiceData?.InvoiceTypeLabel}`),
              invoiceData?.DueDateField
                ? format(new Date(invoiceData?.DueDateField), "dd.MM.yyyy")
                : "",
              StringUtilities.numberCommaDecimal(
                parseInt(invoiceData?.AmountField ?? "")
              ),
              invoiceData?.SapDocumentNumberField,
            ];
          })
        : [],
    classes: [
      "",
      "",
      companyFilter === CompanyFilterTypes.AllCompanies ? "" : "hidden",
    ],
  };

  return (
    <main
      className={`overflow-hidden static h-auto w-full mx-auto flex content-center flex-grow flex-col`}
    >
      <ListFilterLayout mobileFilter={false}>
        <>
          <div className="listFilterLayout__content relative w-auto lg:w-full flex-1 p-6 h-auto overflow-auto">
            <div className="listFilterLayout__content__header flex justify-between md:block mr-5">
              <div className="listFilterLayout__content__header__upper flex justify-between">
                <div>
                  <Icon
                    className="inline-block h-8 w-auto mr-2 stroke-current"
                    name="jonarArrow"
                  />
                  <h1 className="inline-block text-1xl font-bold text-jonar-blue uppercase leading-7 align-bottom">
                    {t("LABEL_FINANCE")}
                  </h1>
                </div>
              </div>
              <Divider className="mt-6" />
              <div className="listFilterLayout__content__header__lower pr-3 lg:pr-0 cursor-pointer flex justify-between items-center mb-1 py-2"></div>
            </div>
            <div className="mr-6">
              {companyFilter === CompanyFilterTypes.SelectedCompany && (
                <>
                  {customerStatus && customerStatus[0]?.Blocked && (
                    <Alert
                      className="py-2 px-2 mb-6 text-base flex items-start w-full"
                      color="error"
                    >
                      <FaBan className={`absolut text-error mr-2 mt-1 w-10`} />
                      {t("TEXT_ACCOUNT_CLOSED")}
                    </Alert>
                  )}
                  <ExpandableComponent
                    className="ml-8"
                    expandedTitle={t("LABEL_HIDE")}
                    closedTitle={t("LABEL_SHOW")}
                    fixedComponent={t("LABEL_FINANCIAL_STATUS")}
                    position="right"
                    isExpanded={true}
                  >
                    <div className="flex gap-8 mt-8 mb-4">
                      <Card className="px-6 py-4 gap-1 box-border flex flex-col items-start min-w-232 h-28 bg-neutral-100 rounded border-neutral-100">
                        {loadingCustomerStatus ||
                        loadingCustomerStatusByDate ||
                        loadingFilteredTransactions ? (
                          <FaSpinner className="fa-spin flex w-full h-1/3 mt-auto mb-auto" />
                        ) : (
                          <>
                            <LabelEx
                              labelCase="upper"
                              className="text-3.25 font-semibold"
                              labelColor="neutral-800"
                            >
                              {t("LABEL_TOTAL_BALANCE")}
                            </LabelEx>
                            <div className="text-neutral-900 font-semibold text-lg">
                              {`${
                                customerStatus &&
                                customerStatus[0]?.TotalBalance
                                  ? t(
                                      StringUtilities.numberCommaDecimal(
                                        customerStatus[0].TotalBalance,
                                        0
                                      )
                                    )
                                  : ""
                              }
                            ${
                              customerStatus && customerStatus[0]?.Currency
                                ? t(customerStatus[0]?.Currency)
                                : ""
                            }`}
                            </div>
                          </>
                        )}
                      </Card>
                      <Card className="px-6 py-4 gap-1 box-border flex flex-col items-start min-w-232 h-28 bg-neutral-100 rounded border-neutral-100">
                        {loadingCustomerStatus ||
                        loadingCustomerStatusByDate ||
                        loadingFilteredTransactions ? (
                          <FaSpinner className="fa-spin flex w-full h-1/3 mt-auto mb-auto" />
                        ) : (
                          <>
                            <LabelEx
                              labelCase="upper"
                              className="text-3.25 font-semibold"
                              labelColor="neutral-800"
                            >
                              {t("LABEL_MAXIMUM_WITHDRAWAL")}
                            </LabelEx>
                            <div className="text-neutral-900 font-semibold text-lg">
                              {`${
                                customerStatus &&
                                customerStatus[0]?.MaximumWithdrawal
                                  ? t(
                                      StringUtilities.numberCommaDecimal(
                                        customerStatus[0].MaximumWithdrawal,
                                        0
                                      )
                                    )
                                  : ""
                              } ${
                                customerStatus && customerStatus[0]?.Currency
                                  ? t(customerStatus[0]?.Currency)
                                  : ""
                              }`}
                            </div>
                          </>
                        )}
                      </Card>
                      <Card className="px-6 py-4 gap-1 box-border flex flex-col items-start min-w-232 h-28 bg-neutral-100 rounded border-neutral-100 mr-6">
                        {loadingCustomerStatus ||
                        loadingCustomerStatusByDate ||
                        loadingFilteredTransactions ? (
                          <FaSpinner className="fa-spin flex w-full h-1/3 mt-auto mb-auto" />
                        ) : (
                          <>
                            <LabelEx
                              labelCase="upper"
                              className="text-3.25 font-semibold"
                              labelColor="neutral-800"
                            >
                              {t("LABEL_BALANCE_OVER_SELECTED_PERIOD")}
                            </LabelEx>
                            {customerStatusByDate &&
                            customerStatusByDate[0]?.KeyBalanceFrom &&
                            customerStatusByDate[0]?.KeyBalanceFrom?.Balance &&
                            customerStatusByDate[0]?.KeyBalanceTo &&
                            customerStatusByDate[0]?.KeyBalanceTo?.Balance ? (
                              <div className="text-neutral-900 font-normal text-sm">
                                <div>
                                  {`${t("TEXT_START")}
                              ${" ("}
                              ${startDate && format(startDate, "dd.MM.yyyy")}
                              ${"):"}
                              ${t(
                                StringUtilities.numberCommaDecimal(
                                  customerStatusByDate[0].KeyBalanceFrom
                                    .Balance,
                                  0
                                )
                              )}
                              ${
                                customerStatusByDate[0]?.KeyBalanceFrom
                                  ?.Currency
                                  ? t(
                                      customerStatusByDate[0].KeyBalanceFrom
                                        ?.Currency
                                    )
                                  : ""
                              }`}
                                </div>
                                <div>
                                  {`${t("TEXT_END")}
                              ${"("}
                              ${endDate && format(endDate, "dd.MM.yyyy")}
                              ${"):"}
                              ${t(
                                StringUtilities.numberCommaDecimal(
                                  customerStatusByDate[0].KeyBalanceTo.Balance,
                                  0
                                )
                              )}
                              ${
                                customerStatusByDate[0]?.KeyBalanceTo?.Currency
                                  ? t(
                                      customerStatusByDate[0].KeyBalanceTo
                                        ?.Currency
                                    )
                                  : ""
                              }`}
                                </div>
                              </div>
                            ) : (
                              <div className="text-neutral-900 font-semibold text-lg">
                                {t("TEXT_NOT_AVAILABLE")}
                              </div>
                            )}
                          </>
                        )}
                      </Card>
                    </div>
                    <div className="flex gap-2 mb-8">
                      <LabelEx
                        className="font-semibold text-sm"
                        labelColor="neutral-800"
                      >
                        {t("LABEL_PAYMENT_TERMS") + ":"}
                      </LabelEx>
                      <span className="text-neutral-900">
                        {customerStatus && customerStatus[0]?.CashPayment
                          ? t("TEXT_PREPAYMENTS")
                          : t("TEXT_ON_CREDIT")}
                      </span>
                    </div>
                    <Divider className="mr-6" />
                  </ExpandableComponent>
                </>
              )}

              {companyFilter === CompanyFilterTypes.AllCompanies && (
                <Alert className="py-2 px-2 mb-6 text-base flex items-start w-full">
                  <FaInfoCircle
                    className={`absolut text-info mr-2 mt-1 w-10`}
                  />
                  {t("TEXT_FINANCE_STATUS")}
                </Alert>
              )}

              <div className="mt-8 flex justify-between">
                <SearchEx
                  id={"invoiceNumber"}
                  className="mb-4"
                  value={invoiceNumber}
                  placeholder={t("PLACEHOLDER_SEARCH_INVOICE_NUMBER")}
                  onChange={(e) => onChangeInvoiceNumber(e.target.value)}
                  onClear={() =>
                    onChangeInvoiceNumber(
                      initialFilterInvoicesState.SearchString
                    )
                  }
                  isClearable={!!invoiceNumber}
                />
                <Button
                  className="flex rounded float-right mr-6"
                  onClick={() => {
                    dispatch(
                      filterTransactionsExcel({
                        SapIDs: sapIds,
                        InvoiceTypes: invoicesTypes,
                        SearchString: invoiceNumber,
                        DateFrom: startDate ?? subMonths(new Date(), 1),
                        DateTo: endDate ?? new Date(),
                      })
                    );
                  }}
                >
                  {t("LABEL_EXCEL_EXPORT")}
                </Button>
              </div>
              <div className="mb-6 text-neutral-600">
                {`${
                  filteredTransactions
                    ? `${t("TEXT_N_INVOICES_FOUND", {
                        count: filteredTransactions?.length,
                      })}`
                    : `${t("TEXT_NO_INVOICES_FOUND")}`
                }`}
              </div>
              {filteredTransactions && filteredTransactions?.length > 0 && (
                <>
                  <div className="py-8 pr-6">
                    <Table
                      className="overflow-auto block whitespace-nowrap table-auto"
                      tableInfo={invoiceDataTable}
                      shouldFade={true}
                      onClickHeader={(head) => onSortTable(head)}
                      isStriped
                      isBordered
                    />
                  </div>
                  <Pagination
                    className="w-auto flex"
                    currentPage={currentPage}
                    totalCount={filteredTransactions?.length ?? 0}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </>
              )}
            </div>
          </div>
          <div
            id="dpFilterPanel"
            className={`listFilterLayout__filter lg:flex-initial bg-lighter-gray p-5 absolute xl:relative z-20 right-0 hidden xl:block ${
              mobileFilter
                ? "showFilter animate-fade-in min-h-full h-auto xl:h-auto"
                : ""
            }`}
            onClick={() => setMobilefilter(false)}
          >
            <button
              className="absolute transition duration-300 top-4r inline-block xl:hidden right-5r bg-lighter-gray border border-current text-jonar-blue hover:bg-jonar-blue hover:text-white p-1"
              onClick={() => setMobilefilter(false)}
            >
              <FiX className="text-xl" />
            </button>
            {userProfile && userProfile?.Companies?.length > 0 && (
              <>
                <Group title={t("LABEL_FINANCIAL_INFORMATION")} direction="col">
                  <Checkbox
                    className="pb-1"
                    label={t("LABEL_CHOSEN_COMPANY")}
                    type="radio"
                    checked={
                      companyFilter === CompanyFilterTypes.SelectedCompany
                    }
                    name={"company"}
                    value={CompanyFilterTypes.SelectedCompany}
                    onChange={onChangeCompanyStatus}
                  />
                  <Checkbox
                    className="pb-1"
                    label={t("LABEL_MY_COMPANIES")}
                    type="radio"
                    checked={companyFilter === CompanyFilterTypes.AllCompanies}
                    name={"company"}
                    value={CompanyFilterTypes.AllCompanies}
                    onChange={onChangeCompanyStatus}
                  />
                </Group>
                <Divider className="mt-6 pt-6" />
              </>
            )}

            <Group
              title={t("LABEL_FILTER_SEARCH")}
              direction="col"
              actions={
                <IconButton
                  className="absolute right-2"
                  onClick={() => resetStatus(userProfile)}
                  active={false}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  <span className="pl-2 font-semibold">
                    {t("LABEL_CLEAR_FILTERS")}
                  </span>
                </IconButton>
              }
            >
              <LabelEx
                labelCase="upper"
                className="text-sm font-semibold mt-2"
                labelColor="neutral-800"
              >
                {t("LABEL_DATE_RANGE")}
              </LabelEx>
              <div className="datePickerContainerFilter flex border border-base rounded h-30px">
                <div
                  className={`w-3/5 bg-white border-transparent focus:border-transparent focus:ring-0 ${stylesDatePicker.dateRangePickerOver}`}
                >
                  <DateRangePicker
                    startDate={moment(startDate)} // momentPropTypes.momentObj or null,
                    startDateId="timePeriodStartDate" // PropTypes.string.isRequired,
                    endDate={moment(endDate)} // momentPropTypes.momentObj or null,
                    endDateId="timePeriodEndDate" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) =>
                      handleDateChange(startDate, endDate, false, true)
                    } // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    } // PropTypes.func.isRequired,
                    isOutsideRange={(day) =>
                      !isInclusivelyBeforeDay(day, moment().add(1, "years"))
                    }
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat="DD.MM.YYYY"
                    renderMonthElement={({
                      month,
                      onMonthSelect,
                      onYearSelect,
                    }) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <select
                            className="border-transparent focus:border-transparent focus:ring-0"
                            value={month.month()}
                            onChange={(e) => {
                              onMonthSelect(month, e.target.value);
                            }}
                          >
                            {moment.months().map((label, value) => (
                              <option value={value} key={value}>
                                {t("LABEL_MONTH_" + toUpper(label))}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                          <select
                            className="border-transparent focus:border-transparent focus:ring-0"
                            value={month.year()}
                            onChange={(e) => {
                              onYearSelect(month, e.target.value);
                            }}
                          >
                            {renderYearOptions()}
                          </select>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="w-2/5">
                  <Select
                    className={`w-full text-sm rounded-none date-range-selector ${stylesShipments.dateRangeSelector}`}
                    name="sortBy"
                    sortDirection={false}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      handleDateRangeSelect(event.target.value)
                    }
                    options={dateRangeOptions}
                    value={selectedDateRangeOption ?? ""}
                  />
                </div>
              </div>
            </Group>
            <Divider className="mt-6 pt-6" />
            <Group title={t("LABEL_INVOICE_TYPE")} direction="col">
              <Checkbox
                className="pb-1"
                label={t("LABEL_CUSTOMS_CLEARANCE")}
                type="checkbox"
                checked={isCustomsClearance}
                onChange={onChangeCustomsClearance}
              />
              <Checkbox
                className="pb-1"
                label={t("LABEL_STORAGE")}
                type="checkbox"
                checked={isStorage}
                onChange={onChangeStorage}
              />
              <Checkbox
                className="pb-1"
                label={t("LABEL_TRANSPORT")}
                type="checkbox"
                checked={isTransport}
                onChange={onChangeTransport}
              />
            </Group>
          </div>
          <div className="fixed md:absolute z-40 bottom-0 left-0 md:left-auto md:bottom-auto md:top-0 md:right-0 w-full md:w-auto listFilterLayout__filter-btn block xl:hidden md:px-5 md:py-3 leading-none">
            <button
              className="px-4 py-5 md:pt-3 pb-4 md:pb-2 w-full md:w-auto bg-lighter-gray uppercase focus:outline-none leading-none"
              onClick={() => setMobilefilter(!mobileFilter)}
            >
              <span className="font-bold">
                {!mobileFilter ? t("LABEL_FILTER_SEARCH") : t("LABEL_HIDE")}
              </span>
            </button>
          </div>
        </>
      </ListFilterLayout>
    </main>
  );
};

export default Finance;
