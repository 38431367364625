import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M11.51,22.68l-6.14-6.14l-4.11,4.11l10.24,10.24L29.5,12.91L25.39,8.8L11.51,22.68z M35.73,20.19h6.45
                c0.54,0,1.04,0.27,1.34,0.71l2.77,4.98h0.81c0.91,0,1.62,0.6,1.62,1.79v5.51c0,0.9-0.73,1.63-1.62,1.63h-1.62 M33,14.5h2.73v20.31
                H23.55 M17.05,34.81h-6.5 M45.48,35.15c0,1.79-1.46,3.24-3.26,3.24c-1.8,0-3.26-1.45-3.26-3.24s1.46-3.24,3.26-3.24
                C44.03,31.9,45.48,33.36,45.48,35.15z M23.56,35.63c0,1.79-1.46,3.24-3.26,3.24c-1.8,0-3.26-1.45-3.26-3.24
                c0-1.79,1.46-3.24,3.26-3.24C22.11,32.39,23.56,33.84,23.56,35.63z M35.73,34.81h3.25 M38.98,26.69h4.06v-0.81l-1.62-2.44"
      />
    </g>
  </svg>
);

export default SVG;
