import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans, Translation } from "react-i18next";

import TableCard from "@components/Card/tableCard";
import MapCard from "@components/Card/mapCard";
import ContactButton from "@components/Button/contactButton";

import {
  TableInfo,
  UserProfileViewModel,
  ActiveRequest,
  ActiveBooking,
  Currency,
} from "@assets/types";
import {
  BookingStatus,
  UserRole,
  FreightMode,
  UserType,
} from "@assets/constants";
import { AppState } from "@state/reducers/types";
import {
  fetchActiveAirFreightBookings,
  fetchActiveSeaFreightBookings,
  fetchActiveRequests,
  fetchLatestCurrencies,
} from "@state/actions/index";

import "currency-flags/dist/currency-flags.min.css";

import AuthService from "@services/AuthService";

import { titlePrefix } from "@assets/constants";
import i18n from "i18next";

import styles from "./master.module.less";
import { FiRefreshCcw } from "react-icons/fi";
import Link from "@components/Link/link";
import { FaSpinner } from "react-icons/fa";
import {
  navigatePage,
  openTabOrWindow,
} from "@assets/utilities/windowUtilities";

const OldServiceWeb = process.env.OldServiceWeb;
class Master extends Component<
  {
    path?: string;
    fetchActiveAirFreightBookings: Function;
    fetchActiveSeaFreightBookings: Function;
    fetchActiveRequests: Function;
    fetchLatestCurrencies: Function;
    userProfile: UserProfileViewModel;
    activeRequests: ActiveRequest[];
    loadingRequests: boolean;
    activeSeaFreightBookings: ActiveBooking[];
    loadingSeaFreightBookings: boolean;
    activeAirFreightBookings: ActiveBooking[];
    loadingAirFreightBookings: boolean;
    latestCurrencies: Currency[];
    loadingCurrencies: boolean;
  },
  {}
> {
  async componentDidMount() {
    document.title = titlePrefix + i18n.t("MENU_HOME");
    await this.props.fetchActiveAirFreightBookings(
      this.props.userProfile.SelectedCompany.PartnerCode
    );
    await this.props.fetchActiveSeaFreightBookings(
      this.props.userProfile.SelectedCompany.PartnerCode
    );
    await this.props.fetchActiveRequests(
      this.props.userProfile.SelectedCompany.PartnerCode
    );
    await this.props.fetchLatestCurrencies();
  }

  async componentDidUpdate(prevProps: { userProfile?: UserProfileViewModel }) {
    if (
      prevProps.userProfile &&
      this.props.userProfile.SelectedCompany.PartnerCode !==
        prevProps.userProfile.SelectedCompany.PartnerCode
    ) {
      await this.props.fetchActiveAirFreightBookings(
        this.props.userProfile.SelectedCompany.PartnerCode
      );
      await this.props.fetchActiveSeaFreightBookings(
        this.props.userProfile.SelectedCompany.PartnerCode
      );
      await this.props.fetchActiveRequests(
        this.props.userProfile.SelectedCompany.PartnerCode
      );
    }
  }

  render() {
    const airTravel: TableInfo = {
      tableHeader: [
        i18n.t("LABEL_BOOKING"),
        i18n.t("LABEL_BOL"),
        i18n.t("LABEL_STATUS"),
        i18n.t("LABEL_CUSTOMSCLEARED"),
      ],
      tableData: this.props.activeAirFreightBookings.map((booking, i) => {
        return [
          <Link
            key={i}
            onClick={() =>
              navigatePage(
                `/singleBooking/${FreightMode.AI}/${booking.JobReference}`
              )
            }
          >
            {booking.JobReference.toString()}
          </Link>,
          booking.Reference,
          i18n.t("BOOKINGSTATUS_" + BookingStatus[booking.Status]),
          booking.IsCustomsCleared ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
        ];
      }),
      classes: ["", "hidden md:table-cell", "", ""],
    };

    const seaTravel: TableInfo = {
      tableHeader: [
        i18n.t("LABEL_BOOKING"),
        i18n.t("LABEL_VOYAGEREFERENCE"),
        i18n.t("LABEL_STATUS"),
        i18n.t("LABEL_CUSTOMSCLEARED"),
      ],
      tableData: this.props.activeSeaFreightBookings.map((booking, i) => {
        return [
          <Link
            key={i}
            onClick={() =>
              navigatePage(
                `/singleBooking/${FreightMode.SH}/${booking.JobReference}`
              )
            }
          >
            {booking.JobReference.toString()}
          </Link>,
          booking.Reference,
          i18n.t("BOOKINGSTATUS_" + BookingStatus[booking.Status]),
          booking.IsCustomsCleared ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
        ];
      }),
      classes: ["", "hidden md:table-cell", "", ""],
    };

    const requestTravel: TableInfo = {
      tableHeader: [
        i18n.t("LABEL_REQUEST"),
        i18n.t("LABEL_BOOKING"),
        i18n.t("LABEL_STATUS"),
        i18n.t("LABEL_TYPE"),
      ],
      tableData: this.props.activeRequests.map((request) => {
        return [
          <Link
            key={request.ID}
            onClick={() =>
              window.open(
                `${OldServiceWeb}/DisplayBookingInquiry.aspx?ReqNo=${request.ID}`,
                "_blank",
                "location=yes,height=780,width=800,scrollbars=yes,status=yes"
              )
            }
          >
            {request.ID.toString()}
          </Link>,
          <Link
            key={request.JobReference}
            onClick={() =>
              openTabOrWindow(
                `/singleBooking/${request.BookingFreightMode}/${request.JobReference}#Requests`
              )
            }
            className="text-left"
          >
            {request.JobReference}
          </Link>,
          i18n.t("REQUESTSTATUS_" + request.Status),
          i18n.t("REQUESTTYPE_" + request.Type),
        ];
      }),
      classes: ["", "hidden md:table-cell", "", ""],
    };

    const currencies: TableInfo = {
      tableHeader: [
        i18n.t("LABEL_CURRENCY"),
        i18n.t("LABEL_BANK_RATE"),
        i18n.t("LABEL_ISK_CROSS"),
      ],
      tableData: this.props.latestCurrencies.map((currency) => {
        return [
          <div
            key={currency.CurrencyCode}
            className={`currency-flag currency-flag-${currency.CurrencyCode.toLowerCase()} align-middle`}
            title={currency.CurrencyCode}
          ></div>,
          currency.BankRate.toFixed(2),
          currency.IskKross.toFixed(7),
        ];
      }),
      classes: ["", "", ""],
    };

    return (
      <Translation>
        {(t) => (
          <main
            className={`${styles.master} h-auto w-full flex content-center flex-grow flex-col`}
          >
            <div
              className={`flex flex-wrap justify-center content-center xl:container w-full mx-auto py-8 px-4`}
            >
              <TableCard
                title={t("LABEL_AIR_FREIGHT")}
                tableTitle={t("LABEL_AIR_FREIGHT", {
                  count: this.props.activeAirFreightBookings.length,
                })}
                subTitle={
                  <>
                    {this.props.loadingAirFreightBookings ? (
                      <FaSpinner className="fa-spin inline" />
                    ) : (
                      this.props.activeAirFreightBookings.length
                    )}{" "}
                    {t("TEXT_ACTIVE_BOOKINGS", {
                      count: this.props.activeAirFreightBookings.length,
                    })}
                  </>
                }
                loadingTable={this.props.loadingAirFreightBookings}
                subTitleLink=""
                subTitleClick={true}
                imageClass="bg-air-freight"
                className="z-5 fade-in-left"
                tableInfo={airTravel}
                hasSearch
                searchPlaceholder={t("TEXT_VOYAGE_OR_AIR_BOOKING_NUMBER")}
                noResultsMessage={t("WARNING_NO_VOYAGE_OR_AIR_BOOKING_FOUND")}
                freightMode={FreightMode.AI}
                hasAccess={AuthService.hasUserAccessToRoles([
                  UserRole.AIR,
                  UserRole.SYSADMIN,
                ])}
                tooltip={
                  <Trans
                    i18nKey="TEXT_AIR_FREIGHT_TOOLTIP"
                    components={{ icon: <FiRefreshCcw className="inline" /> }}
                  />
                }
                createBookingLink={
                  AuthService.hasUserAccessToRoles([
                    UserRole.CREATE,
                    UserRole.SYSADMIN,
                  ])
                    ? "/shipments/createairfreightbooking"
                    : undefined
                }
              />
              <TableCard
                title={t("LABEL_SEA_FREIGHT")}
                tableTitle={t("LABEL_SEA_FREIGHT", {
                  count: this.props.activeSeaFreightBookings.length,
                })}
                subTitle={
                  <>
                    {this.props.loadingSeaFreightBookings ? (
                      <FaSpinner className="fa-spin inline" />
                    ) : (
                      this.props.activeSeaFreightBookings.length
                    )}{" "}
                    {t("TEXT_ACTIVE_BOOKINGS", {
                      count: this.props.activeSeaFreightBookings.length,
                    })}
                  </>
                }
                subTitleLink=""
                subTitleClick={true}
                loadingTable={this.props.loadingSeaFreightBookings}
                imageClass="bg-sea-freight"
                className="z-4 fade-in-right"
                tableInfo={seaTravel}
                hasSearch
                searchPlaceholder={t("TEXT_VOYAGE_OR_SEA_BOOKING_NUMBER")}
                noResultsMessage={t("WARNING_NO_VOYAGE_OR_SEA_BOOKING_FOUND")}
                freightMode={FreightMode.SH}
                hasAccess={AuthService.hasUserAccessToRoles([
                  UserRole.SHIP,
                  UserRole.SYSADMIN,
                ])}
                tooltip={
                  <Trans
                    i18nKey="TEXT_SEA_FREIGHT_TOOLTIP"
                    components={{ icon: <FiRefreshCcw className="inline" /> }}
                  />
                }
                createBookingLink={
                  AuthService.hasUserAccessToRoles([
                    UserRole.CREATE,
                    UserRole.SYSADMIN,
                  ])
                    ? "/shipments/createseafreightbooking"
                    : undefined
                }
              />
              <TableCard
                title={t("LABEL_MY_REQUESTS")}
                tableTitle={t("LABEL_REQUEST", {
                  count: this.props.activeRequests.length,
                })}
                subTitle={t("LABEL_VIEW_ALL_REQUESTS")}
                subTitleLink="/requests"
                subTitleClick={false}
                loadingTable={this.props.loadingRequests}
                imageClass="bg-service-card"
                className="z-3 fade-in-left"
                tableInfo={requestTravel}
                noResultsMessage={t("WARNING_NO_BOOKING_OR_REQUEST_FOUND")}
                hasAccess={AuthService.hasUserAccessToRoles([
                  UserRole.REQUESTS,
                  UserRole.SYSADMIN,
                ])}
                tooltip={
                  <Trans
                    i18nKey="TEXT_REQUESTS_TOOLTIP"
                    components={{ icon: <FiRefreshCcw className="inline" /> }}
                  />
                }
              />
              {this.props.userProfile &&
              this.props.userProfile.Access.UserType === UserType.EMPLOYEE ? (
                <TableCard
                  title={t("LABEL_RATE_OF_EXCHANGE")}
                  tableTitle={t("LABEL_RATE_OF_EXCHANGE")}
                  subTitle={t("TEXT_VIEW_RATE")}
                  subTitleLink=""
                  subTitleClick={true}
                  loadingTable={this.props.loadingCurrencies}
                  imageClass="bg-currency-converter"
                  className="z-3 fade-in-right"
                  tableInfo={currencies}
                  hasSearch={false}
                  hasAccess={true}
                  noResultsMessage={t("WARNING_NO_DATA_FOUND")}
                  dateStamp={
                    this.props.latestCurrencies.length > 0
                      ? new Date(this.props.latestCurrencies[0].CurrencyDate)
                      : undefined
                  }
                  tooltip={t("TEXT_RATE_OF_EXCHANGE_TOOLTIP")}
                />
              ) : null}
              <MapCard
                title={t("LABEL_HARBOUR_OPENING_HOURS")}
                subTitle={t("LABEL_VIEW_OPENINGHOURS")}
                subTitleLink="https://www.jonar.is/upplysingar/erlendar-skrifstofur/"
                subTitleClick={false}
                flip={false}
                imageClass="bg-harbour-card"
                className={`z-2 ${
                  this.props.userProfile &&
                  this.props.userProfile.Access.UserType !== UserType.EMPLOYEE
                    ? "fade-in-right"
                    : "fade-in-left"
                }`}
                hasAccess={true}
                tooltip={null}
                back={true}
              />
              <MapCard
                title={t("LABEL_HARBOUR_OPENING_HOURS")}
                subTitle={t("LABEL_VIEW_OPENINGHOURS")}
                subTitleLink="https://www.jonar.is/upplysingar/erlendar-skrifstofur/"
                subTitleClick={false}
                flip={false}
                imageClass="bg-harbour-card"
                className={`z-2 ${
                  this.props.userProfile &&
                  this.props.userProfile.Access.UserType !== UserType.EMPLOYEE
                    ? "fade-in-bottom"
                    : "fade-in-right"
                }`}
                hasAccess={true}
                tooltip={null}
                back={false}
              />
            </div>
            <ContactButton
              onClick={() => {
                location.href = "mailto:jonar@jonar.is";
              }}
            />
          </main>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      activeRequests: state.HomeReducer.activeRequests,
      loadingRequests: state.HomeReducer.loadingRequests,
      activeSeaFreightBookings: state.HomeReducer.activeSeaFreightBookings,
      loadingSeaFreightBookings: state.HomeReducer.loadingSeaFreightBookings,
      activeAirFreightBookings: state.HomeReducer.activeAirFreightBookings,
      loadingAirFreightBookings: state.HomeReducer.loadingAirFreightBookings,
      latestCurrencies: state.HomeReducer.latestCurrencies,
      loadingCurrencies: state.HomeReducer.loadingCurrencies,
      userProfile: state.UserReducer.userProfile,
    };
  },
  {
    fetchActiveAirFreightBookings,
    fetchActiveSeaFreightBookings,
    fetchActiveRequests,
    fetchLatestCurrencies,
  }
)(Master);
