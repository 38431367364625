import React from "react";
import TooltipComponent from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { classNames } from "@assets/utilities/classNameUtilities";
import { CallbackFunction } from "@assets/types";

export interface TooltipProps {
  /** Styles of the span that's wrapping the children. */
  childClassName?: string;
  /** The tooltip reference element. */
  children: React.ReactNode;
  /** The content of the tooltip. */
  content: React.ReactNode;
  /** The direction of the tooltip.
  NOTE: If there is no space for the selected direction, it won't be taken into consideration. */
  direction?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  /** If true, the tooltip won't be rendered. */
  hidden?: boolean;
  /** The id of the tooltip.  */
  id?: string;
  /** Callback fired after the component is opened. */
  onOpen?: CallbackFunction;
  /** Action that triggers the appearance of the tooltip. */
  trigger?: "hover" | "click" | "focus";
  /** The appearance variant of the component. */
  color?: "primary" | "neutral-900";
}
export const Tooltip: React.FC<TooltipProps> = ({
  childClassName,
  children,
  content,
  direction = "top",
  hidden = false,
  id,
  onOpen,
  trigger = "hover",
  color = "primary",
}) => {
  return (
    <>
      {hidden ? (
        <TooltipComponent id={id} visible={false} overlay={content}>
          <div className={classNames(childClassName)}>{children}</div>
        </TooltipComponent>
      ) : (
        <TooltipComponent
          id={id}
          mouseEnterDelay={0.2}
          onVisibleChange={(showTip) => (showTip && onOpen ? onOpen() : null)}
          overlay={content}
          overlayClassName={
            color === "primary"
              ? "rc-override-primary"
              : "rc-override-neutral-900"
          }
          placement={direction}
          trigger={trigger}
        >
          <div className={classNames(childClassName)}>{children}</div>
        </TooltipComponent>
      )}
    </>
  );
};

export default Tooltip;
