import i18next from "i18next";

import {
  loginConstants,
  userConstants,
  toastConstants,
  viewConstants,
  bookingConstants,
  requestConstants,
  currencyConstants,
  voyageConstants,
  loaderConstants,
} from "./types";
import {
  Alert,
  Booking,
  BookingFilters,
  DropdownOption,
  LoginState,
  ResetPasswords,
  ToastType,
  UserAccess,
  UserWebSettings,
} from "@assets/types";
import AuthService from "@services/AuthService";
import UserService from "@services/UserService";
import { navigate } from "gatsby";
import { ModalType } from "@state/reducers/types";
import AlertService from "@services/AlertService";
import RequestService from "@services/RequestService";
import BookingService from "@services/BookingService";
import CurrencyService from "@services/CurrencyService";
import store from "@state/store";
import VoyageService from "@services/VoyageService";
import { BookingTypes, TransportPath } from "@assets/constants";
import { bookingArrayUnique } from "@assets/helperFunctions";

let selectedLanguage = undefined;

let id = 0;
let timeOutFunction: NodeJS.Timeout;

const _refreshSessionModalTimer =
  (process.env.MinutesUntilPasswordModalOpens
    ? parseInt(process.env.MinutesUntilPasswordModalOpens)
    : 60) * 60000;

function createToast(
  dispatch: Function,
  type: ToastType,
  header: string,
  message: string,
  lifeTime?: number | null
) {
  if (lifeTime) {
    setTimeout(
      () => dispatch({ type: toastConstants.REMOVE_TOAST, payload: id }),
      lifeTime
    );
    return { type, message, header, id: ++id, lifeTime };
  } else {
    return { type, message, header, id: ++id };
  }
}

export const setAccessTokenAndTimeout = (lastLogin: Date) => {
  return async (dispatch: Function) => {
    localStorage.setItem("lastAccessTokenRefresh", lastLogin.toLocaleString());
    clearInterval(timeOutFunction);
    const difference = lastLogin.getTime() - new Date().getTime();
    timeOutFunction = setTimeout(
      () =>
        dispatch({
          type: viewConstants.OPEN_MODAL,
          payload: ModalType.PASSWORD_MODAL,
        }),
      _refreshSessionModalTimer - Math.abs(difference)
    );
  };
};

export const clearAccessTokenAndTimeout = () => {
  localStorage.removeItem("lastAccessTokenRefresh");
  clearInterval(timeOutFunction);
};

export const removeToast = (id: number) => {
  return async function (dispatch: Function) {
    dispatch({ type: toastConstants.REMOVE_TOAST, payload: id });
  };
};

export const login = (
  LoginVM: LoginState,
  options: { route: string; tokenRefresh: boolean }
) => {
  return async function (dispatch: Function) {
    dispatch({
      type:
        !options || !options.tokenRefresh
          ? loginConstants.LOGIN_REQUEST
          : loginConstants.TOKEN_REFRESH,
    });

    await AuthService.login(LoginVM, { tokenRefresh: options?.tokenRefresh })
      .then(async (user) => {
        await dispatch({ type: loginConstants.LOGIN_SUCCESS, payload: user });
        await dispatch({ type: viewConstants.CLOSE_MODAL });
        await dispatch(setAccessTokenAndTimeout(new Date()));
        if (options && options.route) await navigate(options.route);
        selectedLanguage = user.WebSettings.find(
          (websetting) => websetting.Field === "SelectedLanguage"
        )?.StringValue;
        selectedLanguage &&
          i18next.changeLanguage(selectedLanguage.replace(/"/g, ""));
      })
      .catch((e) => {
        dispatch({
          type: toastConstants.ADD_TOAST,
          payload: createToast(
            dispatch,
            ToastType.WARNING,
            "ERROR_LOGIN_FAILED",
            e.response.data
          ),
        });
        dispatch({ type: loginConstants.LOGIN_FAILURE });
      });
  };
};

export const logout = (access: UserAccess) => {
  return function (dispatch: Function) {
    dispatch({ type: loginConstants.LOGOUT_REQUEST });
    AuthService.logout(access).then(async () => {
      await dispatch({ type: loginConstants.LOGOUT_SUCCESS, payload: {} });
    });
    clearAccessTokenAndTimeout();
  };
};

export const selectLanguage = (lang: string) => {
  return async function (dispatch: Function) {
    window.localStorage.setItem("selectedLanguage", lang);
    await i18next.changeLanguage(lang);
    if (await UserService.getUserId()) {
      await UserService.updateSelectedLanguageWebSetting(lang).then((res) => {
        dispatch({
          type: userConstants.USER_SELECT_LANGUAGE,
          payload: res,
        });
      });
    }
  };
};

export const updateWebSetting = (webSetting: UserWebSettings) => {
  return async function (dispatch: Function) {
    await UserService.updateUserWebSettings(webSetting).then(() => {
      dispatch({
        type: userConstants.USER_UPDATE_WEBSETTINGS,
        payload: webSetting,
      });
    });
  };
};

export const getProfile = () => {
  return function (dispatch: Function) {
    const _numberOfMinToWait = process.env.MinutesBetweenProfileFetches || 5;
    let _getNewAccessToken = true;
    const _currentTime = new Date();
    if (localStorage.getItem("lastAccessTokenRefresh")) {
      const lastAccessTokenRefresh = new Date(
        localStorage.getItem("lastAccessTokenRefresh") as string
      );
      const difference =
        lastAccessTokenRefresh.getTime() - _currentTime.getTime();
      const resultInMinutes = Math.abs(Math.round(difference / 60000));

      if (
        resultInMinutes < _numberOfMinToWait &&
        store.getState().UserReducer.userProfile
      ) {
        _getNewAccessToken = false;

        if (!timeOutFunction) {
          timeOutFunction = setTimeout(
            () =>
              dispatch({
                type: viewConstants.OPEN_MODAL,
                payload: ModalType.PASSWORD_MODAL,
              }),
            _refreshSessionModalTimer - Math.abs(difference)
          );
        }
      }
    }

    if (_getNewAccessToken) {
      setAccessTokenAndTimeout(_currentTime);
      return UserService.getUserProfileFromAPI()
        .then((res) => {
          dispatch({ type: userConstants.USER_ME, payload: res });
          dispatch(setAccessTokenAndTimeout(new Date()));
        })
        .catch(() => {
          dispatch({ type: loginConstants.LOGOUT_SUCCESS, payload: {} });
          clearAccessTokenAndTimeout();
        });
    }

    return;
  };
};

export const forgotPassword = (email: string) => {
  return async function (dispatch: Function) {
    await dispatch({ type: userConstants.FORGOT_PASSWORD, payload: email });
    await AuthService.forgottenPassword(email)
      .then(() => {
        dispatch({
          type: toastConstants.ADD_TOAST,
          payload: createToast(
            dispatch,
            ToastType.SUCCESS,
            "LABEL_EMAIL_SENT",
            email
          ),
        });
      })
      .catch((e) => {
        dispatch({
          type: toastConstants.ADD_TOAST,
          payload: createToast(
            dispatch,
            ToastType.WARNING,
            "ERROR_PASSWORD_RESET_FAILED",
            e.response.data
          ),
        });
      });
  };
};

export const resetPassword = (hash: string, passwords: ResetPasswords) => {
  return async function (dispatch: Function) {
    await dispatch({ type: userConstants.RESET_PASSWORD, payload: null });
    await AuthService.resetPassword(hash, passwords)
      .then(() => {
        dispatch({
          type: toastConstants.ADD_TOAST,
          payload: createToast(
            dispatch,
            ToastType.SUCCESS,
            "LABEL_PASSWORD_RESET",
            ""
          ),
        });
      })
      .catch((e) => {
        dispatch({
          type: toastConstants.ADD_TOAST,
          payload: createToast(
            dispatch,
            ToastType.WARNING,
            "ERROR_PASSWORD_RESET_FAILED",
            e.response.data
          ),
        });
      });
  };
};

export const openModal = (modalType: ModalType) => {
  return function (dispatch: Function) {
    dispatch({ type: viewConstants.OPEN_MODAL, payload: modalType });
  };
};

export const closeModal = () => {
  return function (dispatch: Function) {
    dispatch({ type: viewConstants.CLOSE_MODAL });
  };
};

/**
 * @param filters All main filters that the user has selected.
 * @param bookingsAll All bookings that were fetched from the back-end.
 * @returns Bookings based on the provided filters.
 */
export function frontEndFilterBookings(
  filters: BookingFilters,
  bookingsAll: Booking[]
): Booking[] {
  if (filters.freightModes.length > 0) {
    bookingsAll = bookingsAll.filter((x) =>
      filters.freightModes.some((y) => y == x.FreightMode)
    );
  }

  if (
    filters?.isCustomsCleared != null &&
    filters?.isCustomsCleared != undefined
  ) {
    if (
      filters.isCustomsCleared === true ||
      filters.isCustomsCleared === false
    ) {
      bookingsAll = bookingsAll.filter(
        (x) => x.IsCustomsCleared == filters.isCustomsCleared
      );
    }
  }

  if (filters?.isHazardous != null && filters?.isHazardous != undefined) {
    if (filters.isHazardous === true || filters.isHazardous === false) {
      bookingsAll = bookingsAll.filter(
        (x) => x.IsHazardous == filters.isHazardous
      );
    }
  }

  // Filters based on the transport path(s) selected
  if (filters.transportPaths.length > 0) {
    let importBookings = JSON.parse(JSON.stringify(bookingsAll));
    let exportBookings = JSON.parse(JSON.stringify(bookingsAll));
    let domesticBookings = JSON.parse(JSON.stringify(bookingsAll));
    let internationalBookings = JSON.parse(JSON.stringify(bookingsAll));

    // Import bookings
    if (filters.transportPaths.indexOf(TransportPath.IMPORT) > -1) {
      importBookings = importBookings.filter(
        (x: Booking) =>
          !x.PointLoad.startsWith("IS") && x.PointDisch.startsWith("IS")
      );
    } else {
      importBookings = [];
    }

    // Export bookings
    if (filters.transportPaths.indexOf(TransportPath.EXPORT) > -1) {
      exportBookings = exportBookings.filter(
        (x: Booking) =>
          x.PointLoad.startsWith("IS") && !x.PointDisch.startsWith("IS")
      );
    } else {
      exportBookings = [];
    }

    // Domestic bookings
    if (filters.transportPaths.indexOf(TransportPath.DOMESTIC) > -1) {
      domesticBookings = domesticBookings.filter(
        (x: Booking) =>
          x.PointLoad.startsWith("IS") && x.PointDisch.startsWith("IS")
      );
    } else {
      domesticBookings = [];
    }

    // International bookings
    if (filters.transportPaths.indexOf(TransportPath.INTERNATIONAL) > -1) {
      internationalBookings = internationalBookings.filter(
        (x: Booking) =>
          !x.PointLoad.startsWith("IS") && !x.PointDisch.startsWith("IS")
      );
    } else {
      internationalBookings = [];
    }

    bookingsAll = bookingArrayUnique(
      importBookings
        .concat(exportBookings)
        .concat(domesticBookings)
        .concat(internationalBookings)
    );
  }

  // Filters based on the booking status
  if (filters.statuses.length > 0) {
    bookingsAll = bookingsAll.filter((x) =>
      filters.statuses.some((y) => y == x.Status)
    );
  }

  // Filter based on shipping type
  if (filters.shippingType && filters.shippingType.length > 0) {
    bookingsAll = bookingsAll.filter((booking) =>
      filters.shippingType.includes(booking.ShippingType)
    );
  }

  // Filter based on POLs
  if (filters.POL && filters.POL.length > 0) {
    bookingsAll = bookingsAll.filter((x) => filters.POL.includes(x.PointLoad));
  }

  // Filter based on PODs
  if (filters.POD && filters.POD.length > 0) {
    bookingsAll = bookingsAll.filter((x) => filters.POD.includes(x.PointDisch));
  }

  // Filter based on shippers
  if (filters.shippers && filters.shippers.length > 0) {
    bookingsAll = bookingsAll.filter((x) =>
      filters.shippers.includes(x.Shipper?.PartnerCode)
    );
  }

  // Filter based on consignees
  if (filters.consignees && filters.consignees.length > 0) {
    bookingsAll = bookingsAll.filter((x) =>
      filters.consignees.includes(x.Consignee?.PartnerCode)
    );
  }

  // Filter based on booking type
  if (filters.bookingTypes && filters.bookingTypes.length > 0) {
    bookingsAll = bookingsAll.filter((x) => {
      return (
        (filters.bookingTypes.includes(BookingTypes.NORMAL) &&
          x.ShipReference == null &&
          !x.IsBCBooking) ||
        (filters.bookingTypes.includes(BookingTypes.BUYERSCONSOL) &&
          x.IsBCBooking) ||
        (filters.bookingTypes.includes(BookingTypes.SUBBOOKING) &&
          x.ShipReference != null)
      );
    });
  }

  // -- Sort by
  if (filters.sort.sortBy == "JobReference") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return a.JobReference.localeCompare(b.JobReference);
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "Shipper") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return a.Shipper.Name.localeCompare(b.Shipper.Name);
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "Consignee") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return a.Consignee.Name.localeCompare(b.Consignee.Name);
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "Status") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return i18next
          .t("BOOKINGSTATUS_" + a.Status)
          .localeCompare(i18next.t("BOOKINGSTATUS_" + b.Status));
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "ShippingType") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return a.ShippingType.localeCompare(b.ShippingType);
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "VoyageReference") {
    bookingsAll = bookingsAll.sort(function (a, b) {
      try {
        return a.VoyageReference.localeCompare(b.VoyageReference);
      } catch {
        return -1;
      }
    });
  } else if (filters.sort.sortBy == "ETA") {
    bookingsAll = bookingsAll.sort(
      (x, y) => +new Date(x.ETA) - +new Date(y.ETA)
    );
  } else if (filters.sort.sortBy == "ETD") {
    bookingsAll = bookingsAll.sort(
      (x, y) => +new Date(x.ETD) - +new Date(y.ETD)
    );
  }
  if (filters.sort.isDesc) {
    bookingsAll.reverse();
  }
  // --- END SORT BY

  return bookingsAll;
}

export function fetchDistinctPartners(bookingsAll: Booking[]) {
  let uniqueShippers: DropdownOption[] = [];
  let uniqueConsignees: DropdownOption[] = [];

  bookingsAll.forEach((b) => {
    if (!uniqueShippers.some((a) => a.value == b.Shipper?.PartnerCode)) {
      uniqueShippers.push({
        item: b.Shipper.Name,
        value: b.Shipper.PartnerCode,
      });
    }
  });

  bookingsAll.forEach((b) => {
    if (!uniqueConsignees.some((a) => a.value == b.Consignee?.PartnerCode)) {
      uniqueConsignees.push({
        item: b.Consignee.Name,
        value: b.Consignee.PartnerCode,
      });
    }
  });

  if (uniqueShippers.length > 0) {
    uniqueShippers = uniqueShippers.sort(function (a, b) {
      try {
        return a.value.localeCompare(b.value);
      } catch {
        return -1;
      }
    });
  }

  if (uniqueConsignees.length > 0) {
    uniqueConsignees = uniqueConsignees.sort(function (a, b) {
      try {
        return a.value.localeCompare(b.value);
      } catch {
        return -1;
      }
    });
  }

  return {
    Shippers: uniqueShippers,
    Consignees: uniqueConsignees,
  };
}

/**
 * @param bookingsAll All the bookings based on filters applied
 * @returns Distincs points of load (names)
 */
export function fetchDistinctPOLsPODs(bookingsAll: Booking[]) {
  // Finds distinct POLs based on the bookings in question
  let uniquePOLs: DropdownOption[] = [];
  let uniquePODs: DropdownOption[] = [];

  bookingsAll.forEach((b) => {
    if (!uniquePOLs.some((a) => a.value == b.PointLoad)) {
      uniquePOLs.push({
        item: b.PointLoadName,
        value: b.PointLoad,
      });
    }
  });

  bookingsAll.forEach((b) => {
    if (!uniquePODs.some((a) => a.value == b.PointDisch)) {
      uniquePODs.push({
        item: b.PointDischName,
        value: b.PointDisch,
      });
    }
  });

  if (uniquePOLs.length > 0) {
    uniquePOLs = uniquePOLs.sort(function (a, b) {
      try {
        return a.value.localeCompare(b.value);
      } catch {
        return -1;
      }
    });
  }

  if (uniquePODs.length > 0) {
    uniquePODs = uniquePODs.sort(function (a, b) {
      try {
        return a.value.localeCompare(b.value);
      } catch {
        return -1;
      }
    });
  }

  return {
    POLs: uniquePOLs,
    PODs: uniquePODs,
  };
}

export const fetchBookingListFrontEnd = (
  filters: BookingFilters,
  bookingsAll: Booking[]
) => {
  return function (dispatch: Function) {
    const filteredBookings = frontEndFilterBookings(filters, bookingsAll);
    dispatch({
      type: bookingConstants.BOOKING_LIST_FRONTEND_FILTERING,
      payload: filteredBookings,
    });
  };
};

export const changeShippingTypeOptions = (
  uniqueShippingTypes: DropdownOption[],
  selectedShippingTypes: string[]
) => {
  const displayed = uniqueShippingTypes.filter(
    (el) => !selectedShippingTypes.some((x) => x == el.value)
  );

  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_LIST_DISPLAYED_SHIPPING_TYPE,
      payload: displayed,
    });
  };
};

export const changePolOptions = (
  uniquePols: DropdownOption[],
  selectedPols: string[]
) => {
  const displayed = uniquePols.filter(
    (el) => !selectedPols.some((x) => x == el.value)
  );

  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_LIST_DISPLAYED_POLS,
      payload: displayed,
    });
  };
};

export const changePodOptions = (
  uniquePods: DropdownOption[],
  selectedPods: string[]
) => {
  const displayed = uniquePods.filter(
    (el) => !selectedPods.some((x) => x == el.value)
  );

  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_LIST_DISPLAYED_PODS,
      payload: displayed,
    });
  };
};

export const changeConsigneeOptions = (
  uniqueConsignees: DropdownOption[],
  selectedCosignees: string[]
) => {
  const displayed = uniqueConsignees.filter(
    (el) => !selectedCosignees.some((x) => x == el.value)
  );

  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_LIST_DISPLAYED_CONSIGNEES,
      payload: displayed,
    });
  };
};

export const changeShipperOptions = (
  uniqueShippers: DropdownOption[],
  selectedShippers: string[]
) => {
  const displayed = uniqueShippers.filter(
    (el) => !selectedShippers.some((x) => x == el.value)
  );

  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_LIST_DISPLAYED_SHIPPERS,
      payload: displayed,
    });
  };
};

/**
 * @param filters All main filters that the user has selected.
 * @returns Bookings based on the provided filters.
 */
export const fetchBookingList = (filters: BookingFilters) => {
  return function (dispatch: Function) {
    dispatch({ type: bookingConstants.BOOKING_LIST_FETCH_REQUEST });

    // Clears api filters which are rather applied in the front-end.
    const apiFilters = JSON.parse(JSON.stringify(filters));
    apiFilters.freightModes = [];
    apiFilters.shippingType = "";
    apiFilters.isCustomsCleared = null;
    apiFilters.isHazardous = null;
    apiFilters.transportPaths = [];
    apiFilters.statuses = [];
    apiFilters.pointDisch = "";
    apiFilters.pointLoad = "";

    BookingService.getBookingList(apiFilters)
      .then((res) => {
        // Default order
        if (filters.sort.sortBy == "JobReference") {
          res = res.sort((a, b) =>
            a.JobReference.localeCompare(b.JobReference)
          );
        }

        // Starts by getting all unqiue entries based on the entire dataset
        // from the server.
        dispatch({
          type: bookingConstants.BOOKING_LIST_FETCH_SUCCESS,
          payload: res,
        });

        const ports = fetchDistinctPOLsPODs(res);
        const partners = fetchDistinctPartners(res);

        dispatch({
          type: bookingConstants.BOOKING_LIST_UNIQUE_POL,
          payload: ports.POLs,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_DISPLAYED_POLS,
          payload: ports.POLs,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_UNIQUE_POD,
          payload: ports.PODs,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_DISPLAYED_PODS,
          payload: ports.PODs,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_UNIQUE_SHIPPERS,
          payload: partners.Shippers,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_DISPLAYED_SHIPPERS,
          payload: partners.Shippers,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_UNIQUE_CONSIGNEES,
          payload: partners.Consignees,
        });

        dispatch({
          type: bookingConstants.BOOKING_LIST_DISPLAYED_CONSIGNEES,
          payload: partners.Consignees,
        });

        // --------------------------------------------------
        // Filters based on the selected front-end filters
        const filteredBookings = frontEndFilterBookings(filters, res);

        dispatch({
          type: bookingConstants.BOOKING_LIST_FRONTEND_FILTERING,
          payload: filteredBookings,
        });
      })
      .catch(() => {
        dispatch({
          type: bookingConstants.BOOKING_LIST_FETCH_FAILED,
        });
      });
  };
};

export const fetchActiveAirFreightBookings = (partnerCode: string) => {
  return function (dispatch: Function) {
    dispatch({ type: bookingConstants.BOOKING_AIR_FETCH_ACTIVE_REQUEST });
    BookingService.getActiveBookings(partnerCode, "AI")
      .then((res) => {
        dispatch({
          type: bookingConstants.BOOKING_AIR_FETCH_ACTIVE_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: bookingConstants.BOOKING_AIR_FETCH_ACTIVE_FAILED,
        });
      });
  };
};

export const fetchActiveSeaFreightBookings = (partnerCode: string) => {
  return function (dispatch: Function) {
    dispatch({ type: bookingConstants.BOOKING_SEA_FETCH_ACTIVE_REQUEST });
    BookingService.getActiveBookings(partnerCode, "SH")
      .then((res) => {
        dispatch({
          type: bookingConstants.BOOKING_SEA_FETCH_ACTIVE_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: bookingConstants.BOOKING_SEA_FETCH_ACTIVE_FAILED,
        });
      });
  };
};

export const fetchActiveRequests = (partnerCode: string) => {
  return function (dispatch: Function) {
    dispatch({ type: requestConstants.REQUEST_FETCH_ACTIVE_REQUEST });
    RequestService.getActiveBookings(partnerCode)
      .then((res) => {
        dispatch({
          type: requestConstants.REQUEST_FETCH_ACTIVE_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: requestConstants.REQUEST_FETCH_ACTIVE_FAILED,
        });
      });
  };
};

export const fetchLatestCurrencies = () => {
  return function (dispatch: Function) {
    dispatch({ type: currencyConstants.CURRENCY_FETCH_LATEST_REQUEST });
    CurrencyService.getLatestCurrencies()
      .then((res) => {
        dispatch({
          type: currencyConstants.CURRENCY_FETCH_LATEST_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: currencyConstants.CURRENCY_FETCH_LATEST_FAILED,
        });
      });
  };
};

export const fetchActiveVoyages = () => {
  return function (dispatch: Function) {
    dispatch({ type: voyageConstants.VOYAGE_FETCH_ACTIVE_REQUEST });
    VoyageService.getCurrentVoyages()
      .then((res) => {
        dispatch({
          type: voyageConstants.VOYAGE_FETCH_ACTIVE_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: voyageConstants.VOYAGE_FETCH_ACTIVE_FAILED,
        });
      });
  };
};

export const selectCompany = (partnerCode: string) => {
  return function (dispatch: Function) {
    dispatch({ type: userConstants.USER_SELECT_COMPANY_REQUEST, payload: {} });
    UserService.changeUserCompany(partnerCode)
      .then((res) => {
        dispatch({
          type: userConstants.USER_SELECT_COMPANY_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: userConstants.USER_SELECT_COMPANY_FAILED,
          payload: {},
        });
      });
  };
};

export const getFavoriteBookings = () => {
  return (dispatch: Function) => {
    BookingService.getFavoriteBookings().then((res) => {
      dispatch({
        type: bookingConstants.BOOKING_GET_FAVORITES_SUCCESS,
        payload: res,
      });
    });
  };
};

export const toggleFavoriteBooking = (jobReference: string) => {
  return function (dispatch: Function) {
    dispatch({
      type: loaderConstants.FAVORITE_BOOKING_LOADER_ADD,
      payload: {},
    });
    dispatch({
      type: bookingConstants.BOOKING_TOGGLE_FAVORITE_REQUEST,
      payload: jobReference,
    });

    BookingService.toggleFavoriteBooking(jobReference)
      .then(() => {
        dispatch({
          type: bookingConstants.BOOKING_TOGGLE_FAVORITE_SUCCESS,
          payload: {},
        });
        dispatch(getFavoriteBookings());
      })
      .catch(() => {
        dispatch({
          type: bookingConstants.BOOKING_TOGGLE_FAVORITE_FAILED,
          payload: {},
        });
      })
      .finally(() => {
        dispatch({
          type: loaderConstants.FAVORITE_BOOKING_LOADER_REMOVE,
          payload: {},
        });
      });
  };
};

export const getBookingsInExcel = (bookings: Booking[]) => {
  return function (dispatch: Function) {
    dispatch({
      type: bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_REQUEST,
      payload: {},
    });
    BookingService.getBookingsInExcel(bookings)
      .then(() => {
        dispatch({
          type: bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_SUCCESS,
          payload: {},
        });
      })
      .catch(() => {
        dispatch({
          type: bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_FAILED,
          payload: {},
        });
      });
  };
};

export const checkAlerts = (route: string) => {
  return function (dispatch: Function) {
    AlertService.getAlertRoute({
      Route: route,
      Company: 1049,
    }).then((data: Alert) => {
      if (data) {
        if (i18next.language == "is") {
          dispatch({
            type: toastConstants.ADD_TOAST,
            payload: createToast(
              dispatch,
              data.Type as ToastType,
              data.HeadingIS,
              data.BodyHtmlIS
            ),
          });
        } else {
          dispatch({
            type: toastConstants.ADD_TOAST,
            payload: createToast(
              dispatch,
              data.Type as ToastType,
              data.HeadingEN,
              data.BodyHtmlEN
            ),
          });
        }
      }
    });
  };
};
