import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M36.5,26.43h5v-1l-2-3 M32.5,36.43h4 M17.52,37.44c0,2.2-1.8,3.99-4.01,3.99S9.5,39.65,9.5,37.44
                s1.8-3.99,4.01-3.99S17.52,35.24,17.52,37.44z M44.5,37.44c0,2.2-1.8,3.99-4.01,3.99s-4.01-1.79-4.01-3.99s1.79-3.99,4.01-3.99
                S44.5,35.24,44.5,37.44z M9.5,36.43h-8v-25h31v25h-15 M32.5,18.43h7.94c0.66,0,1.28,0.33,1.65,0.87l3.4,6.13h1
                c1.12,0,2,0.74,2,2.21v6.79c0,1.1-0.9,2-2,2h-2"
      />
    </g>
  </svg>
);

export default SVG;
