import { Label } from "@assets/types";
import React from "react";

import { FiX } from "react-icons/fi";
import styles from "./label.module.less";

type LabelProps = {
  className?: string;
  label: Label;
  onClick: Function;
};

const LabelComponent: React.FC<LabelProps> = ({
  label,
  className,
  onClick,
}) => {
  if (!className) {
    className = "flex";
  }

  return (
    <div
      className={`items-center bg-light-gray bg-opacity-60 mr-2 pl-3 pt-2 pb-1 leading-none ${className} ${styles.label}`}
    >
      {label.text}
      <FiX
        onClick={() => onClick(label.key)}
        className={`ml-1 mr-2 ${styles.icon} cursor-pointer float-right`}
      />
    </div>
  );
};

export default LabelComponent;
