import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M45.08,27.29L15.42,9.41 M18.11,4.95l29.66,17.88 M15.73,24.68l-3.24-1.95c-2.06-1.24-2.73-3.92-1.48-5.98
                l7.11-11.8c1.24-2.06,3.92-2.72,5.98-1.48l22.19,13.38c2.06,1.24,2.72,3.92,1.48,5.98l-7.11,11.79c-0.93,1.55-2.67,2.31-4.35,2.07
                M31.42,34.29h-6.38V29.5h6.38V34.29z M28.23,41.18h3.3 M6.3,41.18h14.43 M1.6,42.81V29.03c0-2.41,1.95-4.36,4.36-4.36h25.92
                c2.41,0,4.36,1.95,4.36,4.36v13.77c0,2.41-1.95,4.36-4.36,4.36H5.96C3.55,47.16,1.6,45.21,1.6,42.81z"
      />
    </g>
  </svg>
);

export default SVG;
