import React from "react";

import Step from "./step";

const styles = {
  root: {
    width: "100%",
    minHeight: 0,
    padding: 0,
  },
  stepper: {
    display: "table",
    width: "100%",
    margin: "0 auto",
  },
};

type StepItem = {
  title: string;
  icon?: string;
  href?: string;
  onClick: () => void;
};

type Props = {
  activeStep?: number;
  steps: StepItem[];
  activeColor?: string;
  completeColor?: string;
  defaultColor?: string;
  activeTitleColor?: string;
  completeTitleColor?: string;
  defaultTitleColor?: string;
  circleFontColor?: string;
  size?: number;
  circleFontSize?: number;
  titleFontSize?: number;
  circleTop?: number;
  defaultOpacity?: string;
  completeOpacity?: string;
  activeOpacity?: string;
  defaultTitleOpacity?: string;
  completeTitleOpacity?: string;
  activeTitleOpacity?: string;
  barStyle?: string;
  defaultBarColor?: string;
  completeBarColor?: string;
  defaultBorderColor?: string;
  completeBorderColor?: string;
  activeBorderColor?: string;
  defaultBorderStyle?: string;
  completeBorderStyle?: string;
  activeBorderStyle?: string;
  lineMarginOffset?: number;
  defaultBorderWidth?: number;
  disabledSteps?: number[];
};

const Stepper: React.FC<Props> = (props: Props) => {
  const defaultProps = {
    activeStep: 0,
  };
  const {
    activeStep,
    steps,
    disabledSteps,
    activeColor,
    completeColor,
    defaultColor,
    circleFontColor,
    activeTitleColor,
    completeTitleColor,
    defaultTitleColor,
    size,
    circleFontSize,
    titleFontSize,
    circleTop,
    completeOpacity,
    activeOpacity,
    defaultOpacity,
    completeTitleOpacity,
    activeTitleOpacity,
    defaultTitleOpacity,
    barStyle,
    defaultBorderColor,
    completeBorderColor,
    activeBorderColor,
    defaultBorderStyle,
    completeBorderStyle,
    activeBorderStyle,
    defaultBarColor,
    completeBarColor,
    lineMarginOffset,
    defaultBorderWidth,
  } = props;

  return (
    <div style={styles.root}>
      <div style={styles.stepper}>
        {steps.map((step, index) => (
          <Step
            key={index}
            width={100 / steps.length}
            title={step.title}
            href={step.href}
            onClick={() => step.onClick()}
            active={
              !(disabledSteps || []).includes(index) &&
              index ===
                (activeStep != null ? activeStep : defaultProps.activeStep)
            }
            completed={
              !(disabledSteps || []).includes(index) &&
              index <
                (activeStep != null ? activeStep : defaultProps.activeStep)
            }
            first={index === 0}
            isLast={index === steps.length - 1}
            index={index}
            activeColor={activeColor}
            completeColor={completeColor}
            defaultColor={defaultColor}
            circleFontColor={circleFontColor}
            activeTitleColor={activeTitleColor}
            completeTitleColor={completeTitleColor}
            defaultTitleColor={defaultTitleColor}
            size={size}
            circleFontSize={circleFontSize}
            titleFontSize={titleFontSize}
            circleTop={circleTop}
            defaultOpacity={defaultOpacity}
            completeOpacity={completeOpacity}
            activeOpacity={activeOpacity}
            defaultTitleOpacity={defaultTitleOpacity}
            completeTitleOpacity={completeTitleOpacity}
            activeTitleOpacity={activeTitleOpacity}
            barStyle={barStyle}
            defaultBorderColor={defaultBorderColor}
            completeBorderColor={completeBorderColor}
            activeBorderColor={activeBorderColor}
            defaultBorderStyle={defaultBorderStyle}
            defaultBorderWidth={defaultBorderWidth}
            completeBorderStyle={completeBorderStyle}
            activeBorderStyle={activeBorderStyle}
            defaultBarColor={defaultBarColor}
            completeBarColor={completeBarColor}
            lineMarginOffset={lineMarginOffset}
          />
        ))}
      </div>
    </div>
  );
};

export default Stepper;
