import React, { ReactNode, FC, useEffect, useState } from "react";

type TableFadeProps = {
  className?: string;
  children?: ReactNode;
  scrollPosition?: number;
  endingPoint: number;
};

const TableFade: FC<TableFadeProps> = (props: TableFadeProps) => {
  const [isFaded, setIsFaded] = useState(true);
  const [isFadedLeft, setIsFadedLeft] = useState(false);

  useEffect(() => {
    setIsFaded(true);
    setIsFadedLeft(false);
  }, []);

  useEffect(() => {
    if (props.scrollPosition === 0) {
      if (props.endingPoint === 0 && props.scrollPosition === 0) {
        setIsFadedLeft(false);
      } else {
        setIsFadedLeft(true);
      }
    }
  }, [props.endingPoint === 0]);

  useEffect(() => {
    if (props.scrollPosition && props.endingPoint !== 0) {
      if (props.scrollPosition === props.endingPoint) {
        setIsFaded(false);
      } else if (props.endingPoint != 0) {
        setIsFaded(true);
      }
    } else if (props.scrollPosition != 0) {
      setIsFadedLeft(true);
    }
  }, [props.scrollPosition]);

  return (
    <>
      {isFaded ? (
        <div className="absolute right-0 w-1/12 h-full transition duration-700 ease-in bg-gradient-to-r from-transparent via-white  to-white opacity-60 md:bg-none md:right-auto md:h-auto md:w-auto"></div>
      ) : (
        <div className="absolute right-0 w-1/12 h-full transition duration-700 ease-out bg-gradient-to-r from-transparent via-white  to-white  opacity-0 md:bg-none md:right-auto md:h-auto md:w-auto"></div>
      )}
      {isFadedLeft ? (
        <div className="absolute left-0 w-1/12 h-full transition duration-700 ease-in bg-gradient-to-l from-transparent via-white  to-white  opacity-60 md:bg-none md:right-auto md:h-auto md:w-auto"></div>
      ) : (
        <div className="absolute left-0 w-1/12 h-full transition duration-700 ease-out bg-gradient-to-l from-transparent via-white  to-white  opacity-0 md:bg-none md:right-auto md:h-auto md:w-auto"></div>
      )}
    </>
  );
};
export default TableFade;
