import Spinner from "@components/Spinner/spinner";
import React, { ReactNode } from "react";
import Modal from "./modal";

interface LoadingModalProps {
  isOpen: boolean;
  children: ReactNode;
}

const LoadingModal = ({ isOpen, children }: LoadingModalProps) => (
  <Modal open={isOpen} closeIcon={false} closeOnOutsideClick={false}>
    <Modal.Content className={"flex flex-col items-center justify-center"}>
      <p className="text-neutral-900 font-semibold">{children}</p>
      <Spinner className="mt-3" />
    </Modal.Content>
  </Modal>
);

export default LoadingModal;
