import { CustomerStatus, KeyBalanceCompany } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

class CustomerStatusByDateReq {
  sapId: string;
  dateFrom: Date;
  dateTo: Date;
}

export const getCustomerStatus = createAsyncThunk(
  "Finance/status",
  async (sapId: string) => {
    return await SingleBookingService.getCustomerStatus(sapId);
  }
);

export const getCustomerStatusByDate = createAsyncThunk(
  "Finance/statusbydate",
  async (req: CustomerStatusByDateReq) => {
    return await SingleBookingService.getCustomerStatusByDate(
      req.sapId,
      req.dateFrom,
      req.dateTo
    );
  }
);

export interface CustomerState {
  customerStatus: CustomerStatus[];
  customerStatusByDate: KeyBalanceCompany[];
  loadingCustomerStatus: boolean;
  loadingCustomerStatusByDate: boolean;
}

export const initialCustomerState: CustomerState = {
  customerStatus: [],
  customerStatusByDate: [],
  loadingCustomerStatus: false,
  loadingCustomerStatusByDate: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialCustomerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerStatus.pending, (state) => {
        state.loadingCustomerStatus = true;
      })
      .addCase(getCustomerStatus.rejected, (state) => {
        state.loadingCustomerStatus = false;
        state.customerStatus = [];
      })
      .addCase(
        getCustomerStatus.fulfilled,
        (state, action: PayloadAction<CustomerStatus[]>) => {
          state.loadingCustomerStatus = false;
          state.customerStatus = action.payload;
        }
      )
      .addCase(getCustomerStatusByDate.pending, (state) => {
        state.loadingCustomerStatusByDate = true;
      })
      .addCase(getCustomerStatusByDate.rejected, (state) => {
        state.loadingCustomerStatusByDate = false;
        state.customerStatusByDate = [];
      })
      .addCase(
        getCustomerStatusByDate.fulfilled,
        (state, action: PayloadAction<KeyBalanceCompany[]>) => {
          state.loadingCustomerStatusByDate = false;
          state.customerStatusByDate = action.payload;
        }
      );
  },
});

export const customerState = (state: RootState) => state.customerSlice;

export default customerSlice.reducer;
