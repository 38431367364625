import {
  CompanyAddress,
  ErrorTypes,
  ModalProps,
  PickPartnerChangeRegistration,
  Port,
  PostalCodeFormat,
} from "@assets/types";
import React, { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { Translation } from "react-i18next";
import Modal from "@components/Modal/modal";
import Button from "@components/Button/button";
import InputEx from "@components/Input/inputEx";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import WithFormController from "@components/WithFormController/WithFormController";
import StringUtilities from "utilities/StringUtilities";
import {
  partnerLabelMapper,
  partnerValueMapper,
  portLabelMapper,
  portValueMapper,
} from "@assets/utilities/labelMapperUtils";
import { getErrorMessage } from "@assets/utilities/translationUtilities";
import AsyncSelect from "@components/Select/asyncSelect";
import CreateBookingService from "@services/CreateBookingService";
import {
  MAX_INPUT_LENGTH_ADDRESS,
  MAX_INPUT_LENGTH_GENERIC,
  PartnerModalLabels,
  PortType,
} from "@assets/constants";

interface PartnerRegisterProps extends ModalProps {
  companyAddresses: CompanyAddress[];
  data: PickPartnerChangeRegistration | null;
  onChangeData: (data: PickPartnerChangeRegistration) => void;
  modalTitle: PartnerModalLabels;
  isLoading?: boolean;
}

interface PartnerRegisterForm {
  partner: CompanyAddress | null;
  companyName: string;
  address: string;
  postalCode: string;
  placeCity: Port | null;
  district: string;
  extra: string;
  contact: string;
  email: string;
  phone: string;
}

const defaultFormValues: PartnerRegisterForm = {
  partner: null,
  companyName: "",
  address: "",
  postalCode: "",
  placeCity: null,
  contact: "",
  email: "",
  phone: "",
  district: "",
  extra: "",
};

const PartnerRegisterModal: React.FC<PartnerRegisterProps> = (
  props: PartnerRegisterProps
) => {
  const { companyAddresses, data, onChangeData, open, closeModal, modalTitle } =
    props;

  const [defaultPlaceCities, setDefaultPlaceCities] = useState<Port[]>([]);
  const [postalCodeFormat, setPostalCodeFormat] =
    useState<PostalCodeFormat | null>(null);

  const dropdownSchema = {
    label: yup.string(),
    value: yup.mixed(),
  };

  const schema = yup.object({
    partner: yup.lazy(() => {
      if (modalTitle === PartnerModalLabels.PICK) {
        return yup
          .object()
          .shape(dropdownSchema)
          .required(ErrorTypes.RequiredField)
          .typeError(ErrorTypes.RequiredField);
      }
      return yup.mixed().optional();
    }),
    companyName: yup.string().required(ErrorTypes.RequiredField),
    address: yup
      .string()
      .required(ErrorTypes.RequiredField)
      .max(MAX_INPUT_LENGTH_ADDRESS, ErrorTypes.MaxLengthExceeded),
    postalCode: yup.lazy(() => {
      if (postalCodeFormat) {
        return yup
          .string()
          .matches(new RegExp(`^${postalCodeFormat.PostalCodeRegexFormat}$`), {
            message: ErrorTypes.PostalCodeError,
            excludeEmptyString: true,
          })
          .required(ErrorTypes.RequiredField);
      }
      return yup.string().required(ErrorTypes.RequiredField);
    }),
    placeCity: yup
      .object()
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    contact: yup
      .string()
      .required(ErrorTypes.RequiredField)
      .max(30, ErrorTypes.MaxLengthExceeded),
    email: yup
      .string()
      .required(ErrorTypes.RequiredField)
      .test({
        name: "invalidEmails",
        exclusive: false,
        params: {},
        message: ErrorTypes.EmailIsNotValid,
        test: function (value: string) {
          return StringUtilities.validEmails(value);
        },
      }),
    phone: yup
      .string()
      .max(MAX_INPUT_LENGTH_GENERIC, ErrorTypes.MaxLengthExceeded),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  useEffect(() => {
    CreateBookingService.getPorts(PortType.SEA).then((localPorts: Port[]) => {
      setDefaultPlaceCities(localPorts);
    });
  }, []);

  useEffect(() => {
    setValue("partner", data?.partner ? data.partner : null);
    setValue("companyName", data?.companyName ?? "");
    setValue("address", data?.address ?? "");
    setValue("postalCode", data?.postalCode ?? "");
    setValue("placeCity", data?.placeCity ?? null);
    setValue("contact", data?.contact ?? "");
    setValue("email", data?.email ?? "");
    setValue("phone", data?.phone ?? "");
    setValue("district", data?.district ?? "");
    setValue("extra", data?.extra ?? "");
  }, [data]);

  const onClose = () => {
    closeModal();
    if (modalTitle === PartnerModalLabels.PICK) reset();
  };

  const onChange = (option: CompanyAddress) => {
    setValue("partner", option ? option : null);
    setValue("companyName", option?.FullName ?? "");
    setValue(
      "address",
      `${
        option?.Address2 !== "" && option.Address2 !== null
          ? option.Address2
          : option.Address3
      }` ?? ""
    );
    setValue("postalCode", option?.PostCode ?? "");
    setValue(
      "placeCity",
      {
        PointCode: option.PointCode,
        FullName: option.City,
        Country: option.CountryName,
        CountryCode: option.CountryCode,
        PortCode: option.PostCode,
        PortType: "W",
        TimeZone: "",
        TimeZoneDiff: 0,
        LocalName: "",
      } ?? null
    );
    setValue("contact", option?.ContactName ?? "");
    setValue("email", option?.ContactEmail ?? "");
    setValue("phone", option?.ContactNumber ?? "");
    setValue("district", "");
    setValue("extra", "");

    if (option && option.CountryCode) {
      CreateBookingService.getPostalCodeFormatByCountry(
        option.CountryCode
      ).then((pc: PostalCodeFormat) => {
        setPostalCodeFormat(pc);
      });
    }
  };

  const searchPort = (searchString: string): Promise<any[]> => {
    return new Promise((resolve) => {
      CreateBookingService.searchPartnerPorts({
        searchString: searchString,
        size: 10,
      }).then((ports: Port[]) => {
        resolve(ports);
      });
    });
  };

  const searchPartner = (searchString: string): Promise<any[]> => {
    return new Promise((resolve) => {
      CreateBookingService.searchPartners(searchString).then(
        (ports: CompanyAddress[]) => {
          resolve(ports);
        }
      );
    });
  };

  const onSubmit = (formData: PartnerRegisterForm) => {
    const newData = {
      partner: formData.partner ?? null,
      companyName: formData.companyName ?? "",
      address: formData.address ?? "",
      postalCode: formData.postalCode ?? "",
      placeCity: formData.placeCity ?? null,
      contact: formData.contact ?? "",
      email: formData.email ?? "",
      phone: formData.phone ?? "",
      district: formData.district ?? "",
      extra: formData.extra ?? "",
    };
    onChangeData(newData);
    closeModal();
    return;
  };

  const onChangePortCity = (port: Port) => {
    if (port && port.CountryCode) {
      CreateBookingService.getPostalCodeFormatByCountry(port.CountryCode).then(
        (pc: PostalCodeFormat) => {
          setPostalCodeFormat(pc);
        }
      );
    }
  };

  return (
    <Translation>
      {(t) => {
        return (
          <Modal
            open={open}
            onClose={closeModal}
            closeOnOutsideClick={props.closeOnOutsideClick}
          >
            <Modal.Header>{t(modalTitle)}</Modal.Header>
            <Modal.Content className="bg-white md:w-full overflow-y-auto md:max-h-80vh">
              <form>
                {modalTitle === PartnerModalLabels.PICK && (
                  <WithFormController control={control} name="partner">
                    <AsyncSelect
                      className="mt-6 mb-3"
                      label={t("LABEL_PARTNER")}
                      id="partner"
                      placeholder={t("TEXT_ENTER_PARTNER")}
                      defaultOptions={companyAddresses}
                      getOptionLabel={partnerLabelMapper}
                      getOptionValue={partnerValueMapper}
                      loadOptions={searchPartner}
                      onChange={onChange}
                      errorText={getErrorMessage("partner", errors)}
                      hasError={errors.partner != null}
                      isLoading={props.isLoading}
                    />
                  </WithFormController>
                )}
                <InputEx
                  className="my-3"
                  label={t("LABEL_COMPANY_NAME")}
                  type="text"
                  {...register("companyName")}
                  placeholder={t("TEXT_ENTER_COMPANY_NAME")}
                  errorMessage={getErrorMessage("companyName", errors)}
                  hasError={errors.companyName != null}
                  id="companyName"
                />
                <InputEx
                  className="my-3"
                  label={t("LABEL_ADDRESS")}
                  type="text"
                  {...register("address")}
                  placeholder={t("TEXT_ENTERADDRESS")}
                  errorMessage={getErrorMessage("address", errors, {
                    n: MAX_INPUT_LENGTH_ADDRESS,
                  })}
                  hasError={errors.address != null}
                  id="address"
                />
                <div className="flex flex-row justify-between my-3">
                  <WithFormController control={control} name="placeCity">
                    <AsyncSelect
                      className="mr-3 w-full"
                      defaultOptions={defaultPlaceCities}
                      errorText={t(
                        (errors.placeCity as FieldError)?.message || ""
                      )}
                      hasError={errors.placeCity != null}
                      label={t("LABEL_CITY_COUNTRY")}
                      getOptionLabel={portLabelMapper}
                      getOptionValue={portValueMapper}
                      loadOptions={searchPort}
                      onChange={onChangePortCity}
                      placeholder={t("TEXT_ENTER_CITY")}
                    />
                  </WithFormController>
                  <InputEx
                    className="ml-3 w-full"
                    label={t("LABEL_POSTALCODE")}
                    type="text"
                    {...register("postalCode")}
                    placeholder={t("TEXT_ENTERPOSTCODE")}
                    errorMessage={getErrorMessage("postalCode", errors)}
                    hasError={errors.postalCode != null}
                    id="postalcode"
                    helperDescription={
                      postalCodeFormat && postalCodeFormat?.PostalCodeExample
                        ? t("TEXT_EXAMPLE") +
                          ` ${postalCodeFormat?.PostalCodeExample}`
                        : undefined
                    }
                  />
                </div>
                <div className="grid grid-cols-1">
                  <InputEx
                    className="my-3"
                    label={t("LABEL_DISTRICT")}
                    type="text"
                    {...register("district")}
                    placeholder={t("TEXT_ENTERDISTRICT")}
                    id="district"
                    optional
                  />
                  <InputEx
                    className="my-3"
                    label={t("LABEL_EXTRA")}
                    type="text"
                    {...register("extra")}
                    placeholder={t("TEXT_ENTEREXTRA")}
                    id="extra"
                    optional
                  />
                </div>
                <InputEx
                  className="my-3"
                  label={t("LABEL_CONTACT_PERSON_NAME")}
                  type="text"
                  {...register("contact")}
                  placeholder={t("TEXT_ENTER_CONTACT_PERSON_NAME")}
                  errorMessage={getErrorMessage("contact", errors, {
                    n: 30,
                  })}
                  hasError={errors.contact != null}
                  id="contact"
                />
                <div className="flex flex-row justify-between mt-3 mb-6">
                  <InputEx
                    className="w-full mr-3"
                    label={t("LABEL_EMAIL")}
                    type="text"
                    inputmode="email"
                    {...register("email")}
                    placeholder={t("TEXT_ENTEREMAIL")}
                    errorMessage={getErrorMessage("email", errors)}
                    hasError={errors.email != null}
                    id="email"
                  />
                  <InputEx
                    className="w-full ml-3"
                    label={t("LABEL_PHONE")}
                    type="text"
                    {...register("phone")}
                    placeholder={t("TEXT_ENTERPHONE")}
                    errorMessage={getErrorMessage("phone", errors, {
                      n: MAX_INPUT_LENGTH_GENERIC,
                    })}
                    hasError={errors.phone != null}
                    id="phone"
                  />
                </div>
              </form>
            </Modal.Content>
            <Modal.Footer>
              <Button
                className="ml-2"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                {t("LABEL_CANCEL")}
              </Button>
              <Button
                className="mr-2"
                onClick={handleSubmit(onSubmit)}
                type="submit"
                primary
              >
                {t("LABEL_SAVE")}
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Translation>
  );
};

export default PartnerRegisterModal;
