import React from "react";
import { FaCheck } from "react-icons/fa";

type Props = {
  width?: number;
  activeColor?: string;
  completeColor?: string;
  defaultColor?: string;
  activeTitleColor?: string;
  completeTitleColor?: string;
  defaultTitleColor?: string;
  circleFontColor?: string;
  size?: number;
  circleFontSize?: number;
  titleFontSize?: number;
  circleTop?: number;
  title?: string;
  index: number;
  active?: boolean;
  completed?: boolean;
  first?: boolean;
  isLast?: boolean;
  completeOpacity?: string;
  activeOpacity?: string;
  defaultOpacity?: string;
  completeTitleOpacity?: string;
  activeTitleOpacity?: string;
  defaultTitleOpacity?: string;
  barStyle?: string;
  defaultBarColor?: string;
  completeBarColor?: string;
  defaultBorderColor?: string;
  completeBorderColor?: string;
  activeBorderColor?: string;
  defaultBorderStyle?: string;
  completeBorderStyle?: string;
  activeBorderStyle?: string;
  lineMarginOffset?: number;
  defaultBorderWidth?: number;
  href?: string;
  onClick: () => void;
};

const Step: React.FC<Props> = (props: Props) => {
  const defaultProps = {
    activeColor: "#003a66",
    completeColor: "#003a66",
    defaultColor: "#E0E0E0",
    activeTitleColor: "#000",
    completeTitleColor: "#000",
    defaultTitleColor: "#757575",
    circleFontColor: "#FFF",
    size: 32,
    circleFontSize: 16,
    titleFontSize: 16,
    circleTop: 24,
    titleTop: 8,
    defaultBarColor: "#E0E0E0",
    barStyle: "solid",
    borderStyle: "solid",
    lineMarginOffset: 4,
    defaultBorderWidth: 0,
  };

  const getStyles = () => {
    const {
      activeColor,
      completeColor,
      defaultColor,
      circleFontColor,
      activeTitleColor,
      completeTitleColor,
      defaultTitleColor,
      size,
      circleFontSize,
      titleFontSize,
      circleTop,
      width,
      completeOpacity,
      activeOpacity,
      defaultOpacity,
      completeTitleOpacity,
      activeTitleOpacity,
      defaultTitleOpacity,
      defaultBarColor,
      completeBarColor,
      defaultBorderColor,
      completeBorderColor,
      activeBorderColor,
      defaultBorderStyle,
      completeBorderStyle,
      activeBorderStyle,
      lineMarginOffset,
      defaultBorderWidth,
    } = props;

    return {
      step: {
        width: `${width}%`,
        display: "table-cell",
        paddingTop:
          circleTop != null && circleTop != undefined
            ? circleTop
            : defaultProps.circleTop,
      },
      circle: {
        width: size != null && size != undefined ? size : defaultProps.size,
        height: size != null && size != undefined ? size : defaultProps.size,
        margin: "0 5px",
        backgroundColor:
          defaultColor != null && defaultColor != undefined
            ? defaultColor
            : defaultProps.defaultColor,
        borderRadius: "50%",
        padding: 1,
        fontSize:
          circleFontSize != null && circleFontSize != undefined
            ? circleFontSize
            : defaultProps.circleFontSize,
        color:
          circleFontColor != null && circleFontColor != undefined
            ? circleFontColor
            : defaultProps.circleFontColor,
        display: "block",
        opacity: defaultOpacity,
        borderWidth: defaultBorderColor
          ? defaultBorderWidth != null && defaultBorderWidth != undefined
            ? defaultBorderWidth
            : defaultProps.defaultBorderWidth
          : 0,
        borderColor: defaultBorderColor,
        borderStyle: defaultBorderStyle,
      },
      activeCircle: {
        backgroundColor:
          activeColor != null && activeColor != undefined
            ? activeColor
            : defaultProps.activeColor,
        opacity: activeOpacity,
        borderWidth: (
          activeColor != null && activeColor != undefined
            ? activeColor
            : defaultProps.activeColor
        )
          ? defaultBorderWidth != null && defaultBorderWidth != undefined
            ? defaultBorderWidth
            : defaultProps.defaultBorderWidth
          : 0,
        borderColor: activeBorderColor,
        borderStyle: activeBorderStyle,
      },
      completedCircle: {
        backgroundColor:
          completeColor != null && completeColor != undefined
            ? completeColor
            : defaultProps.completeColor,
        opacity: completeOpacity,
        borderWidth: completeBorderColor
          ? defaultBorderWidth != null && defaultBorderWidth != undefined
            ? defaultBorderWidth
            : defaultProps.defaultBorderWidth
          : 0,
        borderColor: completeBorderColor,
        borderStyle: completeBorderStyle,
      },
      index: {
        lineHeight: `${
          (size != null && size != undefined ? size : defaultProps.size) +
          (circleFontSize != null
            ? circleFontSize
            : defaultProps.circleFontSize) /
            4
        }px`,
        color:
          circleFontColor != null && circleFontColor != undefined
            ? circleFontColor
            : defaultProps.circleFontColor,
      },
      title: {
        marginTop: "4px",
        fontSize:
          titleFontSize != null && titleFontSize != undefined
            ? titleFontSize
            : defaultProps.titleFontSize,
        //display: "block",
        color:
          defaultTitleColor != null && defaultTitleColor != undefined
            ? defaultTitleColor
            : defaultProps.defaultTitleColor,
        opacity: defaultTitleOpacity,
      },
      activeTitle: {
        color:
          activeTitleColor != null && activeTitleColor != undefined
            ? activeTitleColor
            : defaultProps.activeTitleColor,
        opacity: activeTitleOpacity,
      },
      completedTitle: {
        color:
          completeTitleColor != null && completeTitleColor != undefined
            ? completeTitleColor
            : defaultProps.completeTitleColor,
        opacity: completeTitleOpacity,
      },
      leftBar: {
        top:
          (circleTop != null && circleTop != undefined
            ? circleTop
            : defaultProps.circleTop) +
          (size != null && size != undefined ? size : defaultProps.size) / 2,
        height: 1,
        borderTopWidth: 1,
        borderTopColor:
          defaultBarColor != null && defaultBarColor != undefined
            ? defaultBarColor
            : defaultProps.defaultBarColor,
        left: 0,
        right: "67%",
        marginRight:
          (size != null && size != undefined ? size : defaultProps.size) / 2 +
          (lineMarginOffset != null && lineMarginOffset != undefined
            ? lineMarginOffset
            : defaultProps.lineMarginOffset),
        opacity: defaultOpacity,
      },
      rightBar: {
        top:
          (circleTop != null && circleTop != undefined
            ? circleTop
            : defaultProps.circleTop) +
          (size != null && size != undefined ? size : defaultProps.size) / 2,
        height: 1,
        borderTopWidth: 1,
        borderTopColor:
          defaultBarColor != null && defaultBarColor != undefined
            ? defaultBarColor
            : defaultProps.defaultBarColor,
        right: 0,
        left: "67%",
        marginLeft:
          (size != null && size != undefined ? size : defaultProps.size) / 2 +
          (lineMarginOffset != null && lineMarginOffset != undefined
            ? lineMarginOffset
            : defaultProps.lineMarginOffset),
        opacity: defaultOpacity,
      },
      completedBar: {
        borderTopWidth: 1,
        borderTopColor: completeBarColor,
        opacity: completeOpacity,
      },
    };
  };

  const { title, index, active, completed, first, isLast, href, onClick } =
    props;

  const styles = getStyles();
  const circleStyle = Object.assign(
    styles.circle,
    completed ? styles.completedCircle : {},
    active ? styles.activeCircle : {}
  );
  const titleStyle = Object.assign(
    styles.title,
    completed ? styles.completedTitle : {},
    active ? styles.activeTitle : {}
  );
  const leftStyle = Object.assign(
    styles.leftBar,
    active || completed ? styles.completedBar : {}
  );
  const rightStyle = Object.assign(
    styles.rightBar,
    completed ? styles.completedBar : {}
  );

  return (
    <div style={styles.step} className="relative">
      <div className="flex justify-center items-center">
        <div
          style={circleStyle}
          className="text-center"
          onClick={() => onClick()}
        >
          {active || !completed ? (
            <a href={href} style={styles.index}>
              {index + 1}
            </a>
          ) : (
            <a href={href} style={styles.index}>
              <FaCheck className="cursor inline-block text-white text-base mb-1" />
            </a>
          )}
        </div>
        {active || completed ? (
          <a
            href={href}
            style={titleStyle}
            className="font-medium md:block hidden"
          >
            {title}
          </a>
        ) : (
          <div style={titleStyle} className="font-medium md:block hidden">
            {title}
          </div>
        )}
        {!first && <div style={leftStyle} className="absolute"></div>}
        {!isLast && <div style={rightStyle} className="absolute"></div>}
      </div>
    </div>
  );
};

export default Step;
