import React from "react";
import { useTranslation } from "react-i18next";
import {
  BookingCargoContainerResponse,
  BookingMarksAndNumbersRes,
} from "@assets/types";
import Alert from "@components/Alert/alert";
import { FaInfoCircle } from "react-icons/fa";
import {
  BookingStatus,
  FreightMode,
  ShippingTypeValue,
} from "@assets/constants";
import LayoutCell from "@components/LayoutCell/layoutCell";
import CustomIcon from "@components/CustomIcon/customIcon";
import BookingUtilities from "utilities/BookingUtilities";
import ContainerDetailsCell from "./containerDetailsCell";
import Tooltip from "@components/Tooltip/tooltip";


type ContainerCellProps = {
  marksIndex: number;
  containerIndex: number;
  container: BookingCargoContainerResponse;
  marksAndNumbers: BookingMarksAndNumbersRes[];
  className?: string;
  shippingTypeValue?: string | null;
  freightMode?: string;
};

export const ContainerCell: React.FC<ContainerCellProps> = ({
  className,
  containerIndex,
  container,
  marksAndNumbers,
  shippingTypeValue,
  freightMode,
  marksIndex,
}) => {
  const { t } = useTranslation();

  const filterMarks = marksAndNumbers.filter(
    (mark, index) => index === marksIndex
  );

  const marks = filterMarks.map((mark) => (
    <li key={`mark-${marksIndex}`} className="my-2">
      <ul>
        <li>
          {t("LABEL_MARKS")}
          {": "}
          {mark.Marks}
        </li>
        <li>
          {t("LABEL_NUMBERS")}
          {": "}
          {mark.Description}
        </li>
      </ul>
    </li>
  ));

  const containerData = container?.ContainerData?.map((data, index) => (
    <ContainerDetailsCell
      key={data.ContainerType}
      shippingTypeValue={shippingTypeValue}
      containerNumber={container.ContainerNumber ?? "N/A"}
      containerData={data}
      freightMode={freightMode}
    />
  ));

  const renderOnlyForLCLLCL = () => {
    return (
      <>
        <div className="font-semibold text-neutral-9 leading-6 text-xl mb-6 flex">
          <h2>{container.ContainerNumber ?? "N/A"}</h2>
          {container.Delivered == true && (
              <Tooltip
              content={`${t("LABEL_DELIVERED")}`}>
                  <CustomIcon
                    className={`inline-block ml-2 stroke-current text-green-800`}
                    iconName={BookingUtilities.getStatusIcon(
                      BookingStatus.DELIVERED,
                      freightMode == "SH" ? FreightMode.SH : FreightMode.AI
                    )}
                  />
              </Tooltip>
            )}
        </div>
        <div className="border-2 p-4 grid grid-cols-5">
          <div>
            <label className="text-neutral-7">
              {t("LABEL_CONTAINER_NUMBER")}
            </label>
            <div className="text-neutral-9">
              {t(container.ContainerNumber ?? "N/A")}
            </div>
          </div>
        </div>
        {containerData}
        <div className="flex flex-col md:flex-row md:justify-between">
          <LayoutCell
            id="container_cell_marks_and_numbers"
            className="min-h-40 w-12/12"
            labelValue={t("LABEL_MARKS_AND_NUMBERS")}
          >
            <div className="text-neutral-9 leading-5 text-sm font-normal">
              <ul>{marks}</ul>
            </div>
          </LayoutCell>
        </div>
      </>
    );
  };

  const renderOnlyForLCLFCL = () => {
    return (
      <>
        {containerIndex > 0 && (
          <div className="font-semibold text-neutral-9 leading-6 text-xl mb-6 flex">
            <h2>{container.ContainerNumber ?? "N/A"}</h2>
            {container.Delivered == true && (
              <Tooltip
                content={`${t("LABEL_DELIVERED")}`}>
                  <CustomIcon
                    className={`inline-block ml-2 stroke-current text-green-800`}
                    iconName={BookingUtilities.getStatusIcon(
                      BookingStatus.DELIVERED,
                      freightMode == "SH" ? FreightMode.SH : FreightMode.AI
                    )}
                  />
              </Tooltip>
            )}
          </div>
        )}
        {containerData}
        <div>
          <label className="text-neutral-7 font-semibold leading-5 text-sm">
            {t("LABEL_MARKS_AND_NUMBERS")}
          </label>
          <div className="text-neutral-9 leading-5 text-sm font-normal">
            <ul>{marks}</ul>
          </div>
        </div>
      </>
    );
  };

  const renderOnlyForFCLFCL = () => {
    return (
      <>
        {containerIndex > 0 && (
          <div className="font-semibold text-neutral-9 leading-6 text-xl flex">
            <h2>{container.ContainerNumber ?? "N/A"}</h2>
            {container.Delivered == true && (
              <Tooltip
               content={`${t("LABEL_DELIVERED")}`}>
                  <CustomIcon
                    className={`inline-block ml-2 stroke-current text-green-800`}
                    iconName={BookingUtilities.getStatusIcon(
                      BookingStatus.DELIVERED,
                      freightMode == "SH" ? FreightMode.SH : FreightMode.AI
                    )}
                  />
              </Tooltip>
            )}
          </div>
        )}
        {containerData}
        <div>
          <label className="text-neutral-7 font-semibold leading-5 text-sm">
            {t("LABEL_MARKS_AND_NUMBERS")}
          </label>
          <div className="text-neutral-9 leading-5 text-sm font-normal">
            <ul>{marks}</ul>
          </div>
        </div>
      </>
    );
  };

  const renderOnlyForAirLCLLCL = () => {
    return (
      <>
        {containerData}
        <Alert className="py-4 px-2 mb-6 text-base flex items-start w-full">
          <FaInfoCircle className={`absolut text-info mr-2 mt-1 w-10`} />
          {t("TEXT_AIR_LCL_CARGO_DETAILS")}
        </Alert>
        <div>
          <label className="text-neutral-7 font-semibold leading-5 text-sm">
            {t("LABEL_MARKS_AND_NUMBERS")}
          </label>
          <div className="text-neutral-9 leading-5 text-sm font-normal">
            <ul>{marks}</ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`${className} my-6 mx-8`}>
      {freightMode == FreightMode.SH &&
      shippingTypeValue === ShippingTypeValue.LL
        ? renderOnlyForLCLLCL()
        : null}
      {freightMode == FreightMode.SH &&
      (shippingTypeValue === ShippingTypeValue.LF ||
        shippingTypeValue === ShippingTypeValue.FL)
        ? renderOnlyForLCLFCL()
        : null}
      {freightMode == FreightMode.SH &&
      shippingTypeValue === ShippingTypeValue.FF
        ? renderOnlyForFCLFCL()
        : null}
      {freightMode == FreightMode.AI &&
      shippingTypeValue === ShippingTypeValue.LL
        ? renderOnlyForAirLCLLCL()
        : null}
    </div>
  );
};

ContainerCell.displayName = "ContainerCell";

export default ContainerCell;
