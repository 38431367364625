import { UserRole } from "@assets/constants";
import { UserProfileViewModel } from "@assets/types";

class UserUtilities {
  userHasAccessToRoles = (
    userProfile: UserProfileViewModel,
    roles: UserRole[]
  ) => {
    if (userProfile.Roles.some((role) => role.RoleName === "ADMIN"))
      return true;

    return roles.every((role) =>
      userProfile.Roles.some((profileRole) => profileRole.RoleID === role)
    );
  };
}

export default new UserUtilities();
