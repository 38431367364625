import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutCell from "@components/LayoutCell/layoutCell";
import {
  bills,
  FreightMode,
  PortType,
  IncotermPoint,
  PartnerModalLabels,
  PageType,
} from "@assets/constants";
import CreateBookingService from "@services/CreateBookingService";
import {
  BillOfLading,
  CompanyAddress,
  Incoterms,
  PickPartnerChangeRegistration,
  Port,
  RequestCollectionRegistration,
  UserType,
} from "@assets/types";
import Button from "@components/Button/button";
import PartnerRegisterModal from "@components/Modal/partnerRegisterModal";
import Checkbox from "@components/Input/checkbox";
import SelectEx from "@components/Select/selectEx";
import RequestCollectionModal from "@components/Modal/requestCollectionModal";
import { difference } from "@assets/helperFunctions";
import {
  incotermLabelMapper,
  incotermValueMapper,
  portLabelMapper,
  portValueMapper,
} from "@assets/utilities/labelMapperUtils";
import { getIncotermPorts } from "@assets/utilities/incotermUtilities";
import InputEx from "@components/Input/inputEx";
import { translate } from "@assets/utilities/translationUtilities";
import AsyncSelect from "@components/Select/asyncSelect";
import { format } from "date-fns";
import DateUtilities from "utilities/DateUtilities";
import Spinner from "@components/Spinner/spinner";
import { Link } from "@samskip/frontend-components";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

interface RoutePageProps {
  portsOfLoad: Port[];
  portsOfDischarge: Port[];
  companyAddresses: CompanyAddress[];
  portOfLoad: Port | null;
  onChangePortOfLoad: (option: Port | null) => void;
  portOfDischarge: Port | null;
  onChangePortOfDischarge: (option: Port | null) => void;
  onChangeShipperData: (shipperData: PickPartnerChangeRegistration) => void;
  shipperData: PickPartnerChangeRegistration | null;
  onChangeConsigneeData: (consigneeData: PickPartnerChangeRegistration) => void;
  consigneeData: PickPartnerChangeRegistration | null;
  onChangeNotifierData: (notifierData?: PickPartnerChangeRegistration) => void;
  notifierData: PickPartnerChangeRegistration | null;
  userType: UserType | null;
  partnerAddress: CompanyAddress | null;
  onChangeUserType: (
    userType: UserType,
    partnerAddress: CompanyAddress | null
  ) => void;
  incoterms: Incoterms[];
  onChangeIncoterm: (option: Incoterms | null) => void;
  incoterm: Incoterms | null;
  incotermLocations: Port[];
  selectedIncotermLocation: Port | null;
  onChangeIncotermLocation: (incotermLocations: Port[] | null) => void;
  onChangeSelectedIncotermLocation: (option: Port | null) => void;
  fromData: RequestCollectionRegistration | null;
  onChangeFromData: (fromData: RequestCollectionRegistration) => void;
  toData: RequestCollectionRegistration | null;
  onChangeToData: (toData: RequestCollectionRegistration) => void;
  shipperError: string | null;
  consigneeError: string | null;
  podError: string | null;
  polError: string | null;
  collectionDetailsError: string | null;
  deliveryDetailsError: string | null;
  incotermError: string | null;
  incotermLocationError: string | null;
  onHandleContinue: () => void;
  canCollectShipment: boolean;
  onChangeCanCollectShipment: (canCollectShipment: boolean) => void;
  canSetDeliverToPort: boolean;
  onChangeCanSetDeliverToPort: (canSetDeliverToPort: boolean) => void;
  canDeliverShipment: boolean;
  onChangeCanDeliverShipment: (canDeliverShipment: boolean) => void;
  canSetPickUpPort: boolean;
  onChangeCanSetPickUpPort: (canSetPickUpPort: boolean) => void;
  quoteNumber: string | null;
  onChangeQuoteNumber: (quoteNumber: string | null) => void;
  billOfLading: BillOfLading | null;
  onChangeBillOfLading: (billOfLading: BillOfLading | null) => void;
  billOfLadingError: string | null;
  shipmentType: FreightMode;
  loadingPartnerAddresses: boolean;
  loadingCompanyAddresses: boolean;
  loadingCompanyCustomerAddresses: boolean;
  loadingIncoterms: boolean;
  loadingCopyBooking: boolean;
  pageType?: PageType;
}

type PartnerRegisterShipperModal = {
  openPartnerRegisterShipper: boolean;
  modalTitle: PartnerModalLabels;
  formData: PickPartnerChangeRegistration;
};

type PartnerRegisterConsigneeModal = {
  openPartnerRegisterConsignee: boolean;
  modalTitle: PartnerModalLabels;
  formData: PickPartnerChangeRegistration;
};

type PartnerRegisterNotifierModal = {
  openPartnerRegisterNotifier: boolean;
  modalTitle: PartnerModalLabels;
  formData: PickPartnerChangeRegistration;
};

type FromRequestCollectionModal = {
  openFromRequestCollection: boolean;
  isEdit: boolean;
  formData: RequestCollectionRegistration;
  isCollection: boolean;
};

type ToRequestCollectionModal = {
  openToRequestCollection: boolean;
  isEdit: boolean;
  formData: RequestCollectionRegistration;
  isCollection: boolean;
};

const RouteComponent: React.FC<RoutePageProps> = (props: RoutePageProps) => {
  const { t, i18n } = useTranslation();
  const initialPartnerData: PickPartnerChangeRegistration = {
    partner: null,
    companyName: "",
    address: "",
    postalCode: "",
    placeCity: null,
    district: "",
    extra: "",
    contact: "",
    phone: "",
    email: "",
  };
  const initialCollectionData: RequestCollectionRegistration = {
    partner: null,
    companyName: "",
    address: "",
    postalCode: "",
    placeCity: null,
    district: "",
    extra: "",
    contact: "",
    phone: "",
    email: "",
    collectionDate: null,
    from: "",
    to: "",
    collectionReference: "",
    additionalInfo: "",
  };
  const [partnerRegisterShipperModal, setPartnerRegisterShipperModal] =
    useState<PartnerRegisterShipperModal>({
      openPartnerRegisterShipper: false,
      modalTitle: PartnerModalLabels.REGISTER,
      formData: initialPartnerData,
    });
  const [partnerRegisterConsigneeModal, setPartnerRegisterConsigneeModal] =
    useState<PartnerRegisterConsigneeModal>({
      openPartnerRegisterConsignee: false,
      modalTitle: PartnerModalLabels.REGISTER,
      formData: initialPartnerData,
    });
  const [partnerRegisterNotifierModal, setPartnerRegisterNotifierModal] =
    useState<PartnerRegisterNotifierModal>({
      openPartnerRegisterNotifier: false,
      modalTitle: PartnerModalLabels.REGISTER,
      formData: initialPartnerData,
    });
  const [fromRequestCollectionModal, setFromRequestCollectionModal] =
    useState<FromRequestCollectionModal>({
      openFromRequestCollection: false,
      isEdit: false,
      formData: initialCollectionData,
      isCollection: true,
    });
  const [toRequestCollectionModal, setToRequestCollectionModal] =
    useState<ToRequestCollectionModal>({
      openToRequestCollection: false,
      isEdit: false,
      formData: initialCollectionData,
      isCollection: false,
    });

  const [filteredIncoterms, setFilteredIncoterms] = useState<Incoterms[]>([]);

  useEffect(() => {
    setFilteredIncoterms(filterIncoterms());
  }, []);
  /**
   * Update the selectable incoterm places based on what incoterm was selected
   * and what inputs were filled in
   */
  const updateIncotermPlaces = (option: Incoterms | null) => {
    const selectedIncoterm = props.incoterms?.find(
      (x) => x.TermsCode === option?.TermsCode
    );
    if (selectedIncoterm === undefined) {
      return;
    }

    const incotermPlaces = getIncotermPorts(selectedIncoterm, {
      PLR: props.fromData?.placeCity ?? null,
      POL: props.portOfLoad,
      PFD: props.toData?.placeCity ?? null,
      POD: props.portOfDischarge,
    });
    props.onChangeIncotermLocation(incotermPlaces);
    props.onChangeSelectedIncotermLocation(incotermPlaces[0]);
  };

  const openPartnerRegisterShipperModal = (modalTitle: PartnerModalLabels) => {
    const shipperModal = { ...partnerRegisterShipperModal };
    shipperModal.openPartnerRegisterShipper = true;
    shipperModal.modalTitle = modalTitle;
    setPartnerRegisterShipperModal(shipperModal);
  };

  const closePartnerRegisterShipperModal = () => {
    const shipperModal = { ...partnerRegisterShipperModal };
    shipperModal.openPartnerRegisterShipper = false;
    setPartnerRegisterShipperModal(shipperModal);
  };

  const openPartnerRegisterConsigneeModal = (
    modalTitle: PartnerModalLabels
  ) => {
    const consigneeModal = { ...partnerRegisterConsigneeModal };
    consigneeModal.openPartnerRegisterConsignee = true;
    consigneeModal.modalTitle = modalTitle;
    setPartnerRegisterConsigneeModal(consigneeModal);
  };

  const closePartnerRegisterConsigneeModal = () => {
    const consigneeModal = { ...partnerRegisterConsigneeModal };
    consigneeModal.openPartnerRegisterConsignee = false;
    setPartnerRegisterConsigneeModal(consigneeModal);
  };

  const openPartnerRegisterNotifierModal = (modalTitle: PartnerModalLabels) => {
    const notifierModal = { ...partnerRegisterNotifierModal };
    notifierModal.openPartnerRegisterNotifier = true;
    notifierModal.modalTitle = modalTitle;
    setPartnerRegisterNotifierModal(notifierModal);
  };

  const closePartnerRegisterNotifierModal = () => {
    const notifierModal = { ...partnerRegisterNotifierModal };
    notifierModal.openPartnerRegisterNotifier = false;
    setPartnerRegisterNotifierModal(notifierModal);
  };

  const openFromRequestCollectionModal = (isEdit: boolean) => {
    const fromModal = { ...fromRequestCollectionModal };
    fromModal.openFromRequestCollection = true;
    fromModal.isEdit = isEdit;
    fromModal.isCollection = true;
    setFromRequestCollectionModal(fromModal);
  };

  const closeFromRequestCollectionModal = () => {
    const fromModal = { ...fromRequestCollectionModal };
    fromModal.openFromRequestCollection = false;
    resetIncoterm();
    setFromRequestCollectionModal(fromModal);
  };

  const openToRequestCollectionModal = (isEdit: boolean) => {
    const toModal = { ...toRequestCollectionModal };
    toModal.openToRequestCollection = true;
    toModal.isEdit = isEdit;
    toModal.isCollection = false;
    setToRequestCollectionModal(toModal);
  };

  const closeToRequestCollectionModal = () => {
    const toModal = { ...toRequestCollectionModal };
    toModal.openToRequestCollection = false;
    resetIncoterm();
    setToRequestCollectionModal(toModal);
  };

  const onChangePortOfLoad = (option: Port) => {
    updateIncotermPlaces(props.incoterm);
    props.onChangePortOfLoad(option);
  };

  const onChangePortOfDischarge = (option: Port) => {
    updateIncotermPlaces(props.incoterm);
    props.onChangePortOfDischarge(option);
  };

  const searchAirports = (searchString: string): Promise<Port[]> => {
    return CreateBookingService.searchPorts({
      portType: PortType.AIR,
      searchString: searchString,
    });
  };

  const onDeleteShipperData = () => {
    const newData = {
      partner: null,
      companyName: "",
      address: "",
      postalCode: "",
      placeCity: null,
      contact: "",
      email: "",
      phone: "",
      district: "",
      extra: "",
    };
    props.onChangeShipperData(newData);
  };

  const onDeleteConsigneeData = () => {
    const newData = {
      partner: null,
      companyName: "",
      address: "",
      postalCode: "",
      placeCity: null,
      contact: "",
      email: "",
      phone: "",
      district: "",
      extra: "",
    };
    props.onChangeConsigneeData(newData);
  };

  const onDeleteNotifierData = () => {
    const newData = {
      partner: null,
      companyName: "",
      address: "",
      postalCode: "",
      placeCity: null,
      contact: "",
      email: "",
      phone: "",
      district: "",
      extra: "",
    };
    props.onChangeNotifierData(newData);
  };

  const onChangeIncoterm = (option: Incoterms) => {
    props.onChangeIncoterm(option);
    updateIncotermPlaces(option);
  };

  const onChangeSelectedIncotermLocation = (option: Port) => {
    props.onChangeSelectedIncotermLocation(option);
  };

  /**
   * Filter out the incoterms that depend only on PLR/PFD
   * but they are not filled out in the form
   * @returns Filtered array of incoterms that can be selected
   */
  const filterIncoterms = () => {
    let filteredIncoterms = props.incoterms ? [...props.incoterms] : [];
    if (props.userType === UserType.Consignee && props.canCollectShipment) {
      filteredIncoterms = filteredIncoterms.filter((it: Incoterms) => {
        return ["EXW", "FCA"].some((type) => type === it.TermsCode);
      });
    }
    if (props.fromData?.placeCity) {
      filteredIncoterms = filteredIncoterms.filter((it: Incoterms) => {
        return !(
          it.AllowedIncotermsPoints?.length === 1 &&
          it.AllowedIncotermsPoints[0] === IncotermPoint.PLR
        );
      });
    }
    if (props.toData?.placeCity || props.portOfDischarge) {
      filteredIncoterms = filteredIncoterms.filter((it: Incoterms) => {
        return !(
          it.AllowedIncotermsPoints?.length === 1 &&
          it.AllowedIncotermsPoints[0] === IncotermPoint.PFD
        );
      });
    }
    // Get the "TermsCode" of the incoterms that were filtered out from the original incoterm list
    const removedTermCodes = difference(
      props.incoterms || [],
      filteredIncoterms
    ).map((it: Incoterms) => it.TermsCode);

    // If the selected incoterm was one of the values that got filtered out, reset it's value
    if (
      props.incoterm != null &&
      removedTermCodes.indexOf(props.incoterm.TermsCode) > -1
    ) {
      props.onChangeIncoterm(null);
      updateIncotermPlaces(props.incoterm);
    }
    return filteredIncoterms;
  };

  const incotermsEnabled =
    (props.fromData != null || props.portOfLoad?.PointCode != null) &&
    (props.toData != null || props.portOfDischarge?.PointCode != null);
  const incotermLocationEnabled = props.incoterm?.TermsCode != undefined;

  const onChangeShipmentData = () => {
    props.onChangeCanCollectShipment(!props.canCollectShipment);
    props.onChangeCanSetDeliverToPort(!props.canSetDeliverToPort);
    if (props.canSetDeliverToPort) {
      props.onChangePortOfLoad(null);
      props.onChangeFromData({
        partner: null,
        companyName: "",
        address: "",
        postalCode: "",
        placeCity: null,
        district: "",
        extra: "",
        contact: "",
        email: "",
        phone: "",
        collectionDate: null,
        from: "",
        to: "",
        collectionReference: "",
        additionalInfo: "",
      });
    }
  };

  const onChangeDeliverData = () => {
    props.onChangeCanDeliverShipment(!props.canDeliverShipment);
    props.onChangeCanSetPickUpPort(!props.canSetPickUpPort);
    if (props.canSetPickUpPort) {
      props.onChangePortOfDischarge(null);
      props.onChangeToData({
        partner: null,
        companyName: "",
        address: "",
        postalCode: "",
        placeCity: null,
        district: "",
        extra: "",
        contact: "",
        email: "",
        phone: "",
        collectionDate: null,
        from: "",
        to: "",
        collectionReference: "",
        additionalInfo: "",
      });
    }
  };

  const resetIncoterm = () => {
    props.onChangeIncoterm(null);
    setFilteredIncoterms(filterIncoterms());
    props.onChangeIncotermLocation([]);
    props.onChangeSelectedIncotermLocation(null);
  };

  const onChangeBillOfLading = (option: BillOfLading) => {
    props.onChangeBillOfLading(option);
  };

  const shipperRef = useRef<HTMLDivElement>(null);
  const consigneeRef = useRef<HTMLDivElement>(null);
  const fromRef = useRef<HTMLDivElement>(null);
  const toRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.shipperError != null && props.shipperError != "") {
      shipperRef.current?.scrollIntoView();
      return;
    }
    if (props.consigneeError != null && props.consigneeError != "") {
      consigneeRef.current?.scrollIntoView();
      return;
    }
    if (
      props.collectionDetailsError != null &&
      props.collectionDetailsError != ""
    ) {
      fromRef.current?.scrollIntoView();
      return;
    }
    if (
      props.deliveryDetailsError != null &&
      props.deliveryDetailsError != ""
    ) {
      toRef.current?.scrollIntoView();
      return;
    }
  }, [
    props.shipperError,
    props.consigneeError,
    props.collectionDetailsError,
    props.deliveryDetailsError,
  ]);

  if (props.pageType === PageType.COPY && props.loadingCopyBooking) {
    return <Spinner className="w-full flex align-center justify-center" />;
  }

  return (
    <>
      <div>
        <PartnerRegisterModal
          closeIcon
          closeOnOutsideClick={false}
          open={partnerRegisterShipperModal.openPartnerRegisterShipper}
          closeModal={() => closePartnerRegisterShipperModal()}
          companyAddresses={props.companyAddresses}
          data={props.shipperData}
          onChangeData={props.onChangeShipperData}
          modalTitle={partnerRegisterShipperModal.modalTitle}
          isLoading={
            props.loadingCompanyAddresses ||
            props.loadingCompanyCustomerAddresses
          }
        ></PartnerRegisterModal>
        <PartnerRegisterModal
          closeIcon
          closeOnOutsideClick={false}
          open={partnerRegisterConsigneeModal.openPartnerRegisterConsignee}
          closeModal={() => closePartnerRegisterConsigneeModal()}
          companyAddresses={props.companyAddresses}
          data={props.consigneeData}
          onChangeData={props.onChangeConsigneeData}
          modalTitle={partnerRegisterConsigneeModal.modalTitle}
          isLoading={
            props.loadingCompanyAddresses ||
            props.loadingCompanyCustomerAddresses
          }
        ></PartnerRegisterModal>
        <PartnerRegisterModal
          closeIcon
          closeOnOutsideClick={false}
          open={partnerRegisterNotifierModal.openPartnerRegisterNotifier}
          closeModal={() => closePartnerRegisterNotifierModal()}
          companyAddresses={props.companyAddresses}
          data={props.notifierData}
          onChangeData={props.onChangeNotifierData}
          modalTitle={partnerRegisterNotifierModal.modalTitle}
          isLoading={
            props.loadingCompanyAddresses ||
            props.loadingCompanyCustomerAddresses
          }
        ></PartnerRegisterModal>
        <RequestCollectionModal
          closeIcon
          closeOnOutsideClick={false}
          open={fromRequestCollectionModal.openFromRequestCollection}
          closeModal={() => closeFromRequestCollectionModal()}
          companyAddresses={
            props.shipmentType === FreightMode.AI
              ? props.companyAddresses.filter((c) => c.CountryCode == "IS")
              : props.companyAddresses
          }
          userData={
            fromRequestCollectionModal.isCollection
              ? props.shipperData
              : props.consigneeData
          }
          data={props.fromData}
          onChangeData={props.onChangeFromData}
          isEdit={fromRequestCollectionModal.isEdit}
          isCollection={fromRequestCollectionModal.isCollection}
          shipmentType={props.shipmentType}
          isLoadingPartner={
            props.loadingCompanyAddresses ||
            props.loadingCompanyCustomerAddresses
          }
        ></RequestCollectionModal>
        <RequestCollectionModal
          closeIcon
          closeOnOutsideClick={false}
          open={toRequestCollectionModal.openToRequestCollection}
          closeModal={() => closeToRequestCollectionModal()}
          companyAddresses={
            props.shipmentType === FreightMode.AI
              ? props.companyAddresses.filter((c) => c.CountryCode != "IS")
              : props.companyAddresses
          }
          userData={
            toRequestCollectionModal.isCollection
              ? props.shipperData
              : props.consigneeData
          }
          data={props.toData}
          onChangeData={props.onChangeToData}
          isEdit={toRequestCollectionModal.isEdit}
          isCollection={toRequestCollectionModal.isCollection}
          shipmentType={props.shipmentType}
          isLoadingPartner={
            props.loadingCompanyAddresses ||
            props.loadingCompanyCustomerAddresses
          }
        ></RequestCollectionModal>
        <div className="flex py-6 pl-6 ml-6">
          <Checkbox
            className="pb-1 mr-2"
            label={t("LABEL_TOGGLE_SHIPPER")}
            type="radio"
            checked={props.userType === UserType.Shipper}
            onChange={() =>
              props.onChangeUserType(UserType.Shipper, props.partnerAddress)
            }
            disabled={
              props.consigneeData?.placeCity === null ||
              props.consigneeData?.placeCity === undefined
            }
          />
          <Checkbox
            className="pb-1 mr-2 ml-2"
            label={t("LABEL_TOGGLE_CONSIGNEE")}
            type="radio"
            checked={props.userType === UserType.Consignee}
            onChange={() =>
              props.onChangeUserType(UserType.Consignee, props.partnerAddress)
            }
            disabled={
              props.shipperData?.placeCity === null ||
              props.shipperData?.placeCity === undefined
            }
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <LayoutCell
            id="layout_shipper"
            ref={shipperRef}
            className="border-2 ml-6 mr-3 mt-3 mb-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            labelValue={t("LABEL_SHIPPER")}
            canDelete={
              props.shipperData != null &&
              props.shipperData?.companyName != "" &&
              props.userType !== UserType.Shipper
            }
            canEdit={
              props.shipperData != null && props.shipperData?.companyName != ""
            }
            onClickDeleteIcon={onDeleteShipperData}
            onClickEditIcon={() =>
              openPartnerRegisterShipperModal(PartnerModalLabels.EDIT)
            }
            tooltipContent={t("INFO_SHIPPER")}
            error={props.shipperError}
          >
            {props.shipperData == null ||
            props.shipperData?.companyName == "" ? (
              <div className="">
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterShipperModal(PartnerModalLabels.PICK)
                  }
                  active={false}
                >
                  {t("LABEL_PICK_PARTNER")}
                </Button>
                <span className="mx-5">{t("LABEL_OR")}</span>
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterShipperModal(PartnerModalLabels.REGISTER)
                  }
                  active={false}
                >
                  {t("LABEL_REGISTER_NEW")}
                </Button>
              </div>
            ) : (
              <div className="">
                <ul>
                  {props.shipperData?.companyName && (
                    <li>{props.shipperData?.companyName}</li>
                  )}
                  {props.shipperData?.address && (
                    <li>{props.shipperData?.address}</li>
                  )}
                  {props.shipperData?.postalCode && (
                    <li>{props.shipperData?.postalCode}</li>
                  )}

                  {props.shipperData?.placeCity && (
                    <li>
                      {props.shipperData?.placeCity.FullName
                        ? props.shipperData?.placeCity.FullName + ", "
                        : ""}
                      {props.shipperData?.placeCity.Country ??
                        props.shipperData?.placeCity.Country}
                    </li>
                  )}
                  {props.shipperData?.district && (
                    <li>{props.shipperData?.district}</li>
                  )}
                  {props.shipperData?.contact && (
                    <li>{props.shipperData?.contact}</li>
                  )}
                  {props.shipperData?.phone && (
                    <li>{props.shipperData?.phone}</li>
                  )}
                  {props.shipperData?.email && (
                    <li>{props.shipperData?.email}</li>
                  )}
                </ul>
              </div>
            )}
          </LayoutCell>
          <LayoutCell
            id="layout_consignee"
            ref={consigneeRef}
            className="border-2 mx-3 my-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            labelValue={t("LABEL_CONSIGNEE")}
            canDelete={
              props.consigneeData != null &&
              props.consigneeData?.companyName != "" &&
              props.userType !== UserType.Consignee
            }
            canEdit={
              props.consigneeData != null &&
              props.consigneeData?.companyName != ""
            }
            onClickDeleteIcon={onDeleteConsigneeData}
            onClickEditIcon={() =>
              openPartnerRegisterConsigneeModal(PartnerModalLabels.EDIT)
            }
            tooltipContent={t("INFO_CONSIGNEE")}
            error={props.consigneeError}
          >
            {props.consigneeData == null ||
            props.consigneeData?.companyName == "" ? (
              <div className="">
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterConsigneeModal(PartnerModalLabels.PICK)
                  }
                  active={false}
                >
                  {t("LABEL_PICK_PARTNER")}
                </Button>
                <span className="mx-5">{t("LABEL_OR")}</span>
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterConsigneeModal(
                      PartnerModalLabels.REGISTER
                    )
                  }
                  active={false}
                >
                  {t("LABEL_REGISTER_NEW")}
                </Button>
              </div>
            ) : (
              <div className="">
                <ul>
                  {props.consigneeData?.companyName && (
                    <li>{props.consigneeData?.companyName}</li>
                  )}
                  {props.consigneeData?.address && (
                    <li>{props.consigneeData?.address}</li>
                  )}
                  {props.consigneeData?.postalCode && (
                    <li>{props.consigneeData?.postalCode}</li>
                  )}
                  {props.consigneeData?.placeCity && (
                    <li>
                      {props.consigneeData?.placeCity.FullName
                        ? props.consigneeData?.placeCity.FullName + ", "
                        : ""}
                      {props.consigneeData?.placeCity.Country ??
                        props.consigneeData?.placeCity.Country}
                    </li>
                  )}
                  {props.consigneeData?.district && (
                    <li>{props.consigneeData?.district}</li>
                  )}
                  {props.consigneeData?.contact && (
                    <li>{props.consigneeData?.contact}</li>
                  )}
                  {props.consigneeData?.phone && (
                    <li>{props.consigneeData?.phone}</li>
                  )}
                  {props.consigneeData?.email && (
                    <li>{props.consigneeData?.email}</li>
                  )}
                </ul>
              </div>
            )}
          </LayoutCell>
          <LayoutCell
            id="layout_notifier"
            className="border-2 md:border-2 ml-3 mr-6 my-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            labelValue={t("LABEL_NOTIFIER")}
            canDelete={
              props.notifierData != null &&
              props.notifierData?.companyName != ""
            }
            canEdit={
              (props.notifierData != null && props.notifierData?.companyName) !=
              ""
            }
            onClickDeleteIcon={onDeleteNotifierData}
            onClickEditIcon={() =>
              openPartnerRegisterNotifierModal(PartnerModalLabels.EDIT)
            }
            tooltipContent={t("INFO_NOTIFIER")}
            optional
          >
            {props.notifierData == null ||
            props.notifierData?.companyName == "" ? (
              <div className="">
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterNotifierModal(PartnerModalLabels.PICK)
                  }
                  active={false}
                >
                  {t("LABEL_PICK_PARTNER")}
                </Button>
                <span className="mx-5">{t("LABEL_OR")}</span>
                <Button
                  className="mt-2 rounded"
                  type="button"
                  onClick={() =>
                    openPartnerRegisterNotifierModal(
                      PartnerModalLabels.REGISTER
                    )
                  }
                  active={false}
                >
                  {t("LABEL_REGISTER_NEW")}
                </Button>
              </div>
            ) : (
              <div>
                <ul>
                  {props.notifierData?.companyName && (
                    <li>{props.notifierData?.companyName}</li>
                  )}
                  {props.notifierData?.address && (
                    <li>{props.notifierData?.address}</li>
                  )}
                  {props.notifierData?.postalCode && (
                    <li>{props.notifierData?.postalCode}</li>
                  )}
                  {props.notifierData?.placeCity && (
                    <li>
                      {props.notifierData?.placeCity.FullName
                        ? props.notifierData?.placeCity.FullName + ", "
                        : ""}
                      {props.notifierData?.placeCity.Country ??
                        props.notifierData?.placeCity.Country}
                    </li>
                  )}
                  {props.notifierData?.district && (
                    <li>{props.notifierData?.district}</li>
                  )}
                  {props.notifierData?.contact && (
                    <li>{props.notifierData?.contact}</li>
                  )}
                  {props.notifierData?.phone && (
                    <li>{props.notifierData?.phone}</li>
                  )}
                  {props.notifierData?.email && (
                    <li>{props.notifierData?.email}</li>
                  )}
                </ul>
              </div>
            )}
          </LayoutCell>
        </div>
        <div className="flex flex-col md:justify-start md:flex-row justify-between">
          <LayoutCell
            className="border-2 md:border-2 ml-6 mr-3 my-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            labelValue={t("LABEL_COLLECTION_LOCATION")}
            canDelete={false}
            canEdit={false}
            id="layout_from"
          >
            <div onChange={resetIncoterm}>
              <Checkbox
                className="py-1"
                label={t("TEXT_JONAR_COLLECTION")}
                type="radio"
                checked={props.canCollectShipment}
                onChange={onChangeShipmentData}
              />
              {props.canCollectShipment ? (
                <>
                  {props.fromData == null ||
                  props.fromData?.companyName === "" ? (
                    <Button
                      className="my-2 rounded h-8"
                      type="button"
                      onClick={() => openFromRequestCollectionModal(false)}
                      active={false}
                    >
                      {t("LABEL_SET_COLLECTION_DETAILS")}
                    </Button>
                  ) : (
                    <>
                      <div>
                        <ul>
                          {props.fromData?.companyName && (
                            <li>{props.fromData?.companyName}</li>
                          )}
                          {props.fromData?.address && (
                            <li>{props.fromData?.address}</li>
                          )}
                          {props.fromData?.postalCode && (
                            <li>{props.fromData?.postalCode}</li>
                          )}
                          {props.fromData?.placeCity && (
                            <li>
                              {props.fromData?.placeCity.FullName},{" "}
                              {props.fromData?.placeCity.Country}
                            </li>
                          )}
                          {props.fromData?.district && (
                            <li>{props.fromData?.district}</li>
                          )}
                          {props.fromData?.contact && (
                            <li>{props.fromData?.contact}</li>
                          )}
                          {props.fromData?.phone && (
                            <li>{props.fromData?.phone}</li>
                          )}
                          {props.fromData?.email && (
                            <li>({props.fromData?.email})</li>
                          )}

                          {props.fromData?.collectionDate && (
                            <li>
                              {t("Request collection time: ")}
                              {format(
                                DateUtilities.dateToUTC_WithoutTimezoneChange(
                                  props.fromData?.collectionDate
                                ),
                                "dd/MM/yyyy"
                              )}
                            </li>
                          )}
                          <li className="flex">
                            {props.fromData?.from && (
                              <p>{props.fromData?.from}</p>
                            )}
                            {props.fromData?.from && props.fromData?.to && (
                              <p>-</p>
                            )}
                            {props.fromData?.to && <p>{props.fromData?.to}</p>}
                          </li>
                        </ul>
                      </div>
                      <Button
                        className="my-2 rounded h-8"
                        type="button"
                        onClick={() => openFromRequestCollectionModal(true)}
                        active={false}
                      >
                        {t("LABEL_EDIT_COLLECTION")}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {props.canCollectShipment && props.collectionDetailsError ? (
                <div ref={fromRef} className="text-red-600 my-2">
                  <span>{t(props.collectionDetailsError)}</span>
                </div>
              ) : (
                <></>
              )}

              <Checkbox
                className="py-1"
                label={t("TEXT_NO_JONAR_COLLECTION")}
                type="radio"
                checked={props.canSetDeliverToPort}
                onChange={onChangeShipmentData}
              />
              {props.canSetDeliverToPort && (
                <>
                  {!props.canCollectShipment &&
                    props.shipmentType === FreightMode.SH && (
                      <>
                        <SelectEx
                          className="mt-3 mb-5 pr-2 text-base font-normal"
                          label={t("LABEL_PORT_OF_LOAD")}
                          value={props.portOfLoad}
                          onChange={onChangePortOfLoad}
                          options={props.portsOfLoad}
                          getOptionLabel={portLabelMapper}
                          getOptionValue={portValueMapper}
                          name={"port of load"}
                          placeholder={t("TEXT_SEARCH_POL")}
                          hasError={props.polError != null}
                          errorText={translate(props.polError)}
                          isClearable
                        />
                      </>
                    )}
                  {!props.canCollectShipment &&
                    props.shipmentType === FreightMode.AI && (
                      <AsyncSelect
                        label={t("LABEL_PORT_OF_LOAD")}
                        className="text-base font-normal mb-5"
                        name="unit"
                        value={props.portOfLoad}
                        onChange={onChangePortOfLoad}
                        loadOptions={searchAirports}
                        defaultOptions={props.portsOfLoad}
                        getOptionLabel={portLabelMapper}
                        getOptionValue={portValueMapper}
                        placeholder={t("TEXT_SEARCH_POL")}
                        hasError={props.polError != null}
                        errorText={translate(props.polError)}
                        optional={false}
                        isClearable
                      />
                    )}
                  <Link
                    className="text-jonar-blue"
                    icon={faExternalLink}
                    href={
                      i18n.language === "en"
                        ? "https://www.jonar.is/english/offices/"
                        : "https://www.jonar.is/upplysingar/erlendar-skrifstofur/"
                    }
                    target="_blank"
                  >
                    {t("LABEL_VIEW_OPENING_HOURS")}
                  </Link>
                </>
              )}
            </div>
          </LayoutCell>
          <LayoutCell
            id="layout_to"
            className="border-2 md:border-2 mx-3 my-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            labelValue={t("LABEL_DELIVERY_LOCATION")}
            canDelete={false}
            canEdit={false}
          >
            <div onChange={resetIncoterm}>
              <Checkbox
                className="py-1"
                label={t("TEXT_JONAR_DELIVERY")}
                type="radio"
                checked={props.canDeliverShipment}
                onChange={onChangeDeliverData}
              />
              {props.canDeliverShipment ? (
                <>
                  {props.toData == null || props.toData?.companyName === "" ? (
                    <Button
                      className="my-2 rounded h-8"
                      type="button"
                      onClick={() => openToRequestCollectionModal(false)}
                      active={false}
                    >
                      {t("LABEL_SET_DELIVERY_DETAILS")}
                    </Button>
                  ) : (
                    <>
                      <div>
                        <ul>
                          {props.toData?.companyName && (
                            <li>{props.toData?.companyName}</li>
                          )}
                          {props.toData?.address && (
                            <li>{props.toData?.address}</li>
                          )}
                          {props.toData?.postalCode && (
                            <li>{props.toData?.postalCode}</li>
                          )}
                          {props.toData?.placeCity && (
                            <li>
                              {props.toData?.placeCity.FullName},{" "}
                              {props.toData?.placeCity.Country}
                            </li>
                          )}
                          {props.toData?.district && (
                            <li>{props.toData?.district}</li>
                          )}
                          {props.toData?.contact && (
                            <li>{props.toData?.contact}</li>
                          )}
                          {props.toData?.phone && (
                            <li>{props.toData?.phone}</li>
                          )}
                          {props.toData?.email && (
                            <li>({props.toData?.email})</li>
                          )}

                          {props.toData?.collectionDate && (
                            <li>
                              {t("Request collection time: ")}
                              {props.toData?.collectionDate.getDate() +
                                "/" +
                                props.toData?.collectionDate.getMonth() +
                                "/" +
                                props.toData?.collectionDate.getFullYear() +
                                " "}
                            </li>
                          )}
                          <li className="flex">
                            {props.toData?.from && <p>{props.toData?.from}</p>}
                            {props.toData?.from && props.toData?.to && <p>-</p>}
                            {props.toData?.to && <p>{props.toData?.to}</p>}
                          </li>
                        </ul>
                      </div>
                      <Button
                        className="my-2 rounded h-8"
                        type="button"
                        onClick={() => openToRequestCollectionModal(true)}
                        active={false}
                      >
                        {t("LABEL_EDIT_DELIVERY")}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {props.canDeliverShipment && props.deliveryDetailsError && (
                <div ref={toRef} className="text-red-600 my-2">
                  <span>{t(props.deliveryDetailsError)}</span>
                </div>
              )}

              <Checkbox
                className="py-1"
                label={t("TEXT_NO_JONAR_DELIVERY")}
                type="radio"
                checked={props.canSetPickUpPort}
                onChange={onChangeDeliverData}
              />
              {props.canSetPickUpPort && (
                <>
                  {props.shipmentType === FreightMode.SH && (
                    <SelectEx
                      className="mt-3 mb-5 pr-2 text-base font-normal"
                      label={t("LABEL_PORT_OF_DISCHARGE")}
                      value={props.portOfDischarge}
                      onChange={onChangePortOfDischarge}
                      options={props.portsOfDischarge}
                      getOptionLabel={portLabelMapper}
                      getOptionValue={portValueMapper}
                      name={"port of discharge"}
                      placeholder={t("TEXT_SEARCH_POD")}
                      hasError={props.podError != null}
                      errorText={translate(props.podError)}
                      isClearable
                    />
                  )}
                  {props.shipmentType === FreightMode.AI && (
                    <AsyncSelect
                      label={t("LABEL_PORT_OF_DISCHARGE")}
                      className="text-base font-normal mb-5"
                      name="unit"
                      value={props.portOfDischarge}
                      onChange={onChangePortOfDischarge}
                      loadOptions={searchAirports}
                      defaultOptions={props.portsOfDischarge}
                      getOptionLabel={portLabelMapper}
                      getOptionValue={portValueMapper}
                      placeholder={t("TEXT_SEARCH_POD")}
                      optional={false}
                      hasError={props.podError != null}
                      errorText={translate(props.podError)}
                      isClearable
                    />
                  )}
                  <Link
                    className="text-jonar-blue"
                    icon={faExternalLink}
                    href={
                      i18n.language === "en"
                        ? "https://www.jonar.is/english/offices/"
                        : "https://www.jonar.is/upplysingar/erlendar-skrifstofur/"
                    }
                    target="_blank"
                  >
                    {t("LABEL_VIEW_OPENING_HOURS")}
                  </Link>
                </>
              )}
            </div>
          </LayoutCell>
          <LayoutCell
            id="layout_incoterms"
            className="border-2 md:border-2 mr-6 ml-3 my-3 min-h-60 w-12/12 md:w-4/12 py-6 px-6"
            canDelete={false}
            canEdit={false}
          >
            <div className="flex flex-col justify-between">
              <SelectEx
                className="mb-5 px-2 text-base font-normal"
                label={t("LABEL_INCOTERMS")}
                value={props.incoterm}
                onChange={onChangeIncoterm}
                getOptionLabel={incotermLabelMapper}
                getOptionValue={incotermValueMapper}
                options={filteredIncoterms}
                name={"incoterms"}
                disabled={!incotermsEnabled}
                tooltipContent={t("INFO_INCOTERMS")}
                placeholder={t("LABEL_SEARCH_INCOTERMS")}
                hasError={
                  props.incotermError != null && props.incotermError != ""
                }
                errorText={translate(props.incotermError)}
                isLoading={props.loadingIncoterms}
              />
              <SelectEx
                className="mt-3 px-2 text-base font-normal"
                label={t("LABEL_INCOTERMS_LOCATION")}
                value={props.selectedIncotermLocation}
                getOptionLabel={portLabelMapper}
                getOptionValue={portValueMapper}
                onChange={onChangeSelectedIncotermLocation}
                options={props.incotermLocations}
                name={"incoterms location"}
                disabled={!incotermLocationEnabled}
                tooltipContent={t("INFO_INCOTERMS_LOCATION")}
                placeholder={t("LABEL_SEARCH")}
                hasError={
                  props.incotermLocationError != null &&
                  props.incotermLocationError != ""
                }
                errorText={translate(props.incotermLocationError)}
              />
            </div>
          </LayoutCell>
        </div>
        <div className="flex flex-col md:justify-start md:flex-row justify-between">
          <LayoutCell
            id="layout_quote"
            className="border-2 ml-6 mr-6 mt-3 mb-6 min-h-28 w-12/12 md:w-5/7 py-6 px-6"
            canDelete={false}
            canEdit={false}
          >
            <div className="flex md:flex-row flex-col gap-6">
              <InputEx
                className="my-6 w-full"
                label={t("LABEL_QUOTE_NUMBER")}
                type="text"
                placeholder={t("TEXT_QUOTE_NUMBER")}
                id="quoteNumber"
                value={props.quoteNumber != null ? props.quoteNumber : ""}
                onChange={(e) => props.onChangeQuoteNumber(e.target.value)}
                optional
              />
              {props.userType === UserType.Shipper &&
              props.shipmentType === FreightMode.SH ? (
                <SelectEx
                  className="my-6 mr-3"
                  label={t("LABEL_BILL_OF_LADING")}
                  value={props.billOfLading}
                  onChange={onChangeBillOfLading}
                  options={bills.map((b) => ({
                    value: b.value,
                    label: t(b.label),
                  }))}
                  name={"bill of lading"}
                  placeholder={t("LABEL_BILL_OF_LADING")}
                  hasError={
                    props.billOfLadingError != null &&
                    props.billOfLadingError != ""
                  }
                  errorText={translate(props.billOfLadingError)}
                />
              ) : (
                <div className="my-6 mr-3 w-full"></div>
              )}
            </div>
          </LayoutCell>
          <div className="ml-4 mr-6 mt-3 mb-6 min-h-28 w-12/12 md:w-4/12"></div>
        </div>
        <div className="m-6 flex justify-end rounded">
          <Button
            primary
            onClick={() => {
              props.onHandleContinue();
            }}
          >
            {t("LABEL_CONTINUE")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RouteComponent;
