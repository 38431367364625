import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="48.87,45.83 25,4.17 1.13,45.83 	"
      />
      <path
        className={`${styles.st0}`}
        d="M22.72,30.7c0,1.43,1.02,2.6,2.29,2.6c1.26,0,2.28-1.16,2.28-2.6V18.12c0-1.43-1.02-2.6-2.28-2.6
                c-1.26,0-2.29,1.16-2.29,2.6V30.7z"
      />
      <path
        className={`${styles.st0}`}
        d="M25,41.48c1.43,0,2.58-1.16,2.58-2.58c0-1.43-1.16-2.58-2.58-2.58s-2.58,1.16-2.58,2.58
                C22.42,40.33,23.57,41.48,25,41.48z"
      />
    </g>
  </svg>
);

export default SVG;
