import React, { PureComponent } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import Card from "@components/Card/card";

import { FiInfo, FiRefreshCcw, FiSearch } from "react-icons/fi";

import { AppState } from "@state/reducers/types";
import Tooltip from "@components/Tooltip/tooltip";
import Iframe from "react-iframe";
import { ActiveVoyage, Visit } from "@assets/types";

import { fetchActiveVoyages } from "@state/actions/index";

import styles from "./card.module.less";

type MapCardProps = {
  title: string;
  subTitle: string;
  subTitleLink: string;
  subTitleClick: boolean;
  flip: boolean;
  hasAccess: boolean;
  imageClass: string;
  className?: string;
  tooltip?: React.ReactNode;
  fetchActiveVoyages: Function;
  activeVoyages: ActiveVoyage[];
  back: boolean;
};

const vesselmapUrl: string = process.env.VesselMap as string;

class MapCard extends PureComponent<MapCardProps, {}> {
  state = { back: this.props.back };

  async componentDidMount() {
    await this.props.fetchActiveVoyages();
  }

  getCurrentVisit = (visits: Visit[]) => {
    return visits.find(
      (visit) =>
        (!!visit.ATADate && !visit.ATDDate && !!visit.ETDDate) ||
        (!visit.ATADate && !!visit.ETADate)
    );
  };

  renderRow = (voyage: ActiveVoyage, index: number) => {
    const visit = this.getCurrentVisit(voyage.Visits);
    return (
      <div key={index} className="w-2/4 my-4 text-center pb-15px px-4">
        <h3 className="text-lg text-jonar-blue font-medium leading-none">
          {voyage.VesselName.replace("Samskip ", "")}
        </h3>
        <span className="text-xs leading-none">
          {visit &&
            `${visit.PortCode?.slice(2)}, ${
              visit.ATADate ? "ETD:" : "ETA:"
            } @${this.formatTime(
              visit.ATADate ? visit.ETDDate : visit.ETADate,
              visit.TimeZoneDiff
            )}`}
        </span>
      </div>
    );
  };

  formatTime = (dateTime: any, timeZoneDiff: number) => {
    const m = moment(dateTime);
    const startOfHourCheck = moment(dateTime).startOf("hour");
    const halfHourCheck = moment(dateTime).startOf("hour").add(30, "minutes");

    if (m.isSame(startOfHourCheck)) {
      return m.add(timeZoneDiff, "hours").format("HH:mm");
    }
    if (m.isBetween(startOfHourCheck, halfHourCheck)) {
      return m.startOf("hour").add(timeZoneDiff, "hours").format("HH:mm");
    }
    if (m.isSameOrAfter(halfHourCheck)) {
      return m
        .endOf("hour")
        .add(timeZoneDiff, "hours")
        .add(1, "minutes")
        .format("HH:mm");
    }
  };

  render() {
    const {
      title,
      subTitle,
      subTitleLink,
      subTitleClick,
      flip,
      hasAccess,
      className,
      imageClass,
      tooltip,
    } = this.props;
    return (
      <Translation>
        {hasAccess
          ? (t) => {
              return (
                <Card className={`h-280 m-4 ${className} `}>
                  <div
                    className={`shadow-lg content ${
                      this.state.back ? "showBack" : ""
                    }`}
                  >
                    <div
                      className={`front flex flex-col justify-between bg-center bg-cover ${imageClass}`}
                    >
                      <div
                        className={`flex justify-end py-2 px-4 text-lg leading-none text-white`}
                      >
                        {tooltip ? (
                          <Tooltip
                            content={tooltip}
                            childClassName="inline-flex"
                          >
                            <FiInfo className="cursor-help" />
                          </Tooltip>
                        ) : null}
                        {flip ? (
                          <FiRefreshCcw
                            className="ml-3 cursor-pointer"
                            onClick={() => {
                              this.setState({ back: true });
                            }}
                          />
                        ) : null}
                      </div>
                      <div className={`cardTitle absolute w-full text-center`}>
                        <h2 className="text-white text-center text-4xl leading-none font-semibold">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline-block pr-2 pb-1 h-8 w-auto"
                            width="136.089"
                            height="136.089"
                            viewBox="0 0 136.089 136.089"
                          >
                            <path
                              d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                              transform="translate(-3.582 -532.516) rotate(45)"
                              fill="#f66013"
                            />
                          </svg>
                          {title}
                        </h2>
                        {subTitleLink !== "" ? (
                          <a href={subTitleLink}>
                            <div
                              className={`cardQuickLink text-xl leading-5 text-white ${
                                subTitle !== "" ? "visible" : "invisible"
                              }`}
                            >
                              {subTitle}
                            </div>
                          </a>
                        ) : subTitleClick ? (
                          <div
                            onClick={() => {
                              if (flip) {
                                this.setState({ back: true });
                              }
                            }}
                            className={`cardQuickLink text-xl leading-5 text-white cursor-pointer ${
                              subTitle !== "" ? "visible" : "invisible"
                            }`}
                          >
                            {subTitle}
                          </div>
                        ) : (
                          <div
                            className={`cardQuickLink text-xl leading-5 text-white ${
                              subTitle !== "" ? "visible" : "invisible"
                            }`}
                          >
                            {subTitle}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`back flex flex-col justify-between bg-white`}
                    >
                      <div
                        className={`absolute right-0 py-2 px-4 text-lg leading-none text-white`}
                      >
                        {tooltip ? (
                          <Tooltip
                            content={tooltip}
                            childClassName="inline-flex"
                          >
                            <FiInfo className="cursor-help" />
                          </Tooltip>
                        ) : null}
                        {flip ? (
                          <FiRefreshCcw
                            className="ml-3 cursor-pointer"
                            onClick={() => this.setState({ back: false })}
                          />
                        ) : null}
                      </div>
                      <Iframe
                        className="h-full w-full border-none"
                        url={vesselmapUrl}
                        scrolling="no"
                      />
                      <div className="flex overflow-y-hidden bg-white divide-x divide-black divide-opacity-25">
                        {this.props.activeVoyages.map(
                          (voyage: ActiveVoyage, i: number) =>
                            this.renderRow(voyage, i)
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              );
            }
          : (t) => {
              return (
                <Card className={`h-280 m-4  ${className} `}>
                  <div
                    className={`shadow-lg content ${
                      this.state.back ? "showBack" : ""
                    }`}
                  >
                    <div
                      className={`front flex flex-col justify-between bg-center bg-cover ${imageClass}`}
                    >
                      <div className={`cardTitle absolute w-full text-center`}>
                        <h2 className="text-white text-center text-4xl leading-none font-semibold">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline-block pr-2 pb-1 h-8 w-auto"
                            width="136.089"
                            height="136.089"
                            viewBox="0 0 136.089 136.089"
                          >
                            <path
                              d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                              transform="translate(-3.582 -532.516) rotate(45)"
                              fill="#f66013"
                            />
                          </svg>
                          {t(title)}
                        </h2>
                        <div
                          className={`cardQuickLink text-xl leading-5 text-white ${
                            subTitle !== "" ? "visible" : "invisible"
                          }`}
                        >
                          {subTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            }}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
    activeVoyages: state.HomeReducer.activeVoyages,
    loadingVoyages: state.HomeReducer.loadingVoyages,
  }),
  { fetchActiveVoyages }
)(MapCard);
