import { HSCodes } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getHSCodes = createAsyncThunk("registry/hscodes", async () => {
  return await CreateBookingService.getHSCodes();
});

export const searchHSCodes = createAsyncThunk(
  "registry/hscodes/searchString",
  async (searchString: string) => {
    return await CreateBookingService.searchHSCodes(searchString);
  }
);

export interface HSCodesState {
  hsCodes: HSCodes[];
  loadingHSCodes: boolean;
  searchedHSCodes: HSCodes[];
}

export const initialHSCodesState: HSCodesState = {
  hsCodes: [],
  loadingHSCodes: false,
  searchedHSCodes: [],
};

export const hsCodesSlice = createSlice({
  name: "hsCodes",
  initialState: initialHSCodesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHSCodes.pending, (state) => {
        state.loadingHSCodes = true;
      })
      .addCase(getHSCodes.rejected, (state) => {
        state.loadingHSCodes = false;
        state.hsCodes = [];
      })
      .addCase(
        getHSCodes.fulfilled,
        (state, action: PayloadAction<HSCodes[]>) => {
          state.loadingHSCodes = false;
          state.hsCodes = action.payload;
        }
      )
      .addCase(searchHSCodes.rejected, (state) => {
        state.searchedHSCodes = [];
      })
      .addCase(
        searchHSCodes.fulfilled,
        (state, action: PayloadAction<HSCodes[]>) => {
          state.searchedHSCodes = action.payload;
        }
      );
  },
});

export const hsCodesState = (state: RootState) => state.hsCodesSlice;

export default hsCodesSlice.reducer;
