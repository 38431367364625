import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";
import { Request } from "@assets/types";

class RequestsReq {
  jobReference: string;
  requestType: string | null;
}

export const getRequests = createAsyncThunk(
  "Requests",
  async (req: RequestsReq) => {
    return await SingleBookingService.getRequests(
      req.jobReference,
      req.requestType
    );
  }
);

export interface RequestsState {
  requests: Request[];
  loadingRequest: boolean;
}

export const initialRequestsState: RequestsState = {
  requests: [],
  loadingRequest: false,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState: initialRequestsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        state.loadingRequest = true;
      })
      .addCase(getRequests.rejected, (state) => {
        state.loadingRequest = false;
        state.requests = [];
      })
      .addCase(
        getRequests.fulfilled,
        (state, action: PayloadAction<Request[]>) => {
          state.loadingRequest = false;
          state.requests = action.payload;
        }
      );
  },
});

export const requestState = (state: RootState) => state.requestsSlice;

export default requestsSlice.reducer;
