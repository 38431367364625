import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="136.089px"
    y="136.089px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 136.089 136.089"
    {...props}
  >
    <g>
      <path
        d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
        transform="translate(-3.582 -532.516) rotate(45)"
        fill="#f66013"
      />
    </g>
  </svg>
);

export default SVG;
