import React from "react";

import styles from "./button.module.less";

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset" | "button";
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  primary?: boolean;
  disabled?: boolean;
};

const Button = ({
  onClick,
  active,
  type = "button",
  children,
  className,
  primary,
  disabled,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`transition duration-500 ease-in-out hover:bg-gray-200 focus:bg-gray:200 border border-jonar-blue font-semibold rounded ${
        styles.buttonWrapper
      } ${className} ${
        disabled
          ? "bg-gray-200 hover:bg-gray-200  focus:bg-gray-200  text-neutral-600 border-neutral-400 text-opacity-85"
          : ""
      }
         ${
           primary
             ? "bg-jonar-blue hover:bg-primary-600 focus:bg-primary-800 text-neutral-0"
             : "bg-neutral-0 text-jonar-blue hover:bg-gray-100 focus:bg-gray-100"
         } transform focus:outline-none px-3 py-2 ${active ? "spinner" : ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
