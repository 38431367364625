import React from "react";
import styles from "./input.module.less";

type InputProps = {
  className?: string;
  type:
    | "username"
    | "password"
    | "search"
    | "text"
    | "number"
    | "date"
    | "time"
    | "email";
  value: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoComplete?: boolean;
  placeholder?: string;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  onKeyPress?: Function;
};

const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  onChange,
  onBlur,
  className,
  autoComplete,
  placeholder,
  disabled,
  minLength,
  maxLength,
  onKeyPress,
}) => (
  <input
    name={name}
    className={`border-transparent focus:border-transparent focus:ring-0 appearance-none w-full ${styles.input} ${className}`}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    autoComplete={autoComplete ? "on" : "off"}
    disabled={disabled}
    minLength={minLength}
    maxLength={maxLength}
    onKeyPress={onKeyPress ? (e) => onKeyPress(e) : undefined}
  ></input>
);

export default Input;
