class ArrayUtilities {
  /**
   * Compares _array_ against one other array and returns the values that are in _array_ but not in the other.
   * @param array The array to compare from
   * @param other The array to compare against
   * @returns The values that are in _array_ but not in the other.
   */
  difference = <T>(array: T[], other: T[]): T[] => {
    return array.filter((el: any) => !other.some((exclude) => el === exclude));
  };

  /**
   * Searches for the first object in a list based on a value of a property.
   * @param list The list of objects
   * @param value The value based on we can identify the searched object
   * @param propertyName The property that contains the value
   * @returns {T | undefined} If found in the list returns the full object
   */
  getObject = <T>(
    list: T[],
    value: string | number,
    propertyName: string
  ): T | undefined => {
    return list.find((element) => element[propertyName] === value);
  };

  /**
   * Generate a list of integers from _start_ to _stop_, incremented by _step_
   * @param start {number} Integer specifying at which position to start (inclusive)
   * @param end {number} Integer specifying at which position to stop (inclusive)
   * @param step {number} Positive integer specifying the incrementation. Default is 1
   * @returns {number[]} List of integers
   */
  range = (start: number, end: number, step: number = 1): number[] => {
    return Array.from(
      { length: (end - start) / step + 1 },
      (_, i) => start + i * step
    );
  };

  /**
   * Make collection of objects unique by a property
   * @param array Collection of objects
   * @param key Property to make collection unique
   * @returns The array of objects without the duplicated objects based on the given key.
   */
  uniqueBy = <T, Key extends keyof T>(array: T[], key: Key): T[] => {
    return array.filter((e: T, i: number) => {
      return array.findIndex((a: T) => a[key] === e[key]) === i;
    });
  };

  /**
   * Filter out the given values from the array
   * @param array Collection to filter from
   * @param values Values to exclude from collection
   */
  without = <T>(array: T[], ...values: T[]): T[] => {
    return array.filter((el) => !values.some((exclude) => el === exclude));
  };

  /**
   * Sort alphabetically ascending
   * @param array
   */
  sortAlphabeticallyAscending = <T>(field: string, array: T[]): T[] => {
    return array.sort(function (a, b) {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
  };

  /**
   * Sort alphabetically descending
   * @param array
   */
  sortAlphabeticallyDescending = <T>(field: string, array: T[]): T[] => {
    return array.sort(function (a, b) {
      if (a[field] > b[field]) {
        return -1;
      }
      if (a[field] < b[field]) {
        return 1;
      }
      return 0;
    });
  };
}

export default new ArrayUtilities();
