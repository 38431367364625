import React, { PureComponent } from "react";

import styles from "./listFilterLayout.module.less";

import IconButton from "@components/Button/iconButton";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

type LayoutProps = {
  children: React.ReactNode;
  mobileFilter: boolean;
  showFilterSearchButtons?: boolean;
};

//const ListFilterLayout: React.FC<LayoutProps> = ({ children, mobileFilter = false }) => {
class ListFilterLayout extends PureComponent<LayoutProps> {
  state = {
    mobileFilter: false,
    searchString: "",
    dateRange: "",
  };
  render() {
    const { children } = this.props;

    return (
      <div
        className={`listFilterLayout w-full mx-auto my-8 px-2 lg:px-8 transition duration-300 ease-in-out overflow-visible ${styles.listFilterLayout}`}
      >
        <div
          className={`listFilterLayoutInner flex flex-col relative md:flex-row justify-items-stretch ${styles.innerList} bg-white shadow-lg`}
        >
          {this.props.showFilterSearchButtons ? (
            <>
              <span className="arrow-filter-container"></span>
              <IconButton
                disabled={false}
                onClick={() => {
                  document.getElementById("dpFilterPanel").scrollTop -= 50;
                }}
                active={false}
                className={`arrow-filter-scroll-up lg:px-2`}
              >
                <FiArrowUp className="text-base" />
              </IconButton>
              <IconButton
                disabled={false}
                onClick={() => {
                  document.getElementById("dpFilterPanel").scrollTop += 50;
                }}
                active={false}
                className={`arrow-filter-scroll-down lg:px-2`}
              >
                <FiArrowDown className="text-base" />
              </IconButton>
            </>
          ) : null}
          {children}
        </div>
      </div>
    );
  }
}

export default ListFilterLayout;
