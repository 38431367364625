import { Action } from "@assets/types";
import i18next from "i18next";
import React, { PureComponent } from "react";
import { FaSpinner } from "react-icons/fa";

import styles from "./actionMenu.module.less";

const OldServiceWeb = process.env.OldServiceWeb;

type ActionMenuProps = {
  open?: boolean;
  updateState: Function;
  getOptions: Function;
  onOpen: Function;
  onClose: Function;
};

type ActionMenuState = {
  isFetching: boolean;
  mounted: boolean;
};
class ActionMenu extends PureComponent<ActionMenuProps, ActionMenuState> {
  state = {
    options: [],
    isFetching: false,
    mounted: false,
  };

  async fetchTypesAsyncData() {
    if (this.state.isFetching === true && this.props.open === true) {
      await this.props
        .getOptions()
        .then((resp: ActionMenuState) => {
          const resOptions = resp;
          if (this.state.mounted === true) {
            this.setState({ isFetching: false });
            return this.setState({ options: resOptions });
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  renderOptions = (options: Action[]) => {
    let lastSection = options[0]?.Section ?? 0;
    this.fetchTypesAsyncData();
    if (
      this.state.isFetching === false &&
      options.length > 0 &&
      this.props.open === true
    ) {
      let lastSection = options[0]?.Section ?? 0;
      return (
        <ul>
          {options.map((option: Action) => {
            const res = (
              <React.Fragment key={option.ID}>
                {option.Section > lastSection ? (
                  <li>
                    <hr className="my-2" />
                  </li>
                ) : null}
                <li key={option.ID}>
                  {!option.OpenInWindow ? (
                    <a
                      target="_blank"
                      href={`${OldServiceWeb}/${option.Link}`}
                      rel="noreferrer"
                    >
                      {i18next.t(option.Translation)}
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      className="actionFunction"
                      onClick={() => {
                        option.onClick
                          ? option.onClick()
                          : window.open(
                              `${OldServiceWeb}/${option.Link}`,
                              "_blank",
                              "location=yes,height=780,width=700,scrollbars=yes,status=yes"
                            );
                        this.props.onClose();
                      }}
                      rel="noreferrer"
                    >
                      {i18next.t(option.Translation)}
                    </a>
                  )}
                </li>
              </React.Fragment>
            );
            lastSection = option.Section;
            return res;
          })}
        </ul>
      );
    } else {
      return <FaSpinner className="fa-spin inline" />;
    }
  };

  render() {
    const { options } = this.state;
    return (
      <div
        className={`relative ${styles.actionMenu} ${
          this.props.open ? "open z-20" : ""
        }`}
      >
        <div className={`${styles.actionMenu__actionList}`}>
          {this.renderOptions(options)}
        </div>
        <button
          className={`relative ${
            styles.actionMenu__trigger
          } focus:outline-none ${this.props.open === true ? "z-20" : ""}`}
          onClick={() => {
            if (this.props.open) {
              this.props.onClose();
            } else if (this.props.open === false) {
              this.setState({ isFetching: true });
              this.props.onOpen();
            }
          }}
        >
          <span></span>
        </button>
      </div>
    );
  }
}

export default ActionMenu;
