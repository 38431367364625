import React from "react";
import { CargoBookingResponse } from "@assets/types";
import { useTranslation } from "react-i18next";
import LayoutCell from "@components/LayoutCell/layoutCell";
import ContainerCell from "@components/ContainerCell/containerCell";
import { ShippingTypeItem, ShippingTypeValue } from "@assets/constants";
import Divider from "@components/Divider/divider";
import Spinner from "@components/Spinner/spinner";

interface CargoPageProps {
  cargoBooking?: CargoBookingResponse | null;
  freightMode: string;
  isLoadingCargo: boolean;
}

const CargoComponent: React.FC<CargoPageProps> = (props: CargoPageProps) => {
  const { t } = useTranslation();

  const handleShippingType = (shippingType: string): string => {
    if (shippingType === ShippingTypeValue.LL) {
      return ShippingTypeItem.LL;
    }

    if (shippingType === ShippingTypeValue.FL) {
      return ShippingTypeItem.FL;
    }

    if (shippingType === ShippingTypeValue.LF) {
      return ShippingTypeItem.LF;
    }

    if (shippingType === ShippingTypeValue.FF) {
      return ShippingTypeItem.FF;
    }

    return t("TEXT_NOT_PROVIDED");
  };

  const containerData = props.cargoBooking?.Containers?.map(
    (container, index) => (
      <ContainerCell
        key={`container-${index}`}
        marksIndex={index}
        container={container}
        containerIndex={index + 1}
        marksAndNumbers={props.cargoBooking?.MarksAndNumbers ?? []}
        shippingTypeValue={props.cargoBooking?.ShippingType}
        freightMode={props.freightMode}
      />
    )
  );

  return (
    <>
      {props.isLoadingCargo ? (
        <Spinner className="w-full flex align-center justify-center" />
      ) : (
        <div className="w-full">
          <div className="flex flex-col md:flex-row md:justify-between">
            <LayoutCell
              id="cargo_shipping_type"
              className="mx-8 mt-6 min-h-40 md:w-1/3 w-12/12"
              labelValue={t("LABEL_SHIPPING_TYPE")}
            >
              <div>
                {props.cargoBooking?.ShippingType
                  ? handleShippingType(props.cargoBooking?.ShippingType)
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
          </div>
          <Divider className="my-8" />
          {containerData}
          <Divider className="my-8" />
          <div className="flex flex-col md:flex-row md:justify-between">
            <LayoutCell
              id="cargo_hscodes"
              className="mb-6 mx-8 min-h-40 w-12/12"
              labelValue={t("LABEL_HSCODES")}
            >
              <div className="text-neutral-9 leading-5 text-sm font-normal">
                {props.cargoBooking?.HsCodes &&
                props.cargoBooking?.HsCodes?.length > 0
                  ? props.cargoBooking?.HsCodes?.map(
                      (h) => `${h.HSCode} ${h.Description}`
                    ).join("; ")
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
          </div>
        </div>
      )}
    </>
  );
};

export default CargoComponent;
