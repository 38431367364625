import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="29.32,43.84 29.32,14.81 41.35,14.81 41.35,6.16 8.65,6.16 8.65,14.81 20.68,14.81 20.68,43.84"
      />
    </g>
  </svg>
);

export default SVG;
