import axios, { AxiosResponse } from "axios";

import { saveAs } from "file-saver";

import moment from "moment";

const JonarAPI = process.env.JonarAPI;

import {
  Booking,
  ActiveBooking,
  BookingFilters,
  BookingDetail,
  FavoriteBooking,
  BookingAction,
  BuyersConsoleBooking,
  WebNote,
  JonarAPIResponse,
  BuyersConsoleSubBooking,
} from "@assets/types";
import { BookingStatus } from "@assets/constants";
class BookingService {
  getActiveBookings = async (
    partner: string,
    freightMode: string
  ): Promise<ActiveBooking[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/active`,
        {
          freightMode: freightMode,
          partnerCode: partner,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<ActiveBooking[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBookingList = async (filters: BookingFilters): Promise<Booking[]> => {
    return await axios
      .post(`${JonarAPI}/bookings`, filters, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Booking[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBookingDetails = async (
    jobReference: string
  ): Promise<BookingDetail[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/details`,
        { jobReference },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<BookingDetail[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  searchBookingsSimple = async (
    searchString: string,
    freightMode: string
  ): Promise<object[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/search/simple`,
        {
          searchString: searchString,
          freightMode: freightMode,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<object[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getFavoriteBookings = async (): Promise<string[]> => {
    return await axios
      .get(`${JonarAPI}/bookings/favorites`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<FavoriteBooking[]>>) => {
        return res.data.ReturnItem.Data && res.data.ReturnItem.Data.length > 0
          ? res.data.ReturnItem.Data.map(
              (booking: FavoriteBooking) => booking.JobReference
            )
          : [];
      });
  };

  toggleFavoriteBooking = async (jobReference: string): Promise<object[]> => {
    return await axios.put(
      `${JonarAPI}/bookings/favorites`,
      {
        jobReference,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      }
    );
  };

  getBookingsInExcel = async (bookings: Booking[]): Promise<void> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/excel`,
        { bookings },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Expose-Headers": "Content-Disposition",
          },
          withCredentials: true,
          responseType: "blob",
        }
      )
      .then((res: AxiosResponse<Blob>) => {
        saveAs(
          res.data,
          `BookingList-${moment(new Date()).format("DD.MM.YYYY")}.xlsx`
        );
      });
  };

  getBookingActions = async (
    jobReference: string,
    status: BookingStatus,
    isCustomsCleared: boolean
  ): Promise<BookingAction[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/requests/types`,
        {
          jobReference,
          status,
          isCustomsCleared,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<BookingAction[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBuyersConsoleBookings = async (
    jobReference: string
  ): Promise<BuyersConsoleBooking[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/bc`,
        {
          jobReference,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<BuyersConsoleBooking[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBCSubBookings = async (
    jobReference: string
  ): Promise<BuyersConsoleSubBooking[]> => {
    return await axios
      .get(`${JonarAPI}/bookings/bcsubbookings/${jobReference}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then(
        (res: AxiosResponse<JonarAPIResponse<BuyersConsoleSubBooking[]>>) => {
          return res.data.ReturnItem.Data;
        }
      );
  };

  getWebNote = async (
    jobReference: string,
    isInternalNote: boolean
  ): Promise<WebNote> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/note`,
        {
          jobReference,
          isInternalNote,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<WebNote>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getWebNotes = async (jobReference: string): Promise<WebNote[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/notes`,
        {
          jobReference,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<WebNote[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  createWebNote = async (
    jobReference: string,
    note: string,
    internalOnly: boolean
  ): Promise<string> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/note/create`,
        {
          jobReference,
          note,
          internalOnly,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<string>>) => {
        return res.statusText;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  deleteNote = async (jobReference: string, recId: string) => {
    try {
      const response = await axios.delete(
        `${JonarAPI}/bookings/note/delete/${jobReference}/webnote/${recId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getRemarks = async (jobReference: string): Promise<WebNote[]> => {
    return await axios
      .post(
        `${JonarAPI}/bookings/remarks`,
        {
          jobReference,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<WebNote[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };
}

export default new BookingService();
