import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="19.24,29.22 10.1,20.08 3.99,26.2 19.24,41.45 46.01,14.67 39.9,8.55 	"
      />
    </g>
  </svg>
);

export default SVG;
