import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BuyersConsoleSubBooking,
  RouteBookingResponse,
  SubBookingDocuments,
  UploadDocumentsResponse,
  UserProfileViewModel,
} from "@assets/types";
import Link from "@components/Link/link";
import { toast } from "react-toastify";
import { downloadFile, openFile } from "@assets/utilities/fileUtilities";
import moment from "moment";
import UploadDocumentsModal from "@components/Modal/uploadDocumentsModal";
import SendDocumentsEmailModal from "@components/Modal/sendDocumentsEmailModal";
import { FreightMode } from "@assets/constants";
import Button from "@components/Button/button";
import Page from "@components/Page/page";
import Alert from "@components/Alert/alert";
import { FaInfoCircle } from "react-icons/fa";
import DeleteModal from "@components/Modal/deleteModal";
import { UserType } from "@assets/constants";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import {
  deleteDocument,
  documentState,
  getReference,
  resetUploadDocumentsState,
} from "@state/slices/documentsSlice";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import BookingService from "@services/BookingService";
import SingleBookingService from "@services/SingleBookingService";
import { ActionTable } from "@samskip/frontend-components";
import Spinner from "@components/Spinner/spinner";
import { ActionTableProps } from "@samskip/frontend-components/dist/components/Table/actionTable";
import LabelEx from "@components/Label/labelEx";

interface DocumentsPageProps {
  userProfile: UserProfileViewModel | undefined | null;
  jobReference: string;
  freightMode: string;
  routeBooking: RouteBookingResponse | null;
}

type UploadDocumentsModal = {
  openUploadDocuments: boolean;
};

type SendDocumentsEmailModal = {
  openSendDocumentsEmailModal: boolean;
};

const DocumentsComponent: React.FC<DocumentsPageProps> = (
  props: DocumentsPageProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const documentByReference = useAppSelector(documentState).reference;
  const loadingDocumentByReference =
    useAppSelector(documentState).loadingDocumentByReference;
  const uploadStatus = useAppSelector(documentState).uploadStatus;

  const [uploadDocumentsModal, setUploadDocumentsModal] =
    useState<UploadDocumentsModal>({
      openUploadDocuments: false,
    });

  const [sendDocumentsEmailModal, setSendDocumentsEmailModal] =
    useState<SendDocumentsEmailModal>({
      openSendDocumentsEmailModal: false,
    });

  const [deleteModal, setDeleteModal] = useState<{ open: boolean }>({
    open: false,
  });

  const [fileId, setFileId] = useState<string | null>(null);

  const [selectedDocuments, setSelectedDocuments] = useState<
    UploadDocumentsResponse[]
  >([]);

  const [subBookingsDocuments, setSubBookingsDocuments] = useState<
    SubBookingDocuments[] | null
  >(null);

  const [loadingSubbookings, setLoadingSubbookings] = useState<boolean>(false);

  const handleSubbookings = () => {
    setLoadingSubbookings(true);

    const getSubbookings = async (s: BuyersConsoleSubBooking[]) => {
      let subBookingDocuments: SubBookingDocuments[] = [];
      for (let item of s) {
        const docs = await SingleBookingService.getReference(item.JobReference);
        const subbookingDocs: SubBookingDocuments = {
          JobReference: item.JobReference,
          ShipperName: item.ShipperName,
          ConsigneeName: item.ConsigneeName,
          Documents: docs,
        };
        subBookingDocuments = [...subBookingDocuments, subbookingDocs];
      }
      return subBookingDocuments;
    };

    if (
      props.routeBooking &&
      props.routeBooking.HBLFlag &&
      documentByReference
    ) {
      BookingService.getBCSubBookings(props.jobReference).then((s) => {
        getSubbookings(s).then((subBookingDocuments) => {
          setSubBookingsDocuments(subBookingDocuments);
          setLoadingSubbookings(false);
        });
      });
    }
  };

  useEffect(() => {
    if (documentByReference.length === 0) {
      dispatch(getReference(props.jobReference));
    }
    handleSubbookings();
  }, [window.location.href.split("#")[1] === "Documents"]);

  useEffect(() => {
    if (uploadStatus === "OK") {
      toast.success(t("TEXT_FILE_UPLOADED"));
      dispatch(getReference(props.jobReference));
    } else if (uploadStatus === "failed") {
      toast.error(t("ERROR_FILE_DID_NOT_UPLOAD"));
    }
    dispatch(resetUploadDocumentsState());
  }, [uploadStatus]);

  const openUploadDocumentsModal = () => {
    const uploadDocModal = { ...uploadDocumentsModal };
    uploadDocModal.openUploadDocuments = true;
    setUploadDocumentsModal(uploadDocModal);
  };

  const closeUploadDocumentsModal = () => {
    const uploadDocModal = { ...uploadDocumentsModal };
    uploadDocModal.openUploadDocuments = false;
    setUploadDocumentsModal(uploadDocModal);
  };

  const openSendDocumentsEmailModal = () => {
    const sendDocumentEmailModal = { ...sendDocumentsEmailModal };
    sendDocumentEmailModal.openSendDocumentsEmailModal = true;
    setSendDocumentsEmailModal(sendDocumentEmailModal);
  };

  const closeSendDocumentsEmailModal = () => {
    const sendDocumentEmailModal = { ...sendDocumentsEmailModal };
    sendDocumentEmailModal.openSendDocumentsEmailModal = false;
    setSendDocumentsEmailModal(sendDocumentEmailModal);
  };

  const onHandleSelectedDocuments = (selectedDocumentsIds: string[]) => {
    let documents: UploadDocumentsResponse[] = [];
    for (let id in selectedDocumentsIds) {
      const doc = documentByReference[id];
      documents = [...documents, doc];
    }

    setSelectedDocuments(documents);
  };

  const isEmployee = props.userProfile?.Access.UserType === UserType.EMPLOYEE;

  const openDeleteModal = (docID: string | null) => {
    setFileId(docID);

    const openDeleteModal = {
      open: true,
    };
    setDeleteModal(openDeleteModal);
  };

  const closeDeleteModal = () => {
    const closeDeleteModal = {
      ...deleteModal,
      open: false,
    };
    setDeleteModal(closeDeleteModal);
  };

  const getBookingDocumentsColumns = (documents: UploadDocumentsResponse[]) => {
    const bookingDocumentsColumns: ActionTableProps<UploadDocumentsResponse>["columns"] =
      [
        {
          dataIndex: "",
          id: "checkbox",
          title: "",
        },
        {
          dataIndex: "Name",
          id: "fileName",
          title: t("LABEL_FILENAME"),
          render: (document) => (
            <span className="filename-container max-w-xs whitespace-normal break-words">
              {document.Name}
            </span>
          ),
        },
        {
          dataIndex: "Type",
          id: "docType",
          title: t("LABEL_DOCUMENT_TYPE"),
        },
        {
          dataIndex: "Created",
          id: "uploadOn",
          title: t("LABEL_UPLOADED_ON"),
          render: (document) =>
            moment(document.Created).format("DD MMM YYYY").toString(),
        },
        {
          dataIndex: "Description",
          id: "uplodedBy",
          title: t("LABEL_UPLOADED_BY"),
          render: (document) =>
            document.Description ? document.Description : "-",
        },
        {
          dataIndex: "",
          id: "download-all",
          title: (
            <Link
              className="text-jonar-blue"
              onClick={() => onDownloadAll(documents)}
              target="_blank"
            >
              {t("LABEL_DOWNLOAD_ALL")}
            </Link>
          ),
          render: (document: { ETag: string | null; Name: string | null }) => (
            <Link
              className="text-jonar-blue"
              onClick={() => onDownload(document.ETag, document.Name)}
              target="_blank"
            >
              {t("LABEL_DOWNLOAD")}
            </Link>
          ),
        },
      ];
    return bookingDocumentsColumns;
  };

  const handleDelete = (docID: string | null) => {
    dispatch(deleteDocument(docID !== null ? docID : ""));
    toast.success(t("TEXT_DOCUMENT_DELETED"));
    dispatch(getReference(props.jobReference));
    handleSubbookings();
  };

  const onDownloadAll = (documents: UploadDocumentsResponse[]) => {
    documents?.forEach(async (x) => {
      onDownload(x.ETag, x.Name);
    });
  };

  const onDownload = async (id: string | null, name: string | null) => {
    if (id != null && name != null) {
      SingleBookingService.getDocument(id)
        .then((b) => {
          toast.success(t("LABEL_FILE_FOUND"));
          openFile(b);
          downloadFile(name, b);
        })
        .catch((err) => {
          toast.error(t("LABEL_FILE_NOT_FOUND"));
        });
    }
  };

  const renderTableByIndex = (
    jobReference: string,
    documents: UploadDocumentsResponse[]
  ) => {
    return (
      <div className="my-6">
        <ActionTable
          columns={getBookingDocumentsColumns(documents)}
          getRowId={(doc) => doc.ID}
          id={`subbooking-document-${jobReference}`}
          rows={documents}
          isBordered={false}
          canRemove={isEmployee}
          onRemove={(id) => openDeleteModal(id)}
          scrollable={false}
          headerTextCase="none"
        />
      </div>
    );
  };

  const subBookingSection = subBookingsDocuments?.map((item, index) => (
    <div key={`subbooking-${index}`}>
      <div className="grid grid-cols-3 mb-4">
        <div className="flex items-center">
          <div className="rounded-full bg-jonar-blue h-6 w-6 text-white m-2 flex justify-center items-center">
            {index + 1}
          </div>
          <div className="flex flex-col">
            <label className="text-neutral-7">
              {t("LABEL_BOOKING_REFERENCE")}
            </label>
            {item.JobReference ? (
              <div>
                <Link
                  icon={faArrowUpRightFromSquare}
                  href={`/singleBooking/${props.freightMode}/${item.JobReference}`}
                  target="_blank"
                >
                  {item.JobReference}
                </Link>
              </div>
            ) : (
              <div>{"N/A"}</div>
            )}
          </div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_SHIPPER")}</label>
          <div className="text-neutral-9">{item.ShipperName ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_CONSIGNEE")}</label>
          <div className="text-neutral-9">{item.ConsigneeName ?? "N/A"}</div>
        </div>
      </div>
      {renderTableByIndex(item.JobReference, item.Documents)}
    </div>
  ));

  return (
    <>
      <UploadDocumentsModal
        closeIcon
        closeOnOutsideClick={false}
        open={uploadDocumentsModal.openUploadDocuments}
        closeModal={() => closeUploadDocumentsModal()}
        jobReference={props.jobReference}
        freightMode={
          props.freightMode === FreightMode.SH ? FreightMode.SH : FreightMode.AI
        }
      ></UploadDocumentsModal>
      <SendDocumentsEmailModal
        closeIcon
        closeOnOutsideClick={false}
        open={sendDocumentsEmailModal.openSendDocumentsEmailModal}
        closeModal={() => closeSendDocumentsEmailModal()}
        documents={documentByReference ?? []}
        selectedDocumentsIndex={selectedDocuments}
      ></SendDocumentsEmailModal>
      {deleteModal.open && (
        <DeleteModal
          open={deleteModal.open}
          closeModal={closeDeleteModal}
          onDelete={() => handleDelete(fileId)}
          content={t("TEXT_DELETE_DOCUMENT")}
          header={t("LABEL_DELETE_DOCUMENT")}
        />
      )}
      <div className="md:my-6 md:ml-6 flex gap-4 justify-center md:justify-end rounded my-6 ml-40">
        <Button onClick={() => openUploadDocumentsModal()}>
          {t("LABEL_UPLOAD_DOCUMENTS")}
        </Button>

        <Button
          onClick={() => openSendDocumentsEmailModal()}
          disabled={selectedDocuments.length <= 0}
        >
          {t("LABEL_SEND_EMAIL")}
        </Button>
      </div>
      {loadingDocumentByReference && loadingSubbookings ? (
        <Spinner className="w-full flex align-center justify-center" />
      ) : (
        <>
          <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
            <div className="w-full">
              {documentByReference && documentByReference.length == 0 ? (
                <div className="text-neutral-700 px-8 py-6 text-sm">
                  {t("TEXT_NO_DOCUMENTS_AVAILABLE")}
                </div>
              ) : (
                <div className="mx-8 my-6">
                  <LabelEx className="text-lg font-semibold text-neutral-900 mb-6">
                    {t("LABEL_BUYERS_CONSOL")}
                  </LabelEx>
                  <ActionTable
                    columns={getBookingDocumentsColumns(documentByReference)}
                    getRowId={(doc) => doc.ID}
                    id={"booking-document"}
                    rowSelection={{
                      allowSingleRowSelection: true,
                      onChange: (selectedRows) => {
                        onHandleSelectedDocuments(selectedRows);
                      },
                    }}
                    rows={documentByReference}
                    isBordered={false}
                    canRemove={isEmployee}
                    onRemove={(id) => openDeleteModal(id)}
                    scrollable={false}
                    headerTextCase="none"
                  />
                </div>
              )}
            </div>
          </Page>
          {subBookingsDocuments && (
            <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
              <div className="w-full">
                <div className="mx-8 my-6">
                  <LabelEx className="text-lg font-semibold text-neutral-900 mb-6">
                    {t("LABEL_BUYERS_CONSOL_SUB")}
                  </LabelEx>
                  {subBookingSection}
                </div>
              </div>
            </Page>
          )}
        </>
      )}
      {documentByReference && documentByReference.length > 0 && (
        <Alert className="py-2 px-5 mt-6 text-base flex items-start md:w-full w-375px rounded">
          <FaInfoCircle
            className={`text-jonar-blue left-2 right-2 top-2 bottom-2 mr-2 mt-1 w-10`}
          />
          {t("INFO_REPLACE_DOCUMENTS")}
        </Alert>
      )}
    </>
  );
};

export default DocumentsComponent;
