import axios from "axios";
import { navigate } from "gatsby";

import store from "@state/store";
import {
  LoginState,
  UserProfileViewModel,
  UserAccess,
  ResetPasswords,
} from "@assets/types";
import { UserRole, UserType } from "@assets/constants";
import { loginConstants } from "@state/actions/types";

const AuthAPI = process.env.AuthAPI;
const OldServiceWeb = process.env.OldServiceWeb;

class AuthService {
  login = async (
    LoginVM: LoginState,
    options?: { tokenRefresh?: boolean }
  ): Promise<UserProfileViewModel> => {
    if (!options || !options.tokenRefresh)
      store.dispatch({ type: loginConstants.OLD_LOGIN_REQUEST });
    const formData = new FormData();
    formData.append("Username", LoginVM.username);
    formData.append("Password", LoginVM.password);
    fetch(`${OldServiceWeb}/Logon.aspx`, {
      method: "post",
      credentials: "include",
      mode: "no-cors",
      body: formData,
    }).then(() => store.dispatch({ type: loginConstants.OLD_LOGIN_SUCCESS }));

    return await axios
      .post(
        `${AuthAPI}/auth/loginjt`,
        {
          Username: LoginVM.username,
          Password: LoginVM.password,
          Subsys: 1049,
        },
        { withCredentials: true }
      )
      .then(async (response) => {
        return response.data;
      });
  };

  logout = async (userAuth: UserAccess) => {
    await axios.post(
      `${AuthAPI}/auth/logout`,
      { UserId: userAuth.UserID, Subsys: userAuth.SubSys },
      { withCredentials: true }
    );
    await navigate("/login");
  };

  isLoggedIn = async (): Promise<boolean> => {
    const { userProfile } = store.getState().UserReducer;
    const User = userProfile && userProfile.User ? userProfile.User : null;
    return User && User.Username ? true : false;
  };

  isUserAdmin = (): boolean => {
    const profile = store.getState().UserReducer.userProfile;
    return (
      (profile && profile.Roles?.some((r) => r.RoleID === UserRole.ADMIN)) ||
      false
    );
  };

  isUserEmployee = (): boolean => {
    const profile = store.getState().UserReducer.userProfile;
    return (profile && profile.Access.UserType === UserType.EMPLOYEE) || false;
  };

  isUserEmployeeOrAgent = (): boolean => {
    const profile = store.getState().UserReducer.userProfile;
    return (
      (profile &&
        [UserType.EMPLOYEE, UserType.AGENT].some(
          (type) => type === profile.Access.UserType
        )) ||
      false
    );
  };

  hasUserAccessToRoles = (roles: number[]): boolean => {
    if (this.isUserAdmin()) return true;
    const profile = store.getState().UserReducer.userProfile;
    return (
      (profile &&
        profile.Roles?.some((r) => roles.some((val) => val === r.RoleID))) ||
      false
    );
  };

  forgottenPassword = async (email: string): Promise<void> => {
    await axios.post(
      `${AuthAPI}/auth/forgottenpassword/${email}/?system=1049`,
      {}
    );
  };

  resetPassword = async (
    hash: string,
    resetPasswords: ResetPasswords
  ): Promise<void> => {
    await axios.post(`${AuthAPI}/auth/resetpassword/${hash}`, resetPasswords);
  };
}

export default new AuthService();
