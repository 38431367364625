import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableInfo } from "@assets/types";
import Table from "@components/Table/table";
import moment from "moment";
import { Link } from "@components/Link/link";
import StringUtilities from "utilities/StringUtilities";
import Page from "@components/Page/page";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { invoiceState, getInvoicesUrls } from "@state/slices/invoicesSlice";

interface InvoicesPageProps {
  jobReference: string;
  sapID: string;
}

const InvoicesComponent: React.FC<InvoicesPageProps> = (
  props: InvoicesPageProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const invoices = useAppSelector(invoiceState).invoices;
  const loadingInvoices = useAppSelector(invoiceState).loadingInvoices;

  useEffect(() => {
    if (invoices.length === 0) {
      dispatch(
        getInvoicesUrls({
          jobReference: props.jobReference,
          sapID: props.sapID,
        })
      );
    }
  }, [window.location.href.split("#")[1] === "Invoices"]);

  const InvoicesTable: TableInfo = {
    tableHeader: [
      t("LABEL_INVOICE"),
      t("LABEL_AMOUNT"),
      t("LABEL_CURRENCY"),
      t("LABEL_DATE"),
      "",
    ],
    tableData: invoices
      ? invoices?.map((invoice, index) => {
          return [
            invoice.InvoiceID,
            invoice.Amount
              ? StringUtilities.numberCommaDecimal(parseInt(invoice.Amount))
              : t("LABEL_NA"),
            invoice.Curreny,
            moment(invoice.Date).format("DD MMM YYYY").toString(),
            <Link
              key={`view_invoice-${invoice.InvoiceID}`}
              className="text-jonar-blue"
              onClick={() => window.open(invoice.Url ?? "")}
            >
              {t("LABEL_VIEW_INVOICE")}
            </Link>,
          ];
        })
      : [],
    classes: ["", "text-right"],
  };

  return (
    <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
      <div className="w-full">
        {invoices && invoices.length == 0 ? (
          <div className="text-neutral-700 px-8 py-6 text-sm">
            {t("TEXT_NO_INVOICES_AVAILABLE")}
          </div>
        ) : (
          <div className="mx-8 my-6">
            <Table
              className="overflow-auto block whitespace-nowrap table-auto"
              tableInfo={InvoicesTable}
              shouldFade={true}
              isLoading={loadingInvoices}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default InvoicesComponent;
