import React from "react";

import Icon from "./Icons";

type CustomIconProps = {
  className?: string;
  iconName: string;
  onClick?: Function;
  disabled?: boolean;
};

const CustomIcon: React.FC<CustomIconProps> = ({
  className,
  iconName,
  onClick,
  disabled,
}) => {
  return (
    <Icon
      name={iconName}
      className={className}
      onClick={!disabled ? onClick : undefined}
    />
  );
};

export default CustomIcon;
