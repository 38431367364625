import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    className={`${className}`}
    {...props}
  >
    <circle
      cx="11"
      cy="11"
      r="8"
      stroke="#9A9B9C"
      fill="none"
      strokeWidth="2px"
    ></circle>
    <line
      x1="21"
      y1="21"
      x2="16.65"
      y2="16.65"
      stroke="#9A9B9C"
      fill="none"
      strokeWidth="2px"
    ></line>
  </svg>
);

export default SVG;
