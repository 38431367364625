import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableInfo, UserProfileViewModel } from "@assets/types";
import Table from "@components/Table/table";
import moment from "moment";
import LoadingModal from "@components/Modal/loadingModal";
import WebNoteModal from "@components/Modal/webNoteModal";
import { toast } from "react-toastify";
import DropdownButton from "@components/DropdownButton/dropdownButton";
import Page from "@components/Page/page";
import IconButton from "@components/Button/iconButton";
import { FaTrash } from "react-icons/fa";
import { UserType } from "@assets/constants";
import DeleteModal from "@components/Modal/deleteModal";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import {
  deleteWebNote,
  getRemarks,
  getWebNotes,
  noteState,
  resetAddNoteState,
} from "@state/slices/notesSlice";

interface NotesPageProps {
  jobReference: string;
  userProfile: UserProfileViewModel | undefined | null;
}

type AddNotesModal = {
  openAddNotes: boolean;
};

const NotesComponent: React.FC<NotesPageProps> = (props: NotesPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const notes = useAppSelector(noteState).webNotes;
  const addNoteStatus = useAppSelector(noteState).addNoteStatus;
  const loadingNotes = useAppSelector(noteState).loadingNotes;
  const remarks = useAppSelector(noteState).remarks;
  const loadingRemarks = useAppSelector(noteState).loadingRemarks;

  const [deleteModal, setDeleteModal] = useState<{ open: boolean }>({
    open: false,
  });

  const openDeleteModal = () => {
    const openDeleteModal = {
      open: true,
    };
    setDeleteModal(openDeleteModal);
  };

  const closeDeleteModal = () => {
    const closeDeleteModal = {
      ...deleteModal,
      open: false,
    };
    setDeleteModal(closeDeleteModal);
  };

  const isEmployee = props.userProfile?.Access.UserType === UserType.EMPLOYEE;

  let values = isEmployee ? notes.concat(remarks) : remarks;

  const [addNotesModal, setAddNotesModal] = useState<AddNotesModal>({
    openAddNotes: false,
  });
  const [noteType, setNoteType] = useState<number>(1);

  useEffect(() => {
    dispatch(getWebNotes(props.jobReference));
    dispatch(getRemarks(props.jobReference));
  }, [window.location.href.split("#")[1] === "Notes"]);

  useEffect(() => {
    if (addNoteStatus === "OK") {
      toast.success(t("TEXT_NOTE_ADDED"));
      dispatch(getWebNotes(props.jobReference));
      dispatch(getRemarks(props.jobReference));
    } else if (addNoteStatus === "failed") {
      toast.error(t("ERROR_NOTE_NOT_ADDED"));
    }
    dispatch(resetAddNoteState());
  }, [addNoteStatus]);

  const NotesTable: TableInfo = {
    tableHeader: [
      t("LABEL_NOTE"),
      t("LABEL_TYPE"),
      t("LABEL_CREATED_ON"),
      t("LABEL_CREATED_BY"),
      isEmployee ? "" : null,
    ],
    tableData: values
      ? values.map((value, index) => {
          const handleDelete = () => {
            dispatch(
              deleteWebNote({
                jobReference: props.jobReference,
                recId: value.RecId,
              })
            );
            toast.success(t("TEXT_NOTE_DELETED"));
            dispatch(getWebNotes(props.jobReference));
            dispatch(getRemarks(props.jobReference));
          };

          return [
            value.Note,
            value.IsInternal ? "Internal" : "Public",
            moment(value.CreateDate).format("DD MMM YYYY").toString(),
            value.UserName,
            isEmployee && !value.IsRemark ? (
              <div className="flex justify-center items-center">
                <IconButton
                  onClick={openDeleteModal}
                  active={false}
                  className="self-end"
                >
                  <FaTrash className="text-base" />
                </IconButton>
                {deleteModal.open && (
                  <DeleteModal
                    open={deleteModal.open}
                    closeModal={closeDeleteModal}
                    onDelete={handleDelete}
                    content={t("TEXT_DELETE_NOTE")}
                    header={t("LABEL_DELETE_NOTE")}
                  />
                )}
              </div>
            ) : null,
          ];
        })
      : [],
    classes: [],
  };

  const addNotesDropdownButtonOptions = [
    {
      id: 1,
      title: t("INTERNAL_NOTE"),
      type: "INTERNAL",
    },
    {
      id: 2,
      title: t("PUBLIC_NOTE"),
      type: "PUBLIC",
    },
  ];

  const openAddNotesModal = () => {
    const addNoteModal = { ...addNotesModal };
    addNoteModal.openAddNotes = true;
    setAddNotesModal(addNoteModal);
  };

  const closeAddNotesModal = () => {
    const addNoteModal = { ...addNotesModal };
    addNoteModal.openAddNotes = false;
    setAddNotesModal(addNoteModal);
  };

  const onChangeNoteType = (id: number) => {
    setNoteType(id);
    openAddNotesModal();
  };

  return (
    <>
      <WebNoteModal
        closeIcon
        closeOnOutsideClick={false}
        open={addNotesModal.openAddNotes}
        closeModal={() => closeAddNotesModal()}
        jobReference={props.jobReference}
        type={noteType}
        isNote={true}
      ></WebNoteModal>
      {isEmployee && (
        <div className="md:my-6 md:ml-6 flex md:justify-end rounded my-6 ml-40">
          <DropdownButton
            label={t("LABEL_ADD_NOTE")}
            onClick={onChangeNoteType}
            options={addNotesDropdownButtonOptions}
            type="secundary"
          />
        </div>
      )}
      <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
        <div className="w-full">
          <LoadingModal isOpen={loadingNotes && loadingRemarks}>
            {t("TEXT_LOADING_NOTES")}
          </LoadingModal>
          {values && values.length == 0 ? (
            <div className="text-neutral-700 px-8 py-6 text-sm">
              {t("TEXT_NO_NOTES_AVAILABLE")}
            </div>
          ) : (
            <div className="mx-8 my-6">
              <Table
                className="overflow-auto block whitespace-nowrap table-auto"
                tableInfo={NotesTable}
                shouldFade={true}
              />
            </div>
          )}
        </div>
      </Page>
    </>
  );
};

export default NotesComponent;
