import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import {
  BookingReducer,
  HomeReducer,
  LoaderReducer,
  ToastReducer,
  UserReducer,
  ViewReducer,
} from "./reducers/app";

import commoditiesSlice from "./slices/commoditiesSlice";
import portsSlice from "./slices/portsSlice";
import hsCodesSlice from "./slices/hsCodesSlice";
import hazardCodesSlice from "./slices/hazardCodesSlice";
import packageTypesSlice from "./slices/packageTypesSlice";
import containerTypesSlice from "./slices/containerTypesSlice";
import containerTemperatureSlice from "./slices/containerTemperatureSlice";
import incotermsSlice from "./slices/incotermsSlice";
import shippingTypesSlice from "./slices/shippingTypesSlice";
import partnerSlice from "./slices/partnerSlice";
import routeBookingSlice from "./slices/routeBookingSlice";
import cargoBookingSlice from "./slices/cargoBookingSlice";
import documentsSlice from "./slices/documentsSlice";
import requestsSlice from "./slices/requestsSlice";
import invoicesSlice from "./slices/invoicesSlice";
import customerSlice from "./slices/customerSlice";
import transactionsSlice from "./slices/transactionsSlice";
import bookingsSlice from "./slices/bookingsSlice";
import notesSlice from "./slices/notesSlice";
import userSlice from "./slices/userSlice";

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  UserReducer,
  ToastReducer,
  ViewReducer,
  HomeReducer,
  BookingReducer,
  LoaderReducer,
  portsSlice,
  commoditiesSlice,
  hsCodesSlice,
  hazardCodesSlice,
  packageTypesSlice,
  containerTypesSlice,
  containerTemperatureSlice,
  incotermsSlice,
  shippingTypesSlice,
  partnerSlice,
  routeBookingSlice,
  cargoBookingSlice,
  documentsSlice,
  requestsSlice,
  invoicesSlice,
  customerSlice,
  transactionsSlice,
  bookingsSlice,
  notesSlice,
  userSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === "production"
      ? getDefaultMiddleware().concat(thunkMiddleware)
      : getDefaultMiddleware().concat(loggerMiddleware).concat(thunkMiddleware);
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
