import { RouteBookingResponse } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

export const getRouteBooking = createAsyncThunk(
  "SingleBooking/jobReference",
  async (jobReference: string) => {
    return await SingleBookingService.getRouteBooking(jobReference);
  }
);

export interface RouteBookingState {
  routeBooking: RouteBookingResponse | null;
  loadingRouteBooking: boolean;
}

export const initialRouteBookingState: RouteBookingState = {
  routeBooking: null,
  loadingRouteBooking: false,
};

export const routeBookingSlice = createSlice({
  name: "routeBooking",
  initialState: initialRouteBookingState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRouteBooking.pending, (state) => {
        state.loadingRouteBooking = true;
      })
      .addCase(getRouteBooking.rejected, (state) => {
        state.loadingRouteBooking = false;
        state.routeBooking = null;
      })
      .addCase(
        getRouteBooking.fulfilled,
        (state, action: PayloadAction<RouteBookingResponse>) => {
          state.loadingRouteBooking = false;
          state.routeBooking = action.payload;
        }
      );
  },
});

export const routeBookingState = (state: RootState) => state.routeBookingSlice;

export default routeBookingSlice.reducer;
