import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="25,4.53 29.94,20.36 46.52,20.17 32.99,29.76 38.3,45.47 25,35.57 11.7,45.47 17.01,29.76 3.48,20.17 
	20.06,20.36 "
      />
    </g>
  </svg>
);

export default SVG;
