import { Toast as ToastItem, ToastType } from "@assets/types";
import React, { Component, Fragment } from "react";
import parse from "html-react-parser";
import styles from "./toast.module.less";

import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaRegTimesCircle,
  FaTimes,
} from "react-icons/fa";
import { Translation } from "react-i18next";
import StringUtilities from "utilities/StringUtilities";

class Toast extends Component<ToastItem, { visible: boolean }> {
  constructor(props: ToastItem) {
    super(props);

    this.state = { visible: true };
  }

  componentDidMount() {
    if (this.props.lifeTime) {
      setTimeout(
        () => this.setState({ visible: false }),
        this.props.lifeTime - 500
      );
    }
  }

  getColor = (type: ToastType): string => {
    switch (type) {
      case ToastType.SUCCESS:
        return "bg-green-400";
      case ToastType.INFO:
        return "bg-blue-400";
      case ToastType.ALERT:
      case ToastType.WARNING:
        return "bg-orange-300";
      case ToastType.ERROR:
        return "bg-red-400";
      default:
        return "bg-gray-400";
    }
  };

  getIcon = (type: ToastType) => {
    switch (type) {
      case ToastType.SUCCESS:
        return <FaCheckCircle className="mr-1 text-gray-800" />;
      case ToastType.INFO:
        return <FaInfoCircle className="mr-1 text-gray-800" />;
      case ToastType.ALERT:
      case ToastType.WARNING:
        return <FaExclamationTriangle className="mr-1 text-gray-800" />;
      case ToastType.ERROR:
        return <FaRegTimesCircle className="mr-1 text-gray-800" />;
      default:
        return null;
    }
  };

  render() {
    const { visible } = this.state;
    return (
      <Translation>
        {(t) => (
          <span>
            <FaTimes
              className={`${styles.toastclose} right-5 cursor-pointer text-gray-800`}
              onClick={() => this.props.removeToast()}
            />
            <li
              className={`${
                styles.toastcontainer
              } flex flex-row justify-between pb-2 px-4 rounded-sm shadow-md ${
                visible ? "animate-fade-in" : "animate-fade-out"
              } ${this.getColor(this.props.type)} w-64 mb-3`}
            >
              <div className="flex items-center w-full">
                <div className="w-full float-left">
                  <span className="flex items-center">
                    <span className="text-lg">
                      {this.getIcon(this.props.type)}
                    </span>
                    <p
                      className={`${styles.toastheader} uppercase p-2 font-bold text-lg relative`}
                    >
                      {t(this.props.header)}
                    </p>
                  </span>
                  <hr
                    className={`${styles.toastseparator} separator-line-light relative`}
                  ></hr>
                  <p className="w-full mt-1 float-left">
                    {this.props.message
                      ? StringUtilities.isHTML(this.props.message)
                        ? parse(this.props.message)
                        : t(this.props.message)
                      : null}
                  </p>
                </div>
              </div>
            </li>
          </span>
        )}
      </Translation>
    );
  }
}

export default Toast;
