import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableInfo } from "@assets/types";
import Table from "@components/Table/table";
import moment from "moment";
import Link from "@components/Link/link";
import Page from "@components/Page/page";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { requestState, getRequests } from "@state/slices/requestsSlice";

interface RequestsPageProps {
  jobReference: string;
}

const RequestsComponent: React.FC<RequestsPageProps> = (
  props: RequestsPageProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const requests = useAppSelector(requestState).requests;
  const loadingRequest = useAppSelector(requestState).loadingRequest;

  const OldServiceWeb = process.env.OldServiceWeb;

  useEffect(() => {
    dispatch(
      getRequests({ jobReference: props.jobReference, requestType: null })
    );
  }, [window.location.href.split("#")[1] === "Requests"]);

  const RequestsTable: TableInfo = {
    tableHeader: [
      t("LABEL_STATUS"),
      t("LABEL_REQUEST_TYPE"),
      t("LABEL_CREATED_ON"),
      t("LABEL_CLOSED_ON"),
      t("LABEL_REQUEST_BY"),
      "",
    ],
    tableData: requests
      ? requests.map((request, index) => {
          return [
            request.Status,
            request.TypeDescription,
            request.CreateTime != null
              ? moment(request.CreateTime).format("DD MMM YYYY").toString()
              : "N/A",
            request.CloseTime != null
              ? moment(request.CloseTime).format("DD MMM YYYY").toString()
              : "N/A",
            request.UserName,
            <Link
              key={`request-link-${index}`}
              className="text-jonar-blue"
              onClick={() =>
                window.open(
                  `${OldServiceWeb}/DisplayBookingInquiry.aspx?ReqNo=${request.ID}`,
                  "_blank",
                  "location=yes,height=780,width=800,scrollbars=yes,status=yes"
                )
              }
            >
              {t("LABEL_VIEW_REQUEST")}
            </Link>,
          ];
        })
      : [],
    classes: [],
  };

  return (
    <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
      <div className="w-full">
        {requests && requests.length == 0 ? (
          <div className="text-neutral-700 px-8 py-6 text-sm">
            {t("TEXT_NO_REQUESTS_AVAILABLE")}
          </div>
        ) : (
          <div className="mx-8 my-6">
            <Table
              className="overflow-auto block whitespace-nowrap table-auto"
              tableInfo={RequestsTable}
              shouldFade={true}
              isLoading={loadingRequest}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default RequestsComponent;
