import { classNames } from "@assets/utilities/classNameUtilities";
import { DOTS, usePagination } from "@assets/utilities/paginationUtilities";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  onPageChange: (page: number | string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
};

const Pagination: React.FC<Props> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const paginationRange = usePagination(
    totalCount,
    pageSize,
    siblingCount,
    currentPage
  );

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage;
  if (paginationRange) lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classNames(`flex list-none gap-2`, className)}>
      <li
        className={classNames(
          `px-3 h-8 text-center my-auto mx-1 font-normal flex box-border items-center rounded min-w-[32px] border border-neutral-500`,
          currentPage === 1
            ? "pointer-events-none text-neutral-800"
            : "cursor-pointer text-neutal-1000"
        )}
        onClick={onPrevious}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </li>
      {paginationRange?.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`${pageNumber}-${index}-pagination`}
              className={`hover: bg-transparent cursor-default mt-2`}
            >
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={`${pageNumber}-pagination`}
            className={classNames(
              `px-3 h-8 text-center my-auto mx-1 flex box-border items-center rounded min-w-[32px] cursor-pointer`,
              pageNumber === currentPage
                ? "border border-jonar-blue text-jonar-blue"
                : "border border-neutral-500 text-neutral-800"
            )}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classNames(
          `px-3 h-8 text-center my-auto mx-1 flex box-border items-center rounded min-w-[32px] border border-neutral-500`,
          currentPage === lastPage
            ? "pointer-events-none text-neutral-800"
            : "cursor-pointer text-neutral-1000"
        )}
        onClick={onNext}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </li>
    </ul>
  );
};

export default Pagination;
