import React from "react";
import { connect } from "react-redux";

import { AppState } from "@state/reducers/types";

import Search from "@components/Search/search";
import { Translation } from "react-i18next";
import DropdownResults from "./dropdownResults";
import { DropdownOption, Label } from "@assets/types";

type DropdownProps = {
  isSearching?: boolean;
  options: DropdownOption[];
  onSelect: Function;
  multiSelect?: boolean;
  hasSearch?: boolean;
  onSearch?: Function;
  onKeyPress?: boolean;
  searchPlaceholder?: string;
  resultHeaders?: string[];
  open?: boolean;
  className: string;
  icon?: string;
  containerClassName?: string;
  labelClassName?: string;
  onLabelRemove?: Function;
  labels?: Label[];
};

type DropdownState = {
  searchValue: string;
  focused?: boolean;
};

class Dropdown extends React.PureComponent<DropdownProps, DropdownState> {
  setWrapperRef: Function;
  wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: DropdownProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      searchValue: "",
      focused: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({ focused: false });
    }
  }

  removeLabel = (key: string) => {
    this.props.onLabelRemove ? this.props.onLabelRemove(key) : null;
  };

  onSelect = (item: DropdownOption) => {
    this.props.onSelect(item.value);
    this.setState({ focused: false, searchValue: "" });
  };

  onKeyPress = (e: React.KeyboardEvent, value: string) => {
    if (e.key === "Enter") {
      this.onSearch(value);
    }
  };

  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };

  onSearch = async (value: string) => {
    this.props.onSearch ? await this.props.onSearch(value) : undefined;
    this.setState({ searchValue: "" });
  };

  render() {
    const {
      isSearching,
      options,
      onKeyPress,
      searchPlaceholder,
      hasSearch,
      resultHeaders,
      open,
      multiSelect,
      className,
      labelClassName,
      icon,
    } = this.props;

    let containerClassName = this.props.containerClassName;

    if (!containerClassName) {
      containerClassName = "flex flex-col w-full items-center";
    }

    const { searchValue, focused } = this.state;

    return (
      <Translation>
        {() => (
          <div
            className={containerClassName}
            onFocus={() => this.setState({ focused: true })}
            ref={this.wrapperRef}
          >
            {hasSearch ? (
              <Search
                className={className}
                isSearching={isSearching}
                onChange={this.onSearchChange}
                onKeyPress={(e: React.KeyboardEvent) =>
                  onKeyPress ? this.onKeyPress(e, searchValue) : undefined
                }
                value={searchValue}
                placeHolder={searchPlaceholder}
                onSearch={() => this.onSearch(this.state.searchValue)}
                icon={icon}
                labelClassName={labelClassName}
                labels={
                  multiSelect
                    ? {
                        labels: this.props.labels,
                        labelClick: this.removeLabel,
                      }
                    : undefined
                }
              />
            ) : null}
            {(open ?? focused ?? true) && options && options.length > 0 ? (
              <DropdownResults headers={resultHeaders}>
                {options
                  .filter((item: DropdownOption) =>
                    this.props.onSearch
                      ? true
                      : item.item
                          ?.toString().toUpperCase()
                          .includes(this.state.searchValue.toUpperCase())
                  )
                  .map((item: DropdownOption, i: number) => {
                    return (
                      <button
                        className="flex justify-between bg-white text-gray-700 font-normal p-3 hover:bg-gray-100 cursor-pointer w-full border-t appearance-none focus:outline-none"
                        key={i}
                        onClick={() =>
                          item.value ? this.onSelect(item) : null
                        }
                        value={item.value}
                      >
                        {item.item}
                      </button>
                    );
                  })}
              </DropdownResults>
            ) : null}
          </div>
        )}
      </Translation>
    );
  }
}

export default connect((state: AppState) => ({}), {})(Dropdown);
