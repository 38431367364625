import { classNames } from "@assets/utilities/classNameUtilities";
import React from "react";
import Tooltip from "@components/Tooltip/tooltip";
import { FiInfo } from "react-icons/fi";
import { t } from "i18next";

export type labelCases = "upper" | "lower" | "capitalize" | "none";

type LabelExProps = {
  /** The content of the component. */
  children: string;
  /** Override or extend the style applied to the component. */
  className?: string;
  /** The id of the element the label is bound to. */
  forID?: string;
  /** Transform the label displayed case. */
  labelCase?: "upper" | "lower" | "capitalize" | "none";
  labelColor?: "neutral-800" | "neutral-900";
  /* If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /**	If true, the input element is required. */
  required?: boolean;
  tooltipContent?: string;
};

const labelCases = {
  upper: "uppercase",
  lower: "lowercase",
  capitalize: "capitalize",
  none: "normal-case",
};

const labelColors = {
  "neutral-800": "text-neutral-800",
  "neutral-900": "text-neutral-900",
};

export const LabelEx: React.FC<LabelExProps> = ({
  children,
  className,
  forID,
  labelCase = "none",
  labelColor = "neutral-800",
  optional = false,
  optionalText = `${`(` + t("LABEL_OPTIONAL") + `)`}`,
  required = false,
  tooltipContent,
}) => (
  <div className="flex">
    <label
      className={classNames(
        "inline-block leading-5.5",
        labelColors[labelColor],
        className,
        labelCases[labelCase]
      )}
      htmlFor={forID}
    >
      {children}
      {required ? " *" : ""}
      {optional && optionalText ? ` ${optionalText}` : ""}
    </label>
    {tooltipContent ? (
      <Tooltip content={tooltipContent} childClassName="inline-flex">
        <FiInfo className="cursor-help inline-block ml-1 text-primary" />
      </Tooltip>
    ) : (
      <></>
    )}
  </div>
);

export default LabelEx;
