import { BookingStatus } from "@assets/constants";
import {
  Booking,
  BookingAddress,
  BookingAddressRequest,
  RouteBookingResponse,
} from "@assets/types";

/**
 * Convert a booking address request to a booking address
 */
export const bookingAddressRequestToBookingAddress = (
  bookingAddressRequest: BookingAddressRequest
) => {
  const bookingAddress: BookingAddress = {
    PartnerCode: bookingAddressRequest.PartnerCode ?? "",
    Name: bookingAddressRequest.FullName ?? "",
  };
  return bookingAddress;
};

const bookingAddressEmpty: BookingAddress = {
  PartnerCode: "",
  Name: "",
};

/**
 * Convert a route booking response to a booking
 */
export const routeBookingResponseToBooking = (
  routeBookingResponse: RouteBookingResponse
) => {
  const booking: Booking = {
    JobReference: routeBookingResponse.JobReference ?? "",
    Status: routeBookingResponse.Status ?? BookingStatus.BOOKED,
    IsCustomsCleared: false,
    CustomsClearedDate: routeBookingResponse.CustomsClearanceDate ?? new Date(),
    BOLNumber: routeBookingResponse.BOLNumber ?? "",
    PointFrom: routeBookingResponse.PLR?.PointCode ?? "",
    PointLoad: routeBookingResponse.POL?.PointCode ?? "",
    PointLoadName: routeBookingResponse.POL?.FullName ?? "",
    PointTrans: "",
    PointDisch: routeBookingResponse.POD?.PointCode ?? "",
    PointDischName: routeBookingResponse.POD?.FullName ?? "",
    PointTO: routeBookingResponse.PFD?.PointCode ?? "",
    ETD: routeBookingResponse.VoyageReference?.ETD
      ? routeBookingResponse.VoyageReference?.ETD
      : new Date(),
    ETA: routeBookingResponse.VoyageReference?.ETA
      ? routeBookingResponse.VoyageReference?.ETA
      : new Date(),
    Shipper: routeBookingResponse.Shipper
      ? bookingAddressRequestToBookingAddress(routeBookingResponse.Shipper)
      : bookingAddressEmpty,
    Consignee: routeBookingResponse.Consignee
      ? bookingAddressRequestToBookingAddress(routeBookingResponse.Consignee)
      : bookingAddressEmpty,
    VoyageReference:
      routeBookingResponse.VoyageReference?.VoyageReference ?? "",
    ShippingType: routeBookingResponse.ShippingType ?? "",
    FreightMode: "",
    IsHazardous: false,
    IsBCBooking: routeBookingResponse.HBLFlag ?? false,
    IsBOL: false,
    ConsignmentNumberSimplified: "",
    ConsignmentNumber: "",
    InternalNoteCount: 0,
    ExternalNoteCount: 0,
  };
  return booking;
};
