import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M26.81,32.87V7.27c0-2.31-1.75-4.17-3.91-4.17c-2.16,0-3.91,1.87-3.91,4.17v25.6
                c-2.1,1.34-3.51,3.72-3.51,6.44c0,4.19,3.32,7.6,7.42,7.6c4.1,0,7.42-3.4,7.42-7.6C30.31,36.59,28.91,34.21,26.81,32.87z"
      />
      <line
        className={`${styles.st0}`}
        x1="31.86"
        y1="7.97"
        x2="34.52"
        y2="7.97"
      />
      <line
        className={`${styles.st0}`}
        x1="31.86"
        y1="12.57"
        x2="34.52"
        y2="12.57"
      />
      <line
        className={`${styles.st0}`}
        x1="31.86"
        y1="17.17"
        x2="34.52"
        y2="17.17"
      />
      <line
        className={`${styles.st0}`}
        x1="31.86"
        y1="21.77"
        x2="34.52"
        y2="21.77"
      />
      <line
        className={`${styles.st0}`}
        x1="31.86"
        y1="26.37"
        x2="34.52"
        y2="26.37"
      />
      <polyline
        className={`${styles.st1}`}
        points="14.48,47.91 35.52,47.91 35.52,2.09 	"
      />
      <line
        className={`${styles.st0}`}
        x1="22.96"
        y1="34.56"
        x2="22.96"
        y2="14.86"
      />
      <path
        className={`${styles.st0}`}
        d="M22.9,43.24c2.07,0,3.75-1.68,3.75-3.75c0-2.07-1.68-3.75-3.75-3.75s-3.75,1.68-3.75,3.75
                C19.15,41.57,20.83,43.24,22.9,43.24z"
      />
      <polyline
        className={`${styles.st1}`}
        points="14.48,47.91 35.52,47.91 35.52,2.09 	"
      />
    </g>
  </svg>
);

export default SVG;
