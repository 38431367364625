import React from "react";
import { classNames } from "@assets/utilities/classNameUtilities";

type AlertProps = {
  children: React.ReactNode;
  className?: string;
  /** The color of the component */
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "normal"
    | "error";
};

const normalBackground = {
  primary: "bg-primary",
  secondary: "bg-secondary",
  success: "bg-success",
  info: "bg-info",
  warning: "bg-warning-light",
  error: "bg-error-light",
  normal: "bg-info-light",
};

const normalTextColor = {
  primary: "text-neutral-1000",
  secondary: "text-neutral-1000",
  success: "text-neutral-1000",
  info: "text-neutral-1000",
  warning: "text-neutral-1000",
  error: "text-neutral-1000",
  normal: "text-neutral-1000",
};

const Alert = ({ children, className, color = "normal" }: AlertProps) => {
  return (
    <div
      className={classNames(
        normalTextColor[color],
        normalBackground[color],
        className
      )}
      role="alert"
    >
      {children}
    </div>
  );
};

export default Alert;
