import { classNames } from "@assets/helperFunctions";
import React, { ChangeEventHandler } from "react";
import ValidationError from "@components/ValidationError/validationError";

type CheckboxProps = {
  className?: string;
  value?: string | number;
  name?: string;
  type: "checkbox" | "radio";
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  /** The error message to show. !NOTE: use with hasError=true. */
  errorMessage?: string;
};

const filledTextColors = {
  primary: "text-jonar-blue",
  secondary: "text-secondary-500",
};
const borderRadius = {
  checkbox: "rounded",
  radio: "rounded-full",
};
const size = {
  checkbox: "h-6 w-6",
  radio: "h-6 w-6",
};
const labelWeight = {
  checkbox: "font-normal",
  radio: "font-semibold",
};
const position = {
  top: "flex-col-reverse",
  right: "flex-row",
  bottom: "flex-col",
  left: "flex-row-reverse",
};

const Checkbox: React.FC<CheckboxProps> = React.forwardRef(
  (
    {
      className,
      value,
      name,
      type,
      label,
      checked,
      disabled,
      hasError = false,
      errorMessage,
      onChange,
      id,
    },
    ref: any
  ) => {
    const borderColor = hasError ? "border-error" : "border-neutral-500";

    return (
      <div className={`cursor-pointer relative flex leading-5 ${className}`}>
        <label className="flex items-center" htmlFor={id}>
          <input
            name={name}
            className={classNames(
              `appearance-none 
            focus:outline-none focus:ring-0 focus:border-neutral-500
            border inline-grid place-items-center cursor-pointer without-ring`,
              borderColor,
              borderRadius[type],
              size[type],
              filledTextColors["primary"],
              className,
              disabled
                ? "bg-neutral-200 border-neutral-500 cursor-default hover:border-neutral-500 hover:bg-neutral-200"
                : ""
            )}
            type={type}
            checked={checked}
            value={value}
            disabled={disabled}
            onChange={onChange}
            id={id}
            ref={ref}
          />
          {label && (
            <label
              className={classNames(
                `cursor-pointer pl-2 text-regular-color align-baseline`,
                labelWeight[type]
              )}
            >
              {label}
            </label>
          )}
        </label>
        {hasError && errorMessage && (
          <ValidationError className="mt-2" errorText={errorMessage} />
        )}
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
