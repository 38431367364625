import {
  Company,
  Toast,
  UserProfileViewModel,
  UserWebSettings,
  ActiveBooking,
  Booking,
} from "@assets/types";

import { ModalType } from "@state/reducers/types";

export const loginConstants = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  OLD_LOGIN_REQUEST: "OLD_LOGIN_REQUEST",
  OLD_LOGIN_SUCCESS: "OLD_LOGIN_SUCCESS",

  LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",

  TOKEN_REFRESH: "USER_TOKEN_REFRESH",
};

export const userConstants = {
  USER_SELECT_COMPANY_REQUEST: "USER_SELECT_COMPANY_REQUEST",
  USER_SELECT_COMPANY_SUCCESS: "USER_SELECT_COMPANY_SUCCESS",
  USER_SELECT_COMPANY_FAILED: "USER_SELECT_COMPANY_FAILED",
  USER_ME: "USER_ME",
  USER_SELECT_LANGUAGE: "USER_SELECT_LANGUAGE",
  USER_UPDATE_WEBSETTINGS: "USER_UPDATE_WEBSETTINGS",
  FORGOT_PASSWORD: "USER_FORGOT_PASSWORD",
  RESET_PASSWORD: "USER_RESET_PASSWORD",
};

export const toastConstants = {
  ADD_TOAST: "ADD_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
};

export const viewConstants = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};

export const loaderConstants = {
  FAVORITE_BOOKING_LOADER_ADD: "FAVORITE_BOOKING_LOADER_ADD",
  FAVORITE_BOOKING_LOADER_REMOVE: "FAVORITE_BOOKING_LOADER_REMOVE",
};

export const bookingConstants = {
  BOOKING_SEA_FETCH_ACTIVE_REQUEST: "BOOKING_SEA_FETCH_ACTIVE_REQUEST",
  BOOKING_SEA_FETCH_ACTIVE_SUCCESS: "BOOKING_SEA_FETCH_ACTIVE_SUCCESS",
  BOOKING_SEA_FETCH_ACTIVE_FAILED: "BOOKING_SEA_FETCH_ACTIVE_FAILED",
  BOOKING_AIR_FETCH_ACTIVE_REQUEST: "BOOKING_AIR_FETCH_ACTIVE_REQUEST",
  BOOKING_AIR_FETCH_ACTIVE_SUCCESS: "BOOKING_AIR_FETCH_ACTIVE_SUCCESS",
  BOOKING_AIR_FETCH_ACTIVE_FAILED: "BOOKING_AIR_FETCH_ACTIVE_FAILED",
  BOOKING_LIST_FRONTEND_FILTERING: "BOOKING_LIST_FRONTEND_FILTERING",
  BOOKING_LIST_DISPLAYED_SHIPPING_TYPE: "BOOKING_LIST_DISPLAYED_SHIPPING_TYPE",
  BOOKING_LIST_DISPLAYED_POLS: "BOOKING_LIST_DISPLAYED_POLS",
  BOOKING_LIST_DISPLAYED_PODS: "BOOKING_LIST_DISPLAYED_PODS",
  BOOKING_LIST_DISPLAYED_CONSIGNEES: "BOOKING_LIST_DISPLAYED_CONSIGNEES",
  BOOKING_LIST_DISPLAYED_SHIPPERS: "BOOKING_LIST_DISPLAYED_SHIPPERS",
  BOOKING_LIST_UNIQUE_SHIPPING_TYPE: "BOOKING_LIST_UNIQUE_SHIPPING_TYPE",
  BOOKING_LIST_UNIQUE_POL: "BOOKING_LIST_UNIQUE_POL",
  BOOKING_LIST_UNIQUE_POD: "BOOKING_LIST_UNIQUE_POD",
  BOOKING_LIST_UNIQUE_SHIPPERS: "BOOKING_LIST_UNIQUE_SHIPPERS",
  BOOKING_LIST_UNIQUE_CONSIGNEES: "BOOKING_LIST_UNIQUE_CONSIGNEES",
  BOOKING_LIST_FETCH_REQUEST: "BOOKING_LIST_FETCH_REQUEST",
  BOOKING_LIST_FETCH_SUCCESS: "BOOKING_LIST_FETCH_SUCCESS",
  BOOKING_LIST_FETCH_FAILED: "BOOKING_LIST_FETCH_FAILED",
  BOOKING_TOGGLE_FAVORITE_REQUEST: "BOOKING_TOGGLE_FAVORITE_REQUEST",
  BOOKING_TOGGLE_FAVORITE_SUCCESS: "BOOKING_TOGGLE_FAVORITE_SUCCESS",
  BOOKING_TOGGLE_FAVORITE_FAILED: "BOOKING_TOGGLE_FAVORITE_FAILED",
  BOOKING_GET_FAVORITES_SUCCESS: "BOOKING_GET_FAVORITES_SUCCESS",
  BOOKING_GET_BOOKING_IN_EXCEL_REQUEST: "BOOKING_GET_BOOKING_IN_EXCEL_REQUEST",
  BOOKING_GET_BOOKING_IN_EXCEL_SUCCESS: "BOOKING_GET_BOOKING_IN_EXCEL_SUCCESS",
  BOOKING_GET_BOOKING_IN_EXCEL_FAILED: "BOOKING_GET_BOOKING_IN_EXCEL_FAILED",
};

export const requestConstants = {
  REQUEST_FETCH_ACTIVE_REQUEST: "REQUEST_FETCH_ACTIVE_REQUEST",
  REQUEST_FETCH_ACTIVE_SUCCESS: "REQUEST_FETCH_ACTIVE_SUCCESS",
  REQUEST_FETCH_ACTIVE_FAILED: "REQUEST_FETCH_ACTIVE_FAILED",
};

export const currencyConstants = {
  CURRENCY_FETCH_LATEST_REQUEST: "CURRENCY_FETCH_LATEST_REQUEST",
  CURRENCY_FETCH_LATEST_SUCCESS: "CURRENCY_FETCH_LATEST_SUCCESS",
  CURRENCY_FETCH_LATEST_FAILED: "CURRENCY_FETCH_LATEST_FAILED",
};

export const voyageConstants = {
  VOYAGE_FETCH_ACTIVE_REQUEST: "VOYAGE_FETCH_ACTIVE_REQUEST",
  VOYAGE_FETCH_ACTIVE_SUCCESS: "VOYAGE_FETCH_ACTIVE_SUCCESS",
  VOYAGE_FETCH_ACTIVE_FAILED: "VOYAGE_FETCH_ACTIVE_FAILED",
};

interface AddToastAction {
  type: typeof toastConstants.ADD_TOAST;
  payload: Toast;
}

interface FavoriteLoaderAdd {
  type: typeof toastConstants.ADD_TOAST;
  payload: Toast;
}

interface FavoriteLoaderRemove {
  type: typeof toastConstants.ADD_TOAST;
  payload: Toast;
}

interface RemoveToastAction {
  type: typeof toastConstants.REMOVE_TOAST;
  payload: number;
}

interface LoginSuccessAction {
  type: typeof loginConstants.LOGIN_SUCCESS;
  payload: UserProfileViewModel;
}

interface LogoutRequestAction {
  type: typeof loginConstants.LOGOUT_REQUEST;
  payload: object;
}
interface LogoutSuccessAction {
  type: typeof loginConstants.LOGOUT_SUCCESS;
  payload: object;
}

interface TokenRefreshAction {
  type: typeof loginConstants.TOKEN_REFRESH;
  payload: undefined;
}

interface ForgotPasswordAction {
  type: typeof userConstants.FORGOT_PASSWORD;
  payload: string;
}

interface ResetPasswordAction {
  type: typeof userConstants.RESET_PASSWORD;
  payload: object;
}

interface SelectCompanyActionRequest {
  type: typeof userConstants.USER_SELECT_COMPANY_REQUEST;
  payload: object;
}

interface SelectCompanyActionSuccess {
  type: typeof userConstants.USER_SELECT_COMPANY_SUCCESS;
  payload: Company;
}

interface SelectCompanyActionFailed {
  type: typeof userConstants.USER_SELECT_COMPANY_FAILED;
  payload: object;
}

interface SelectLanguageAction {
  type: typeof userConstants.USER_SELECT_LANGUAGE;
  payload: UserWebSettings;
}

interface UpdateWebSettingsAction {
  type: typeof userConstants.USER_UPDATE_WEBSETTINGS;
  payload: UserWebSettings;
}

interface OpenModalAction {
  type: typeof viewConstants.OPEN_MODAL;
  payload: ModalType;
}

interface CloseModalAction {
  type: typeof viewConstants.CLOSE_MODAL;
  payload: undefined;
}

interface FetchActiveSeaFreightBookingsActionRequest {
  type: typeof bookingConstants.BOOKING_SEA_FETCH_ACTIVE_REQUEST;
  payload: undefined;
}

interface FetchActiveSeaFreightBookingsActionSuccess {
  type: typeof bookingConstants.BOOKING_SEA_FETCH_ACTIVE_SUCCESS;
  payload: ActiveBooking[];
}

interface FetchActiveSeaFreightBookingsActionFailed {
  type: typeof bookingConstants.BOOKING_SEA_FETCH_ACTIVE_FAILED;
  payload: object;
}

interface FetchActiveAirFreightBookingsActionRequest {
  type: typeof bookingConstants.BOOKING_AIR_FETCH_ACTIVE_REQUEST;
  payload: undefined;
}

interface FetchActiveAirFreightBookingsActionSuccess {
  type: typeof bookingConstants.BOOKING_AIR_FETCH_ACTIVE_SUCCESS;
  payload: ActiveBooking[];
}

interface FetchActiveAirFreightBookingsActionFailed {
  type: typeof bookingConstants.BOOKING_AIR_FETCH_ACTIVE_FAILED;
  payload: object;
}

interface FetchActiveRequestsActionRequest {
  type: typeof requestConstants.REQUEST_FETCH_ACTIVE_SUCCESS;
  payload: object;
}
interface FetchActiveRequestsActionSuccess {
  type: typeof requestConstants.REQUEST_FETCH_ACTIVE_SUCCESS;
  payload: object;
}

interface FetchActiveRequestsActionFailed {
  type: typeof requestConstants.REQUEST_FETCH_ACTIVE_SUCCESS;
  payload: object;
}

interface FetchLatestCurrenciesActionRequest {
  type: typeof currencyConstants.CURRENCY_FETCH_LATEST_REQUEST;
  payload: object;
}
interface FetchLatestCurrenciesActionSuccess {
  type: typeof currencyConstants.CURRENCY_FETCH_LATEST_SUCCESS;
  payload: object;
}

interface FetchLatestCurrenciesActionFailed {
  type: typeof currencyConstants.CURRENCY_FETCH_LATEST_FAILED;
  payload: object;
}

interface FetchBookingListActionRequest {
  type: typeof bookingConstants.BOOKING_LIST_FETCH_REQUEST;
  payload: object;
}

interface FetchBookingListActionSuccess {
  type: typeof bookingConstants.BOOKING_LIST_FETCH_SUCCESS;
  payload: Booking[];
}

interface FetchBookingListActionFailed {
  type: typeof bookingConstants.BOOKING_LIST_FETCH_FAILED;
  payload: object;
}

interface BookingGetFavoritesActionSuccess {
  type: typeof bookingConstants.BOOKING_GET_FAVORITES_SUCCESS;
  payload: string[];
}

interface BookingToggleFavoriteActionRequest {
  type: typeof bookingConstants.BOOKING_TOGGLE_FAVORITE_REQUEST;
  payload: string;
}

interface BookingToggleFavoriteActionSuccess {
  type: typeof bookingConstants.BOOKING_TOGGLE_FAVORITE_SUCCESS;
  payload: object;
}

interface BookingToggleFavoriteActionFailed {
  type: typeof bookingConstants.BOOKING_TOGGLE_FAVORITE_FAILED;
  payload: object;
}

export type LoginType =
  | LoginSuccessAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | TokenRefreshAction;

export type UserType =
  | SelectCompanyActionRequest
  | SelectCompanyActionSuccess
  | SelectCompanyActionFailed
  | SelectLanguageAction
  | UpdateWebSettingsAction
  | ForgotPasswordAction
  | ResetPasswordAction;

export type ToastType = AddToastAction | RemoveToastAction;

export type LoaderType = FavoriteLoaderAdd | FavoriteLoaderRemove;

export type ViewType = OpenModalAction | CloseModalAction;

export type HomeType =
  | FetchActiveRequestsActionRequest
  | FetchActiveRequestsActionSuccess
  | FetchActiveRequestsActionFailed
  | FetchActiveSeaFreightBookingsActionRequest
  | FetchActiveSeaFreightBookingsActionSuccess
  | FetchActiveSeaFreightBookingsActionFailed
  | FetchActiveAirFreightBookingsActionRequest
  | FetchActiveAirFreightBookingsActionSuccess
  | FetchActiveAirFreightBookingsActionFailed
  | FetchLatestCurrenciesActionRequest
  | FetchLatestCurrenciesActionSuccess
  | FetchLatestCurrenciesActionFailed;

export type BookingType =
  | FetchBookingListActionRequest
  | FetchBookingListActionSuccess
  | FetchBookingListActionFailed
  | BookingToggleFavoriteActionRequest
  | BookingToggleFavoriteActionSuccess
  | BookingToggleFavoriteActionFailed
  | BookingGetFavoritesActionSuccess;
