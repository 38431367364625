import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M13.44,29.49l-7.32-7.32l-4.9,4.9l12.22,12.22L34.9,17.83l-4.9-4.9L13.44,29.49z M48.78,16.84h-9.01V7.83
          M24.08,35.29v6.87h24.7V16.84l-9.01-9.01H24.08v5.1"
      />
      <path
        className={`${styles.st0}`}
        d="M28,31.95h16.85 M28,26.71h16.85 M31.41,21.47h13.45"
      />
    </g>
  </svg>
);

export default SVG;
