import { ShippingTypes } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getShippingTypes = createAsyncThunk("registry/types", async () => {
  return await CreateBookingService.getShippingTypes();
});

export interface ShippingTypeState {
  shippingTypes: ShippingTypes[];
  loadingShippingTypes: boolean;
}

export const initialShippingTypesState: ShippingTypeState = {
  shippingTypes: [],
  loadingShippingTypes: false,
};

export const shippingTypesSlice = createSlice({
  name: "shippingTypes",
  initialState: initialShippingTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShippingTypes.pending, (state) => {
        state.loadingShippingTypes = true;
      })
      .addCase(getShippingTypes.rejected, (state) => {
        state.loadingShippingTypes = false;
        state.shippingTypes = [];
      })
      .addCase(
        getShippingTypes.fulfilled,
        (state, action: PayloadAction<ShippingTypes[]>) => {
          state.loadingShippingTypes = false;
          state.shippingTypes = action.payload;
        }
      );
  },
});

export const shippingTypesState = (state: RootState) =>
  state.shippingTypesSlice;

export default shippingTypesSlice.reducer;
