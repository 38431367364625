import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="48.87,45.83 25,4.17 1.13,45.83 	"
      />
      <polygon
        className={`${styles.st0}`}
        points="33.29,36.96 27.9,31.58 33.29,26.2 30.38,23.29 25,28.68 19.62,23.29 16.71,26.2 22.1,31.58 
                16.71,36.96 19.62,39.87 25,34.49 30.38,39.87 	"
      />
    </g>
  </svg>
);

export default SVG;
