import { BookingStatus } from "@assets/constants";
import {
  ActiveBooking,
  Booking,
  BookingAction,
  BookingDetail,
  BookingFilters,
  BuyersConsoleBooking,
} from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import BookingService from "@services/BookingService";
import { RootState } from "@state/store";

class ActiveBookingsReq {
  partner: string;
  freightMode: string;
}

class SearchBookingsReq {
  searchString: string;
  freightMode: string;
}

class BookingActionsReq {
  jobReference: string;
  status: BookingStatus;
  isCustomsCleared: boolean;
}

export const getActiveBookings = createAsyncThunk(
  "bookings/active",
  async (req: ActiveBookingsReq) => {
    return await BookingService.getActiveBookings(req.partner, req.freightMode);
  }
);

export const getBookingList = createAsyncThunk(
  "bookings/list",
  async (filter: BookingFilters) => {
    return await BookingService.getBookingList(filter);
  }
);

export const getBookingDetails = createAsyncThunk(
  "bookings/details",
  async (jobReference: string) => {
    return await BookingService.getBookingDetails(jobReference);
  }
);

export const searchBookingsSimple = createAsyncThunk(
  "bookings/search/simple",
  async (req: SearchBookingsReq) => {
    return await BookingService.searchBookingsSimple(
      req.searchString,
      req.freightMode
    );
  }
);

export const getFavoriteBookings = createAsyncThunk(
  "bookings/favorites",
  async () => {
    return await BookingService.getFavoriteBookings();
  }
);

export const toggleFavoriteBooking = createAsyncThunk(
  "bookings/favorites/toggle",
  async (jobReference: string) => {
    return await BookingService.toggleFavoriteBooking(jobReference);
  }
);

export const getBookingsInExcel = createAsyncThunk(
  "bookings/excel",
  async (bookings: Booking[]) => {
    return await BookingService.getBookingsInExcel(bookings);
  }
);

export const getBookingActions = createAsyncThunk(
  "bookings/requests/types",
  async (req: BookingActionsReq) => {
    return await BookingService.getBookingActions(
      req.jobReference,
      req.status,
      req.isCustomsCleared
    );
  }
);

export const getBuyersConsoleBookings = createAsyncThunk(
  "bookings/bc",
  async (jobReference: string) => {
    return await BookingService.getBuyersConsoleBookings(jobReference);
  }
);

export interface BookingsState {
  activeBookings: ActiveBooking[];
  bookingsList: Booking[];
  bookingDetails: BookingDetail[];
  searchBookings: object[];
  favoriteBookings: string[];
  toogleFavoriteBookings: object[];
  bookingsInExcel: void | null;
  bookingActions: BookingAction[];
  bcBookings: BuyersConsoleBooking[];
  loadingBookingActions: boolean;
  isFetchingExcelBookings: boolean;
  loadingBookingsInit: boolean;
  loadingBookings: boolean;
}

export const initialBookingsState: BookingsState = {
  activeBookings: [],
  bookingsList: [],
  bookingDetails: [],
  searchBookings: [],
  favoriteBookings: [],
  toogleFavoriteBookings: [],
  bookingsInExcel: null,
  bookingActions: [],
  bcBookings: [],
  loadingBookingActions: false,
  isFetchingExcelBookings: false,
  loadingBookingsInit: false,
  loadingBookings: false,
};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState: initialBookingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveBookings.rejected, (state) => {
        state.activeBookings = [];
      })
      .addCase(
        getActiveBookings.fulfilled,
        (state, action: PayloadAction<ActiveBooking[]>) => {
          state.activeBookings = action.payload;
        }
      )
      .addCase(getBookingList.rejected, (state) => {
        state.bookingsList = [];
      })
      .addCase(
        getBookingList.fulfilled,
        (state, action: PayloadAction<Booking[]>) => {
          state.bookingsList = action.payload;
        }
      )
      .addCase(getBookingDetails.rejected, (state) => {
        state.bookingDetails = [];
      })
      .addCase(
        getBookingDetails.fulfilled,
        (state, action: PayloadAction<BookingDetail[]>) => {
          state.bookingDetails = action.payload;
        }
      )
      .addCase(searchBookingsSimple.rejected, (state) => {
        state.searchBookings = [];
      })
      .addCase(
        searchBookingsSimple.fulfilled,
        (state, action: PayloadAction<object[]>) => {
          state.searchBookings = action.payload;
        }
      )
      .addCase(getFavoriteBookings.rejected, (state) => {
        state.favoriteBookings = [];
      })
      .addCase(
        getFavoriteBookings.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.favoriteBookings = action.payload;
        }
      )
      .addCase(toggleFavoriteBooking.rejected, (state) => {
        state.toogleFavoriteBookings = [];
      })
      .addCase(
        toggleFavoriteBooking.fulfilled,
        (state, action: PayloadAction<object[]>) => {
          state.toogleFavoriteBookings = action.payload;
        }
      )
      .addCase(getBookingsInExcel.pending, (state) => {
        state.isFetchingExcelBookings = true;
      })
      .addCase(getBookingsInExcel.rejected, (state) => {
        state.isFetchingExcelBookings = false;
        state.bookingsInExcel = null;
      })
      .addCase(
        getBookingsInExcel.fulfilled,
        (state, action: PayloadAction<void>) => {
          state.isFetchingExcelBookings = false;
          state.bookingsInExcel = action.payload;
        }
      )
      .addCase(getBookingActions.pending, (state) => {
        state.loadingBookingActions = true;
      })
      .addCase(getBookingActions.rejected, (state) => {
        state.loadingBookingActions = false;
        state.bookingActions = [];
      })
      .addCase(
        getBookingActions.fulfilled,
        (state, action: PayloadAction<BookingAction[]>) => {
          state.loadingBookingActions = false;
          state.bookingActions = action.payload;
        }
      )
      .addCase(getBuyersConsoleBookings.rejected, (state) => {
        state.bcBookings = [];
      })
      .addCase(
        getBuyersConsoleBookings.fulfilled,
        (state, action: PayloadAction<BuyersConsoleBooking[]>) => {
          state.bcBookings = action.payload;
        }
      );
  },
});

export const bookingsState = (state: RootState) => state.bookingsSlice;

export default bookingsSlice.reducer;
