import Button from "@components/Button/button";
import React from "react";
import { useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa";

type dropdownButtonProps = {
  onClick: (id: number) => void;
  label: string;
  options: any;
  className?: string;
  disabled?: boolean;
  type: "primary" | "secundary";
};

function DropdownButton(props: dropdownButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleDropDownFocus = (state: boolean) => {
    setOpen(!state);
  };

  const handleClickOutsideDropdown = (e: any) => {
    if (open && !dropdownRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  };
  window.addEventListener("click", handleClickOutsideDropdown);

  return (
    <div
      className={`content-center justify-center ${props.className}`}
      ref={dropdownRef}
    >
      <Button
        primary={props.type === "primary"}
        onClick={(e) => handleDropDownFocus(open)}
        className={`h-10`}
      >
        {`${props.label}`}
        <FaCaretDown className="ml-2 inline-block" />
      </Button>
      {open && (
        <ul className="content-center justify-center border fixed z-10 opacity-100">
          {props.options.map((item: any) => {
            return (
              <li
                className="py-2 mr-2 pl-5 border-b w-full bg-white hover:bg-gray-100 text-jonar-blue font-semibold cursor-pointer"
                onClick={() => {
                  props.onClick(item.id), setOpen(false);
                }}
                key={item.id}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default DropdownButton;
