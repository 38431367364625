import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { FreightMode, ShippingType } from "@assets/constants";
import { ActionTableJonar } from "@samskip/frontend-components";
import { AddCargoData } from "@assets/types";
import Tooltip from "@components/Tooltip/tooltip";
import {
  FaBuffer,
  FaExclamationTriangle,
  FaExpandAlt,
  FaTemperatureHigh,
} from "react-icons/fa";

type CargoSectionProps = {
  data: AddCargoData[][];
  shippingType: string | null;
  freightMode: FreightMode;
  /** If true, the edit button will be shown on each line. */
  canEdit?: boolean;
  /** If true, the delete button will be shown on each line. */
  canRemove?: boolean;
  openAddCargoModal?: (
    addCargoData: AddCargoData,
    isEdit: boolean,
    modalType: ShippingType | null,
    cargoIndex?: number,
    containerIndex?: number
  ) => void;
  onDeleteCargoData?: (
    cargoIndex: number,
    containerIndex: number | null
  ) => void;
};

export const CargoSection: React.FC<CargoSectionProps> = ({
  data,
  shippingType,
  freightMode,
  canEdit = false,
  canRemove = false,
  openAddCargoModal,
  onDeleteCargoData,
}) => {
  const { t } = useTranslation();

  const createLCLCargoDataTableHeaders = () => {
    return [
      {
        id: "Description",
        name: t("LABEL_CARGO_DESCRIPTION"),
        className: "w-96",
      },
      {
        id: "QTY",
        name: t("LABEL_QUANTITY_ABBREV"),
      },
      {
        id: "PackageType",
        name: t("LABEL_PACKAGE"),
      },
      {
        id: "Volume",
        name: t("LABEL_VOLUME"),
      },
      {
        id: "Weight",
        name: t("LABEL_WEIGHT"),
      },
      {
        id: "Stackable",
        name: (
          <Tooltip
            content={t("INFO_STACKABLE")}
            key={"Stackable"}
            childClassName="inline-flex"
          >
            <FaBuffer key={"Stackable"} className="text-base" />
          </Tooltip>
        ),
      },
      {
        id: "Hazardous",
        name: (
          <Tooltip
            content={t("INFO_HAZARDOUS")}
            key={"Hazardous"}
            childClassName="inline-flex"
          >
            <FaExclamationTriangle key={"Hazardous"} className="text-base" />
          </Tooltip>
        ),
      },
      {
        id: "TemperatureControlled",
        name: (
          <Tooltip
            content={t("INFO_TEMPERATURE")}
            key={"Temperature"}
            childClassName="inline-flex"
          >
            <FaTemperatureHigh key={"Temperature"} className="text-base" />
          </Tooltip>
        ),
      },
    ];
  };

  const createFCLCargoDataTableHeaders = () => {
    return [
      {
        id: "Description",
        name: t("LABEL_CARGO_DESCRIPTION"),
        className: "w-96",
      },
      {
        id: "QTY",
        name: t("LABEL_QUANTITY_ABBREV"),
      },
      {
        id: "PackageType",
        name: t("LABEL_PACKAGE"),
      },
      {
        id: "Volume",
        name: t("LABEL_VOLUME"),
      },
      {
        id: "Weight",
        name: t("LABEL_WEIGHT"),
      },
      {
        id: "OutOfGauge",
        name: (
          <Tooltip
            content={t("INFO_STACKABLE")}
            key={"OutOfGauge"}
            childClassName="inline-flex"
          >
            <FaExpandAlt key={"OutOfGauge"} className="text-base" />
          </Tooltip>
        ),
      },
      {
        id: "Hazardous",
        name: (
          <Tooltip
            content={t("INFO_HAZARDOUS")}
            key={"Hazardous"}
            childClassName="inline-flex"
          >
            <FaExclamationTriangle key={"Hazardous"} className="text-base" />
          </Tooltip>
        ),
      },
      {
        id: "TemperatureControlled",
        name: (
          <Tooltip
            content={t("INFO_TEMPERATURE")}
            key={"Temperature"}
            childClassName="inline-flex"
          >
            <FaTemperatureHigh key={"Temperature"} className="text-base" />
          </Tooltip>
        ),
      },
    ];
  };

  const createLCLCargoDataTable = (cargoData: AddCargoData[]) => {
    return cargoData.map((cargoData: AddCargoData, index: number) => {
      return {
        id: `cargo-${index}`,
        cells: [
          cargoData?.description,
          cargoData?.quantity,
          cargoData?.unit?.Description,
          cargoData?.volume,
          cargoData?.weight,
          cargoData?.stackable ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          cargoData?.hazardous ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          cargoData?.temperatureControl
            ? cargoData?.temperatureMin +
              " " +
              i18n.t("TEXT_TO") +
              " " +
              cargoData?.temperatureMax
            : i18n.t("TEXT_NO"),
        ],
      };
    });
  };

  const createFCLCargoDataTable = (cargoData: AddCargoData[]) => {
    return cargoData.map((cargoData: AddCargoData, index: number) => {
      return {
        id: `cargo-${index}`,
        cells: [
          cargoData?.description,
          cargoData?.quantity,
          cargoData?.unit?.Description,
          cargoData?.volume,
          cargoData?.weight,
          cargoData?.outOfGauge ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          cargoData?.hazardous ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          cargoData?.temperatureControl
            ? cargoData?.temperatureMin +
              " " +
              i18n.t("TEXT_TO") +
              " " +
              cargoData?.temperatureMax
            : i18n.t("TEXT_NO"),
        ],
      };
    });
  };

  const sectionSeaFCL = () =>
    data.map((data, containerIndex) => (
      <div key={containerIndex}>{renderOnlyForFCL(data, containerIndex)}</div>
    ));

  const onEdit = (
    cargoData: AddCargoData,
    isEdit: boolean,
    cargoIndex?: number,
    containerIndex?: number
  ) => {
    if (canEdit && openAddCargoModal) {
      switch (shippingType) {
        case ShippingType.FCL:
          openAddCargoModal(
            cargoData,
            true,
            ShippingType.FCL,
            cargoIndex,
            containerIndex
          );
          break;
        default:
          openAddCargoModal(cargoData, true, ShippingType.LCL, cargoIndex);
          break;
      }
    }
  };

  const onRemove = (cargoIndex: number, containerIndex: number | null) => {
    if (canEdit && onDeleteCargoData) {
      switch (shippingType) {
        case ShippingType.FCL:
          onDeleteCargoData(cargoIndex, containerIndex);
          break;
        default:
          onDeleteCargoData(cargoIndex, null);
          break;
      }
    }
  };

  const renderOnlyForLCL = (cargoData: AddCargoData[]) => {
    if (cargoData?.length === 0) {
      return (
        <>
          <div className="m-6">
            <ActionTableJonar
              className="divide-y divide-neutral-500"
              headers={createLCLCargoDataTableHeaders()}
              id="CargoTable"
              tableData={createLCLCargoDataTable([])}
              shouldFade={false}
              canRemove={canRemove}
              canEdit={canEdit}
              onEdit={(cargoIndex) =>
                onEdit(cargoData[cargoIndex], true, cargoIndex)
              }
              onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
              scrollable={false}
            />
          </div>
          <p className="ml-10">{t("LABEL_NO_CARGO_ADDED")}</p>
        </>
      );
    }

    return (
      <>
        {cargoData?.length > 0 && (
          <div className="mx-6">
            <ActionTableJonar
              className="divide-y divide-neutral-500"
              headers={createLCLCargoDataTableHeaders()}
              id="CargoTable"
              tableData={createLCLCargoDataTable(cargoData)}
              shouldFade={false}
              canRemove={canRemove}
              canEdit={canEdit}
              onEdit={(cargoIndex) =>
                onEdit(cargoData[cargoIndex], true, cargoIndex)
              }
              onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
              scrollable={false}
            />
          </div>
        )}
      </>
    );
  };

  const renderOnlyForFCL = (
    cargoData: AddCargoData[] | undefined,
    containerIndex: number
  ) => {
    if (cargoData === undefined || cargoData === null) {
      return (
        <>
          <div className="m-6">
            <ActionTableJonar
              className="divide-y divide-neutral-500"
              headers={createFCLCargoDataTableHeaders()}
              id="CargoTable"
              tableData={createFCLCargoDataTable([])}
              shouldFade={false}
              canRemove={canRemove}
              canEdit={canEdit}
              onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
              scrollable={false}
            />
          </div>
          <p className="ml-10">{t("LABEL_CONTAINER_CARGO_ADDED")}</p>
        </>
      );
    }

    return (
      <>
        <div className="font-semibold text-neutral-9 leading-6 text-xl m-6">
          <h2>
            {t("LABEL_CONTAINER")} {containerIndex + 1}
          </h2>
        </div>
        <div className="m-6 border-2 p-4 grid grid-cols-5">
          {cargoData[0]?.containerNumber !== "" && (
            <div>
              <label className="text-neutral-7">
                {t("LABEL_CONTAINER_NUMBER")}
              </label>
              <div className="text-neutral-9">
                {t(cargoData[0]?.containerNumber ?? "N/A")}
              </div>
            </div>
          )}
          <div>
            <label className="text-neutral-7">
              {t("LABEL_CONTAINER_TYPE")}
            </label>
            <div className="text-neutral-9">
              {t(cargoData[0]?.containerType?.Description ?? "N/A")}
            </div>
          </div>
          {cargoData[0]?.containerSealNumber !== "" && (
            <div>
              <label className="text-neutral-7">{t("LABEL_SEAL_NUMBER")}</label>
              <div className="text-neutral-9">
                {t(cargoData[0]?.containerSealNumber ?? "N/A")}
              </div>
            </div>
          )}
          <div>
            <label className="text-neutral-7">{t("LABEL_VOLUME")}</label>
            <div className="text-neutral-9">
              {cargoData[0]?.volume ?? "N/A"}
            </div>
          </div>
          <div>
            <label className="text-neutral-7">{t("LABEL_WEIGHT")}</label>
            <div className="text-neutral-9">
              {cargoData[0]?.weight ?? "N/A"}
            </div>
          </div>
        </div>
        <div className="m-6">
          <ActionTableJonar
            className="divide-y divide-neutral-500"
            headers={createFCLCargoDataTableHeaders()}
            id="CargoTable"
            tableData={createFCLCargoDataTable(cargoData)}
            shouldFade={false}
            canRemove={canRemove}
            canEdit={canEdit}
            onEdit={(cargoIndex) =>
              onEdit(cargoData[cargoIndex], true, cargoIndex, containerIndex)
            }
            onRemove={(cargoIndex) => onRemove(cargoIndex, containerIndex)}
            scrollable={false}
          />
        </div>
      </>
    );
  };

  const renderEmptyActionTable = () => {
    return (
      <>
        <div className="m-6">
          <ActionTableJonar
            className="divide-y divide-neutral-500"
            headers={
              shippingType === ShippingType.LCL ||
              freightMode === FreightMode.AI
                ? createLCLCargoDataTableHeaders()
                : createFCLCargoDataTableHeaders()
            }
            id="CargoTable"
            tableData={createLCLCargoDataTable([])}
            shouldFade={false}
            canRemove={canRemove}
            canEdit={canEdit}
            onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
            scrollable={false}
          />
        </div>

        <p className="ml-10">
          {shippingType === ShippingType.LCL || freightMode === FreightMode.AI
            ? t("LABEL_NO_CARGO_ADDED")
            : t("LABEL_NO_CONTAINER_CARGO_ADDED")}
        </p>
      </>
    );
  };

  const renderOnlyForAir = (cargoData: AddCargoData[]) => {
    if (cargoData?.length === 0) {
      return (
        <>
          <div className="m-6">
            <ActionTableJonar
              className="divide-y divide-neutral-500"
              headers={createLCLCargoDataTableHeaders()}
              id="CargoTable"
              tableData={createLCLCargoDataTable(cargoData)}
              shouldFade={false}
              canRemove={canRemove}
              canEdit={canEdit}
              onEdit={(cargoIndex) =>
                onEdit(cargoData[cargoIndex], true, cargoIndex)
              }
              onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
              scrollable={false}
            />
          </div>
          <p className="ml-10">{t("LABEL_NO_CARGO_ADDED")}</p>
        </>
      );
    }

    return (
      <>
        {cargoData?.length > 0 && (
          <div className="mx-6">
            <ActionTableJonar
              className="divide-y divide-neutral-500"
              headers={createLCLCargoDataTableHeaders()}
              id="CargoTable"
              tableData={createLCLCargoDataTable(cargoData)}
              shouldFade={false}
              canRemove={canRemove}
              canEdit={canEdit}
              onEdit={(cargoIndex) =>
                onEdit(cargoData[cargoIndex], true, cargoIndex)
              }
              onRemove={(cargoIndex) => onRemove(cargoIndex, null)}
              scrollable={false}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {freightMode == FreightMode.SH && shippingType === ShippingType.FCL
        ? sectionSeaFCL()
        : null}
      {freightMode == FreightMode.SH && shippingType === ShippingType.LCL
        ? renderOnlyForLCL(data[0])
        : null}
      {data.length === 0 ? renderEmptyActionTable() : null}
      {freightMode == FreightMode.AI ? renderOnlyForAir(data[0]) : null}
    </>
  );
};

CargoSection.displayName = "CargoSection";

export default CargoSection;
