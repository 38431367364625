import React, { Children, useEffect, useRef } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import { AppState } from "@state/reducers/types";

type DropdownResultsProps = {
  headers?: string[];
  children: React.ReactNode;
  onOutsideClick?: Function;
};

const DropdownResults: React.FC<DropdownResultsProps> = ({
  headers,
  children,
  onOutsideClick,
}) => {
  const childrenLength = Children.toArray(children).length;

  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (
      node.current &&
      e.target instanceof Node &&
      node.current.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    if (onOutsideClick) {
      onOutsideClick();
    }
    return;
  };

  useEffect(() => {
    // add when mounted
    if (childrenLength > 0) document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <Translation>
      {(t) => (
        <div className="w-full" ref={node}>
          {headers ? (
            <div className="h-auto bg-gray-100 flex justify-between items-center normal-case text-gray-600 font-normal px-3 py-2 text-xs z-50">
              {headers &&
                headers.map((header: string, i: number) => {
                  if (i === 0) {
                    return (
                      <span key={i} className="font-bold">
                        {header}
                      </span>
                    );
                  }
                  return (
                    <span key={i}>
                      {header}{" "}
                      {childrenLength == 1000 ? <span>(max)</span> : null}{" "}
                    </span>
                  );
                })}
            </div>
          ) : null}
          <div
            className={`max-h-56 ${
              childrenLength > 5 ? "overflow-y-scroll" : null
            }`}
          >
            {childrenLength > 0 ? (
              children
            ) : (
              <button className="flex justify-between bg-white text-gray-700 font-normal p-3 hover:bg-gray-100 cursor-pointer w-full border-t">
                <span className="text-left">{t("LABEL_NO_ITEMS_FOUND")}</span>
              </button>
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
  }),
  {}
)(DropdownResults);
