import React, { PureComponent } from "react";

import { FiChevronDown } from "react-icons/fi";
import styles from "./accordion.module.less";

type AccordionProps = {
  children: React.ReactNode;
  accTitle: string;
  openAccordion?: boolean;
  className?: string;
};

class Accordion extends PureComponent<AccordionProps> {
  state = {
    openAccordion: this.props.openAccordion ? this.props.openAccordion : false,
    firstLoad: true,
    className: this.props.className ? this.props.className : "",
  };
  render() {
    return (
      <div
        className={`pt-4 w-full float-left block ${this.state.className} ${
          styles.accordion
        } ${this.state.openAccordion ? "open" : ""}`}
      >
        <div
          className={`${styles.accordion__header} relative justify-between content-center leading-none cursor-pointer`}
          onClick={() =>
            this.setState({
              openAccordion: !this.state.openAccordion,
              firstLoad: false,
            })
          }
        >
          <h3 className="relative inline-block font-semibold font-normal-text-color pb-2px pt-1 pr-2 uppercase bg-lighter-gray">
            {this.props.accTitle}
          </h3>
          <span className="relative inline-block bg-lighter-gray pl-2 float-right">
            <FiChevronDown
              className={`inline-block text-lg text-jonar-blue leading-3 transition duration-300 ${
                this.state.openAccordion
                  ? "transform rotate-180"
                  : "transform rotate-0"
              }`}
            />
          </span>
        </div>
        <div
          className={`${
            styles.accordion__content
          } pt-2 block float-left w-full ${
            this.state.openAccordion ? "animate-slide-down" : "animate-slide-up"
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Accordion;
