import React from "react";

import Link from "@components/Link/link";
import moment from "moment";
import { Translation } from "react-i18next";

const Footer = () => (
  <Translation>
    {(t, { i18n }) => (
      <footer className="text-white bg-jonar-blue">
        <div
          className={`flex flex-col md:flex-row justify-between xl:container mx-auto px-8`}
        >
          <div className="flex flex-col justify-between py-2 md:py-3">
            <p>
              © {moment().format("YYYY")} Jónar Transport | Kjalarvogur 7-15 |
              104 Reykjavik | Ísland
            </p>
            <p>
              Tel <span className="font-bold">+354 535 8000</span>
            </p>
            <p>
              E-mail{" "}
              <span>
                <a
                  className="font-bold hover:underline"
                  href="mailto:customerservice@jonar.is"
                >
                  customerservice@jonar.is
                </a>
              </span>
            </p>
          </div>
          <div className="flex flex-col py-2 md:py-4">
            <span>
              <Link
                className="pr-2 hover:underline"
                href="http://www.jonar.is/hafa-samband"
                rel="noreferrer"
                target="_blank"
                color="neutral-0"
              >
                <span>{t("LABEL_CONTACT")}</span>
              </Link>
              <span>|</span>
              <Link
                className="pl-2 hover:underline"
                href="http://www.jonar.is/um-okkur/skilmalar"
                rel="noreferrer"
                target="_blank"
                color="neutral-0"
              >
                <span>{t("LABEL_TERMS")}</span>
              </Link>
            </span>
            <span>
              <Link
                className="pr-2 hover:underline"
                href={
                  i18n.language === "en"
                    ? "https://www.jonar.is/english/offices/"
                    : "https://www.jonar.is/upplysingar/erlendar-skrifstofur/"
                }
                rel="noreferrer"
                target="_blank"
                color="neutral-0"
              >
                <span>{t("LABEL_OFFICES_AND_AGENTS")}</span>
              </Link>
            </span>
          </div>
        </div>
      </footer>
    )}
  </Translation>
);

export default Footer;
