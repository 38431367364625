import React from "react";
import {
  BookingCargoContainerResponse,
  CargoBookingResponse,
} from "@assets/types";
import PalletCell from "@components/PalletCell/palletCell";
import { useTranslation } from "react-i18next";

interface PalletInfoPageProps {
  cargoBooking: CargoBookingResponse | undefined | null;
}

const PalletInfoComponent: React.FC<PalletInfoPageProps> = (
  props: PalletInfoPageProps
) => {
  const { t } = useTranslation();

  const getPallets = (container: BookingCargoContainerResponse) => {
    if (container.Pallets == null || container.Pallets.length == 0) {
      if (props.cargoBooking?.Pallets != null) {
        return props.cargoBooking.Pallets;
      } else {
        return [];
      }
    } else {
      return container.Pallets;
    }
  };

  const palletData = props.cargoBooking?.Containers?.map((container, index) => (
    <PalletCell
      key={`pallet-${index}`}
      className="mt-6"
      title={container.ContainerNumber ?? "N/A"}
      containerIndex={index + 1}
      palletInfo={getPallets(container)}
    />
  ));

  return (
    <div className="w-full my-6 px-8">
      <div>
        <label className="font-semibold text-neutral-9 leading-6 text-xl">
          {t("LABEL_PALLET_INFORMATION")}
        </label>
      </div>
      {palletData}
    </div>
  );
};

export default PalletInfoComponent;
