import { Moment } from "moment";
import React, { ReactElement } from "react";
import {
  BookingStatus,
  TransportPath,
  FreightMode,
  IncotermPoint,
  PortType,
  BookingTypes,
} from "./constants";

export type LoginState = {
  username: string;
  password: string;
};

export interface UserProfileViewModel extends UserProfile {
  SelectedCompany: Company;
  WebSettings: UserWebSettings[];
}

export interface UserProfile {
  User: User;
  Access: UserAccess;
  Roles: WebRole[];
  Companies: Company[];
  Menu: MenuItem[];
}

export type User = {
  ID: number;
  Username: string;
  Language: string;
  Created: Date;
  Disabled: string;
  Locked: string;
  Country: string;
  Phone: string;
  SendEmail: string;
  SendMessage: string;
};

export type UserAccess = {
  UserID: number;
  LoginID: string;
  Password: null;
  Salt: null;
  SubSys: number;
  Created: Date;
  Active: string;
  MapID: string;
  LastLogin: Date;
  Email: string;
  UserType: string;
  SendMail: string;
  SendMessage: string;
  ResetPassHash: null;
  ResetPassDate: null;
  LoginAttempts: number;
  Secret: null;
};

export type WebRole = {
  RoleID: number;
  RoleName: string;
  RoleDescription: string;
  Employee: string;
  Customer: string;
};

export type Company = {
  FullName: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: null;
  PointCode: null;
  PostCode: string;
  Country: string;
  CompanyCode: string;
  PartnerCode: string;
  PartnerSubCode: string;
  CompanyRegNo: string;
  SapID: string;
  WarehouseID: string;
  DeleteFlag: string;
  CompanySubSys: number;
};

export type MenuItem = {
  MenuID: number;
  MenuLevel: number;
  MenuParent: number;
  MenuName: string;
  Site: string;
  Translate: string;
  Tooltip: string;
  SystemID: number;
  OrderID: number;
  ExternalLink: number;
  children?: MenuItem[];
};

export type UserWebSettings = {
  UserID: number;
  Site: null;
  Field: string;
  StringValue: string;
  SubSys: number;
};

export type BookingTile = {
  Week: number;
  Ready: number;
};

export enum ToastType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  ALERT = "alert",
}

export interface Toast {
  id: number;
  removeToast: Function;
  type: ToastType;
  header: string;
  message: string;
  lifeTime: number;
}
export interface ModalProps {
  header?: string;
  open: boolean;
  closeModal: CallbackFunction;
  children?: ReactElement[] | ReactElement;
  closeIcon?: boolean;
  className?: string;
  closeOnOutsideClick?: boolean;
}

export interface ModalFooterProps {
  className?: string;
  children: ReactElement[];
}

export interface PasswordModalProps extends ModalProps {
  login: Function;
  logout: Function;
  userProfile: UserProfileViewModel;
  loginInProgress: boolean;
  logoutInProgress: boolean;
  setAccessTokenAndTimeout: Function;
}

export type Booking = {
  JobReference: string;
  Status: BookingStatus;
  IsCustomsCleared: boolean;
  CustomsClearedDate: Date;
  BOLNumber: string;
  PointFrom: string;
  PointLoad: string;
  PointLoadName: string;
  PointTrans: string;
  PointDisch: string;
  PointDischName: string;
  PointTO: string;
  ETD: Date;
  ETA: Date;
  Shipper: BookingAddress;
  Consignee: BookingAddress;
  VoyageReference: string;
  ShippingType: string;
  FreightMode: string;
  IsHazardous: boolean;
  IsBCBooking: boolean;
  IsBOL: boolean;
  ConsignmentNumberSimplified: string;
  ConsignmentNumber: string;
  InternalNoteCount: number;
  ExternalNoteCount: number;
  ShipReference: string;
};

export type BookingDetail = {
  Dimensions: BookingDimension[];
  ContainerNumber: string;
  ContainerSealNumber: string;
  Description: string;
  Weight: number;
  WeightUOM: string;
  Volume: number;
  VolumeUOM: string;
  Length?: number;
  LengthUOM?: string;
  PackageCode?: string;
  Quantity: number;
  QuantityUOM?: string;
  IsTemperatureControlled: boolean;
  HazardFlag: boolean;
  Marks: string;
  MarksDescription: string;
  MinTemperature: number | null;
  MaxTemperature: number | null;
  Temperature: number | null;
};

export type BookingDimension = {
  PackageCode: string;
  NumUnits: number;
  Weight: number;
  Volume: number;
  Height: number;
  Length: number;
  Width: number;
  Hazardous: HazardCodes[];
  Oversize: BookingOversize | null;
};

export type BookingOversize = {
  OverHeight: number | null;
  OverLengthAfter: number | null;
  OverLengthFore: number | null;
  OverWidthLeft: number | null;
  OverWidthRight: number | null;
};

export type BookingAddress = {
  PartnerCode: string;
  Name: string;
};

export type BookingAddressRequest = {
  PartnerCode: string | undefined;
  FullName: string | undefined;
  AddressType: string | undefined;
  JobReference: string | undefined;
  Address1: string | undefined;
  Address2: string | undefined;
  Address3: string | undefined;
  Address4: string | undefined;
  Address5: string | undefined;
  PointCode: string | undefined;
  PostCode: string | undefined;
  ContactName: string | undefined;
  ContactNumber: string | undefined;
  ContactEmail: string | undefined;
  SSN: string | undefined;
  RequestDate: Date | undefined;
  RequestTime: number | undefined;
  RequestTolerance: number | undefined;
  SpecialInstructions: string | undefined;
  TheirRef: string | undefined;
  Currency: string | undefined;
  Town: string | undefined;
};

export type FavoriteBooking = {
  JobReference: string;
};

export type BookingAction = {
  TypeID: number;
  TypeMap: string;
  TypeDescription: string;
  Site: string;
  Section: number;
};

export type Action = {
  ID: number;
  Translation: string;
  Link?: string;
  OpenInWindow?: boolean;
  Section: number;
  onClick?: Function;
};

export type TableInfo = {
  tableHeader: Array<string | ReactElement | null | undefined>;
  tableData: Array<
    Array<string | number | null | undefined | Date | ReactElement>
  >;
  classes: Array<string>;
};

export type ActiveBooking = {
  JobReference: string;
  Reference: string;
  Status: string;
  IsCustomsCleared: boolean;
};

export type ActiveRequest = {
  ID: number;
  JobReference: string;
  Status: string;
  Type: string;
  BookingFreightMode: string;
};

export type ActiveVoyage = {
  VoyageReference: string;
  VesselName: string;
  AltVoyageReference: string;
  VoyageStatus: string;
  ServiceNumber: string;
  Vessel: string;
  PointTo: string;
  PointFrom: string;
  StartDate?: Date;
  EndDate?: Date;
  ETDDate?: Date;
  LastChangedDate?: Date;
  Visits: Visit[];
};

export interface Visit {
  VoyageReference: string;
  PoolCode: string;
  VisitNumber: string;
  OriginalETADate?: Date | null;
  OriginalETDDate?: Date | null;
  ETADate?: Date | null;
  ETDDate?: Date | null;
  ATADate?: Date | null;
  ATDDate?: Date | null;
  CutoffDate?: Date | null;
  PortActivity: string;
  PortCode: string;
  TimeZone: string;
  TimeZoneDiff: number;
}

export interface Currency {
  CurrencyCode: string;
  CurrencyDate: string;
  BankRate: number;
  IskKross: number;
}

export enum REQUEST_TYPES {
  BOOKINGQUERY = "BOOKINGQUERY",
  CORRECTION = "CORRECTION",
  EXPISRPASSIST = "EXPISRPASSIST",
  IMPREADYNOTIFY = "IMPREADYNOTIFY",
  DRREQCNTIMP = "DRREQCNTIMP",
  DELIVREQ = "DELIVREQ",
  DRREQVANINP = "DRREQVANINP",
  CUSTCLR = "CUSTCLR",
  DELIVAIRREQ = "DELIVAIRREQ",
  RELFCLIMP = "RELFCLIMP",
  RELEASE = "RELEASE",
  BULKDELIVERY = "BULKDELIVERY",
  DRREQDOMINP = "DRREQDOMINP",
  MOVETOCUST = "MOVETOCUST",
  WAREHOUSEMOVEMENT = "WAREHOUSEMOVEMENT",
}

export type BookingSearchResult = {
  JobReference: string;
  Status: BookingStatus;
  FreightMode: string;
};
export interface BookingFilters {
  searchString: string;
  searchTypes: string[];
  partnerCode: string;
  freightModes: FreightMode[];
  shippingType: string[];
  startDate: Moment | null;
  endDate: Moment | null;
  POL: string[];
  POD: string[];
  shippers: string[];
  consignees: string[];
  statuses: BookingStatus[];
  bookingTypes: BookingTypes[];
  transportPaths: TransportPath[];
  isCustomsCleared?: boolean | string;
  isHazardous?: boolean | string;
  sort: { sortBy?: string; isDesc: boolean };
}

export interface Label {
  text: React.ReactNode;
  key: string;
}

export type DropdownOption = {
  value: string;
  item: React.ReactNode;
};

export interface BuyersConsoleBooking {
  JobReference: string;
  ShipperName: string;
  Description: string;
  FreightMode: string;
  Weight: string;
  WeightUOM: string;
  Volume: string;
  VolumeUOM: string;
  Quantity: string;
  QuantityUOM: string;
  IsTemperatureControlled: boolean;
  MinTemperature: number | null;
  MaxTemperature: number | null;
  Temperature: number | null;
}

export interface BuyersConsoleSubBooking {
  JobReference: string;
  ShipperName: string;
  ConsigneeName: string;
}

export interface WebNote {
  RecId: string;
  JobReference: string;
  CreateDate: Date;
  Note: string;
  IsInternal: boolean;
  UserName: string;
  IsRemark: boolean;
}

export interface Alert {
  Type: string;
  HeadingIS: string;
  BodyHtmlIS: string;
  HeadingEN: string;
  BodyHtmlEN: string;
}

export type Port = {
  PointCode: string;
  FullName: string;
  Country: string;
  CountryCode: string;
  PortCode: string;
  LocalName: string;
  PortType: string;
  TimeZone: string;
  TimeZoneDiff: number;
};

/** The values to determine if a specific incoterm place can be selected or not. */
export interface Places {
  PLR: Port | null;
  POL: Port | null;
  PFD: Port | null;
  POD: Port | null;
}

export interface PortFilters {
  portType?: PortType;
  searchString?: string;
  country?: string;
  size?: number;
}

export type CompanyAddress = {
  CompanyCode: string;
  PartnerCode: string;
  PartnerSubCode: string;
  AddressType: string;
  AddressCode: string;
  FullName: string;
  PointCode: string;
  PostCode: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  CountryCode: string;
  StateCode: string;
  CountryName: string;
  AddressTypeDescription: string;
  PointName: string;
  ContactNumber: string;
  ContactName: string;
  ContactEmail: string;
  City: string;
  Currency: string;
};

export type PickPartnerChangeRegistration = {
  partner: CompanyAddress | null;
  companyName: string;
  address: string;
  postalCode: string;
  placeCity: Port | null;
  district?: string;
  extra?: string;
  contact: string;
  email: string;
  phone: string;
};

export enum UserType {
  Consignee,
  Shipper,
}

export type ShippingTypes = {
  Id?: number;
  First?: string | undefined;
  Second?: string | undefined;
};

export type Incoterms = {
  TermsCode: string;
  Description: string;
  AllowedIncotermsPoints: IncotermPoint[];
  PrimaryIncotermsPoint: IncotermPoint;
  BookingParty: string;
};

export type AddCargoData = {
  commodity: Commodities | null;
  quantity?: number;
  containerType: ContainerTypes | null;
  containerShipperOwned: boolean;
  containerNumber: string;
  containerSealNumber: string;
  unit: PackageTypes | null;
  description: string;
  dimensions: AddDimensionsData[];
  length?: number;
  width?: number;
  height?: number;
  volume?: number;
  weight?: number;
  temperatureControl: boolean;
  temperatureMin: string;
  temperatureMax: string;
  stackable: boolean;
  hazardous: boolean;
  unNumbers: HazardCodes[] | null;
  outOfGauge: boolean;
  marksAndNumbers: string;
};

export type AddDimensionsData = {
  quantity?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
};

export type ContainerTemperatures = {
  Temperatures: number;
};

export type ContainerTypes = {
  CubicMetres: number;
  Description: string;
  Height: number;
  PackageCode: string;
  Width: number;
  PackageType: string;
  StackableFlag: string;
  Length: string;
  ResourceType: string;
  ResourceSize: number;
  Refrigerated: string;
};

export type PackageTypes = {
  Description: string;
  PackageCode: string;
  StackableFlag: string;
  Refrigerated: string;
  DefaultTeus: number;
};

export type ResourceMaxSizes = {
  ContainerType: string;
  Weight: number;
  WeightUOM: string;
  Volume: number;
  VolumeUOM: number;
};

export type HazardCodes = {
  UnNumber: string;
  Description: string;
  ImdgClass: string;
  UnVariant: string;
  FlashPoint: string;
  MarinePollutant: string;
  DTLSequence: number;
};

export type HSCodes = {
  HSCode: string;
  Description: string;
};

export type Commodities = {
  CommodityCode: string;
  Description: string;
};

export type PostalCodeFormat = {
  Country: string;
  PostalCodeRegexFormat: string;
  PostalCodeExample: string;
};

export type Partner = {
  PointCode: string;
  FullName: string;
};

export type SelectOption<T> = {
  value: T;
  label: string;
};

export type Document = {
  document: File;
  doctype: string;
};

export type BookingJourneyTransit = {
  VoyageReference: string;
  POL: string;
  POD: string;
  ETD: Date | undefined;
  ETA: Date | undefined;
};

export type BookingRequest = {
  JobReference: string | null;
  ExternalJobReference: string | null;
  ControlNumber: string | null;
  DocumentReference: string | null;
  Shipper: BookingAddressRequest | null;
  Consignee: BookingAddressRequest | null;
  Notifier: BookingAddressRequest | null;
  CollectionAddress: BookingAddressRequest | null;
  DeliveryAddress: BookingAddressRequest | null;
  BookingPartyReference: string | null;
  BookingParty: string | null;
  BookingPartyType: string | null;
  PLR: Port | null;
  PFD: Port | null;
  POL: Port | null;
  POD: Port | null;
  IncoTerms: string | null;
  IncoTermsPoint: string | null;
  Status: number | null;
  VoyageReference: BookingJourneyTransit | null;
  ShippingType: string | null;
  FreightMode: string | null;
  Details: BookingDetail[] | [];
  HsCodes: HSCodes[] | null;
  TheirRef: string | null;
  QuoteNumber: string | null;
  BOLType: string | null;
  Documents: UploadDocumentsResponse[] | [];
};

export enum ErrorTypes {
  AlreadyExists = "ERROR_DOCUMENTS_DUPLICATE",
  FileNameSizeExceded = "ERROR_DOCUMENTS_NAME",
  FileSizeExceded = "ERROR_DOCUMENTS_SIZE",
  FileTypeNotSupported = "ERROR_DOCUMENTS_TYPE_UNSUPPORTED",
  MandatoryField = "ERROR_DOCUMENTS_TYPE_MANDATORY",
  RequiredField = "ERROR_REQUIRED_FIELD",
  MaxLengthExceeded = "ERROR_MAX_N_CHAR_EXCEEDED",
  SamePortNotPossible = "ERROR_SAME_PORT_NOT_POSSIBLE",
  ShipperIsRequired = "ERROR_SHIPPER_NAME",
  ShipperDetailsRequireChange = "ERROR_SHIPPER_DETAILS_CHANGE",
  ConsigneeIsRequired = "ERROR_CONSIGNEE_NAME",
  ConsigneeDetailsRequireChange = "ERROR_CONSIGNEE_DETAILS_CHANGE",
  EmailIsNotValid = "ERROR_EMAIL_FORMAT",
  TimeValidation = "ERROR_HOURS_EXCEEDED",
  CollectionDetailsIsRequired = "ERROR_COLLECTION_REQUIRED",
  DeliveryDetailsIsRequired = "ERROR_DELIVERY_REQUIRED",
  CargoDataIsRequired = "ERROR_CARGO_LINES_REQUIRED",
  ContainerTemperatureError = "ERROR_CONTAINER_TEMPERATURE",
  ContainerNumberValidation = "ERROR_CONTAINER_NUMBER_FORMAT",
  DescriptionSizeExceded = "ERROR_DESCRIPTION_SIZE",
  VolumeExceded = "ERROR_VOLUME_EXCEDED",
  WeightExceded = "ERROR_WEIGHT_EXCEDED",
  MaxTempExceded = "ERROR_MAX_TEMP_EXCEEDED",
  MinTempExceded = "ERROR_MIN_TEMP_EXCEEDED",
  MaxTempGreaterThenMinTemp = "ERROR_MAX_TEMP_GREATER_THEN_MIN_TEMP",
  EmptyCollectionDetails = "ERROR_EMPTY_COLLECTION_DETAILS",
  EmptyCargoData = "ERROR_EMPTY_CARGO_DATA",
  PostalCodeError = "ERROR_POSTAL_CODE_EXAMPLE",
}

export type DocumentError = {
  errorType: ErrorTypes;
  index: number;
};

export type RequestCollectionRegistration = {
  partner: CompanyAddress | null;
  companyName: string;
  address: string;
  postalCode: string;
  placeCity: Port | null;
  district?: string;
  extra?: string;
  contact: string;
  email: string;
  phone: string;
  collectionDate: Date | null;
  from: string;
  to: string;
  collectionReference: string;
  additionalInfo: string;
};

export type BillOfLading = {
  type: string;
  value: string;
  label: string;
};

export type RouteBookingResponse = {
  JobReference: string | null;
  ExternalJobReference: string | null;
  QuoteNumber: string | null;
  Shipper: BookingAddressRequest | null;
  Consignee: BookingAddressRequest | null;
  Notifier: BookingAddressRequest | null;
  CollectionAddress: BookingAddressRequest | null;
  DeliveryAddress: BookingAddressRequest | null;
  BookingPartyReference: string | null;
  BookingPartyType: string | null;
  BOLType: string | null;
  BOLNumber: string | null;
  NumOriginalBills: number | null;
  NumCopyBills: number | null;
  PLR: Port | null;
  PFD: Port | null;
  POL: Port | null;
  POD: Port | null;
  IncoTerms: string | null;
  IncoTermsPoint: string | null;
  VoyageReference: BookingJourneyTransit | null;
  ShippingType: string | null;
  Warehouse: string[] | null;
  ShipmentNumber: string | null;
  InputReference: string | null;
  Status: BookingStatus | null;
  CustomsStatus: string | null;
  CustomsClearanceDate: Date | null;
  HBLFlag: boolean | null;
  ShipReference: string | null;
};

export type CargoBookingResponse = {
  JobReference: string | null;
  ShippingType: string | null;
  Containers: BookingCargoContainerResponse[] | null;
  MarksAndNumbers: BookingMarksAndNumbersRes[] | null;
  HsCodes: HsCodesResponse[] | null;
  Pallets: BookingPalletResponse[] | null;
};

export type BookingCargoContainerResponse = {
  ContainerNumber: string | null;
  ContainerData: ExposedContainerDataRes[] | null;
  Pallets: BookingPalletResponse[] | null;
  Delivered: boolean | null;
};

export type ExposedContainerDataRes = {
  ContainerType: string | null;
  SealNumber: string | null;
  Details: ExposedBookingCargoDetailsRes[] | null;
};

export type ExposedBookingCargoDetailsRes = {
  BcBookingReference: string | null;
  BcBookingShipper: string | null;
  BcBookingConsignee: string | null;
  Description: string | null;
  Quantity: number | null;
  PackageCode: string | null;
  CommodityCode: string | null;
  Length: number | null;
  Width: number | null;
  Height: number | null;
  DimensionsUOM: string | null;
  Weight: number | null;
  WeightUOM: string | null;
  Volume: number | null;
  VolumeUOM: string | null;
  IsStackable: boolean | null;
  IsHazardous: boolean | null;
  IsRefrigerated: boolean | null;
  MinTemperature: number | null;
  MaxTemperature: number | null;
};

export type BookingMarksAndNumbersRes = {
  Marks: string | null;
  Description: string | null;
};

export type HsCodesResponse = {
  HSCode: string | null;
  Description: string | null;
};

export type BookingPalletResponse = {
  ID: string | null;
  Container: string | null;
  Location: string | null;
  LocationType: string | null;
  Quantity: number | null;
  Status: number | null;
  StatusDescription: string | null;
  DateOpened: Date | null;
  DateClosed: Date | null;
  Type: string | null;
};

export interface JonarAPIResponse<T> {
  RequestStatus: 0 | 1;
  ReturnItem: ResultItem<T>;
  ErrorItem: object;
}

export interface ResultItem<T> {
  Data: T;
  Status: object;
}

export type BookingDocumentResponse = {
  Job_Reference: string | null;
  Document_Type: string | null;
  Document_Code: string | null;
  Document_Path: string | null;
  Document_UID: number | null;
  Version_no: number | null;
  Inv_Number: string | null;
  Inv_Partner_Code: string | null;
  Inv_Shipper_Code: string | null;
};

export type UploadDocumentsResponse = {
  ID: string;
  ETag: string | null;
  Name: string | null;
  ContentType: string | null;
  Type: string | null;
  Code: string | null;
  Created: Date | null;
  Modified: Date | null;
  Description: string | null;
};

export type SubBookingDocuments = {
  JobReference: string;
  ShipperName: string;
  ConsigneeName: string;
  Documents: UploadDocumentsResponse[];
};

export type ExposedDocumentsByEmail = {
  FileNumbers: (string | null)[];
  Emails: (string | null)[];
};

export type DocumentsList = {
  Name: string;
  Id: number;
  ETag: string;
};

export type CallbackFunction = () => void;

export type Request = {
  ID: number | null;
  JobReference: string | null;
  Status: string | null;
  TypeDescription: string | null;
  Type: string | null;
  UserName: string | null;
  CreateTime: Date | null;
  CloseTime: Date | null;
};

export type Invoice = {
  InvoiceID: string | null;
  Amount: string | null;
  Curreny: string | null;
  Date: Date | null;
  InvoiceType: string | null;
  Url: string | null;
};

export interface ResetPasswords {
  password: string;
  passwordConfirm: string;
}

export type CustomerStatus = {
  Blocked: boolean | null;
  CashPayment: boolean | null;
  MaximumWithdrawal: number | null;
  TotalBalance: number | null;
  Currency: string | null;
  CompanyCode: string | null;
};

export type KeyBalance = {
  SapId: string | null;
  CompanyCode: string | null;
  Date: Date | null;
  Currency: string | null;
  Balance: number | null;
};

export type KeyBalanceCompany = {
  CurrentStatus: CustomerStatus | null;
  KeyBalanceFrom: KeyBalance | null;
  KeyBalanceTo: KeyBalance | null;
};

export type Transaction = {
  SapDocumentNumberField: string | null;
  InvoiceNumberField: string | null;
  InvoiceDateField: string | null;
  AmountField: string | null;
  ExchangeCodeField: string | null;
  DepartmentField: string | null;
  SapTxtField: string | null;
  BalancedField: string | null;
  BalancedDateField: string | null;
  DueDateField: string | null;
  FinalDueDateField: string | null;
  AccountTypeField: string | null;
  BookNoField: string | null;
  InvoiceType: string | null;
  InvoiceTypeIS: string | null;
  InvoiceTypeLabel: string | null;
  IsOverdueField: boolean | null;
  IsOverdue30Field: boolean | null;
  IsOverdue60Field: boolean | null;
  IsOverdue90Field: boolean | null;
  IsOverdue90PlusField: boolean | null;
  DepositField: string | null;
  CompanyCodeField: string | null;
  InvoiceUrl: string | null;
  Status: string | null;
};

export interface TransactionFilter {
  SapIDs: string[];
  InvoiceTypes: string[];
  SearchString: string;
  DateFrom: Date;
  DateTo: Date;
}

export interface BookingCreatedResponse {
  JobReference: string;
  ControlNumber: string;
  IsImportAirFreight: boolean;
  DocumentReference: string;
  ErrorMessage: string;
}
