import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";

import Input from "@components/Input/input";
import Card from "@components/Card/card";
import Button from "@components/Button/button";

import { resetPassword } from "@state/actions";

import styles from "./resetPassword.module.less";
import { Translation } from "react-i18next";

import { titlePrefix } from "@assets/constants";
import i18n from "i18next";
import { navigate } from "gatsby";

type ResetPasswordPageProps = {
  path?: string;
  hash?: string;
  resetPassword: Function;
};

const ResetPassword: FC<ResetPasswordPageProps> = ({ hash, resetPassword }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = titlePrefix + i18n.t("MENU_SERVICE_WEB");
  }, []);

  const resetValues = () => {
    setPassword("");
    setPasswordConfirm("");
  };

  const handleResetPassword = async () => {
    if (!password || !passwordConfirm) return;

    setLoading(true);
    await resetPassword(hash, { password, passwordConfirm })
      .then(() => {
        resetValues();
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <main
          className={`${styles.resetPassword} flex bg-light-gray flex-col flex-grow justify-center items-center w-full`}
        >
          <Card className="w-3/4 lg:w-3/4 max-w-600 bg-white ease-in-out bg-opacity-85 hover:bg-opacity-100 h-auto flip-in-hor-top h-280 m-4">
            <h2 className="uppercase pt-2 pl-3 font-bold text-jonar-blue text-xl">
              {t("LABEL_RESET_PWD")}
            </h2>
            <form
              className="container p-5"
              autoComplete="on"
              name="loginForm"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="border-b border-gray-300 p-2">
                <Input
                  className="bg-transparent leading-tight  text-gray-700 mr-3"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(event.target.value)
                  }
                  placeholder={t("LABEL_PASSWORD")}
                />
              </div>
              <div className="border-b border-gray-300 p-2">
                <Input
                  className="bg-transparent leading-tight  text-gray-700 mr-3"
                  type="password"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordConfirm(event.target.value)
                  }
                  placeholder={t("LABEL_CONFIRM_PASSWORD")}
                />
              </div>
              <div className="flex flex-row-reverse justify-between items-center">
                <Button
                  className="mt-2"
                  type="submit"
                  onClick={async () => {
                    await handleResetPassword();
                  }}
                  primary
                  active={loading}
                >
                  {t("LABEL_RESET_PWD")}
                </Button>
              </div>
            </form>
          </Card>
        </main>
      )}
    </Translation>
  );
};

export default connect(
  () => {
    return {};
  },
  { resetPassword }
)(ResetPassword);
