import { Booking } from "./types";

export function bookingArrayUnique(array: Booking[]) {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].JobReference === a[j].JobReference) a.splice(j--, 1);
    }
  }

  return a;
}

/**
 * Filter out the given values from the array
 * @param array Collection to filter from
 * @param values Values to exclude from collection
 */
export function without(array: any[], ...values: any[]): any[] {
  return array.filter((el) => !values.some((exclude) => el === exclude));
}

/**
 * Compares _array_ against one or more other arrays and returns the values that are in _array_ but not in the others.
 * @param array The array to compare from
 * @param others The arrays to compare against
 * @returns
 */
export function difference(array: any[], ...others: any[]): any[] {
  const combined = [].concat(...others);
  return array.filter((el: any) => !combined.some((exclude) => el === exclude));
}

export function classNames(...classes: (false | null | undefined | string)[]) {
  return classes.filter(Boolean).join(" ");
}
