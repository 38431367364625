import { Company, UserProfileViewModel, UserWebSettings } from "@assets/types";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "@services/UserService";
import { RootState } from "@state/store";

export const searchCompanies = createAsyncThunk(
  "users/companies/search",
  async (searchString: string) => {
    return await UserService.searchCompanies(searchString);
  }
);

export const changeUserCompany = createAsyncThunk(
  "users/companies",
  async (partnerCode: string) => {
    return await UserService.changeUserCompany(partnerCode);
  }
);

export const getUserProfileFromAPI = createAsyncThunk("auth/me", async () => {
  return await UserService.getUserProfileFromAPI();
});

export const getUserSelectedLanguage = createAsyncThunk(
  "users/selectedLanguage",
  async () => {
    return await UserService.getUserSelectedLanguage();
  }
);

export const updateSelectedLanguageWebSetting = createAsyncThunk(
  "users/settings/selectedLanguage",
  async (selectedLanguage: string) => {
    return await UserService.updateSelectedLanguageWebSetting(selectedLanguage);
  }
);

export const getUserWebSettings = createAsyncThunk(
  "users/websettings",
  async () => {
    return await UserService.getUserWebSettings();
  }
);

export const updateUserWebSettings = createAsyncThunk(
  "users/websettings/update",
  async (webSetting: UserWebSettings) => {
    return await UserService.updateUserWebSettings(webSetting);
  }
);

export interface UserState {
  searchedCompanies: Company[];
  userCompany: Company | null;
  userProfile: UserProfileViewModel | null;
  userSelectedLanguage: string | null;
  updateSelectedLanguage: UserWebSettings | void | null;
  userWebSettings: UserWebSettings | null;
  updateUserWebSettings: void | null;
}

export const initialUserState: UserState = {
  searchedCompanies: [],
  userCompany: null,
  userProfile: null,
  userSelectedLanguage: null,
  updateSelectedLanguage: null,
  userWebSettings: null,
  updateUserWebSettings: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchCompanies.rejected, (state) => {
        state.searchedCompanies = [];
      })
      .addCase(
        searchCompanies.fulfilled,
        (state, action: PayloadAction<Company[]>) => {
          state.searchedCompanies = action.payload;
        }
      )
      .addCase(changeUserCompany.rejected, (state) => {
        state.userCompany = null;
      })
      .addCase(
        changeUserCompany.fulfilled,
        (state, action: PayloadAction<Company>) => {
          state.userCompany = action.payload;
        }
      )
      .addCase(getUserProfileFromAPI.rejected, (state) => {
        state.userProfile = null;
      })
      .addCase(
        getUserProfileFromAPI.fulfilled,
        (state, action: PayloadAction<UserProfileViewModel>) => {
          state.userProfile = action.payload;
        }
      )
      .addCase(getUserSelectedLanguage.rejected, (state) => {
        state.userSelectedLanguage = null;
      })
      .addCase(
        getUserSelectedLanguage.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.userSelectedLanguage = action.payload;
        }
      )
      .addCase(updateSelectedLanguageWebSetting.rejected, (state) => {
        state.updateSelectedLanguage = null;
      })
      .addCase(
        updateSelectedLanguageWebSetting.fulfilled,
        (state, action: PayloadAction<UserWebSettings | void>) => {
          state.updateSelectedLanguage = action.payload;
        }
      )
      .addCase(getUserWebSettings.rejected, (state) => {
        state.userWebSettings = null;
      })
      .addCase(
        getUserWebSettings.fulfilled,
        (state, action: PayloadAction<UserWebSettings>) => {
          state.userWebSettings = action.payload;
        }
      )
      .addCase(updateUserWebSettings.rejected, (state) => {
        state.updateUserWebSettings = null;
      })
      .addCase(
        updateUserWebSettings.fulfilled,
        (state, action: PayloadAction<void>) => {
          state.updateUserWebSettings = action.payload;
        }
      );
  },
});

export const userState = (state: RootState) => state.userSlice;

export default userSlice.reducer;
