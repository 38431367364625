import React from "react";

type IconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  active: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

const IconButton = ({
  onClick,
  active,
  children,
  className,
  disabled,
}: IconButtonProps) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`transition duration-500 ease-in-out text-jonar-blue hover:text-gray-400 focus:bg-gray:200 flex items-center leading-none rounded-sm py-2 ${className} ${
        active ? "spinner" : ""
      }`}
    >
      {children}
    </button>
  );
};

export default IconButton;
