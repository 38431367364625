import { Transaction, TransactionFilter } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

export const filterTransactions = createAsyncThunk(
  "Finance/transactions",
  async (transactionsFilter: TransactionFilter) => {
    return await SingleBookingService.filterTransactions(transactionsFilter);
  }
);

export const filterTransactionsExcel = createAsyncThunk(
  "finance/transactions/excel",
  async (transactionsFilter: TransactionFilter) => {
    return await SingleBookingService.filterTransactionsExcel(
      transactionsFilter
    );
  }
);

export interface TransactionState {
  filterTransactions: Transaction[];
  filterTransactionsExcel: any;
  loadingFilteredTransactions: boolean;
}

export const initialTransactionState: TransactionState = {
  filterTransactions: [],
  filterTransactionsExcel: null,
  loadingFilteredTransactions: false,
};

export const transactionsSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(filterTransactions.pending, (state) => {
        state.loadingFilteredTransactions = true;
      })
      .addCase(filterTransactions.rejected, (state) => {
        state.loadingFilteredTransactions = false;
        state.filterTransactions = [];
      })
      .addCase(
        filterTransactions.fulfilled,
        (state, action: PayloadAction<Transaction[]>) => {
          state.loadingFilteredTransactions = false;
          state.filterTransactions = action.payload;
        }
      )
      .addCase(filterTransactionsExcel.rejected, (state) => {
        state.filterTransactionsExcel = [];
      })
      .addCase(
        filterTransactionsExcel.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.filterTransactionsExcel = action.payload;
        }
      );
  },
});
export const filterTransactionsState = (state: RootState) =>
  state.transactionsSlice;

export default transactionsSlice.reducer;
