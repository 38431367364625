import React from "react";
import { navigate } from "gatsby";

import { ConnectedComponent } from "react-redux";
import { RouteComponentProps } from "@reach/router";

import Spinner from "@components/Spinner/spinner";
import { FreightMode, PageType } from "@assets/constants";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isLoaded,
  loggedIn,
  hasAccess = true,
  component: Component,
  children,
  ...rest
}) => {
  if (isLoaded && !loggedIn) {
    navigate("/login", { replace: true });
    return null;
  }

  if (isLoaded && !hasAccess) {
    navigate("/", { replace: true });
    return null;
  }

  return isLoaded ? (
    <Component {...rest}>{children}</Component>
  ) : (
    <main className="flex flex-col flex-grow">
      <Spinner />
    </main>
  );
};

interface PrivateRouteProps extends RouteComponentProps {
  component:
    | typeof React.Component
    | React.FunctionComponent
    | ConnectedComponent<typeof React.Component | React.FunctionComponent, any>;
  loggedIn: boolean;
  hasAccess?: boolean;
  isLoaded: boolean;
  children?: React.ReactNode;
  freightMode?: FreightMode;
  pageType?: PageType;
}

export default PrivateRoute;
