import { Incoterms } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getIncoterms = createAsyncThunk("registry/incoterms", async () => {
  return await CreateBookingService.getIncoterms();
});

export const getIncotermByCode = createAsyncThunk(
  "registry/incoterm/code",
  async (code: string) => await CreateBookingService.getIncotermByCode(code)
);

export interface IncotermsState {
  incoterms: Incoterms[];
  loadingIncoterms: boolean;
  incotermByCode: Incoterms | null;
}

export const initialIncotermsState: IncotermsState = {
  incoterms: [],
  loadingIncoterms: false,
  incotermByCode: null,
};

export const incotermsSlice = createSlice({
  name: "incoterms",
  initialState: initialIncotermsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIncoterms.pending, (state) => {
        state.loadingIncoterms = true;
      })
      .addCase(getIncoterms.rejected, (state) => {
        state.loadingIncoterms = false;
        state.incoterms = [];
      })
      .addCase(
        getIncoterms.fulfilled,
        (state, action: PayloadAction<Incoterms[]>) => {
          state.loadingIncoterms = false;
          state.incoterms = action.payload;
        }
      )
      .addCase(getIncotermByCode.rejected, (state) => {
        state.incotermByCode = null;
      })
      .addCase(
        getIncotermByCode.fulfilled,
        (state, action: PayloadAction<Incoterms>) => {
          state.incotermByCode = action.payload;
        }
      );
  },
});

export const incotermsState = (state: RootState) => state.incotermsSlice;

export default incotermsSlice.reducer;
