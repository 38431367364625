import { classNames } from "@assets/utilities/classNameUtilities";
import React, { MouseEventHandler } from "react";

export interface TagProps {
  children: React.ReactNode;
  className?: string;
  /** The color of the component */
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "neutral-200"
    | "neutral-600";
  id?: string;
  isLight?: boolean;
  isFaded?: boolean;
  onClick?: MouseEventHandler;
  size?: "medium" | "default" | "small";
  variant?: "rounded" | "soft" | "sharp";
  value?: string | number;
}

const normalBackground = {
  primary: "bg-primary",
  secondary: "bg-secondary",
  success: "bg-success",
  info: "bg-info",
  warning: "bg-warning",
  "neutral-200": "bg-neutral-200",
  "neutral-600": "bg-neutral-600",
};

const normalTextColor = {
  primary: "text-neutral-0",
  secondary: "text-neutral-0",
  success: "text-neutral-0",
  info: "text-neutral-0",
  warning: "text-neutral-0",
  "neutral-200": "text-neutral-900",
  "neutral-600": "text-neutral-0",
};

const lightBackground = {
  primary: "bg-neutral-0",
  secondary: "bg-neutral-0",
  success: "bg-neutral-0",
  info: "bg-neutral-0",
  warning: "bg-neutral-0",
  "neutral-200": "bg-neutral-900",
  "neutral-600": "bg-neutral-0",
};

const lightTextColor = {
  primary: "text-primary",
  secondary: "text-secondary",
  success: "text-success",
  info: "text-info",
  warning: "text-warning",
  "neutral-200": "text-neutral-200",
  "neutral-600": "text-neutral-600",
};

const fadedBackground = {
  primary: "bg-primary",
  secondary: "bg-secondary",
  success: "bg-success-tag-faded",
  info: "bg-info-70%",
  warning: "bg-warning-light",
  "neutral-200": "bg-neutral-200",
  "neutral-600": "bg-neutral-600",
};

const fadedTextColor = {
  primary: "text-neutral-0",
  secondary: "text-neutral-0",
  success: "text-success-tag",
  info: "text-info",
  warning: "text-warning-tag",
  "neutral-200": "text-neutral-900",
  "neutral-600": "text-neutral-0",
};

const borderVariant = {
  rounded: "rounded-full",
  soft: "rounded",
  sharp: "rounded-none",
};

const sizes = {
  medium: "px-6 py-2 text-base",
  default: "px-4 py-1.375 text-sm",
  small: "px-2 py-0.75 text-xs",
};

export const Tag = ({
  children,
  className,
  color = "primary",
  id,
  isLight = false,
  isFaded = false,
  onClick,
  size = "default",
  value,
  variant = "rounded",
}: TagProps) => {
  const setVersion = () => {
    if (isLight) {
      return [lightTextColor[color], lightBackground[color]];
    }
    if (isFaded) {
      return [fadedTextColor[color], fadedBackground[color]];
    }
    return [normalTextColor[color], normalBackground[color]];
  };

  return (
    <button
      className={classNames(
        "font-semibold w-fit",
        sizes[size],
        ...setVersion(),
        borderVariant[variant],
        onClick ? "cursor-pointer" : "cursor-default",
        className
      )}
      id={id}
      onClick={onClick}
      value={value}
    >
      {children}
    </button>
  );
};

export default Tag;
