import IconButton from "@components/Button/iconButton";
import SelectEx from "@components/Select/selectEx";
import React from "react";
import { FaPaperclip, FaTrash } from "react-icons/fa";
import styles from "./fileUploaderComponent.module.less";
import {
  Document,
  DocumentError,
  ErrorTypes,
  SelectOption,
} from "@assets/types";
import { AirDocType, SeaDocType } from "@assets/constants";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { FreightMode } from "@assets/constants";

type PresentationalProps = {
  freightMode: FreightMode;
  dragging: boolean;
  documents: Document[];
  onSelectFileClick: () => void;
  onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  fileRemove: (index: number) => void;
  onChangeSelectedDocType: (
    selectedDataType: SelectOption<any>,
    index: number
  ) => void;
  errors?: DocumentError[];
  invalidDocument: boolean;
  className?: string;
  children?: React.ReactNode;
};

const FileUploaderComponent: React.FC<PresentationalProps> = (props) => {
  const {
    dragging,
    documents,
    onSelectFileClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    fileRemove,
    onChangeSelectedDocType,
    errors,
    invalidDocument,
    className,
  } = props;

  const { t } = useTranslation();

  let uploaderClasses = styles.fileuploader;
  if (dragging) {
    uploaderClasses += ` ${styles.fileuploaderdragging}`;
  }

  const getErrorsForCurrentDoc = (docIndex: number) => {
    if (!invalidDocument) return;
    return errors?.filter((x) => x.index === docIndex);
  };

  const docTypeValue = (docTypeValue: string | null | undefined) => {
    if (
      docTypeValue === undefined ||
      docTypeValue === null ||
      docTypeValue === ""
    ) {
      return null;
    }
    return {
      value: docTypeValue,
      label: i18n.t(`DOCTYPE_${docTypeValue}`),
    } as SelectOption<any>;
  };

  return (
    <div className={className}>
      <div
        className={uploaderClasses}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="flex">
          <FaPaperclip />
          <span className="mx-2">{t("TEXT_DRAG_AND_DROP")}</span>
          <a className="text-jonar-blue font-bold" onClick={onSelectFileClick}>
            {t("TEXT_BROWSE_UPLOAD")}
          </a>
        </div>
        {props.children}
      </div>
      <div className="text-neutral-700">{t("TEXT_MAXIMUM_FILE_SIZE")}</div>
      {documents.length > 0 ? (
        <ul className="bg-white rounded-lg w-full text-gray-900 flex-1 my-6">
          <li>
            <div className="grid grid-cols-2">
              <span className="ml-6"> {t("LABEL_FILENAME")}</span>
              <span className="ml-6"> {t("LABEL_DOCUMENT_TYPE")}</span>
            </div>
          </li>
          {documents.map((item, index) => (
            <li
              key={index}
              // className="px-6 py-2 mx-2 border-b border-t border-gray-200 w-full"
              className="border-b border-t border-gray-200 w-full"
            >
              <div className="md:grid md:grid-cols-2 flex justify-between">
                <div className="mt-4 ml-6">
                  <span className="mt-2">{item.document.name}</span>
                  {getErrorsForCurrentDoc(index) ? (
                    <div className="mx-6 text-red-600">
                      {getErrorsForCurrentDoc(index)
                        ?.filter(
                          (x) => x.errorType != ErrorTypes.MandatoryField
                        )
                        .map((value, index) => (
                          <span key={`fileError-${index}`}>
                            {t(value.errorType) + " "}
                          </span>
                        ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex justify-between">
                  <SelectEx
                    className="mt-3 mb-3 mx-6 text-base font-normal md:w-2/3"
                    value={docTypeValue(item.doctype)}
                    onChange={(e: unknown) =>
                      onChangeSelectedDocType(e as SelectOption<any>, index)
                    }
                    options={Object.values(
                      props.freightMode == FreightMode.AI
                        ? AirDocType
                        : SeaDocType
                    ).map((x) => ({
                      value: x,
                      label: i18n.t(`DOCTYPE_${x}`),
                    }))}
                    name={"select doc type"}
                    hasError={
                      invalidDocument &&
                      (documents[index].doctype == undefined ||
                        documents[index].doctype == null ||
                        documents[index].doctype == "")
                    }
                    errorText={t(ErrorTypes.MandatoryField)}
                  />
                  <IconButton
                    disabled={false}
                    onClick={() => fileRemove(index)}
                    active={false}
                    className="self-start md:mt-2 mt-4"
                  >
                    <FaTrash className="text-base" />
                  </IconButton>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default FileUploaderComponent;
