import React, { FocusEventHandler } from "react";
import {
  ActionMeta,
  default as ReactSelect,
  GroupBase,
  ThemeConfig,
  Props,
  OnChangeValue,
} from "react-select";
import ValidationError from "@components/ValidationError/validationError";
import LabelEx, { labelCases } from "@components/Label/labelEx";
import { classNames } from "@assets/helperFunctions";
import { styles } from "./styles";

interface SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
  /** Focus the control when it is mounted */
  autoFocus?: boolean;
  /** Override or extend the style applied to the outside wrapper. */
  className?: string;
  /** Opens the menu open by default. */
  defaultMenuIsOpen?: boolean;
  /** The default value of the dropdown. */
  defaultValue?: Option | null | Option[];
  /** If true, the component is disabled. */
  disabled?: boolean;
  /** The error message to show. !NOTE: use with hasError=true. */
  errorText?: string;
  /** If true, the component is displayed with a error style. */
  hasError?: boolean;
  /** The id of the select element.  */
  id?: string;
  /** If true, the select is clearable. */
  isClearable?: boolean;
  /** Support multiple selected options. */
  isMulti?: IsMulti;
  /** A text to be used in the label element. */
  label?: string;
  tooltipContent?: string;
  // TODO: After merging input and label changes uncomment and import the necessary
  // /** Transform the label displayed case. */
  // labelCase?: labelCases;
  menuIsOpen?: boolean;
  /** Name attribute of the select element. */
  name?: string;
  /** Callback fired when focus has left the element */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** Callback fired when the value is changed. ActionMeta describes what changed. */
  onChange?: (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>
  ) => void;
  /** If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /** Array of options that populate the select menu. */
  options: Option[];
  /** Placeholder for the select value. */
  placeholder?: string;
  /** If true, the menu appears in front of the elements instead of being cut off at the end of container. */
  portaling?: boolean;
  /**	If true, the input element shows as required. */
  required?: boolean;
  /** The value of the select; reflected by the selected option */
  value?: Option | Option[] | null;
  /**
   * Resolves option data to a string to be displayed as the label by components
   * (the option doesn't need to have the {value:, label:} form with this)
   *
   * Note: Failure to resolve to a string type can interfere with filtering and
   * screen reader support.
   */
  getOptionLabel?: (value: Option) => string;
  /** Resolves option data to a string to compare options and specify value attributes
   *  (the option doesn't need to have the {value:, label:} form with this)
   */
  getOptionValue?: (value: Option) => string;
  isLoading?: boolean;
  /** Transform the label displayed case. */
  labelCase?: labelCases;
  /** If true, the component takes up the full width of it's container. */
  fullWidth?: boolean;
  /** To make sure the dropdown menu's length is the of the longest option. */
  menuFitLongestOption?: boolean;
  /**  To hide the border. */
  noBorder?: boolean;
  /** To  hide the indicator separator. */
  noIndicatorSeparator?: boolean;
}

const theme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "rgb(59, 130, 246, 0.5)", // selected option background
    neutral5: "#e5e7eb", // disabled background
    neutral10: "#D1D5DB", //disabled border
    neutral40: "#374151", //disabled text
  },
});

const SelectEx = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  autoFocus = false,
  className,
  defaultMenuIsOpen = false,
  defaultValue,
  disabled = false,
  errorText,
  getOptionLabel,
  getOptionValue,
  hasError = false,
  id,
  isClearable = false,
  label,
  menuIsOpen,
  name,
  onBlur,
  onChange,
  options,
  placeholder,
  portaling = false,
  value,
  isMulti,
  tooltipContent,
  optional = false,
  optionalText,
  required = false,
  isLoading = false,
  labelCase = "none",
  fullWidth = true,
  menuFitLongestOption = false,
  noBorder = false,
  noIndicatorSeparator = false,
}: SelectProps<Option, IsMulti, Group>) => {
  return (
    <div
      className={classNames(
        `flex flex-col gap-2 items-baseline`,
        fullWidth ? "w-full" : "max-w-90",
        className
      )}
    >
      {label && (
        <LabelEx
          className="font-semibold text-neutral-800 text-3.25"
          forID={id}
          labelCase={labelCase}
          optional={optional}
          optionalText={optionalText}
          required={required}
          tooltipContent={tooltipContent}
        >
          {label}
        </LabelEx>
      )}
      <ReactSelect
        inputId={id}
        autoFocus={autoFocus}
        classNamePrefix="samskip-select"
        defaultMenuIsOpen={defaultMenuIsOpen}
        defaultValue={defaultValue}
        isClearable={isClearable}
        isDisabled={disabled || isLoading}
        isMulti={isMulti}
        menuPortalTarget={
          portaling
            ? document.getElementById("main-component") ?? document.body
            : null
        }
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        placeholder={placeholder}
        styles={{
          ...styles<Option, IsMulti, Group>(
            hasError,
            disabled,
            menuFitLongestOption,
            "normal"
          ),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        theme={theme}
        value={value}
        isLoading={isLoading}
      />
      {hasError && errorText && <ValidationError errorText={errorText} />}
    </div>
  );
};

export default SelectEx;
