import LabelEx from "@components/Label/labelEx";
import ValidationError from "@components/ValidationError/validationError";
import React, { ChangeEventHandler, ReactNode } from "react";

type GroupProps = {
  /** The elements in a group. */
  children?: ReactNode[] | ReactNode;
  /** Override or extend the style applied to the component. */
  className?: string;
  /** The direction of the children components. */
  direction?: "col" | "row";
  /** Message to show as an error under the grouped elements. */
  errorText?: string;
  /** The title of the group. */
  title?: string;
  /** Callback fired when the value is changed. */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  actions?: React.ReactNode;
};

const flexDirection = {
  col: "md:flex-col",
  row: "md:flex-row",
};

const Group: React.FC<GroupProps> = ({
  children,
  direction = "row",
  errorText,
  onChange,
  title,
  actions,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {title && (
        <LabelEx
          labelCase="upper"
          className="text-base font-semibold"
          labelColor="neutral-900"
        >
          {title}
        </LabelEx>
      )}
      {actions && actions}
      <div
        className={`flex flex-col align-baseline ${flexDirection[direction]} gap-4`}
        onChange={onChange}
      >
        {children}
      </div>
      {errorText && <ValidationError errorText={errorText} className="mt-4" />}
    </div>
  );
};

export default Group;
