import Button from "@components/Button/button";
import React from "react";
import logo from "../../assets/images/jonar-logo.jpg";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const handleButtonClick = () => {
    window.location.href = "/";
  };
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-center gap-8 pt-8">
        <div className="bg-neutral-0 flex flex-col gap-6 justify-center items-center p-8">
          <img src={logo} className="max-h-52" alt="Jonar logo" />
          <h1 className="text-center">
            {t("TEXT_NOT_FOUND_PAGE")}
          </h1>
          <Button type="button" className="w-auto h-auto" onClick={handleButtonClick}>
            {t("LABEL_GO_MY_PAGE")}
          </Button>
        </div>
    </div>
  );
};

export default NotFound;
