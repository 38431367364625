import axios, { AxiosResponse, AxiosError } from "axios";

import { Company, UserProfileViewModel, UserWebSettings } from "@assets/types";
import store from "@state/store";

const AuthAPI = process.env.AuthAPI;
const JonarAPI = process.env.JonarAPI;

class UserService {
  searchCompanies = async (searchString: string): Promise<Company[]> => {
    const userID = await this.getUserId();
    return await axios
      .get(`${AuthAPI}/users/${userID}/companies/1049/search/${searchString}`, {
        withCredentials: true,
      })
      .then((res: AxiosResponse<Company[]>) => res.data);
  };

  changeUserCompany = async (partnerCode: string): Promise<Company> => {
    const userID = await this.getUserId();
    return await axios
      .put(
        `${AuthAPI}/users/${userID}/companies/1049`,
        '"' + partnerCode + '"',
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res: AxiosResponse<Company>) => res.data);
  };

  getUserProfile = (): UserProfileViewModel => {
    return store.getState().UserReducer.userProfile as UserProfileViewModel;
  };

  getUserId = async (): Promise<number> => {
    const { userProfile } = store.getState().UserReducer;
    const User = userProfile && userProfile.User ? userProfile.User : null;
    return User ? User.ID : 0;
  };

  getUserProfileFromAPI = async (): Promise<UserProfileViewModel> => {
    return await axios
      .get(`${AuthAPI}/auth/me`, { withCredentials: true })
      .then(async (res) => {
        return res.data;
      });
  };

  getUserSelectedLanguage = async (): Promise<string> => {
    return await axios
      .get(`${AuthAPI}/users/selectedLanguage`, { withCredentials: true })
      .then((res) => {
        return res.data.replace(/"/g, "");
      })
      .catch(() => window.localStorage.getItem("selectedLanguage"));
  };

  updateSelectedLanguageWebSetting = async (
    selectedLanguage: string
  ): Promise<UserWebSettings | void> => {
    const userid = await this.getUserId();
    if (userid)
      return await axios
        .put(
          `${AuthAPI}/users/${userid}/access/1049/settings/SelectedLanguage`,
          `"${selectedLanguage}"`,
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => res.data);
  };

  getUserWebSettings = async (): Promise<UserWebSettings> => {
    return await axios.get(`${JonarAPI}/users/websettings`, {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });
  };

  updateUserWebSettings = async (
    webSetting: UserWebSettings
  ): Promise<void> => {
    return await axios.put(`${JonarAPI}/users/websettings/update`, webSetting, {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });
  };
}

export default new UserService();
