import { DocumentReferenceType } from "@assets/constants";
import {
  BookingDocumentResponse,
  CargoBookingResponse,
  CustomerStatus,
  ExposedDocumentsByEmail,
  Invoice,
  JonarAPIResponse,
  KeyBalanceCompany,
  RouteBookingResponse,
  Transaction,
  TransactionFilter,
  Request,
  UploadDocumentsResponse,
} from "@assets/types";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import saveAs from "file-saver";
import moment from "moment";

const JonarAPI = process.env.JonarAPI;

class SingleBookingService {
  getRouteBooking = async (
    jobReference: string
  ): Promise<RouteBookingResponse> => {
    return await axios
      .get(`${JonarAPI}/SingleBooking/${jobReference}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<RouteBookingResponse>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBookingCargo = async (
    jobReference: string
  ): Promise<CargoBookingResponse> => {
    return await axios
      .get(`${JonarAPI}/SingleBooking/${jobReference}/cargo`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<CargoBookingResponse>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getBookingDocuments = async (
    jobReference: string
  ): Promise<BookingDocumentResponse[]> => {
    return await axios
      .get(`${JonarAPI}/SingleBooking/${jobReference}/documents`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then(
        (res: AxiosResponse<JonarAPIResponse<BookingDocumentResponse[]>>) => {
          return res.data.ReturnItem.Data;
        }
      );
  };

  getReference = async (
    jobReference: string
  ): Promise<UploadDocumentsResponse[]> => {
    return await axios
      .get(`${JonarAPI}/Documents/${jobReference}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then(
        (res: AxiosResponse<JonarAPIResponse<UploadDocumentsResponse[]>>) => {
          return res.data.ReturnItem.Data;
        }
      )
      .catch((error: any) => {
        throw error;
      });
  };

  uploadDocuments = async (
    jobReference: string,
    docTypes: string[],
    referenceType: DocumentReferenceType,
    files: File[]
  ): Promise<UploadDocumentsResponse[]> => {
    const formData = new FormData();
    files.forEach((f) => formData.append(f.name, f));
    return await axios
      .post(
        `${JonarAPI}/Documents/${jobReference}?docType=${docTypes.join(
          ","
        )}&referenceType=${referenceType}`,
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<UploadDocumentsResponse[]>) => {
        return res.data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  getDocument = async (id: string): Promise<string> => {
    return await axios
      .get(`${JonarAPI}/Documents/file/${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<string>) => {
        return res.data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  deleteDocument = async (documentUid: string) => {
    try {
      const response = await axios.delete(
        `${JonarAPI}/Documents/file/${documentUid}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getRequests = async (
    jobReference: string,
    requestType: string | null
  ): Promise<Request[]> => {
    return await axios
      .post(
        `${JonarAPI}/Requests/`,
        {
          jobReference: jobReference,
          requestType: requestType,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<Request[]>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  getInvoicesUrls = async (
    jobReference: string,
    sapID: string
  ): Promise<Invoice[]> => {
    return await axios
      .get(`${JonarAPI}/Invoices/${jobReference}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
        params: {
          SAPID: sapID,
        },
      })
      .then((res: AxiosResponse<JonarAPIResponse<Invoice[]>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  sendDocumentsByEmail = async (
    exposedDocumentByEmail: ExposedDocumentsByEmail
  ): Promise<string> => {
    return await axios
      .post(
        `${JonarAPI}/Documents/email/send`,
        { ...exposedDocumentByEmail },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<string>>) => {
        return res.statusText;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  getCustomerStatus = async (sapId: string): Promise<CustomerStatus[]> => {
    return await axios
      .get(`${JonarAPI}/Finance/status/${sapId}`, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Subsystem: "1049",
        },
      })
      .then((res: AxiosResponse<JonarAPIResponse<CustomerStatus[]>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  getCustomerStatusByDate = async (
    sapId: string,
    dateFrom: Date,
    dateTo: Date
  ): Promise<KeyBalanceCompany[]> => {
    return await axios
      .get(
        `${JonarAPI}/Finance/statusbydate/${sapId}?dateFrom=${format(
          dateFrom,
          "yyyy-MM-dd"
        )}&dateTo=${format(dateTo, "yyyy-MM-dd")}`,
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Subsystem: "1049",
          },
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<KeyBalanceCompany[]>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  filterTransactions = async (
    transactionsFilter: TransactionFilter
  ): Promise<Transaction[]> => {
    return await axios
      .post(
        `${JonarAPI}/Finance/transactions`,
        { ...transactionsFilter },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Subsystem: "1049",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<Transaction[]>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  filterTransactionsExcel = async (
    transactionsFilter: TransactionFilter
  ): Promise<any> => {
    return await axios
      .post(
        `${JonarAPI}/finance/transactions/excel`,
        { ...transactionsFilter },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Expose-Headers": "Content-Disposition",
          },
          withCredentials: true,
          responseType: "blob",
        }
      )
      .then((res) => {
        saveAs(
          res.data,
          `Transactions-${moment(new Date()).format("DD.MM.YYYY")}.xlsx`
        );
      });
  };
}
export default new SingleBookingService();
