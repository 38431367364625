//import React from "react";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Translation } from "react-i18next";
import moment from "moment";

import { FaRegStickyNote } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";

import Spinner from "@components/Spinner/spinner";
import CustomIcon from "@components/CustomIcon/customIcon";
import ActionMenu from "@components/ActionMenu/actionMenu";
import Table from "@components/Table/table";
import MemoModal from "@components/Modal/memoModal";

import { AppState } from "@state/reducers/types";
import { toggleFavoriteBooking } from "@state/actions";

import BookingService from "@services/BookingService";

import {
  BookingDetail,
  BuyersConsoleBooking,
  TableInfo,
  UserProfileViewModel,
  WebNote,
} from "@assets/types";
import Checkbox from "@components/Input/checkbox";
import { FreightMode, UserRole, UserType } from "@assets/constants";
import UserUtilities from "utilities/UserUtilities";

type ListLineProps = {
  userProfile: UserProfileViewModel;
  children: React.ReactNode;
  className?: string;
  tableHeaders: string[];
  reference: string;
  favoriteBookings: string[];
  toggleFavoriteBooking: Function;
  togglingFavoriteBooking: boolean;
  getActionOptions: Function;
  isBuyersConsoleBooking?: boolean;
  showInfo?: boolean;
  checkBoxChecked: boolean;
  onCheckboxCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openActionMenu: Function;
  closeActionMenu: Function;
  actionMenuJobRef: string;
};

type ListLineState = {
  openAccordion: boolean;
  onTop: boolean;
  loadingDetails: boolean;
  details: BookingDetail[];
  buyersConsoleBookings: BuyersConsoleBooking[];
  infoContent?: React.ReactNode;
  memoModal: {
    open: boolean;
    bookingReference: string;
    webObject: WebNote[];
  };
};

class ListLine extends PureComponent<ListLineProps, ListLineState> {
  state: ListLineState = {
    openAccordion: false,
    onTop: false,
    loadingDetails: false,
    details: [],
    buyersConsoleBookings: [],
    infoContent: undefined,
    memoModal: {
      open: false,
      bookingReference: "",
      webObject: [],
    },
  };

  openMemoModal = async (show: boolean, bookR: string) => {
    const isCustomer =
      this.props.userProfile?.Access.UserType === UserType.CUSTOMER;
    const webObj = await BookingService.getWebNotes(this.props.reference);
    const remarks = await BookingService.getRemarks(this.props.reference);

    const memoModal = {
      open: show,
      bookingReference: bookR,
      webObject: isCustomer ? remarks : webObj.concat(remarks),
    };
    this.setState({ memoModal });
  };

  closeMemoModal = () => {
    const memoModal = {
      ...this.state.memoModal,
      open: false,
    };
    this.setState({ memoModal });
  };

  handleFocus = (newStatus: boolean) => {
    this.setState({ onTop: newStatus });
  };

  async componentDidUpdate(prevProps: ListLineProps) {
    if (
      prevProps.reference != this.props.reference &&
      this.state.openAccordion
    ) {
      if (this.state.openAccordion) {
        this.getDetails();
      }
    }
  }

  renderInfoContent = (values: WebNote[]) => {
    const tableData: TableInfo = {
      tableHeader: ["LABEL_USER", "LABEL_CREATE_DATE", "LABEL_NOTE"],
      tableData: values.map((value, i) => [
        <span className={`${value.IsInternal ? "font-bold" : ""}`} key={i}>
          {value.UserName}
        </span>,
        <span className={`${value.IsInternal ? "font-bold" : ""}`} key={i}>
          {moment(value.CreateDate).format("DD.MM.yyyy HH:mm")}
        </span>,
        <span className={`${value.IsInternal ? "font-bold" : ""}`} key={i}>
          {value.Note}
        </span>,
      ]),
      classes: ["", "", ""],
    };
    return <Table tableInfo={tableData} isLight />;
  };

  openActionMenu = () => {
    this.props.openActionMenu(this.props.reference);
  };

  onTooltipOpen = async () => {
    await BookingService.getWebNotes(this.props.reference).then((res) =>
      this.setState({ infoContent: this.renderInfoContent(res) })
    );
  };

  getDetails = async () => {
    this.setState({ loadingDetails: true });
    if (this.props.isBuyersConsoleBooking) {
      BookingService.getBuyersConsoleBookings(this.props.reference).then(
        (data) => {
          this.setState({ buyersConsoleBookings: data });
        }
      );
    }
    await BookingService.getBookingDetails(this.props.reference)
      .then((res) => {
        this.setState({
          details: res,
          loadingDetails: false,
        });
      })
      .catch(() => {
        this.setState({ loadingDetails: false });
      });
  };

  toggleCollapse = async () => {
    if (this.state.openAccordion) {
      this.setState({ openAccordion: false });
    } else {
      this.setState({ openAccordion: true });
      this.getDetails();
    }
  };

  render() {
    const { tableHeaders } = this.props;
    const { memoModal } = this.state;

    const handleTemperature = (
      isTemperatureControlled: boolean | null,
      minTemp: number | null,
      maxTemp: number | null,
      temp: number | null
    ): string => {
      let temperature = "";
      if (isTemperatureControlled) {
        if (minTemp && maxTemp) {
          minTemp < 0
            ? (temperature = `${minTemp}°C`)
            : (temperature = `+${minTemp}°C`);
          temperature = temperature.concat("/");
          maxTemp < 0
            ? (temperature = temperature.concat(`${maxTemp}°C`))
            : (temperature = temperature.concat(`+${maxTemp}°C`));
        } else {
          temperature = temp ? `${temp}°C` : "TEXT_YES";
        }
      } else temperature = "TEXT_NO";
      return temperature;
    };

    const table: TableInfo = {
      tableHeader: tableHeaders,
      tableData: this.state.details.map((detail: BookingDetail) => {
        return [
          detail.Description,
          detail.Weight ? `${detail.Weight} ${detail.WeightUOM}` : "",
          detail.Volume ? `${detail.Volume} ${detail.VolumeUOM}` : "",
          detail.Quantity ? `${detail.Quantity} ${detail.QuantityUOM}` : "",
          handleTemperature(
            detail.IsTemperatureControlled,
            detail.MinTemperature,
            detail.MaxTemperature,
            detail.Temperature
          ),
        ];
      }),
      classes: ["w-1/3", "text-right", "text-right", "", "text-right", ""],
    };

    const BcTable: TableInfo = {
      tableHeader: [
        "TEXT_JOB_REFERENCE",
        "TEXT_SHIPPER",
        "TEXT_DESCRIPTION",
        "TEXT_WEIGHT",
        "TEXT_VOLUME",
        "TEXT_QUANTITY",
        "TEXT_TEMP_CONTROL",
      ],
      tableData: this.state.buyersConsoleBookings.map(
        (bcBooking: BuyersConsoleBooking, i: number) => {
          return [
            <a
              className="text-jonar-blue actionFunction transition duration-300 hover:text-jonar-orange"
              key={i}
              href={`/singleBooking/${
                bcBooking.FreightMode === "AI" ? FreightMode.AI : FreightMode.SH
              }/${bcBooking.JobReference}`}
              target="_blank"
              rel="noreferrer"
            >
              {bcBooking.JobReference}
            </a>,
            bcBooking.ShipperName,
            bcBooking.Description,
            bcBooking.Weight
              ? `${bcBooking.Weight} ${bcBooking.WeightUOM}`
              : "",
            bcBooking.Volume
              ? `${bcBooking.Volume} ${bcBooking.VolumeUOM}`
              : "",
            bcBooking.Quantity
              ? `${bcBooking.Quantity} ${bcBooking.QuantityUOM}`
              : "",
            handleTemperature(
              bcBooking.IsTemperatureControlled,
              bcBooking.MinTemperature,
              bcBooking.MaxTemperature,
              bcBooking.Temperature
            ),
          ];
        }
      ),
      classes: ["", "text-right", "text-right"],
    };

    return (
      <Translation>
        {(t) => {
          return (
            <div
              className={`relative py-3 border-b flex flex-wrap mr-3 border-jonar-orange ${
                this.props.className
              }
               ${this.state.onTop ? "z-20" : ""}`}
            >
              {this.props.children}
              <div className="w-full md:hidden pt-1 md:pt-5 text-center">
                <button
                  onClick={() => this.toggleCollapse()}
                  className="text-xs text-jonar-blue leading-none focus:outline-none"
                >
                  {t("LABEL_CARGODESCRIPTION")}
                  <FiChevronDown
                    className={`inline-block text-base text-jonar-blue leading-3 transition duration-300 ${
                      this.state.openAccordion
                        ? "transform rotate-180"
                        : "transform rotate-0"
                    }`}
                  />
                </button>
              </div>
              <div className="absolute md:relative right-0 top-0 flex-col md:flex-row flex-1 flex flex-wrap justify-end items-center leading-none md:min-w-100">
                {this.props.showInfo ? (
                  <>
                    <FaRegStickyNote
                      className="text-jonar-blue mb-1 md:mb-0 md:mr-1 cursor-pointer"
                      onClick={() =>
                        this.openMemoModal(true, this.props.reference)
                      }
                    />
                    {memoModal.open && (
                      <MemoModal
                        open={memoModal.open}
                        closeModal={this.closeMemoModal}
                        bookingReference={memoModal.bookingReference}
                        webObject={memoModal.webObject}
                      />
                    )}
                  </>
                ) : null}
                <CustomIcon
                  className={`svg-jonar-blue svg-lg svg-dark cursor-pointer ${
                    this.props.favoriteBookings.includes(this.props.reference)
                      ? "svg-fill-jonar-blue"
                      : ""
                  }`}
                  iconName="stjarna"
                  onClick={() => {
                    this.props.toggleFavoriteBooking(this.props.reference);
                  }}
                />
                {UserUtilities.userHasAccessToRoles(this.props.userProfile, [
                  UserRole.REQUESTS,
                ]) ? (
                  <Checkbox
                    className="mt-1 md:mt-0 md:ml-1"
                    type="checkbox"
                    onChange={this.props.onCheckboxCheck}
                    checked={this.props.checkBoxChecked}
                  />
                ) : null}
                <div className="md:ml-2 pt-8 md:pt-0 text-right">
                  <ActionMenu
                    open={this.props.actionMenuJobRef === this.props.reference}
                    onClose={this.props.closeActionMenu}
                    onOpen={this.openActionMenu}
                    updateState={this.handleFocus}
                    getOptions={this.props.getActionOptions}
                  />
                </div>
                <div className="w-full text-right hidden md:block">
                  <button
                    onClick={() => this.toggleCollapse()}
                    className="text-xs text-jonar-blue leading-none focus:outline-none"
                  >
                    {t("LABEL_CARGODESCRIPTION")}
                    <FiChevronDown
                      className={`inline-block text-base text-jonar-blue leading-3 transition duration-300 ${
                        this.state.openAccordion
                          ? "transform rotate-180"
                          : "transform rotate-0"
                      }`}
                    />
                  </button>
                </div>
              </div>
              <div
                className={`float-left w-full mt-5 overflow-x-scroll lg:overflow-x-auto ${
                  this.state.openAccordion ? "block" : "hidden"
                }`}
              >
                {!this.state.loadingDetails ? (
                  <>
                    {this.state.buyersConsoleBookings &&
                    this.state.buyersConsoleBookings.length > 0 ? (
                      <Table
                        className="md:w-full min-w-500"
                        tableInfo={BcTable}
                      />
                    ) : (
                      <Table
                        className="md:w-full min-w-500"
                        tableInfo={table}
                      />
                    )}
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      favoriteBookings: state.BookingReducer.favoriteBookings,
      userProfile: state.UserReducer.userProfile,
    };
  },
  { toggleFavoriteBooking }
)(ListLine);
