import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M32.5,18.43h7.94c0.66,0,1.28,0.33,1.65,0.87l3.4,6.13h1c1.12,0,2,0.74,2,2.21v6.79c0,1.1-0.9,2-2,2h-2
                M16.5,11.43h16v25h-15 M9.5,36.43h-4 M44.5,37.44c0,2.2-1.8,3.99-4.01,3.99c-2.21,0-4.01-1.79-4.01-3.99c0-2.2,1.8-3.99,4.01-3.99
                C42.7,33.45,44.5,35.24,44.5,37.44z M17.52,37.44c0,2.2-1.79,3.99-4.01,3.99c-2.22,0-4.01-1.79-4.01-3.99
                c0-2.2,1.79-3.99,4.01-3.99C15.72,33.45,17.52,35.24,17.52,37.44z M32.5,36.43h4 M36.5,26.43h5v-1l-2-3 M2.5,23.43h16 M10.5,17.43
                h14 M6.5,17.43h-1 M12.5,11.43h-1"
      />
    </g>
  </svg>
);

export default SVG;
