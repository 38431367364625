import { classNames } from "@assets/utilities/classNameUtilities";
import React from "react";

type DividerProps = {
  /** Override or extend the style applied to the divider */
  className?: string;
  /** if true, the divider will not be shown on small devices. */
  hideOnMedium?: boolean;
  /** if true, the divider will not be shown on medium devices. */
  hideOnMobile?: boolean;
};

const Divider = ({
  className,
  hideOnMedium = false,
  hideOnMobile = false,
}: DividerProps) => {
  return (
    <hr
      className={classNames(
        "md:col-span-full",
        hideOnMobile && "hidden md:block",
        hideOnMedium && "md:hidden",
        className
      )}
    />
  );
};

export default Divider;
