import { BillOfLading, SelectOption } from "./types";

export const titlePrefix = "Jónar Transport - ";

export enum PageType {
  CREATE = "CREATE",
  COPY = "COPY",
}

export enum FreightMode {
  SH = "SH",
  AI = "AI",
  RD = "RD",
  RL = "RL",
}

export enum DocumentReferenceType {
  JOBREFERENCE = "JOBREFERENCE",
  OTHER = "OTHER",
  CONTROLNUMBER = "CONTROLNUMBER",
}

export enum ShippingType {
  FCL = "FCL",
  LCL = "LCL",
}

export enum PortType {
  SEA = "S",
  AIR = "A",
  POPULARAIR = "J",
  POPULARSEA = "O",
}

export enum UserRole {
  SHIP = 1001,
  DOM = 1002,
  WHOUSE = 1003,
  FINANCE = 1004,
  ADMIN = 1005,
  CUST = 1006,
  STOR = 1007,
  CLERK = 1008,
  CLAIM = 1009,
  CNT = 1010,
  BUISN = 1011,
  USERMANAGER = 1012,
  TEST = 1013,
  PILOT = 1014,
  REQUESTS = 1015,
  AIR = 1016,
  NEWS = 1017,
  SYSADMIN = 1018,
  DOCUPLOAD = 1019,
  CREATE = 1024,
}

export enum TransportPath {
  IMPORT = 1,
  EXPORT = 2,
  DOMESTIC = 3,
  INTERNATIONAL = 4,
}

export enum BookingTypes {
  NORMAL = "NORMAL",
  BUYERSCONSOL = "BUYERSCONSOL",
  SUBBOOKING = "SUBBOOKING",
}

export enum BookingStatus {
  ONHOLD = 0,
  BOOKED = 1,
  PICKUP_REGISTERED = 105,
  PICKUP_SCHEDULED = 107,
  PICKUP_STARTED = 110,
  PARTIALLY_PICKED_UP = 112,
  PICKUP_COMPLETED = 115,
  IN_WAREHOUSE_RECEPTION = 120,
  COLLECTED = 3,
  LOAD_ON_BOARD = 993,
  IN_TRANSIT = 300,
  DISCHARGED = 55,
  SHIPMENT_UNLOADED = 995,
  ARRIVED_IN_POD = 945,
  DELIVERY_BOOKED = 976,
  BEING_PICKED_AT_WAREHOUSE = 977,
  READY_FOR_DELIVERY = 978,
  LOADED_ON_TRUCK = 979,
  OUT_FOR_DELIVERY = 980,
  DELIVERED_POD = 985,
  DELIVERED_TO_DOMESTIC_TRANSPORT = 986,
  DELIVERED_TO_WAREHOUSE = 987,
  PICKED_UP_BY_CUSTOMER = 988,
  DELIVERED = 989,
  DELIVERED_EXPORT = 400,
  CANCELED = 9,
  DELETED = 999,
}

export const FreightModeIcon = {
  AIR: "iFlutningiFlug",
  SHIP: "iFlutningiSjor",
};

export const BookingStatusIcons = {
  iBid: "iBid",
  skrad: "skrad",
  iMottokukIVoruhusi: "iMottokukIVoruhusi",
  iFlutningiFlug: "iFlutningiFlug",
  iFlutningiSjor: "iFlutningiSjor",
  sendingLosudUrSkipi: "sendingLosudUrSkipi",
  tilfaerslaIVoruhusi: "tilfaerslaIVoruhusi",
  tilbuinTilAfgreidslu: "tilbuinTilAfgreidslu",
  afhendingPontud: "afhendingPontud",
  aksturPantadur: "aksturPantadur",
  lestadIBil: "lestadIBil",
  iAkstri: "iAkstri",
  sott: "sott",
  afhentTilLandflutninga: "afhentTilLandflutninga",
  afhent: "afhent",
  haettVid: "haettVid",
};

export const DateRangeOptions = {
  INIT: "INIT",
  NEXT_MONTH: "NEXT_MONTH",
  NEXT_TWO_WEEKS: "NEXT_TWO_WEEKS",
  LAST_WEEK: "LAST_WEEK",
  LAST_TWO_WEEKS: "LAST_TWO_WEEKS",
  LAST_MONTH: "LAST_MONTH",
  LAST_THREE_MONTHS: "LAST_THREE_MONTHS",
  LAST_YEAR: "LAST_YEAR",
  CUSTOM_DATE: "CUSTOM_DATE",
};

export const ActionTypeMap = {
  CORRECTION: "CORRECTION",
  WEBNOTE: "WEBNOTE",
  INNERWEBNOTE: "INNERWEBNOTE",
  DELIVREG: "DELIVREG",
  CUSTOMSREQ: "CUSTOMSREQ",
  CUSTOMSREG: "CUSTOMSREG",
  BILLOFLADING: "BILLOFLADING",
  BOLSHIPDOC: "BOLSHIPDOC",
  BOLAIRDOC: "BOLAIRDOC",
  ARRDOC: "ARRDOC",
  BKCFDOC: "BKCFDOC",
  DEPDOC: "DEPDOC",
  ALLDOCS: "ALLDOCS",
  PAYMSTATUS: "PAYMSTATUS",
  INVOICES: "INVOICES",
  DELIVREQ: "DELIVREQ",
  COPYBOOKING: "COPYBOOKING",
};

export const WebNoteType = {
  INTERNAL: 1,
  EXTERNAL: 2,
};

export const UserType = {
  EMPLOYEE: "E",
  CUSTOMER: "C",
  AGENT: "A",
};

export const ShippingTypeOptions = [
  { value: "L L", item: "LCL-LCL" },
  { value: "L F", item: "LCL-FCL" },
  { value: "F L", item: "FCL-LCL" },
  { value: "F F", item: "FCL-FCL" },
];

export enum PartnerModalLabels {
  REGISTER = "LABEL_REGISTER_PARTNER",
  EDIT = "LABEL_EDIT_REGISTRATION",
  PICK = "LABEL_PICK_PARTNER",
}

export enum IncotermPoint {
  PLR = "PLR",
  PFD = "PFD",
  POL = "POL",
  POD = "POD",
}

export const fileMaxSize = 15;
export const fileNameSize = 100;
export const volumeMax = 10000;
export const weightMax = 10000;
export const minTemperature = -40;
export const maxTemperature = 40;

export const Temperatures: SelectOption<number[]>[] = [
  { value: [+15, +25], label: "+15 to +25" },
  { value: [+10, +18], label: "+10 to +18" },
  { value: [+2, +8], label: "+2 to +8" },
  { value: [-19, -17], label: "-19 to -17" },
];

export const bills: BillOfLading[] = [
  {
    type: "SWB",
    value: "W",
    label: "LABEL_SEAWAYBILL",
  },
  {
    type: "B/L",
    value: "G",
    label: "LABEL_BILLOFLADING",
  },
];

export enum AddressType {
  SHP = "SHP",
  CEE = "CEE",
  COL = "COL",
  DEL = "DEL",
  NOT = "NOT",
  DEFAULT = "000",
}

export const NoteType = {
  INTERNAL: 1,
  PUBLIC: 2,
};

export enum ShippingTypeValue {
  LL = "L L",
  LF = "L F",
  FL = "F L",
  FF = "F F",
}

export enum ShippingTypeItem {
  LL = "LCL-LCL",
  LF = "LCL-FCL",
  FL = "FCL-LCL",
  FF = "FCL-FCL",
}

export enum SeaDocType {
  COMI = "COMI",
  HBL = "HBL",
  PACK = "PACK",
  EXDOC = "EXDOC",
  LDL = "LDL",
  DGD = "DGD",
  COO = "COO",
  CMR = "CMR",
  VGM = "VGM",
  CUS = "CUS",
  EPOD = "EPOD",
  QTL = "QTL",
  ARR = "ARR",
  BKCF = "BKCF",
  OTHER = "OTHER",
}

export enum AirDocType {
  COMI = "COMI",
  HAWB = "HAWB",
  PACK = "PACK",
  EXDOC = "EXDOC",
  DGD = "DGD",
  COO = "COO",
  CMR = "CMR",
  EUR1 = "EUR1",
  EPOD = "EPOD",
  MAWB = "MAWB",
  QTL = "QTL",
  OTHER = "OTHER",
}

export enum AcceptedFileType {
  PDF = "application/pdf",
  DOC = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOCX = "application/msword",
  XLS = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLSX = "application/vnd.ms-excel",
  JPEG = "image/jpeg",
}

export enum InvoiceStatus {
  Unpaid = "Unpaid",
  Paid = "Paid",
  Credit = "Credit",
  Deposit = "Deposit",
}

export enum InvoiceType {
  Invoice = "Invoice",
  Customs = "Customs Invoice",
  Transport = "Transport invoice",
  Storage = "Storage invoice",
}

export enum CompanyFilterTypes {
  SelectedCompany = "SelectedCompany",
  AllCompanies = "AllCompanies",
}

/** Max input size for create booking specific fields - values that have the maximum characters in GFS */
export const MAX_INPUT_LENGTH_GENERIC = 20;
export const MAX_INPUT_LENGTH_ADDRESS = 35;
export const MAX_INPUT_LENGTH_ADDITIONAL_INFO = 120;
