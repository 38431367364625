import React, { useState } from "react";
import { connect } from "react-redux";
import { withTrans } from "@components/WithTrans/WithTrans";
import Header from "@components/Header/header";
import Footer from "@components/Footer/footer";
import Spinner from "@components/Spinner/spinner";
import Toasts from "@components/Toast/toasts";
import Routes from "@routes/routes";
import { AppState, ModalType, ViewState } from "@state/reducers/types";
import CompanyModal from "@components/Modal/companyModal";
import UserModal from "@components/Modal/userModal";
import PasswordModal from "@components/Modal/passwordModal";
import { useTranslation, Translation } from "react-i18next";

const Layout: React.FC<ViewState> = ({ modalOpen, modalType }) => {
  const { ready } = useTranslation();

  if (!ready) {
    return (
      <div className="h-screen flex flex-col">
        <Spinner />
      </div>
    );
  }

  return (
    <Translation>
      {(t) => (
        <div className="flex flex-col bg-light-gray h-full w-full">
          <Header />
          <Toasts />
          <CompanyModal
            header={t("LABEL_COMPANYINFO")}
            open={modalOpen && modalType === ModalType.COMPANY_MODAL}
          />
          <UserModal
            header={t("LABEL_USER_PROFILE")}
            open={modalOpen && modalType === ModalType.USER_MODAL}
          />
          <PasswordModal
            header={t("LABEL_LOGIN")}
            open={modalOpen && modalType === ModalType.PASSWORD_MODAL}
          />
          <div className="flex flex-col flex-grow overflow-y-auto h-auto">
            <Routes />
            <Footer />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default withTrans(
  connect(
    (state: AppState) => ({
      modalOpen: state.ViewReducer.modalOpen,
      modalType: state.ViewReducer.modalType,
    }),
    {}
  )(Layout)
);
