import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    className={`${styles.customSvgIcon} ${className}`}
    viewBox="0 0 50 50"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M33.22,21.9c-1.31,1.28-3.41,1.26-4.69-0.05c-1.28-1.31-2.24-4.43-0.93-5.71s4.39-0.25,5.67,1.06
                C34.55,18.51,34.53,20.62,33.22,21.9z M24.82,21.87c0.43-0.01,0.85,0.28,1.03,0.76l1.04,2.75c0.24,0.64-0.02,1.42-0.58,1.42h-2.99
                c-0.54,0-0.82-0.78-0.58-1.42l1.04-2.75C23.98,22.15,24.39,21.87,24.82,21.87z M16.78,21.9c-1.31-1.28-1.33-3.39-0.05-4.7
                c1.28-1.31,4.36-2.35,5.67-1.06c1.31,1.28,0.35,4.4-0.93,5.71C20.18,23.16,18.09,23.18,16.78,21.9z M36.93,18.15
                c0.37-1.18,0.57-2.45,0.57-3.75c0-0.87-0.09-1.72-0.26-2.54c-1.17-5.68-6.16-9.95-12.16-9.99c-0.03,0-0.06,0-0.09,0
                c-0.03,0-0.06,0-0.09,0c-6,0.04-10.99,4.32-12.16,9.99c-0.17,0.82-0.26,1.67-0.26,2.54c0,1.31,0.2,2.57,0.57,3.75
                c0.25,0.79,0.17,1.64-0.2,2.37c-0.23,0.46-0.36,0.98-0.37,1.53c0,0.28,0.03,0.55,0.09,0.8c0.12,0.53,0.37,1.02,0.71,1.43
                c0.64,0.78,1.62,1.27,2.7,1.27c0.02,0,0.05,0,0.07,0.01h1.04c0.69,0,1.25,0.56,1.25,1.26v1.53c0,2.18,1.76,3.94,3.93,3.94h5.41
                c2.17,0,3.93-1.76,3.93-3.94v-1.53c0-0.69,0.56-1.26,1.25-1.26h1.04c0.02-0.01,0.05-0.01,0.07-0.01c1.08,0,2.05-0.5,2.7-1.27
                c0.34-0.41,0.59-0.89,0.71-1.43c0.06-0.26,0.09-0.53,0.09-0.8c0-0.55-0.14-1.07-0.37-1.53C36.76,19.79,36.69,18.94,36.93,18.15z"
      />
      <path
        className={`${styles.st0}`}
        d="M39.61,35.72c0.36-1.01-0.2-2.1-1.25-2.45l-0.08-0.03c-0.13-0.04-0.24-0.14-0.3-0.25
                c-0.06-0.11-0.07-0.25-0.02-0.38l0.03-0.08c0.36-1.01-0.2-2.1-1.25-2.45c-1.05-0.35-2.19,0.19-2.56,1.19l-0.55,1.52
                c-0.18,0.5-0.56,0.91-1.06,1.14L25,37.48l-7.57-3.54c-0.5-0.23-0.88-0.64-1.06-1.14l-0.55-1.52c-0.36-1.01-1.51-1.54-2.56-1.19
                c-1.05,0.35-1.61,1.44-1.25,2.45l0.03,0.08c0.05,0.13,0.03,0.26-0.02,0.38c-0.06,0.11-0.16,0.21-0.3,0.25l-0.08,0.03
                c-1.05,0.35-1.61,1.44-1.25,2.45c0.36,1.01,1.51,1.54,2.56,1.19l1.59-0.52c0.52-0.17,1.09-0.14,1.59,0.1l5.5,2.57l-0.84,0.39
                l-4.66,2.18c-0.5,0.23-1.07,0.27-1.59,0.1l-1.59-0.52c-1.05-0.35-2.19,0.19-2.56,1.19c-0.36,1.01,0.2,2.1,1.25,2.45l0.08,0.03
                c0.14,0.04,0.24,0.14,0.3,0.25c0.06,0.11,0.07,0.25,0.02,0.38l-0.03,0.08c-0.36,1.01,0.2,2.1,1.25,2.45
                c1.05,0.35,2.19-0.19,2.56-1.19l0.55-1.52c0.18-0.5,0.56-0.91,1.06-1.14L22.08,42L25,40.63L27.92,42l4.66,2.18
                c0.5,0.23,0.88,0.64,1.06,1.14l0.55,1.52c0.36,1.01,1.51,1.54,2.56,1.19c1.05-0.35,1.61-1.44,1.25-2.45l-0.03-0.08
                c-0.05-0.13-0.03-0.26,0.02-0.38c0.06-0.11,0.16-0.21,0.3-0.25l0.08-0.03c1.05-0.35,1.61-1.44,1.25-2.45
                c-0.36-1.01-1.51-1.54-2.56-1.19l-1.59,0.52c-0.52,0.17-1.09,0.14-1.59-0.1l-4.66-2.18l-0.84-0.39l5.5-2.57
                c0.5-0.23,1.07-0.27,1.59-0.1l1.59,0.52C38.1,37.26,39.24,36.72,39.61,35.72z"
      />
    </g>
  </svg>
);

export default SVG;
