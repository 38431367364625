import { ModalProps } from "@assets/types";
import React from "react";
import Modal from "./modal";
import { Translation } from "react-i18next";
import CancelButton from "@components/Button/button";
import { Button } from "@samskip/frontend-components";

interface DeleteModalProps extends ModalProps {
  open: boolean;
  onDelete: () => void;
  content: string;
  header: string;
}

const DeleteModal: React.FC<DeleteModalProps> = (
  props: DeleteModalProps
) => {
  const { open, closeModal, onDelete, content, header } = props;

  const onClose = () => {
    closeModal();
  };

  const handleDeleteClick = () => {
    onClose();
    onDelete();
  };

  return (
    <>
        <Translation>
        {(t) => (
            <Modal
                open={open}
                size="small"
                className="left-2 right-2 mb-auto mt-[32px]"
                closeIcon={false}
            >
                <Modal.Header><p>{header}</p></Modal.Header>
                <Modal.Content>
                    <div className="whitespace-normal">
                        <p>
                            {content}
                        </p>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <CancelButton
                        className="mx-1"
                        type="button"
                        onClick={onClose}
                    >
                        {t("LABEL_CANCEL")}
                    </CancelButton>
                    <Button
                        onClick= {handleDeleteClick}
                        variant="error"
                        className="bg-error"
                    >
                        {t("LABEL_DELETE")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )}
        </Translation>
    </>
  );
};

export default DeleteModal
