import React, { Component } from "react";
import { connect } from "react-redux";

import Iframe from "react-iframe";
import Spinner from "@components/Spinner/spinner";

import { AppState } from "@state/reducers/types";

import { titlePrefix } from "@assets/constants";
import i18n from "i18next";

const OldServiceWeb = process.env.OldServiceWeb;

class Requests extends Component<
  { path?: string; iframeUpdater: number },
  { loading: boolean }
> {
  state = { loading: true };

  componentDidMount() {
    document.title = titlePrefix + i18n.t("MENU_REQUESTS");
  }

  componentDidUpdate(prevProps: { path?: string; iframeUpdater: number }) {
    if (this.props.iframeUpdater !== prevProps.iframeUpdater) {
      this.setState({ loading: true });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Spinner isHidden={!loading} />
        <Iframe
          key={`${this.props.iframeUpdater}`}
          url={`${OldServiceWeb}/RequestSearch.aspx?Iframe=1`}
          className="overflow-hidden iframe-main-container shadow-lg flex-grow"
          width={loading ? "0%" : "100%"}
          height={loading ? "0%" : "100%"}
          onLoad={() => this.setState({ loading: false })}
        />
      </>
    );
  }
}

export default connect((state: AppState) => {
  return { iframeUpdater: state.UserReducer.iframeUpdater };
}, {})(Requests);
