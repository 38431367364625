import {
  Booking,
  BookingAddress,
  Company,
  ErrorTypes,
  ModalProps,
} from "@assets/types";
import Button from "@components/Button/button";
import Checkbox from "@components/Input/checkbox";
import { Modal, ValidationError } from "@samskip/frontend-components";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface SwitchCompanyModalProps extends ModalProps {
  userSelectedCompany: Company | null;
  booking: Booking | null;
  shipper: BookingAddress | null | undefined;
  consignee: BookingAddress | null | undefined;
  open: boolean;
  onSwitchCompany: (booking: Booking | null, partnerCode: string) => void;
  loadingSwitchCompany: boolean;
}

const SwitchCompanyModal: React.FC<SwitchCompanyModalProps> = (
  props: SwitchCompanyModalProps
) => {
  const { t } = useTranslation();
  const {
    userSelectedCompany,
    open,
    closeModal,
    booking,
    onSwitchCompany,
    shipper,
    consignee,
    loadingSwitchCompany,
  } = props;

  const [selectedCompany, setSetSelectedCompany] = useState<
    string | null | undefined
  >(null);

  const [selectedCompanyError, setSetSelectedCompanyError] = useState<
    string | null
  >(null);

  const onClose = () => {
    closeModal();
  };

  const onHandleProceed = () => {
    if (!selectedCompany) {
      setSetSelectedCompanyError(ErrorTypes.RequiredField);
    } else {
      setSetSelectedCompanyError(null);
      onSwitchCompany(booking, selectedCompany ? selectedCompany : "");
    }
  };

  return (
    <Modal open={open} closeIcon onClose={onClose}>
      <Modal.Header divider>{t("LABEL_SWITCH_COMPANY")}</Modal.Header>
      <Modal.Content className="text-neutral-900">
        <p>
          <Trans
            i18nKey={"TEXT_SWITCH_COMPANY_HEADER_N"}
            values={{ n: userSelectedCompany?.FullName }}
            components={{ bold: <strong /> }}
          />
        </p>
        <p>{t("TEXT_SELECT_COMPANY")}</p>
        <div className="flex">
          <Checkbox
            className="pb-1 mr-2"
            label={shipper?.Name}
            type="radio"
            checked={selectedCompany === shipper?.PartnerCode}
            onChange={() => setSetSelectedCompany(shipper?.PartnerCode)}
          />
          <Checkbox
            className="pb-1 mr-2 ml-2"
            label={consignee?.Name}
            type="radio"
            checked={selectedCompany === consignee?.PartnerCode}
            onChange={() => setSetSelectedCompany(consignee?.PartnerCode)}
          />
        </div>
        {selectedCompanyError && (
          <ValidationError>{t(selectedCompanyError)}</ValidationError>
        )}
        <p>{t("TEXT_SWITCH_COMPANY_FOOTER")}</p>
      </Modal.Content>
      <Modal.Footer divider>
        <Button onClick={onClose}>{t("LABEL_CANCEL")}</Button>
        <Button
          primary
          onClick={() => {
            onHandleProceed();
          }}
          active={loadingSwitchCompany}
        >
          {t("LABEL_PROCEED")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwitchCompanyModal;
