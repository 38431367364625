import i18next from "i18next";
import { DeepMap, FieldError, get } from "react-hook-form";

/**
 * Function to use when translating a label outside a react component.
 *
 * Important: when translating in a React component use useTranslation hook or Translation wrapper component!
 * @param label The label that should be translated
 * @param {Record<string, any>} [interpolationObject] The key value pairs for the dynamic translations.
 * @return If the label has a translation, the text from the database, otherwise the label itself.
 */
export const translate = (
  label: string | null | undefined,
  interpolationObject?: Record<string, any>
) => {
  if (!label) return;

  if (i18next.exists(label)) {
    return interpolationObject
      ? i18next.t(label, interpolationObject)
      : i18next.t(label);
  }
  return label;
};

/** Get the translated error message of a given react-hook-form element.
 * @param {string} name The registered name of the element.
 * @param { DeepMap<Record<string, any>, FieldError>} errors The react-hook-form errors object.
 * @param {Record<string, any>} [interpolationObject] The key value pairs for the dynamic translations.
 */
export const getErrorMessage = (
  name: string,
  errors: DeepMap<Record<string, any>, FieldError>,
  interpolationObject?: Record<string, any>
): undefined | string => {
  const labelKey = get(errors, name)?.message;

  if (!labelKey) return;

  return translate(labelKey, interpolationObject);
};
