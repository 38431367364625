import axios from "axios";
import i18next from "i18next";

import { Alert } from "@assets/types";

const JonarAPI = process.env.JonarAPI;

class AlertsService {
  getAlertRoute = async (req: {
    Route: string;
    Company: number;
  }): Promise<Alert> => {
    return axios
      .post(`${JonarAPI}/alerts/getAlertRoute`, req, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res) => {
        return res.data.ReturnItem.Data;
      });
  };
}

export default new AlertsService();
