import React from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Translation } from "react-i18next";
import UserService from "@services/UserService";

import { openModal } from "@state/actions/index";

import { Company, DropdownOption, UserProfileViewModel } from "@assets/types";
import { AppState, ModalType } from "@state/reducers/types";

import DropdownSearch from "@components/Dropdown/dropdownSearch";
import { UserType } from "@assets/constants";

const SEARCH_DELAY_TIMER = 1000;

type CompanyPickerSearchProps = {
  className?: string;
  open: boolean;
  closeSearch: Function;
  onSelect: Function;
  openModal: Function;
  userProfile: UserProfileViewModel;
};

type CompanyPickerSearchState = {
  isSearching: boolean;
  options: DropdownOption[];
};

class CompanyPickerSearch extends React.PureComponent<
  CompanyPickerSearchProps,
  CompanyPickerSearchState
> {
  state: CompanyPickerSearchState = {
    isSearching: false,
    options:
      this.props.userProfile.Access.UserType !== UserType.CUSTOMER
        ? []
        : this.props.userProfile.Companies.map((company: Company) => {
            return {
              value: company.PartnerCode,
              item: (
                <>
                  <span className="text-left">{company.FullName}</span>
                  <span>{company.PartnerCode}</span>
                </>
              ),
            };
          }),
  };

  handleSearch = async (value: string) => {
    try {
      this.setState({ isSearching: true });
      await UserService.searchCompanies(value).then((res) => {
        this.setState({
          options: res
            ? res.map((company: Company) => ({
                value: company.PartnerCode,
                item: (
                  <>
                    <span className="text-left">{company.FullName}</span>
                    <span>{company.PartnerCode}</span>
                  </>
                ),
              }))
            : [],
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSearching: false });
    }
  };

  selectCompany = async (partnerCode: string) => {
    await this.props.onSelect(partnerCode);
    this.setState({
      options:
        this.props.userProfile.Access.UserType !== UserType.CUSTOMER
          ? []
          : this.props.userProfile.Companies.map((company: Company) => {
              return {
                value: company.PartnerCode,
                item: (
                  <>
                    <span className="text-left">{company.FullName}</span>
                    <span>{company.PartnerCode}</span>
                  </>
                ),
              };
            }),
    });
    this.props.closeSearch();
  };

  searchWhenUserStopsTyping = debounce((val: string) => {
    this.handleSearch(val);
  }, SEARCH_DELAY_TIMER);

  render() {
    const { open, className, userProfile } = this.props;
    const { isSearching, options } = this.state;
    return (
      <Translation>
        {(t) => (
          <div
            id="companyDropDownSearch"
            className={`z-10 absolute border border-gray-500 shadow-md bg-gray-100 h-auto cursor-default transition-fade ease-in duration-fade-300 md:right-0 ${
              open
                ? "opacity-100 visible"
                : "opacity-0 delay-fade-300 invisible"
            } ${className}`}
          >
            <div className="bg-gray-100 border-b-2 flex justify-between uppercase text-gray-700 font-normal px-2 pt-9px pb-4px">
              <span
                className={
                  userProfile.Access.UserType === UserType.CUSTOMER
                    ? "hidden"
                    : ""
                }
              >
                {t("LABEL_COMPANYSEARCH")}
              </span>

              <span
                className="underline cursor-pointer"
                onClick={() => {
                  this.props.openModal(ModalType.COMPANY_MODAL);
                  this.props.closeSearch();
                }}
              >
                {t("LABEL_VIEWCURRENTCOMPANY")}
              </span>
            </div>
            <div className="w-full pt-2 z-10 flex flex-col justify-center items-center">
              <DropdownSearch
                icon="search"
                className="w-11/12 mb-3"
                hasSearch={userProfile.Access.UserType !== UserType.CUSTOMER}
                isSearching={isSearching}
                onSearch={this.handleSearch}
                onKeyPress
                searchPlaceholder={t("LABEL_COMPANYSEARCH_INPUT")}
                resultHeaders={[
                  t("TEXT_NAME"),
                  `${t("TEXT_RECORD", {
                    count: options.length,
                  })}: ${options.length}`,
                ]}
                onSelect={this.selectCompany}
                options={options}
              />

              {
                // TODO: we may want to refactor this later on.
                userProfile.Access.UserType === UserType.CUSTOMER ? (
                  <>
                    <div className="h-auto bg-gray-100 w-full flex justify-between items-center normal-case text-gray-600 font-normal px-3 pb-2 pt-1 text-xs z-50">
                      {[
                        t("TEXT_NAME"),
                        `${t("TEXT_RECORD", {
                          count: options.length,
                        })}: ${options.length}`,
                      ].map((header: string, i: number) => {
                        if (i === 0) {
                          return (
                            <span key={i} className="font-bold">
                              {header}
                            </span>
                          );
                        }
                        return (
                          <span key={i}>
                            {header}{" "}
                            {options.length == 1000 ? <span>(max)</span> : null}{" "}
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={`max-h-56 w-full ${
                        options.length > 5 ? "overflow-y-scroll" : null
                      }`}
                    >
                      {options.map((item: DropdownOption, i: number) => {
                        return (
                          <button
                            className="flex justify-between bg-white text-gray-700 font-normal p-3 hover:bg-gray-100 cursor-pointer w-full border-t"
                            key={i}
                            onClick={() => this.selectCompany(item.value)}
                            value={item.value}
                          >
                            {item.item}
                          </button>
                        );
                      })}
                    </div>
                  </>
                ) : null
              }
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
  }),
  {
    openModal,
  }
)(CompanyPickerSearch);
