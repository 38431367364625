import {
  BookingStatus,
  BookingStatusIcons,
  FreightMode,
  FreightModeIcon,
} from "@assets/constants";
class BookingUtilities {
  getBookingFreightModeIcon = (freightMode?: FreightMode) => {
    switch (freightMode) {
      case FreightMode.AI:
        return FreightModeIcon.AIR;
      case FreightMode.SH:
        return FreightModeIcon.SHIP;
      default:
        return undefined;
    }
  };

  getStatusIcon = (status: BookingStatus, freightMode?: FreightMode) => {
    switch (status) {
      case BookingStatus.ONHOLD:
        return BookingStatusIcons.iBid;
      case BookingStatus.BOOKED:
        return BookingStatusIcons.skrad;
      case BookingStatus.PICKUP_REGISTERED:
        return BookingStatusIcons.skrad;
      case BookingStatus.PICKUP_SCHEDULED:
        return BookingStatusIcons.skrad;
      case BookingStatus.PICKUP_STARTED:
        return BookingStatusIcons.skrad;
      case BookingStatus.PARTIALLY_PICKED_UP:
        return BookingStatusIcons.skrad;
      case BookingStatus.PICKUP_COMPLETED:
        return BookingStatusIcons.skrad;
      case BookingStatus.IN_WAREHOUSE_RECEPTION:
        return BookingStatusIcons.iMottokukIVoruhusi;
      case BookingStatus.LOAD_ON_BOARD:
        return freightMode === FreightMode.AI
          ? BookingStatusIcons.iFlutningiFlug
          : BookingStatusIcons.iFlutningiSjor;
      case BookingStatus.IN_TRANSIT:
        return freightMode === FreightMode.AI
          ? BookingStatusIcons.iFlutningiFlug
          : BookingStatusIcons.iFlutningiSjor;
      case BookingStatus.DISCHARGED:
        return BookingStatusIcons.sendingLosudUrSkipi;
      case BookingStatus.SHIPMENT_UNLOADED:
        return BookingStatusIcons.sendingLosudUrSkipi;
      case BookingStatus.ARRIVED_IN_POD:
        return BookingStatusIcons.sendingLosudUrSkipi;
      case BookingStatus.DELIVERY_BOOKED:
        return BookingStatusIcons.afhendingPontud;
      case BookingStatus.BEING_PICKED_AT_WAREHOUSE:
        return BookingStatusIcons.tilfaerslaIVoruhusi;
      case BookingStatus.READY_FOR_DELIVERY:
        return BookingStatusIcons.tilbuinTilAfgreidslu;
      case BookingStatus.LOADED_ON_TRUCK:
        return BookingStatusIcons.lestadIBil;
      case BookingStatus.OUT_FOR_DELIVERY:
        return BookingStatusIcons.iAkstri;
      case BookingStatus.DELIVERED_POD:
        return BookingStatusIcons.afhent;
      case BookingStatus.DELIVERED_TO_DOMESTIC_TRANSPORT:
        return BookingStatusIcons.afhent;
      case BookingStatus.DELIVERED_TO_WAREHOUSE:
        return BookingStatusIcons.afhent;
      case BookingStatus.PICKED_UP_BY_CUSTOMER:
        return BookingStatusIcons.afhent;
      case BookingStatus.DELIVERED:
        return BookingStatusIcons.afhent;
      case BookingStatus.CANCELED:
        return BookingStatusIcons.haettVid;
      case BookingStatus.DELETED:
        return BookingStatusIcons.haettVid;
      default:
        return BookingStatusIcons.skrad;
    }
  };
}

export default new BookingUtilities();
