import React, { Component } from "react";

import { connect } from "react-redux";

import logo from "@assets/svg/jonar-transport-logo.svg";

import Link from "@components/Link/link";
import Navigation from "@components/Header/navigation";
import i18next from "i18next";
import CompanyPicker from "@components/CompanyPicker/companyPicker";

import { logout, openModal, selectLanguage } from "@state/actions/index";

import { UserProfileViewModel } from "@assets/types";
import { AppState, ModalType } from "@state/reducers/types";
import { Translation } from "react-i18next";
import { FiMenu, FiX } from "react-icons/fi";
import { navigate } from "gatsby";

class Header extends Component<
  {
    userProfile: UserProfileViewModel;
    logout: Function;
    openModal: Function;
    selectLanguage: Function;
    profileLoaded: boolean;
    loadingBookings: boolean;
    favoriteBookingIsLoading: number;
  },
  { companyPickerOpen: boolean; navOpen: boolean; firstRender: boolean }
> {
  state = { companyPickerOpen: false, navOpen: false, firstRender: true };
  closeCompanyPicker = () => {
    this.setState({ companyPickerOpen: false });
  };

  toggleCompanyPicker = () => {
    this.setState({ companyPickerOpen: !this.state.companyPickerOpen });
  };

  toggleNavMenu = () => {
    this.setState({ navOpen: !this.state.navOpen });
  };

  componentDidUpdate(
    prevProps: any,
    prevState: {
      companyPickerOpen: boolean;
      navOpen: boolean;
      firstRender: boolean;
    }
  ) {
    if (prevState.navOpen !== this.state.navOpen) {
      this.setState({ firstRender: false });
    }
  }

  render() {
    const { companyPickerOpen } = this.state;
    return (
      <Translation>
        {(t) => (
          <header className="z-100 text-white bg-jonar-blue leading-8 md:leading-4 w-full border-b-solid">
            <div
              className={`flex flex-row justify-between xl:container mx-auto px-8`}
            >
              <div className="ml-0 block cursor-pointer">
                <img
                  className={`h-16 w-auto mt-10px fade-in-left mb-2 pt-2" ${
                    this.props.userProfile?.User ? "hidden md:block" : ""
                  }`}
                  src={logo}
                  onClick={() =>
                    this.props.profileLoaded ? navigate("/") : null
                  }
                />
              </div>
              <div className="relative left-0 bg-jonar-blue flex justify-around flex-col w-full md:w-auto py-4 md:py-0">
                <div
                  id="companyHeader"
                  className="py-1 md:py-3 flex flex-col md:flex-row font-bold justify-end mx-2"
                >
                  <div>
                    {this.props.userProfile?.User &&
                    this.props.userProfile.User.ID ? (
                      <div className="flex flex-row justify-between md:items-baseline">
                        <div className="flex flex-col items-baseline md:flex-row">
                          <span
                            className="text-white px-1 font-bold leading-none uppercase cursor-pointer"
                            onClick={() =>
                              this.props.openModal(ModalType.USER_MODAL)
                            }
                          >
                            {this.props.userProfile.User.Username}
                          </span>
                          <CompanyPicker
                            toggle={this.toggleCompanyPicker}
                            close={this.closeCompanyPicker}
                            isOpen={companyPickerOpen}
                          />
                        </div>
                        <div className="flex flex-col justify-between">
                          <div
                            id="hamburger"
                            className="md:hidden pointer flex items-center self-end"
                            onClick={this.toggleNavMenu}
                          >
                            {this.state.navOpen ? (
                              <FiX className="text-white h-8 w-8" />
                            ) : (
                              <FiMenu className="text-white h-8 w-8" />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="block">
                    <div
                      className={`${
                        this.props.userProfile ? "hidden" : "float-right"
                      }  md:flex flex-row justify-end`}
                    >
                      {this.props.userProfile?.User &&
                      this.props.userProfile.User.ID ? (
                        <div>
                          <Link
                            className="text-white px-1 font-bold uppercase"
                            color="neutral-0"
                            href="#"
                            onClick={async () =>
                              await this.props.logout(
                                this.props.userProfile.Access
                              )
                            }
                          >
                            {t("TEXT_LOGOUT")}
                          </Link>
                        </div>
                      ) : null}
                      {[...i18next.languages]
                        .sort()
                        .reverse()
                        .map((lang, i) => {
                          return (
                            <Link
                              key={i}
                              href="#"
                              className="text-white px-1 font-bold uppercase"
                              color="neutral-0"
                              onClick={() => this.props.selectLanguage(lang)}
                            >
                              {lang}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {this.props.userProfile?.User &&
                this.props.userProfile.User.ID ? (
                  <div
                    className={`${
                      this.state.navOpen
                        ? "animate-fade-in md:animate-none h-screen md:h-auto"
                        : (this.state.firstRender ? "" : "hide-element ") +
                          "animate-fade-out md:animate-none h-0 md:h-auto overflow-hidden"
                    } w-full bg-jonar-blue flex justify-center md:overflow-visible flex-col md:flex-row leading-8 md:leading-4`}
                  >
                    <Navigation
                      className="relative bg-red flex flex-col md:flex-row -mt-16 md:mt-0"
                      close={() => this.setState({ navOpen: false })}
                    />
                    {this.state.navOpen ? (
                      <div className="flex w-full fixed bottom-12">
                        <div className="flex flex-row justify-start w-full">
                          {this.props.userProfile?.User &&
                          this.props.userProfile.User.ID ? (
                            <div>
                              <Link
                                className="px-1 font-bold uppercase"
                                color="neutral-0"
                                href="#"
                                onClick={async () =>
                                  await this.props.logout(
                                    this.props.userProfile.Access
                                  )
                                }
                              >
                                {t("TEXT_LOGOUT")}
                              </Link>
                            </div>
                          ) : null}
                          {[...i18next.languages]
                            .sort()
                            .reverse()
                            .map((lang, i) => {
                              return (
                                <Link
                                  key={i}
                                  href="#"
                                  className="text-white px-1 font-bold uppercase"
                                  color="neutral-0"
                                  onClick={() =>
                                    this.props.selectLanguage(lang)
                                  }
                                >
                                  {lang}
                                </Link>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            {!this.state.navOpen &&
            this.props.userProfile &&
            (this.props.loadingBookings ||
              this.props.favoriteBookingIsLoading > 0) ? (
              <div className="progress fixed-loader">
                <div className="indeterminate"></div>
              </div>
            ) : null}
          </header>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
    profileLoaded: state.UserReducer.profileLoaded,
    loadingBookings: state.BookingReducer.loadingBookings,
    favoriteBookingIsLoading: state.LoaderReducer.favoriteBookingIsLoading,
  }),
  { logout, openModal, selectLanguage }
)(Header);
