import axios from "axios";

import { ActiveRequest } from "@assets/types";

const JonarAPI = process.env.JonarAPI;

class RequestService {
  getActiveBookings = async (partner: string): Promise<ActiveRequest> => {
    return await axios
      .post(
        `${JonarAPI}/requests/active`,
        {
          partnerCode: partner,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        return res.data.ReturnItem.Data;
      });
  };
}

export default new RequestService();
