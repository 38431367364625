import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import * as _ from "lodash";

import {
  ActionTypeMap,
  BookingStatus,
  DateRangeOptions,
  FreightMode,
  WebNoteType,
  titlePrefix,
  TransportPath,
  UserRole,
  UserType,
  BookingTypes,
} from "@assets/constants";
import i18n from "i18next";

import { FiX, FiDownload } from "react-icons/fi";

import ListFilterLayout from "@components/Layout/listFilterLayout";
import ListLine from "@components/ListLine/listLine";
import ListLineItem from "@components/ListLineItem/listLineItem";
import Checkbox from "@components/Input/checkbox";
import ActionBar from "@components/ActionBar/actionBar";
import Select, { SelectItemProps } from "@components/Select/select";
import LabelList, { onAdd, onRemove } from "@components/Label/labelList";
import IconButton from "@components/Button/iconButton";
import CustomIcon from "@components/CustomIcon/customIcon";
import Accordion from "@components/Accordion/accordion";
import DropdownSearch from "@components/Dropdown/dropdownSearch";

import {
  Booking,
  UserProfileViewModel,
  BookingFilters,
  Label,
  BookingAction,
  Action,
  DropdownOption,
} from "@assets/types";
import { AppState } from "@state/reducers/types";

import {
  fetchBookingList,
  fetchBookingListFrontEnd,
  getFavoriteBookings,
  getBookingsInExcel,
  changePolOptions,
  changeConsigneeOptions,
  changePodOptions,
  changeShipperOptions,
  updateWebSetting,
  changeShippingTypeOptions,
} from "@state/actions/index";
import { nameof } from "ts-simple-nameof";
import ArrayUtilities from "utilities/ArrayUtilities";
import DateUtilities from "utilities/DateUtilities";
import { Translation } from "react-i18next";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import stylesDatePicker from "@components/Input/reactDateOverrides.module.less";
import stylesShipments from "./shipments.module.less";
import BookingService from "@services/BookingService";
import VirtualScroll from "react-dynamic-virtual-scroll";
import BookingUtilities from "utilities/BookingUtilities";
import i18next from "i18next";
import UserUtilities from "utilities/UserUtilities";
import Search from "@components/Search/search";
import Icon from "@components/CustomIcon/Icons";
import { toUpper } from "lodash";
import Tooltip from "@components/Tooltip/tooltip";
import WebNoteModal from "@components/Modal/webNoteModal";
import { openTabOrWindow } from "@assets/utilities/windowUtilities";
import SingleBookingService from "@services/SingleBookingService";
import UserService from "@services/UserService";
import SwitchCompanyModal from "@components/Modal/switchCompanyModal";

type ShipmentPageProps = {
  fetchBookingList: Function;
  fetchBookingListFrontEnd: Function;
  getFavoriteBookings: Function;
  changeShippingTypeOptions: Function;
  changePolOptions: Function;
  changePodOptions: Function;
  changeShipperOptions: Function;
  changeConsigneeOptions: Function;
  getBookingsInExcel: Function;
  updateWebSetting: Function;
  userProfile: UserProfileViewModel;
  bookings: Booking[];
  bookingsAll: Booking[];
  shippingTypeOptions: DropdownOption[];
  displayedShippingTypeOptions: DropdownOption[];
  polOptions: DropdownOption[];
  displayedPolOptions: DropdownOption[];
  displayedPodOptions: DropdownOption[];
  displayedConsigneeOptions: DropdownOption[];
  displayedShipperOptions: DropdownOption[];
  podOptions: DropdownOption[];
  shipperOptions: DropdownOption[];
  consigneeOptions: DropdownOption[];
  isFetchingExcelBookings: boolean;
  favoriteBookings: string[];
  loadingBookingsInit: boolean;
  loadingBookings: boolean;
};

type ShipmentPageState = {
  loading: boolean;
  mobileFilter: boolean;
  filters: BookingFilters;
  labels: Label[];
  labelShippingType: Label[];
  labelPols: Label[];
  labelPods: Label[];
  labelShippers: Label[];
  labelConsignees: Label[];
  sortOptions: SelectItemProps[];
  dateRangeOptions: SelectItemProps[];
  selectedDateRangeOption: string;
  tableHeaders: string[];
  focusedInput: any;
  calendarFocus: any;
  shouldFilterFavorites: boolean;
  displayedItems: number;
  minItemHeight: number;
  shouldShowFreightModeFilter: boolean;
  memoModal: { open: boolean; type: number; jobReference: string };
  switchCompanyModal: { open: boolean; booking: Booking | null };
  selectedBookings: string[];
  actionMenuJobRef: string;
  loadingSwitchCompany: boolean;
};

class Shipments extends Component<ShipmentPageProps, ShipmentPageState> {
  InitialFilters = {
    searchString: "",
    searchTypes: [],
    partnerCode: this.props.userProfile.SelectedCompany.PartnerCode,
    freightModes: [],
    shippingType: [],
    startDate: moment().subtract(2, "weeks"),
    endDate: moment().add(2, "weeks"),
    POL: [],
    POD: [],
    labelsPods: [],
    shippers: [],
    consignees: [],
    isCustomsCleared: undefined,
    statuses: [],
    transportPaths: [],
    bookingTypes: [],
    sort: {
      sortBy: nameof<Booking>((item) => item.ETA),
      isDesc: false,
    },
    shouldShowFreightModeFilter: true,
  };

  state: ShipmentPageState = {
    loading: true,
    sortOptions: [
      {
        value: nameof<Booking>((item) => item.JobReference),
        translationId: "LABEL_JOB_REFERENCE",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.Shipper),
        translationId: "LABEL_SHIPPER",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.Consignee),
        translationId: "LABEL_CONSIGNEE",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.Status),
        translationId: "LABEL_A_STATUS",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.ShippingType),
        translationId: "LABEL_SHIPPING_TYPE",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.VoyageReference),
        translationId: "LABEL_VOYAGE_REFERENCE",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.ETA),
        translationId: "LABEL_ETA",
        text: "",
      },
      {
        value: nameof<Booking>((item) => item.ETD),
        translationId: "LABEL_ETD",
        text: "",
      },
    ],
    dateRangeOptions: [
      {
        value: DateRangeOptions.NEXT_MONTH,
        translationId: `LABEL_${DateRangeOptions.NEXT_MONTH}`,
        text: "",
      },
      {
        value: DateRangeOptions.NEXT_TWO_WEEKS,
        translationId: `LABEL_${DateRangeOptions.NEXT_TWO_WEEKS}`,
        text: "",
      },
      {
        value: DateRangeOptions.LAST_WEEK,
        translationId: `LABEL_${DateRangeOptions.LAST_WEEK}`,
        text: "",
      },
      {
        value: DateRangeOptions.LAST_TWO_WEEKS,
        translationId: `LABEL_${DateRangeOptions.LAST_TWO_WEEKS}`,
        text: "",
      },
      {
        value: DateRangeOptions.LAST_MONTH,
        translationId: `LABEL_${DateRangeOptions.LAST_MONTH}`,
        text: "",
      },
      {
        value: DateRangeOptions.LAST_THREE_MONTHS,
        translationId: `LABEL_${DateRangeOptions.LAST_THREE_MONTHS}`,
        text: "",
      },
      {
        value: DateRangeOptions.LAST_YEAR,
        translationId: `LABEL_${DateRangeOptions.LAST_YEAR}`,
        text: "",
      },
      {
        value: DateRangeOptions.CUSTOM_DATE,
        translationId: `LABEL_${DateRangeOptions.CUSTOM_DATE}`,
        text: "",
      },
    ],
    selectedDateRangeOption: DateRangeOptions.CUSTOM_DATE,
    mobileFilter: false,
    filters: _.cloneDeep(this.InitialFilters),
    labels: [],
    tableHeaders: [
      "TEXT_DESCRIPTION",
      "TEXT_WEIGHT",
      "TEXT_VOLUME",
      "TEXT_QUANTITY",
      "TEXT_TEMP_CONTROL",
    ],
    focusedInput: null,
    calendarFocus: null,
    shouldFilterFavorites: false,
    displayedItems: 12,
    minItemHeight: 95,
    labelShippingType: [],
    labelPols: [],
    labelPods: [],
    labelConsignees: [],
    labelShippers: [],
    shouldShowFreightModeFilter:
      this.InitialFilters.shouldShowFreightModeFilter,
    memoModal: { open: false, type: WebNoteType.EXTERNAL, jobReference: "" },
    switchCompanyModal: { open: false, booking: null },
    selectedBookings: [],
    actionMenuJobRef: "",
    loadingSwitchCompany: false,
  };

  addLabelShippingType = (label: Label) => {
    const labels = this.state.labelShippingType;
    labels.push(label);
    this.setState({
      labelShippingType: labels,
    });
  };

  addLabelPol = (label: Label) => {
    const labels = this.state.labelPols;
    labels.push(label);
    this.setState({
      labelPols: labels,
    });
  };

  addLabelShipper = (label: Label) => {
    const labels = this.state.labelShippers;
    labels.push(label);
    this.setState({
      labelShippers: labels,
    });
  };

  addLabelConsignee = (label: Label) => {
    const labels = this.state.labelConsignees;
    labels.push(label);
    this.setState({
      labelConsignees: labels,
    });
  };

  addLabelPod = (label: Label) => {
    const labels = this.state.labelPods;
    labels.push(label);
    this.setState({
      labelPods: labels,
    });
  };

  removeLabelShippingType = (key: string) => {
    this.setState({
      labelShippingType: this.state.labelShippingType.filter(
        (label) => label.key !== key
      ),
    });
  };

  removeLabelPol = (key: string) => {
    this.setState({
      labelPols: this.state.labelPols.filter((label) => label.key !== key),
    });
  };

  removeLabelPod = (key: string) => {
    this.setState({
      labelPods: this.state.labelPods.filter((label) => label.key !== key),
    });
  };

  removeLabelShipper = (key: string) => {
    this.setState({
      labelShippers: this.state.labelShippers.filter(
        (label) => label.key !== key
      ),
    });
  };

  removeLabelConsignee = (key: string) => {
    this.setState({
      labelConsignees: this.state.labelConsignees.filter(
        (label) => label.key !== key
      ),
    });
  };

  renderYearOptions = () => {
    const optionsArray = [];
    for (let x = 0; x < 5; x++) {
      optionsArray[x] = (
        <option value={moment().year() - x + 1}>
          {moment().year() - x + 1}
        </option>
      );
    }
    return optionsArray;
  };

  scrollTop() {
    if (this.props.bookings.length > 0)
      document.getElementsByClassName("List")[0].scrollTop -= 999999;
  }

  async componentDidMount() {
    const filterWebSettings = this.props.userProfile.WebSettings.filter(
      (websetting) => websetting.Field === "ShipmentListPage"
    )[0];
    if (filterWebSettings) {
      const WebSettingsFilters: {
        startDate: string;
        endDate: string;
        filterBySelectedCompany?: boolean;
      } = JSON.parse(filterWebSettings.StringValue);
      const filters = this.state.filters;
      filters.startDate = WebSettingsFilters.startDate
        ? moment(WebSettingsFilters.startDate)
        : this.InitialFilters.startDate;
      filters.endDate = WebSettingsFilters.endDate
        ? moment(WebSettingsFilters.endDate)
        : this.InitialFilters.endDate;
      filters.partnerCode =
        WebSettingsFilters.filterBySelectedCompany === false
          ? ""
          : this.InitialFilters.partnerCode;
    }

    // Triggers recalculation for virtual scroll on a resize event.
    window.addEventListener("resize", this.updateDimensions);
    this.virtualScrollPropsCalc(window.innerWidth, window.innerHeight);

    document.title = titlePrefix + i18n.t("MENU_TRACE_BOOKINGS");
    await this.props.fetchBookingList(this.state.filters);
    this.props.getFavoriteBookings();

    this.setState({
      shouldShowFreightModeFilter: UserUtilities.userHasAccessToRoles(
        this.props.userProfile,
        [UserRole.SHIP, UserRole.AIR]
      ),
    });
  }

  async componentDidUpdate(prevProps: { userProfile?: UserProfileViewModel }) {
    if (
      prevProps.userProfile &&
      this.props.userProfile.SelectedCompany.PartnerCode !==
        prevProps.userProfile.SelectedCompany.PartnerCode
    ) {
      if (this.state.filters.partnerCode) {
        this.onFilter(
          nameof<BookingFilters>((item) => item.partnerCode),
          i18next.t("LABEL_COMPANY"),
          this.props.userProfile.SelectedCompany.PartnerCode,
          true
        );
      }
    }
  }

  /** Fixes calculation for virtual scroll based on a resize event */
  virtualScrollPropsCalc(width: number, height: number) {
    if (window.innerWidth > 767) {
      this.setState({ minItemHeight: 95 });
      this.setState({ displayedItems: 12 });
    } else {
      this.setState({ minItemHeight: 218 });
      this.setState({ displayedItems: 6 });
    }
  }

  updateDimensions = () => {
    this.virtualScrollPropsCalc(window.innerWidth, window.innerHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  /** END */

  onFilter = async (
    key: string,
    text: string,
    value:
      | string
      | Date
      | boolean
      | string[]
      | object
      | moment.Moment
      | undefined
      | null,
    update = false,
    serverSide = true
  ) => {
    this.scrollTop();

    this.state.filters[key] = value;

    this.setState(
      {
        filters: this.state.filters,
      },
      async () => {
        this.setState({
          labels: onAdd(this.state.labels, this.state.filters[key], key, text),
        });
        update && serverSide
          ? await this.props.fetchBookingList(this.state.filters)
          : !serverSide
          ? await this.props.fetchBookingListFrontEnd(
              this.state.filters,
              this.props.bookingsAll
            )
          : null;
      }
    );
  };

  onLabelRemove = (key: string) => {
    this.setState(
      {
        labels: onRemove(this.state.labels, key),
      },
      () => {
        if (
          [
            nameof<BookingFilters>((item) => item.freightModes),
            nameof<BookingFilters>((item) => item.transportPaths),
            nameof<BookingFilters>((item) => item.statuses),
            nameof<BookingFilters>((item) => item.isCustomsCleared),
            nameof<BookingFilters>((item) => item.isHazardous),
            nameof<BookingFilters>((item) => item.bookingTypes),
          ].includes(key.toString())
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.POL)
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
          this.onFilter(
            nameof<BookingFilters>((item) => item.POL),
            "",
            [],
            true,
            false
          );
          this.props.changePolOptions(this.props.polOptions, []);
          this.setState({ labelPols: [] });
        } else if (
          key.toString() === nameof<BookingFilters>((item) => item.shippingType)
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
          this.onFilter(
            nameof<BookingFilters>((item) => item.shippingType),
            "",
            [],
            true,
            false
          );
          this.setState({ labelShippingType: [] });
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.POD)
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
          this.onFilter(
            nameof<BookingFilters>((item) => item.POD),
            "",
            [],
            true,
            false
          );
          this.props.changePodOptions(this.props.podOptions, []);
          this.setState({ labelPods: [] });
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.shippers)
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
          this.onFilter(
            nameof<BookingFilters>((item) => item.shippers),
            "",
            [],
            true,
            false
          );
          this.props.changePolOptions(this.props.shipperOptions, []);
          this.setState({ labelShippers: [] });
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.consignees)
        ) {
          this.onFilter(key, "", this.InitialFilters[key], true, false);
          this.onFilter(
            nameof<BookingFilters>((item) => item.consignees),
            "",
            [],
            true,
            false
          );
          this.props.changePolOptions(this.props.consigneeOptions, []);
          this.setState({ labelConsignees: [] });
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.searchString)
        ) {
          const _filter = this.state.filters;
          _filter.searchTypes = [];
          this.setState({ filters: _filter });
          this.onFilter(key, "", this.InitialFilters[key], true);
        } else if (
          key.toString() == nameof<BookingFilters>((item) => item.endDate)
        ) {
          this.handleDateRangeSelect(DateRangeOptions.INIT);
          this.setState({
            selectedDateRangeOption: DateRangeOptions.CUSTOM_DATE,
          });
        } else {
          this.onFilter(key, "", this.InitialFilters[key], true);
        }
      }
    );
  };

  handleFocusChange = (focused: any) => {
    this.setState(() => ({ calendarFocus: focused }));
  };

  handleDateRangeSelect(value: string) {
    switch (value) {
      case DateRangeOptions.INIT:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.INIT },
          () =>
            this.handleDateChange(
              moment().subtract(2, "weeks"),
              moment().add(2, "weeks")
            )
        );
      case DateRangeOptions.NEXT_MONTH:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.NEXT_MONTH },
          () => this.handleDateChange(moment(), moment().add(1, "months"))
        );
      case DateRangeOptions.NEXT_TWO_WEEKS:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.NEXT_TWO_WEEKS },
          () => this.handleDateChange(moment(), moment().add(2, "weeks"))
        );
      case DateRangeOptions.LAST_WEEK:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.LAST_WEEK },
          () => this.handleDateChange(moment().subtract(1, "weeks"), moment())
        );
      case DateRangeOptions.LAST_TWO_WEEKS:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.LAST_TWO_WEEKS },
          () => this.handleDateChange(moment().subtract(2, "weeks"), moment())
        );
      case DateRangeOptions.LAST_MONTH:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.LAST_MONTH },
          () => this.handleDateChange(moment().subtract(1, "months"), moment())
        );
      case DateRangeOptions.LAST_THREE_MONTHS:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.LAST_THREE_MONTHS },
          () => this.handleDateChange(moment().subtract(3, "months"), moment())
        );
      case DateRangeOptions.LAST_YEAR:
        return this.setState(
          { selectedDateRangeOption: DateRangeOptions.LAST_YEAR },
          () => this.handleDateChange(moment().subtract(1, "years"), moment())
        );
      case DateRangeOptions.CUSTOM_DATE:
        return this.setState({
          selectedDateRangeOption: DateRangeOptions.CUSTOM_DATE,
        });
      default:
        return;
    }
  }

  handleDateChange = (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    filterTitle: string = i18n.t("LABEL_DATE_RANGE"),
    shouldRender = true,
    revertToCustom = false
  ) => {
    if (revertToCustom) {
      this.setState({
        selectedDateRangeOption: DateRangeOptions.CUSTOM_DATE,
      });
    }
    if (endDate != this.state.filters.endDate || shouldRender) {
      startDate
        ? this.onFilter(
            nameof<BookingFilters>((item) => item.startDate),
            filterTitle,
            startDate
          )
        : null;
      endDate
        ? this.onFilter(
            nameof<BookingFilters>((item) => item.endDate),
            filterTitle,
            endDate,
            true
          )
        : null;

      if (startDate && endDate) {
        this.updateWebSettings();
      }
    }

    const _filters = this.state.filters;
    _filters.startDate = startDate;
    _filters.endDate = endDate;
    this.setState({ filters: _filters });
  };

  updateWebSettings = () => {
    const { startDate, endDate, partnerCode } = this.state.filters;
    if (!startDate || !endDate) return;
    const difference = moment.duration(endDate.diff(startDate));
    if (difference.asMonths() <= 12) {
      this.props.updateWebSetting({
        UserID: this.props.userProfile.Access.UserID,
        Field: "ShipmentListPage",
        Site: null,
        StringValue: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          filterBySelectedCompany: partnerCode ? true : false,
        }),
        SubSys: 1049,
      });
    }
  };

  openMemoModal = (memoType: number, jobReference: string) => {
    const memoModal: { open: boolean; type: number; jobReference: string } = {
      open: true,
      type: memoType,
      jobReference,
    };
    this.setState({ memoModal });
  };

  closeMemoModal = () => {
    const memoModal = this.state.memoModal;
    memoModal.open = false;
    this.setState({ memoModal });
  };

  openSwitchCompanyModal = (booking: Booking) => {
    const switchCompanyModal: { open: boolean; booking: Booking } = {
      open: true,
      booking: booking,
    };
    this.setState({ switchCompanyModal });
  };

  closeSwitchCompanyModal = () => {
    const switchCompanyModal = this.state.switchCompanyModal;
    switchCompanyModal.open = false;
    this.setState({ switchCompanyModal });
  };

  openActionMenu = (jobReference: string) => {
    let actionMenuJobRef = this.state.actionMenuJobRef;
    actionMenuJobRef = jobReference;
    this.setState({ actionMenuJobRef });
  };

  closeActionMenu = () => {
    const actionMenuJobRef = "";
    this.setState({ actionMenuJobRef });
  };

  selectedBookingsHaveCleared = (listCleared: any) => {
    const bookings = this.state.selectedBookings;
    const customsNotCleared = [];
    const customsCleared = [];

    for (let i = 0; i < bookings.length; i++) {
      const temp = this.props.bookings.find(
        (booking) => booking.JobReference === bookings[i]
      );
      if (temp?.IsCustomsCleared === false) {
        customsNotCleared.push(temp.JobReference);
      } else {
        customsCleared.push(temp?.JobReference);
      }
    }
    if (listCleared === true) {
      return customsCleared;
    } else {
      return customsNotCleared;
    }
  };

  openBillOfLadingDocument = async (jobReference: string) => {
    try {
      const result = await SingleBookingService.getBookingDocuments(
        jobReference
      );

      for (const item of result) {
        if (
          (item.Document_Code === "HBL" || item.Document_Code === "HAWB") &&
          item.Document_Path
        ) {
          window.open("http://" + item.Document_Path);
        }
      }
    } catch (error) {
      console.error("Error retrieving bill of lading document", error);
    }
  };

  handleSwitchCompany = async (
    booking: Booking | null,
    partnerCode: string
  ) => {
    this.setState({ loadingSwitchCompany: true });
    UserService.changeUserCompany(partnerCode)
      .then(() => {
        openTabOrWindow(
          `/shipments/${
            booking?.FreightMode === FreightMode.AI
              ? "copycreateairfreightbooking"
              : "copycreateseafreightbooking"
          }/${booking?.JobReference}`
        );
      })
      .finally(() => {
        this.setState({ loadingSwitchCompany: false });
        this.closeSwitchCompanyModal();
        window.location.reload();
      });
  };

  getActionOptions = async (booking: Booking): Promise<Action[]> => {
    return await BookingService.getBookingActions(
      booking.JobReference,
      booking.Status,
      booking.IsCustomsCleared
    ).then((results: BookingAction[]) => {
      return results.map((result) => {
        // For webnotes open a modal
        if (
          [ActionTypeMap.WEBNOTE, ActionTypeMap.INNERWEBNOTE].includes(
            result.TypeMap
          )
        ) {
          return {
            ID: result.TypeID,
            Link: "",
            onClick: () =>
              this.openMemoModal(
                result.TypeMap === ActionTypeMap.WEBNOTE
                  ? WebNoteType.EXTERNAL
                  : WebNoteType.INTERNAL,
                booking.JobReference
              ),
            OpenInWindow: true,
            Translation: `LABEL_ACTION_${result.TypeMap}`,
            Section: result.Section,
          };
        }

        // Bill of lading
        if (result.TypeMap === ActionTypeMap.BILLOFLADING) {
          return {
            ID: result.TypeID,
            Link: "",
            onClick: () => this.openBillOfLadingDocument(booking.JobReference),
            OpenInWindow: true,
            Translation: `LABEL_ACTION_${result.TypeMap}`,
            Section: result.Section,
          };
        }

        if (result.TypeMap === ActionTypeMap.ALLDOCS) {
          return {
            ID: result.TypeID,
            Link: "",
            onClick: () =>
              openTabOrWindow(
                `/singleBooking/${booking.FreightMode}/${booking.JobReference}#Documents`
              ),
            OpenInWindow: true,
            Translation: `LABEL_ACTION_${result.TypeMap}`,
            Section: result.Section,
          };
        }
        if (result.TypeMap === ActionTypeMap.INVOICES) {
          return {
            ID: result.TypeID,
            Link: "",
            onClick: () =>
              openTabOrWindow(
                `/singleBooking/${booking.FreightMode}/${booking.JobReference}#Invoices`
              ),
            OpenInWindow: true,
            Translation: `LABEL_ACTION_${result.TypeMap}`,
            Section: result.Section,
          };
        }
        if (result.TypeMap === ActionTypeMap.COPYBOOKING) {
          return {
            ID: result.TypeID,
            Link: "",
            onClick: () => {
              if (
                booking.Consignee.PartnerCode ===
                  this.props.userProfile.SelectedCompany.PartnerCode ||
                booking.Shipper.PartnerCode ===
                  this.props.userProfile.SelectedCompany.PartnerCode
              ) {
                openTabOrWindow(
                  `/shipments/${
                    booking.FreightMode === FreightMode.AI
                      ? "copycreateairfreightbooking"
                      : "copycreateseafreightbooking"
                  }/${booking.JobReference}`
                );
              } else {
                this.openSwitchCompanyModal(booking);
              }
            },
            OpenInWindow: true,
            Translation: `LABEL_ACTION_${result.TypeMap}`,
            Section: result.Section,
          };
        }
        return {
          ID: result.TypeID,
          Link:
            result.Site +
            booking.JobReference +
            (result.TypeMap === ActionTypeMap.ALLDOCS
              ? `&Partner=${booking.Consignee.PartnerCode}`
              : ""),
          OpenInWindow: true,
          Translation: `LABEL_ACTION_${result.TypeMap}`,
          Section: result.Section,
        };
      });
    });
  };

  getShippingType(shippingType: string) {
    const shippingTypes = shippingType.split(" ");
    const collection = shippingTypes[0] === "L" ? "LCL" : "FCL";
    const delivery = shippingTypes[1] === "L" ? "LCL" : "FCL";
    return collection + " - " + delivery;
  }

  render() {
    const { userProfile } = this.props;
    let { bookings } = this.props;
    const {
      filters,
      labels,
      sortOptions,
      dateRangeOptions,
      tableHeaders,
      shouldFilterFavorites,
      memoModal,
      switchCompanyModal,
      actionMenuJobRef,
    } = this.state;
    if (shouldFilterFavorites) {
      bookings = bookings.filter((booking) =>
        this.props.favoriteBookings.includes(booking.JobReference)
      );
    }

    return (
      <Translation>
        {(t) => (
          <main
            className={`overflow-hidden static h-auto w-full mx-auto flex content-center flex-grow flex-col`}
          >
            <SwitchCompanyModal
              userSelectedCompany={userProfile.SelectedCompany}
              shipper={switchCompanyModal.booking?.Shipper}
              consignee={switchCompanyModal.booking?.Consignee}
              booking={switchCompanyModal.booking}
              open={switchCompanyModal.open}
              closeModal={() => this.closeSwitchCompanyModal()}
              onSwitchCompany={this.handleSwitchCompany}
              loadingSwitchCompany={this.state.loadingSwitchCompany}
            ></SwitchCompanyModal>
            <WebNoteModal
              closeIcon
              closeOnOutsideClick
              type={memoModal.type}
              open={memoModal.open}
              jobReference={memoModal.jobReference}
              isNote={false}
              closeModal={() => this.closeMemoModal()}
            ></WebNoteModal>
            <ListFilterLayout
              showFilterSearchButtons={true}
              mobileFilter={false}
            >
              <>
                <div className="listFilterLayout__content relative w-auto lg:w-full flex-1 p-5">
                  {this.props.loadingBookings ? (
                    <span className="list-loader"></span>
                  ) : null}

                  <div className="listFilterLayout__content__header border-b flex justify-between md:block mr-5 border-jonar-orange">
                    <div className="listFilterLayout__content__header__upper flex justify-between">
                      <div>
                        <Icon
                          className="inline-block h-8 w-auto mr-2 stroke-current"
                          name="jonarArrow"
                        />
                        <h1 className="inline-block text-1xl font-bold text-jonar-blue uppercase leading-7 align-bottom">
                          {t("LABEL_TRACE")}
                        </h1>
                        <span className="text-base-icon pl-4 ml-6 md:ml-auto">
                          {this.props.loadingBookingsInit
                            ? `${bookings.length} ${t("TEXT_SHIPMENTS_FOUND", {
                                count: bookings.length,
                              })}`
                            : null}
                        </span>
                      </div>
                      <div>
                        <IconButton
                          disabled={false}
                          onClick={() =>
                            this.props.getBookingsInExcel(this.props.bookings)
                          }
                          active={this.props.isFetchingExcelBookings}
                          className={`mb-5 ml-5 md:mb-auto xl:m-auto`}
                        >
                          <FiDownload className="text-base mr-2" />
                          <span className="pt-1">{t("LABEL_EXCEL")}</span>
                        </IconButton>
                      </div>
                    </div>
                    <div className="listFilterLayout__content__header__lower pr-3 lg:pr-0 cursor-pointer flex justify-between items-center mb-1 py-2">
                      <div className="hidden md:block">
                        <div className="flex items-center">
                          <span className="inline-block pt-1">
                            {t("LABEL_SORT_BY")}
                          </span>
                          <Select
                            options={sortOptions}
                            value={this.state.filters.sort.sortBy}
                            className="text-sm mx-2 w-border pt-4px pb-2px"
                            name="sortBy"
                            sortDirection={true}
                            isDesc={filters.sort.isDesc}
                            onSortClick={() =>
                              this.onFilter(
                                nameof<BookingFilters>((item) => item.sort),
                                "",
                                {
                                  sortBy: filters.sort.sortBy,
                                  isDesc: !filters.sort.isDesc,
                                },
                                true,
                                false
                              )
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                              this.onFilter(
                                nameof<BookingFilters>((item) => item.sort),
                                "",
                                {
                                  sortBy: event.target.value,
                                  isDesc: filters.sort.isDesc,
                                },
                                true,
                                false
                              )
                            }
                          />
                          <>
                            {labels.length > 0 ? (
                              <LabelList
                                className="ml-6"
                                labelList={labels}
                                onClick={this.onLabelRemove}
                              />
                            ) : null}
                          </>
                        </div>
                      </div>
                      <div>
                        <div className="flex-row flex-1 flex flex-wrap justify-end leading-none">
                          <div>
                            <CustomIcon
                              className={`svg-jonar-blue svg-lg svg-dark translate-y-4 transform translate-x-3 sm:-translate-y-2 sm:-translate-x-2 md:mr-0 md:-translate-x-2 lg:translate-x-0 lg:-m-auto${
                                this.state.shouldFilterFavorites
                                  ? "svg-fill-jonar-blue"
                                  : ""
                              }`}
                              iconName="stjarna"
                              onClick={() => {
                                document.getElementsByClassName(
                                  "List"
                                )[0].scrollTop -= 999999;
                                this.setState({
                                  shouldFilterFavorites: !shouldFilterFavorites,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="listFilterLayout__content__list">
                    {bookings.length > 0 ? (
                      <VirtualScroll
                        className="List"
                        minItemHeight={this.state.minItemHeight}
                        totalLength={bookings.length}
                        length={this.state.displayedItems}
                        buffer={4}
                        renderItem={(i: number) => {
                          return (
                            <ListLine
                              key={bookings[i].JobReference}
                              tableHeaders={tableHeaders}
                              togglingFavoriteBooking={false}
                              reference={bookings[i].JobReference}
                              actionMenuJobRef={actionMenuJobRef}
                              openActionMenu={this.openActionMenu}
                              closeActionMenu={this.closeActionMenu}
                              getActionOptions={() =>
                                this.getActionOptions(bookings[i])
                              }
                              isBuyersConsoleBooking={bookings[i].IsBCBooking}
                              showInfo={
                                userProfile.Access.UserType ===
                                UserType.EMPLOYEE
                                  ? bookings[i].InternalNoteCount +
                                      bookings[i].ExternalNoteCount >
                                    0
                                  : bookings[i].ExternalNoteCount > 0
                              }
                              checkBoxChecked={this.state.selectedBookings.includes(
                                bookings[i].JobReference
                              )}
                              onCheckboxCheck={() =>
                                this.setState({
                                  selectedBookings:
                                    ArrayUtilities.toggleValueInArray(
                                      this.state.selectedBookings,
                                      bookings[i].JobReference
                                    ),
                                })
                              }
                            >
                              <ListLineItem
                                className="w-2/5 md:w-auto md:flex-2"
                                preTitle={t("LABEL_BOOKING_REF")}
                                mainTitle={bookings[i].JobReference}
                                secondLineTitle={t("LABEL_TYPE")}
                                secondLine={
                                  <span>
                                    {this.getShippingType(
                                      bookings[i].ShippingType
                                    )}
                                    {bookings[i].FreightMode ==
                                      FreightMode.SH && bookings[i].IsBOL ? (
                                      <>
                                        <span>, </span>
                                        <span className="font-bold text-red-700">
                                          BOL
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                }
                                actionLink={`/singleBooking/${bookings[i].FreightMode}/${bookings[i].JobReference}`}
                                target="_blank"
                                icon={BookingUtilities.getBookingFreightModeIcon(
                                  FreightMode[bookings[i].FreightMode]
                                )}
                                iconClasses="svg-dark"
                              />
                              <ListLineItem
                                className={`w-2/5 md:w-auto md:flex-1-half`}
                                preTitle={t("LABEL_STATUS")}
                                mainTitle={i18n.t(
                                  "BOOKINGSTATUS_" +
                                    (bookings[i].Status
                                      ? BookingStatus[bookings[i].Status]
                                      : "BOOKED")
                                )}
                                icon={BookingUtilities.getStatusIcon(
                                  bookings[i].Status,
                                  FreightMode[bookings[i].FreightMode]
                                )}
                                iconClasses={`${
                                  [
                                    BookingStatus.DELIVERED,
                                    BookingStatus.DELIVERED_EXPORT,
                                    BookingStatus.DELIVERED_POD,
                                    BookingStatus.DELIVERED_TO_DOMESTIC_TRANSPORT,
                                    BookingStatus.DELIVERED_TO_WAREHOUSE,
                                    BookingStatus.PICKED_UP_BY_CUSTOMER,
                                  ].includes(bookings[i].Status)
                                    ? "stroke-current text-green-800"
                                    : "svg-dark"
                                }`}
                                secondLineTitle="ETA"
                                secondLine={moment(bookings[i].ETA).format(
                                  "DD.MM.yyyy"
                                )}
                              />
                              <ListLineItem
                                className="flex-1 hidden xl:block"
                                preTitle={t("LABEL_VOYAGE")}
                                mainTitle={bookings[i].VoyageReference || ""}
                                secondLine={
                                  bookings[i].ConsignmentNumberSimplified
                                }
                              />
                              <ListLineItem
                                className="w-2/5 md:w-auto md:flex-2 pt-5 md:pt-0"
                                preTitle={t("LABEL_A_SHIPPER")}
                                mainTitle={bookings[i].Shipper.Name}
                                secondLine={bookings[i].Shipper.PartnerCode}
                              />
                              <ListLineItem
                                className="w-2/5 md:w-auto md:flex-2 pt-5 md:pt-0"
                                preTitle={t("LABEL_A_CONSIGNEE")}
                                mainTitle={bookings[i].Consignee.Name}
                                secondLine={bookings[i].Consignee.PartnerCode}
                              />
                              {/* <div className="w-1/3 md:w-small-item pt-4 pr-6 text-left md:text-center">
                              <BsHouseDoor className="mx-auto text-lg text-base-icon inline-block md:block" />
                              <span className="text-xs pl-2 md:pl-0 pt-2 inline-block leading-none">
                                A & B
                              </span>
                            </div> */}
                              <div className="flex md:flex-row-reverse mt-2 md:mt-0 md:justify-start justify-around md:w-1/6 w-full">
                                <div
                                  className={`flex-nowrap w-1/3 md:w-small-item md:pr-6 text-center`}
                                >
                                  {bookings[i].IsCustomsCleared ? (
                                    <Tooltip
                                      childClassName="inline-flex"
                                      content={`${t(
                                        "LABEL_CUSTOMSCLEARED"
                                      )}: ${DateUtilities.toDateString(
                                        bookings[i].CustomsClearedDate
                                      )}`}
                                    >
                                      <div>
                                        <CustomIcon
                                          className={`mx-auto svg-lg inline-block md:block stroke-current text-green-800`}
                                          iconName="tollad"
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <CustomIcon
                                      className={`mx-auto svg-lg inline-block md:block stroke-current text-red-800`}
                                      iconName="oTollad"
                                    />
                                  )}
                                  <span className="flex justify-center text-xs md:inline-block leading-none">
                                    {bookings[i].IsCustomsCleared ? (
                                      <>{t("LABEL_CUSTOMSCLEARED")}</>
                                    ) : (
                                      <>{t("LABEL_NOT_CUSTOMSCLEARED")}</>
                                    )}
                                  </span>
                                </div>
                                {bookings[i].IsBCBooking ||
                                (bookings[i].ShipReference !== null &&
                                  bookings[i].ShipReference !== "") ? (
                                  <>
                                    {bookings[i].IsBCBooking ? (
                                      <div className="flex-nowrap w-1/3 md:w-small-item md:pt-px md:pr-6 text-center">
                                        <div>
                                          <CustomIcon
                                            className="mx-auto svg-lg md:block inline-block"
                                            iconName="gripHorizontal"
                                          />
                                        </div>
                                        <span className="flex text-xs md:inline-block leading-none md:pt-1.5">
                                          {t("LABEL_BUYERS_CONSOL")}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="flex-nowrap w-1/3 md:w-small-item md:pt-px md:pr-6 text-center">
                                        <CustomIcon
                                          className="mx-auto svg-lg md:block inline-block"
                                          iconName="diagramNested"
                                        />
                                        <span className="flex text-xs md:inline-block leading-none md:pt-1.5">
                                          {t("LABEL_BUYERS_CONSOL_SUB")}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </ListLine>
                          );
                        }}
                      />
                    ) : this.props.loadingBookings ? null : (
                      <div className="text-base-icon text-3xl text-center m-20 md:m-60 select-none opacity-50">
                        {t("WARNING_NO_DATA_FOUND")}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  // TODO: This makes sure that the virtual scroll works as should. Possibly we can fix this more elegantly later.
                  onFocus={() => this.scrollTop()}
                  id="dpFilterPanel"
                  className={`listFilterLayout__filter lg:flex-initial bg-lighter-gray p-5 absolute xl:relative z-20 right-0 hidden xl:block ${
                    this.state.mobileFilter
                      ? "showFilter animate-fade-in min-h-full h-auto xl:h-auto"
                      : ""
                  }`}
                >
                  <button
                    className="absolute transition duration-300 top-4r inline-block xl:hidden right-5r bg-lighter-gray border border-current text-jonar-blue hover:bg-jonar-blue hover:text-white p-1"
                    onClick={() => this.setState({ mobileFilter: false })}
                  >
                    <FiX className="text-xl" />
                  </button>
                  <h2 className="text-lg font-bold text-jonar-blue uppercase leading-7">
                    {t("LABEL_FILTER_SEARCH")}
                  </h2>
                  <div className="flex justify-between md:hidden pt-2">
                    <div>
                      <span>Raða eftir</span>
                      <Select
                        options={sortOptions}
                        className="text-sm ml-2"
                        name="sortBy"
                        sortDirection={true}
                        isDesc={filters.sort.isDesc}
                        onSortClick={() =>
                          this.onFilter(
                            nameof<BookingFilters>((item) => item.sort),
                            "",
                            {
                              sortBy: filters.sort.sortBy,
                              isDesc: !filters.sort.isDesc,
                            },
                            true
                          )
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) =>
                          this.onFilter(
                            nameof<BookingFilters>((item) => item.sort),
                            "",
                            {
                              sortBy: event.target.value,
                              isDesc: filters.sort.isDesc,
                            },
                            true
                          )
                        }
                      />
                    </div>
                    <div>
                      <IconButton
                        disabled={false}
                        onClick={() =>
                          this.props.getBookingsInExcel(this.props.bookings)
                        }
                        active={this.props.isFetchingExcelBookings}
                        className={`excel-button-mobile`}
                      >
                        <FiDownload className="text-base mr-2" />
                        <span className="pt-1">Excel</span>
                      </IconButton>
                    </div>
                  </div>
                  <div className="sm:block md:hidden pt-4">
                    <LabelList
                      className=""
                      labelList={labels}
                      onClick={this.onLabelRemove}
                    />
                  </div>
                  <Accordion accTitle={t("LABEL_SEARCH")} openAccordion={true}>
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_BOOKING_REFERENCE")}
                      type="checkbox"
                      checked={filters.searchTypes.includes(
                        nameof<Booking>((item) => item.JobReference)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchTypes),
                          "",
                          ArrayUtilities.toggleValueInArray(
                            filters.searchTypes,
                            nameof<Booking>((item) => item.JobReference)
                          ),
                          this.state.filters.searchString.length > 0
                            ? true
                            : false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_VOYAGE_REFERENCE")}
                      type="checkbox"
                      checked={filters.searchTypes.includes(
                        nameof<Booking>((item) => item.VoyageReference)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchTypes),
                          "",
                          ArrayUtilities.toggleValueInArray(
                            filters.searchTypes,
                            nameof<Booking>((item) => item.VoyageReference)
                          ),
                          this.state.filters.searchString.length > 0
                            ? true
                            : false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_BOL_NUMBER")}
                      type="checkbox"
                      checked={filters.searchTypes.includes(
                        nameof<Booking>((item) => item.BOLNumber)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchTypes),
                          "",
                          ArrayUtilities.toggleValueInArray(
                            filters.searchTypes,
                            nameof<Booking>((item) => item.BOLNumber)
                          ),
                          this.state.filters.searchString.length > 0
                            ? true
                            : false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_CONSIGNMENT_NUMBER")}
                      type="checkbox"
                      checked={filters.searchTypes.includes(
                        nameof<Booking>((item) => item.ConsignmentNumber)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchTypes),
                          "",
                          ArrayUtilities.toggleValueInArray(
                            filters.searchTypes,
                            nameof<Booking>((item) => item.ConsignmentNumber)
                          ),
                          this.state.filters.searchString.length > 0
                            ? true
                            : false
                        );
                      }}
                    />
                    <Search
                      className="w-border mt-2 h-8"
                      isSearching={this.props.loadingBookings}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchString),
                          t("LABEL_SEARCH"),
                          event.target.value,
                          false
                        )
                      }
                      onKeyPress={(event: React.KeyboardEvent) => {
                        if (event.key === "Enter") {
                          this.onFilter(
                            nameof<BookingFilters>((item) => item.searchString),
                            t("LABEL_SEARCH"),
                            this.state.filters.searchString,
                            true
                          );
                        }
                      }}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchString),
                          t("LABEL_SEARCH"),
                          event.target.value,
                          true
                        );
                      }}
                      value={this.state.filters.searchString}
                      onSearch={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.searchString),
                          t("LABEL_SEARCH"),
                          this.state.filters.searchString,
                          true
                        );
                      }}
                      icon="search"
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_DATE_RANGE")}
                    openAccordion={true}
                  >
                    <div className="datePickerContainerFilter flex border border-base rounded h-30px">
                      <div
                        className={`w-3/5 bg-white border-transparent focus:border-transparent focus:ring-0 ${stylesDatePicker.dateRangePickerOver}`}
                      >
                        <DateRangePicker
                          startDate={this.state.filters.startDate} // momentPropTypes.momentObj or null,
                          startDateId="timePeriodStartDate" // PropTypes.string.isRequired,
                          endDate={this.state.filters.endDate} // momentPropTypes.momentObj or null,
                          endDateId="timePeriodEndDate" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) =>
                            this.handleDateChange(
                              startDate,
                              endDate,
                              t("LABEL_DATE_RANGE"),
                              false,
                              true
                            )
                          } // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                          } // PropTypes.func.isRequired,
                          isOutsideRange={(day) =>
                            !isInclusivelyBeforeDay(
                              day,
                              moment().add(1, "years")
                            )
                          }
                          noBorder={true}
                          numberOfMonths={1}
                          displayFormat="DD.MM.YYYY"
                          renderMonthElement={({
                            month,
                            onMonthSelect,
                            onYearSelect,
                          }) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <select
                                  className="border-transparent focus:border-transparent focus:ring-0"
                                  value={month.month()}
                                  onChange={(e) => {
                                    onMonthSelect(month, e.target.value);
                                  }}
                                >
                                  {moment.months().map((label, value) => (
                                    <option value={value} key={value}>
                                      {t("LABEL_MONTH_" + toUpper(label))}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <select
                                  className="border-transparent focus:border-transparent focus:ring-0"
                                  value={month.year()}
                                  onChange={(e) => {
                                    onYearSelect(month, e.target.value);
                                  }}
                                >
                                  {this.renderYearOptions()}
                                </select>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="w-2/5">
                        <Select
                          className={`w-full text-sm rounded-none date-range-selector ${stylesShipments.dateRangeSelector}`}
                          name="sortBy"
                          sortDirection={false}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => this.handleDateRangeSelect(event.target.value)}
                          options={dateRangeOptions}
                          value={this.state.selectedDateRangeOption}
                        />
                      </div>
                    </div>
                  </Accordion>
                  <Accordion accTitle={t("LABEL_COMPANY")} openAccordion={true}>
                    <Checkbox
                      className="pb-1"
                      value={userProfile.SelectedCompany.PartnerCode}
                      label={t("LABEL_CHOSEN_COMPANY")}
                      type="radio"
                      checked={
                        filters.partnerCode ===
                        userProfile.SelectedCompany.PartnerCode
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.partnerCode),
                          t("LABEL_COMPANY"),
                          event.target.value,
                          true
                        );
                        this.updateWebSettings();
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      value=""
                      label={
                        userProfile.Access.UserType === UserType.EMPLOYEE
                          ? t("LABEL_ALL_COMPANIES")
                          : t("LABEL_MY_COMPANIES")
                      }
                      type="radio"
                      checked={
                        filters.partnerCode !==
                        userProfile.SelectedCompany.PartnerCode
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.partnerCode),
                          t("LABEL_COMPANY"),
                          event.target.value,
                          true
                        );
                        this.updateWebSettings();
                      }}
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_BOOKING_TYPE")}
                    openAccordion={true}
                  >
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_REGULAR_BOOKING")}
                      type="checkbox"
                      checked={filters.bookingTypes.includes(
                        BookingTypes.NORMAL
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.bookingTypes),
                          t("LABEL_BOOKING_TYPE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.bookingTypes,
                            BookingTypes.NORMAL
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_BUYERS_CONSOL")}
                      type="checkbox"
                      checked={filters.bookingTypes.includes(
                        BookingTypes.BUYERSCONSOL
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.bookingTypes),
                          t("LABEL_BOOKING_TYPE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.bookingTypes,
                            BookingTypes.BUYERSCONSOL
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_BUYERS_CONSOL_SUB")}
                      type="checkbox"
                      checked={filters.bookingTypes.includes(
                        BookingTypes.SUBBOOKING
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.bookingTypes),
                          t("LABEL_BOOKING_TYPE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.bookingTypes,
                            BookingTypes.SUBBOOKING
                          ),
                          true,
                          false
                        );
                      }}
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_BOOKING_STATUS")}
                    openAccordion={true}
                  >
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_BOOKED")}
                      type="checkbox"
                      checked={filters.statuses.includes(BookingStatus.BOOKED)}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.BOOKED
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_COLLECTED")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.COLLECTED
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.COLLECTED
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_PICKUP_SCHEDULED")}
                      type="checkbox"
                      checked={filters.statuses.some((s) =>
                        [
                          BookingStatus.PICKUP_SCHEDULED,
                          BookingStatus.PICKUP_REGISTERED,
                        ].includes(s)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(filters.statuses, [
                            BookingStatus.PICKUP_SCHEDULED,
                            BookingStatus.PICKUP_REGISTERED,
                          ]),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_IN_TRANSIT")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.IN_TRANSIT
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.IN_TRANSIT
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_DISCHARGED")}
                      type="checkbox"
                      checked={filters.statuses.some((s) =>
                        [
                          BookingStatus.DISCHARGED,
                          BookingStatus.SHIPMENT_UNLOADED,
                        ].includes(s)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(filters.statuses, [
                            BookingStatus.DISCHARGED,
                            BookingStatus.SHIPMENT_UNLOADED,
                          ]),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_IN_WAREHOUSE_RECEPTION")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.IN_WAREHOUSE_RECEPTION
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.IN_WAREHOUSE_RECEPTION
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_ARRIVED_IN_POD")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.ARRIVED_IN_POD
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.ARRIVED_IN_POD
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      value="company-3"
                      name="company"
                      label={t("BOOKINGSTATUS_BEING_PICKED_AT_WAREHOUSE")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.BEING_PICKED_AT_WAREHOUSE
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.BEING_PICKED_AT_WAREHOUSE
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_READY_FOR_DELIVERY")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.READY_FOR_DELIVERY
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.READY_FOR_DELIVERY
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_LOADED_ON_TRUCK")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.LOADED_ON_TRUCK
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.LOADED_ON_TRUCK
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_LOAD_ON_BOARD")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.LOAD_ON_BOARD
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.LOAD_ON_BOARD
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_DELIVERY_BOOKED")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.DELIVERY_BOOKED
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.DELIVERY_BOOKED
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("BOOKINGSTATUS_OUT_FOR_DELIVERY")}
                      type="checkbox"
                      checked={filters.statuses.includes(
                        BookingStatus.OUT_FOR_DELIVERY
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(
                            filters.statuses,
                            BookingStatus.OUT_FOR_DELIVERY
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      value="company-1"
                      name="company"
                      label={t("BOOKINGSTATUS_DELIVERED")}
                      type="checkbox"
                      checked={filters.statuses.some((s) =>
                        [
                          BookingStatus.DELIVERED,
                          BookingStatus.DELIVERED_POD,
                          BookingStatus.DELIVERED_TO_DOMESTIC_TRANSPORT,
                          BookingStatus.DELIVERED_TO_WAREHOUSE,
                          BookingStatus.PICKED_UP_BY_CUSTOMER,
                          BookingStatus.DELIVERED_EXPORT,
                        ].includes(s)
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.statuses),
                          t("LABEL_BOOKING_STATUS"),
                          ArrayUtilities.toggleValueInArray(filters.statuses, [
                            BookingStatus.DELIVERED,
                            BookingStatus.DELIVERED_POD,
                            BookingStatus.DELIVERED_TO_DOMESTIC_TRANSPORT,
                            BookingStatus.DELIVERED_TO_WAREHOUSE,
                            BookingStatus.PICKED_UP_BY_CUSTOMER,
                            BookingStatus.DELIVERED_EXPORT,
                          ]),
                          true,
                          false
                        );
                      }}
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_SHIPPING_TYPE")}
                    openAccordion={false}
                  >
                    <DropdownSearch
                      labelClassName={"sm-light-tag block float-left mb-1"}
                      className="w-full h-f p-0"
                      containerClassName="float-left width-full contents h-12"
                      onSelect={(value: string) => {
                        const text = this.props.shippingTypeOptions.find(
                          (x) => x.value === value
                        )?.item;

                        this.addLabelShippingType({ text: text, key: value });

                        if (!filters.shippingType) {
                          filters.shippingType = [];
                        }
                        const selectedshippingTypes =
                          ArrayUtilities.addValueToArray(
                            filters.shippingType,
                            value
                          );

                        this.props.changeShippingTypeOptions(
                          this.props.shippingTypeOptions,
                          selectedshippingTypes
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.shippingType),
                          t("LABEL_SHIPPING_TYPE"),
                          selectedshippingTypes,
                          true,
                          false
                        );
                      }}
                      onLabelRemove={(value: string) => {
                        this.removeLabelShippingType(value);

                        const selectedshippingTypes =
                          ArrayUtilities.removeValueFromArray(
                            filters.shippingType,
                            value
                          );

                        this.props.changeShippingTypeOptions(
                          this.props.shippingTypeOptions,
                          selectedshippingTypes
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.shippingType),
                          t("LABEL_SHIPPING_TYPE"),
                          selectedshippingTypes.length > 0
                            ? selectedshippingTypes
                            : null,
                          true,
                          false
                        );
                      }}
                      hasSearch
                      multiSelect
                      options={this.props.displayedShippingTypeOptions}
                      labels={this.state.labelShippingType}
                    />
                  </Accordion>
                  <Accordion accTitle={t("LABEL_POL")} openAccordion={false}>
                    <DropdownSearch
                      labelClassName={"sm-light-tag block float-left mb-1"}
                      className="w-full standard-input"
                      containerClassName="float-left width-full contents"
                      onSelect={(value: string) => {
                        const text = this.props.polOptions.find(
                          (x) => x.value == value
                        )?.item;

                        this.addLabelPol({ text: text, key: value });

                        if (!filters.POL) {
                          filters.POL = [];
                        }
                        const selectedPols = ArrayUtilities.addValueToArray(
                          filters.POL,
                          value
                        );

                        this.props.changePolOptions(
                          this.props.polOptions,
                          selectedPols
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.POL),
                          t("LABEL_POL"),
                          selectedPols,
                          true,
                          false
                        );
                      }}
                      onLabelRemove={(value: string) => {
                        this.removeLabelPol(value);

                        const selectedPols =
                          ArrayUtilities.removeValueFromArray(
                            filters.POL,
                            value
                          );

                        this.props.changePolOptions(
                          this.props.polOptions,
                          selectedPols
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.POL),
                          t("LABEL_POL"),
                          selectedPols.length > 0 ? selectedPols : null,
                          true,
                          false
                        );
                      }}
                      labels={this.state.labelPols}
                      hasSearch
                      multiSelect
                      options={this.props.displayedPolOptions}
                    />
                  </Accordion>
                  <Accordion accTitle={t("LABEL_POD")} openAccordion={false}>
                    <DropdownSearch
                      labelClassName={"sm-light-tag block float-left mb-1"}
                      className="w-full standard-input"
                      containerClassName="float-left width-full contents"
                      onSelect={(value: string) => {
                        const text = this.props.podOptions.find(
                          (x) => x.value == value
                        )?.item;

                        this.addLabelPod({ text: text, key: value });

                        if (!filters.POD) {
                          filters.POD = [];
                        }
                        const selectedPods = ArrayUtilities.addValueToArray(
                          filters.POD,
                          value
                        );

                        this.props.changePodOptions(
                          this.props.podOptions,
                          selectedPods
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.POD),
                          t("LABEL_POD"),
                          selectedPods,
                          true,
                          false
                        );
                      }}
                      onLabelRemove={(value: string) => {
                        this.removeLabelPod(value);

                        const selectedPods =
                          ArrayUtilities.removeValueFromArray(
                            filters.POD,
                            value
                          );

                        this.props.changePodOptions(
                          this.props.podOptions,
                          selectedPods
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.POD),
                          t("LABEL_POD"),
                          selectedPods.length > 0 ? selectedPods : null,
                          true,
                          false
                        );
                      }}
                      labels={this.state.labelPods}
                      hasSearch
                      multiSelect
                      options={this.props.displayedPodOptions}
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_SHIPPER")}
                    openAccordion={false}
                  >
                    <DropdownSearch
                      labelClassName={"sm-light-tag block float-left mb-1"}
                      className="w-full standard-input"
                      containerClassName="float-left width-full contents"
                      onSelect={(value: string) => {
                        const text = this.props.shipperOptions.find(
                          (x) => x.value == value
                        )?.item;

                        this.addLabelShipper({ text: text, key: value });

                        if (!filters.shippers) {
                          filters.shippers = [];
                        }
                        const selectedShippers = ArrayUtilities.addValueToArray(
                          filters.shippers,
                          value
                        );

                        this.props.changeShipperOptions(
                          this.props.shipperOptions,
                          selectedShippers
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.shippers),
                          t("LABEL_SHIPPER"),
                          selectedShippers,
                          true,
                          false
                        );
                      }}
                      onLabelRemove={(value: string) => {
                        this.removeLabelShipper(value);

                        const selectedShippers =
                          ArrayUtilities.removeValueFromArray(
                            filters.shippers,
                            value
                          );

                        this.props.changeShipperOptions(
                          this.props.shipperOptions,
                          selectedShippers
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.shippers),
                          t("LABEL_SHIPPER"),
                          selectedShippers.length > 0 ? selectedShippers : null,
                          true,
                          false
                        );
                      }}
                      labels={this.state.labelShippers}
                      hasSearch
                      multiSelect
                      options={this.props.displayedShipperOptions}
                    />
                  </Accordion>
                  <Accordion
                    accTitle={t("LABEL_CONSIGNEE")}
                    openAccordion={false}
                  >
                    <DropdownSearch
                      labelClassName={"sm-light-tag block float-left mb-1"}
                      className="w-full standard-input"
                      containerClassName="float-left width-full contents"
                      onSelect={(value: string) => {
                        const text = this.props.consigneeOptions.find(
                          (x) => x.value == value
                        )?.item;

                        this.addLabelConsignee({ text: text, key: value });

                        if (!filters.consignees) {
                          filters.consignees = [];
                        }
                        const selectedConsignees =
                          ArrayUtilities.addValueToArray(
                            filters.consignees,
                            value
                          );

                        this.props.changeConsigneeOptions(
                          this.props.consigneeOptions,
                          selectedConsignees
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.consignees),
                          t("LABEL_CONSIGNEE"),
                          selectedConsignees,
                          true,
                          false
                        );
                      }}
                      onLabelRemove={(value: string) => {
                        this.removeLabelConsignee(value);

                        const selectedConsignees =
                          ArrayUtilities.removeValueFromArray(
                            filters.consignees,
                            value
                          );

                        this.props.changeConsigneeOptions(
                          this.props.consigneeOptions,
                          selectedConsignees
                        );

                        this.onFilter(
                          nameof<BookingFilters>((item) => item.consignees),
                          t("LABEL_POL"),
                          selectedConsignees.length > 0
                            ? selectedConsignees
                            : null,
                          true,
                          false
                        );
                      }}
                      labels={this.state.labelConsignees}
                      hasSearch
                      multiSelect
                      options={this.props.displayedConsigneeOptions}
                    />
                  </Accordion>
                  {this.state.shouldShowFreightModeFilter ? (
                    <Accordion
                      accTitle={t("LABEL_TRANSPORT_TYPE")}
                      openAccordion={false}
                    >
                      <Checkbox
                        className="pb-1"
                        label={t("LABEL_AIR_FREIGHT")}
                        type="checkbox"
                        checked={filters.freightModes.includes(FreightMode.AI)}
                        onChange={() => {
                          this.onFilter(
                            nameof<BookingFilters>((item) => item.freightModes),
                            t("LABEL_TRANSPORT_TYPE"),
                            ArrayUtilities.toggleValueInArray(
                              filters.freightModes,
                              FreightMode.AI
                            ),
                            true,
                            false
                          );
                        }}
                      />
                      <Checkbox
                        className="pb-1"
                        label={t("LABEL_SEA_FREIGHT")}
                        type="checkbox"
                        checked={filters.freightModes.includes(FreightMode.SH)}
                        onChange={() => {
                          this.onFilter(
                            nameof<BookingFilters>((item) => item.freightModes),
                            t("LABEL_TRANSPORT_TYPE"),
                            ArrayUtilities.toggleValueInArray(
                              filters.freightModes,
                              FreightMode.SH
                            ),
                            true,
                            false
                          );
                        }}
                      />
                    </Accordion>
                  ) : null}

                  <Accordion
                    accTitle={t("LABEL_FILTER_CUSTOMS")}
                    openAccordion={false}
                  >
                    <Checkbox
                      className="pb-1"
                      value=""
                      label={t("TEXT_ALL")}
                      type="radio"
                      checked={
                        filters.isCustomsCleared === "" ||
                        typeof filters.isCustomsCleared === "undefined"
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.onFilter(
                          nameof<BookingFilters>(
                            (item) => item.isCustomsCleared
                          ),
                          "",
                          event.target.value,
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_NO")}
                      type="radio"
                      checked={filters.isCustomsCleared === false}
                      onChange={() =>
                        this.onFilter(
                          nameof<BookingFilters>(
                            (item) => item.isCustomsCleared
                          ),
                          t("LABEL_FILTER_CUSTOMS"),
                          false,
                          true,
                          false
                        )
                      }
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_YES")}
                      type="radio"
                      checked={filters.isCustomsCleared === true}
                      onChange={() =>
                        this.onFilter(
                          nameof<BookingFilters>(
                            (item) => item.isCustomsCleared
                          ),
                          t("LABEL_FILTER_CUSTOMS"),
                          true,
                          true,
                          false
                        )
                      }
                    />
                  </Accordion>

                  <Accordion
                    accTitle={t("LABEL_EXPORT_HAZ")}
                    openAccordion={false}
                  >
                    <Checkbox
                      className="pb-1"
                      value=""
                      label={t("TEXT_ALL")}
                      type="radio"
                      checked={
                        filters.isHazardous === "" ||
                        typeof filters.isHazardous === "undefined"
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.isHazardous),
                          "",
                          event.target.value,
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("TEXT_NO")}
                      type="radio"
                      checked={filters.isHazardous === false}
                      onChange={() =>
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.isHazardous),
                          t("LABEL_EXPORT_HAZ"),
                          false,
                          true,
                          false
                        )
                      }
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("TEXT_YES")}
                      type="radio"
                      checked={filters.isHazardous === true}
                      onChange={() =>
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.isHazardous),
                          t("LABEL_EXPORT_HAZ"),
                          true,
                          true,
                          false
                        )
                      }
                    />
                  </Accordion>

                  <Accordion
                    accTitle={t("LABEL_DELIVERY_ROUTE")}
                    openAccordion={false}
                  >
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_IMPORT_ICELAND")}
                      type="checkbox"
                      checked={filters.transportPaths.includes(
                        TransportPath.IMPORT
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.transportPaths),
                          t("LABEL_DELIVERY_ROUTE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.transportPaths,
                            TransportPath.IMPORT
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_EXPORT_ICELAND")}
                      type="checkbox"
                      checked={filters.transportPaths.includes(
                        TransportPath.EXPORT
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.transportPaths),
                          t("LABEL_DELIVERY_ROUTE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.transportPaths,
                            TransportPath.EXPORT
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_WITHIN_ICELAND")}
                      type="checkbox"
                      checked={filters.transportPaths.includes(
                        TransportPath.DOMESTIC
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.transportPaths),
                          t("LABEL_DELIVERY_ROUTE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.transportPaths,
                            TransportPath.DOMESTIC
                          ),
                          true,
                          false
                        );
                      }}
                    />
                    <Checkbox
                      className="pb-1"
                      label={t("LABEL_OUTSIDE_ICELAND")}
                      type="checkbox"
                      checked={filters.transportPaths.includes(
                        TransportPath.INTERNATIONAL
                      )}
                      onChange={() => {
                        this.onFilter(
                          nameof<BookingFilters>((item) => item.transportPaths),
                          t("LABEL_DELIVERY_ROUTE"),
                          ArrayUtilities.toggleValueInArray(
                            filters.transportPaths,
                            TransportPath.INTERNATIONAL
                          ),
                          true,
                          false
                        );
                      }}
                    />
                  </Accordion>
                </div>
                <div className="fixed md:absolute z-40 bottom-0 left-0 md:left-auto md:bottom-auto md:top-0 md:right-0 w-full md:w-auto listFilterLayout__filter-btn block xl:hidden md:px-5 md:py-3 leading-none">
                  <button
                    className="px-4 py-5 md:pt-3 pb-4 md:pb-2 w-full md:w-auto bg-lighter-gray uppercase focus:outline-none leading-none"
                    onClick={() =>
                      this.setState({ mobileFilter: !this.state.mobileFilter })
                    }
                  >
                    <span className="font-bold">
                      {!this.state.mobileFilter
                        ? t("LABEL_FILTER_SEARCH")
                        : t("LABEL_HIDE")}{" "}
                    </span>{" "}
                    {labels.length > 0 ? `(${labels.length})` : null}
                  </button>
                </div>
              </>
              {UserUtilities.userHasAccessToRoles(userProfile, [
                UserRole.REQUESTS,
              ]) ? (
                <ActionBar
                  haveNotClearedCustoms={this.selectedBookingsHaveCleared(
                    false
                  )}
                  haveClearedCustoms={this.selectedBookingsHaveCleared(true)}
                  references={this.state.selectedBookings}
                  show={this.state.selectedBookings.length > 1}
                  onClickCallback={() =>
                    this.setState({ selectedBookings: [] })
                  }
                />
              ) : null}
            </ListFilterLayout>
          </main>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      bookingsAll: state.BookingReducer.bookingsAll,
      bookings: state.BookingReducer.bookings,
      favoriteBookings: state.BookingReducer.favoriteBookings,
      userProfile: state.UserReducer.userProfile,
      isFetchingExcelBookings: state.BookingReducer.isFetchingExcelBookings,
      loadingBookingsInit: state.BookingReducer.loadingBookingsInit,
      loadingBookings: state.BookingReducer.loadingBookings,
      shippingTypeOptions: state.BookingReducer.shippingTypeOptions,
      displayedShippingTypeOptions:
        state.BookingReducer.displayedShippingTypeOptions,
      polOptions: state.BookingReducer.polOptions,
      displayedPolOptions: state.BookingReducer.displayedPolOptions,
      displayedPodOptions: state.BookingReducer.displayedPodOptions,
      displayedConsigneeOptions: state.BookingReducer.displayedConsigneeOptions,
      displayedShipperOptions: state.BookingReducer.displayedShipperOptions,
      podOptions: state.BookingReducer.podOptions,
      shipperOptions: state.BookingReducer.shipperOptions,
      consigneeOptions: state.BookingReducer.consigneeOptions,
    };
  },
  {
    fetchBookingListFrontEnd,
    fetchBookingList,
    changeShippingTypeOptions,
    changePodOptions,
    changePolOptions,
    changeShipperOptions,
    changeConsigneeOptions,
    getFavoriteBookings,
    getBookingsInExcel,
    updateWebSetting,
  }
)(Shipments);
