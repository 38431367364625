import React, { useEffect, useRef } from "react";
import { UserProfileViewModel } from "@assets/types";

import { connect } from "react-redux";
import { FaAngleDown, FaSpinner } from "react-icons/fa";

import CompanyPickerSearch from "@components/CompanyPicker/companyPickerSearch";

import { selectCompany } from "@state/actions";
import { AppState } from "@state/reducers/types";

type CompanyPickerProps = {
  isOpen: boolean;
  userProfile: UserProfileViewModel;
  changingCompany: boolean;
  close: Function;
  toggle: Function;
  selectCompany: Function;
};

const CompanyPicker: React.FC<CompanyPickerProps> = ({
  isOpen,
  userProfile,
  changingCompany,
  close,
  toggle,
  selectCompany,
}) => {
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (
      node.current &&
      e.target instanceof Node &&
      node.current.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    close();
  };

  useEffect(() => {
    // add when mounted
    if (isOpen) document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <div className="inline pt-1 md:py-0 leading-none" ref={node}>
      <span
        className="text-white px-1 font-normal hover:underline cursor-pointer truncate"
        onClick={() => {
          toggle();
        }}
      >
        ({userProfile.SelectedCompany.FullName}{" "}
        {changingCompany ? (
          <FaSpinner className="fa-spin inline" />
        ) : (
          <FaAngleDown className="inline" />
        )}
        )
      </span>
      <CompanyPickerSearch
        className="w-11/12 md:w-5/6"
        open={isOpen}
        onSelect={selectCompany}
        closeSearch={() => close()}
      />
    </div>
  );
};

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
    changingCompany: state.UserReducer.changingCompany,
  }),
  { selectCompany }
)(CompanyPicker);
